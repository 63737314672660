import React, { useEffect, useState } from 'react';
import {
  Container,
  Paper,
  Card,
  CardContent,
  CardHeader,
  Grid,
  TextField,
  Typography,
  Divider,
  Button,
  CardActions,
  LinearProgress
} from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Page from 'src/components/common/Page';
import GoBack from 'src/components/common/goBack';
import DropZone from 'src/components/common/dropZone';
import useStyles from './styles';
import * as actionCreators from 'src/store/actions';
import { connect, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import DropZoneBanner from 'src/components/common/dropZoneBanner';
import { encode, decode, isValid } from 'js-base64';
import SunEditor, { buttonList } from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

const ButtonCustomList = [
  ['undo', 'redo'],
  ['font', 'fontSize'],
  ['paragraphStyle', 'blockquote'],
  ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
  ['fontColor', 'hiliteColor'],
  ['align', 'list', 'lineHeight'],
  ['outdent', 'indent'],
  ['table', 'horizontalRule'],
  ['fullScreen', 'showBlocks', 'codeView'],
  ['preview', 'print'],
  ['removeFormat']
];
const ButtonCustomList1 = [
  ['undo', 'redo'],
  ['paragraphStyle', 'blockquote'],
  ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
  ['fontColor', 'hiliteColor'],
  ['align', 'list', 'lineHeight'],
  ['fullScreen', 'showBlocks', 'codeView'],
  ['preview', 'print'],
  ['removeFormat']
];
const sortedFontOptions = [
  'Almarai'
  // 'Almarai-Light',
  // 'Almarai-Medium',
  // 'Almarai-SemiBold',
  // 'Almarai-Bold'
].sort();
const validationSchema = Yup.object().shape({
  title: Yup.string().required('News Title is required'),
  description: Yup.string().required('News Description is required'),
  files_video: Yup.array()
    .min(1, 'Please Upload Atleast One File')
    .max(1)
    .required('Please Upload Atleast One File')
});

const validConsecutiveChar = new RegExp(
  '^(?!\\b([a-zA-Z0-9])\\1\\1\\1\\1\\1\\1\\1\\1\\1+\\b)'
);
const initialValues = {
  title: '',
  description: '',
  title_ar: '',
  description_ar: '',
  files: [''],
  files_video: []
};

const count = 0;

const addNewsComponent = ({ addNews, progress, news }) => {
  const { news_id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const classes = useStyles();
  const [files, setFiles] = useState([]);
  const [files_video, setFilesVideo] = useState([]);
  const [desc, setDesc] = useState();
  const [desc_ar, setDescAr] = useState();
  const [title, setTitle] = useState();
  const [title_ar, setTitleAr] = useState();
  console.log('RENDER :', count + 1);

  const { handleSubmit, register, errors, control, setValue } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'onChange'
  });
  const action = news_id ? 'edit' : 'add';

  useEffect(() => {
    if (news_id) {
      prefillForm();
    } else {
      setDesc('<p></p>');
      setDescAr('<p></p>');
    }
  }, []);

  const onSubmit = (data, e) => {
    if (
      !validConsecutiveChar.test(
        decode(data?.title)?.replace(/<[^>]+>/g, '')
      ) ||
      !validConsecutiveChar.test(
        decode(data?.title_ar)?.replace(/<[^>]+>/g, '')
      ) ||
      !validConsecutiveChar.test(
        decode(data?.description)?.replace(/<[^>]+>/g, '')
      ) ||
      !validConsecutiveChar.test(
        decode(data?.description_ar)?.replace(/<[^>]+>/g, '')
      )
    ) {
      return enqueueSnackbar('Remove Consecutive character', {
        variant: 'error'
      });
    }
    const body = {
      action,
      title: data.title,
      description: data.description,
      title_ar: data.title_ar,
      description_ar: data.description_ar,
      news_id
    };
    if (data.files_video[0].type.includes('video') && data.files[0] == '') {
      enqueueSnackbar('Select The Thumbnail Image', { variant: 'error' });
    } else {
      addNews(body, data.files, data.files_video)
        .then(msg => {
          console.log(msg);
          enqueueSnackbar(msg, {
            variant: 'success'
          });
          navigate(-1);
          return;
        })
        .catch(err => {
          console.error(err);
          enqueueSnackbar(err, { variant: 'error' });
        });
    }
  };

  const onError = (errors, e) => console.log(errors, e);

  const prefillForm = () => {
    if (news.length < 0) {
      enqueueSnackbar('No News Found. Please come-back again', {
        variant: 'error'
      });
      navigate(-1);
      return;
    }

    const newsToBeEditted = news.find(e => e.news_id == news_id);
    if (!newsToBeEditted) {
      enqueueSnackbar('Error Editting the News. Please come-back again', {
        variant: 'error'
      });
      navigate(-1);
      return;
    }
    setValue('title', newsToBeEditted.title);
    setValue('title_ar', newsToBeEditted.title_ar);
    setValue('description', newsToBeEditted.description);
    setValue('description_ar', decode(newsToBeEditted.description_ar));
    var thumbnail = newsToBeEditted.files.map(f => f.thumbnail);
    if (thumbnail[0] != null) {
      setFiles(newsToBeEditted.files.map(f => f.thumbnail));
      setValue(
        'files',
        newsToBeEditted.files.map(f => f.thumbnail)
      );
    }
    setDesc(
      isValid(newsToBeEditted.description)
        ? decode(newsToBeEditted.description)
        : decode(newsToBeEditted.description)
    );
    setDescAr(
      isValid(newsToBeEditted.description_ar)
        ? decode(newsToBeEditted.description_ar)
        : decode(newsToBeEditted.description_ar)
    );
    setTitle(
      isBase64(newsToBeEditted.title)
        ? decode(newsToBeEditted.title)
        : newsToBeEditted.title
    );
    setTitleAr(
      isBase64(newsToBeEditted.title_ar)
        ? decode(newsToBeEditted.title_ar)
        : newsToBeEditted.title_ar
    );
    var type = newsToBeEditted.files.map(f => f.type);
    var url = newsToBeEditted.files.map(f => f.file);
    setFilesVideo([
      {
        url: url[0],
        type: type[0],
        blob: null
      }
    ]);
    setValue('files_video', [
      {
        url: url[0],
        type: type[0],
        blob: null
      }
    ]);
  };
  const isBase64 = str => {
    if (str === '' || str.trim() === '') {
      return false;
    }
    try {
      return btoa(atob(str)) == str;
    } catch (err) {
      return false;
    }
  };
  const handleChange = content => {
    console.log(content);
  };
  const goBack = () => navigate(-1);
  return (
    <Page className={classes.root} title="News">
      <Container>
        <GoBack onClick={goBack} />
        <Card raised>
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <CardHeader title="Add News" />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <h4 style={{ paddingBottom: '14px' }}>News Title</h4>
                  <Controller
                    control={control}
                    name="title"
                    render={({ onChange }) => (
                      <SunEditor
                        setContents={title}
                        onChange={data => {
                          var sectionTitle = data.replace(/<[^>]+>/g, '');
                          if (!validConsecutiveChar.test(sectionTitle)) {
                            enqueueSnackbar(
                              "Don't Enter Consecutive character",
                              {
                                variant: 'error'
                              }
                            );
                          }
                          onChange(encode(data));
                        }}
                        setDefaultStyle="font-family: Almarai; font-size: 14px; color:#F5F8F8"
                        setOptions={{
                          buttonList: ButtonCustomList1,
                          defaultTag: 'div',
                          minHeight: '100px',
                          showPathLabel: false,
                          font: sortedFontOptions
                        }}
                      />
                    )}
                  />
                  {errors?.title?.message && (
                    <Typography
                      variant="caption"
                      component="p"
                      style={{ color: 'red' }}
                    >
                      {errors?.title?.message}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <h4 style={{ paddingBottom: '14px' }}>News Title(Ar)</h4>
                  <Controller
                    control={control}
                    name="title_ar"
                    render={({ onChange }) => (
                      <SunEditor
                        setContents={title_ar}
                        onChange={data => {
                          var sectionTitle = data.replace(/<[^>]+>/g, '');
                          if (!validConsecutiveChar.test(sectionTitle)) {
                            enqueueSnackbar(
                              "Don't Enter Consecutive character",
                              {
                                variant: 'error'
                              }
                            );
                          }
                          onChange(encode(data));
                        }}
                        setDefaultStyle="font-family: Almarai; font-size: 14px; color:#F5F8F8"
                        setOptions={{
                          buttonList: ButtonCustomList1,
                          defaultTag: 'div',
                          minHeight: '100px',
                          showPathLabel: false,
                          font: sortedFontOptions,
                          rtl: true
                        }}
                      />
                    )}
                  />
                  {errors?.title_ar?.message && (
                    <Typography
                      variant="caption"
                      component="p"
                      style={{ color: 'red' }}
                    >
                      {errors?.title_ar?.message}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <h4 style={{ paddingBottom: '14px' }}>News Description</h4>
                  <Controller
                    control={control}
                    name="description"
                    render={({ onChange }) => (
                      <SunEditor
                        setContents={desc}
                        onChange={data => {
                          var sectionTitle = data.replace(/<[^>]+>/g, '');
                          if (!validConsecutiveChar.test(sectionTitle)) {
                            enqueueSnackbar(
                              "Don't Enter Consecutive character",
                              {
                                variant: 'error'
                              }
                            );
                          }
                          onChange(
                            encode(
                              data
                                .replaceAll(
                                  '<table>',
                                  '<table style="border-spacing: 0px;width: 100%;">'
                                )
                                .replaceAll(
                                  '<td>',
                                  '<td style="border-bottom: 1px solid #827373;padding: 12px 0px;font-size: 14px;">'
                                )
                            )
                          );
                        }}
                        setDefaultStyle="font-family: Almarai; font-size: 14px; color:#F5F8F8"
                        setOptions={{
                          buttonList: ButtonCustomList,
                          defaultTag: 'div',
                          minHeight: '200px',
                          showPathLabel: false,
                          font: sortedFontOptions
                        }}
                      />
                    )}
                  />
                  {errors?.description?.message && (
                    <Typography
                      variant="caption"
                      component="p"
                      style={{ color: 'red' }}
                    >
                      {errors?.description?.message}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <h4 style={{ paddingBottom: '14px' }}>
                    News Description(Ar)
                  </h4>
                  <Controller
                    control={control}
                    name="description_ar"
                    render={({ onChange }) => (
                      <SunEditor
                        title="News Description(Ar)"
                        setContents={desc_ar}
                        onChange={data => {
                          var sectionTitle = data.replace(/<[^>]+>/g, '');
                          if (!validConsecutiveChar.test(sectionTitle)) {
                            enqueueSnackbar(
                              "Don't Enter Consecutive character",
                              {
                                variant: 'error'
                              }
                            );
                          }
                          onChange(
                            encode(
                              data
                                .replaceAll(
                                  '<table>',
                                  '<table style="border-spacing: 0px;width: 100%;">'
                                )
                                .replaceAll(
                                  '<td>',
                                  '<td style="border-bottom: 1px solid #827373;padding: 12px 0px;font-size: 14px;">'
                                )
                            )
                          );
                        }}
                        setDefaultStyle="font-family: Almarai; font-size: 14px; color:#F5F8F8"
                        setOptions={{
                          buttonList: ButtonCustomList,
                          defaultTag: 'div',
                          minHeight: '200px',
                          showPathLabel: false,
                          font: sortedFontOptions,
                          rtl: true
                        }}
                      />
                    )}
                  />
                  {errors?.description_ar?.message && (
                    <Typography
                      variant="caption"
                      component="p"
                      style={{ color: 'red' }}
                    >
                      {errors?.description_ar?.message}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="files"
                    render={({ value, ref }) => (
                      <DropZone
                        col={1}
                        onFilesChange={filesArray =>
                          setValue('files', filesArray, {
                            shouldValidate: true,
                            shouldDirty: true
                          })
                        }
                        initialFiles={files}
                        label="Upload thumbnail"
                        error={Boolean(errors?.description?.files)}
                        inputRef={ref}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="files_video"
                    render={({ value, ref }) => (
                      <DropZoneBanner
                        id="files_video"
                        col={1}
                        onFilesChange={filesVideo =>
                          setValue('files_video', filesVideo, {
                            shouldValidate: true,
                            shouldDirty: true
                          })
                        }
                        initialFiles={files_video}
                        label="Upload News Image/Video"
                        error={errors?.files_video?.message}
                        inputRef={ref}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}></Grid>
              </Grid>
            </CardContent>
            <Divider />
            {progress > 0 && (
              <LinearProgress variant="determinate" value={progress} />
            )}

            <CardActions>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={Boolean(progress > 0)}
              >
                Add News
              </Button>
            </CardActions>
          </form>
        </Card>
      </Container>
    </Page>
  );
};

const mapStateToProps = state => {
  return {
    progress: state.progress.fileProgress,
    news: state.news.news
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addNews: (body, files, files_video) =>
      dispatch(actionCreators.addNews(body, files, files_video))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(addNewsComponent);
