import React, { useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Button,
  List,
  ListItem,
  Collapse,
  makeStyles,
  Tooltip
} from '@material-ui/core';
import {
  ChevronDown as ExpandMore,
  ChevronUp as ExpandLess
} from 'react-feather';
import { HomeOutlined } from '@material-ui/icons';
const useStyles = makeStyles(theme => ({
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '10px 8px',
    textTransform: 'none',
    width: '100%'
  },
  icon: {
    marginRight: theme.spacing(1)
  },
  title: {
    marginRight: 'auto'
  },
  active: {
    color: theme.palette.primary.main,
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium
    },
    '& $icon': {
      color: theme.palette.primary.main
    }
  },
  nested: {
    paddingLeft: theme.spacing(4)
  }
}));

const NavItem = ({ className, href, icon: Icon, title, children, ...rest }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const expandList = () => {
    setOpen(!open);
  };
  return children?.length > 0 ? (
    <>
      <ListItem
        onClick={expandList}
        className={clsx(classes.item, className)}
        disableGutters
        {...rest}
      >
        <Button className={classes.button}>
          {Icon && <Icon className={classes.icon} size="20" />}
          <span className={classes.title}>{title}</span>
          {open ? <ExpandLess /> : <ExpandMore />}
        </Button>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List>
          {children.map((el, id) => (
            <ListItem
              key={id}
              className={clsx(classes.item, className)}
              disableGutters
              {...rest}
            >
              <Tooltip title={el.desc != undefined ? el.desc : el.title}>
                <Button
                  activeClassName={classes.active}
                  className={clsx(classes.button, classes.nested)}
                  component={RouterLink}
                  to={el.href}
                >
                  {el.icon && <el.icon className={classes.icon} size="20" />}

                  <span className={classes.title}>{el.title}</span>
                </Button>
              </Tooltip>
            </ListItem>
          ))}
        </List>
      </Collapse>
    </>
  ) : (
    <ListItem
      className={clsx(classes.item, className)}
      disableGutters
      {...rest}
    >
      <Button
        activeClassName={classes.active}
        className={classes.button}
        component={RouterLink}
        to={href}
      >
        {Icon && <Icon className={classes.icon} size="20" />}
        <span className={classes.title}>{title}</span>
      </Button>
    </ListItem>
  );
};

NavItem.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  title: PropTypes.string,
  children: PropTypes.array
};

export default NavItem;
