import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Chip,
  Container,
  Grid,
  makeStyles,
  Snackbar
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

import InfiniteScroll from 'react-infinite-scroll-component';
import Page from 'src/components/common/Page';
import Toolbar from './Toolbar';
import ProductCard from './ProductCard';
import Loader from 'src/components/common/loader';
import { connect } from 'react-redux';
import * as actionCreators from '../../../../store/actions';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  productCard: {
    height: '100%'
  },
  infiniteMsg: {
    margin: '24px 0px',
    textAlign: 'center'
  }
}));

const ProductList = ({
  loadProducts,
  clearProducts,
  products,
  deleteProduct,
  filteredProducts
}) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [isMsg, setIsMsg] = useState(false);
  const [msg, setMsg] = useState({
    msg: '',
    type: ''
  });
  const loader = useRef(null);

  useEffect(() => {
    init();
  }, []);

  const init = () => {
    setPage(0);
    clearProducts();
    loadMoreProducts('refresh');
  };

  // Callbacks from Component

  const newProductAdded = msg => {
    setMsg({
      msg: 'New Product Added',
      type: 'success'
    });
    setIsMsg(true);
    console.log('New Product Added');
    init();
  };

  const handleOnError = msg => {
    console.log(msg, 'From Index');
    setMsg({
      msg,
      type: 'error'
    });
    setIsMsg(true);
  };

  const deleteProductHandler = (product_id, status) => {
    deleteProduct({
      product_id,
      status: status
    }).then(msg => {
      setMsg({
        msg,
        type: 'success'
      });
      setIsMsg(true);
      init();
    });
  };

  const loadMoreProducts = (type = '') => {
    let PageNo = page + 1;
    if (type === 'refresh') {
      PageNo = 1;
      setHasMore(true);
      setPage(0);
    }
    console.log('Loading Products ' + JSON.stringify(PageNo));
    loadProducts('', PageNo)
      .then(value => {
        setPage(PageNo);
      })
      .catch(err => {
        console.log('Setting HAS MORE FALSE');
        setHasMore(false);
      });
  };

  return (
    <Page className={classes.root} title="Products">
      <Container maxWidth={false}>
        <Toolbar onFormSubmitted={newProductAdded} onError={handleOnError} />
        <Box mt={3}>
          {products?.length > 0 ? (
            <InfiniteScroll
              style={{ overflow: 'hidden' }}
              dataLength={products.length}
              next={loadMoreProducts}
              hasMore={hasMore}
              scrollableTarget="scroll"
              loader={
                <center>
                  <Chip
                    className={classes.infiniteMsg}
                    size="small"
                    color="default"
                    label="Loading..."
                  />
                </center>
              }
              endMessage={
                <center>
                  <Chip
                    className={classes.infiniteMsg}
                    size="small"
                    color="default"
                    label="All Products Have Been Loaded. "
                  />
                </center>
              }
            >
              <Grid
                container
                spacing={3}
                id="scroll"
                style={{
                  overflowY: 'scroll',
                  maxHeight: '800px'
                }}
              >
                {filteredProducts.map((product, id) => (
                  <Grid item key={id} lg={4} md={4} sm={12} xs={12}>
                    {product && (
                      <ProductCard
                        className={classes.productCard}
                        product={product}
                        onDeleteProduct={deleteProductHandler}
                      />
                    )}
                  </Grid>
                ))}
              </Grid>
            </InfiniteScroll>
          ) : (
            <Loader />
          )}
        </Box>
      </Container>
      <Snackbar
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        open={isMsg}
        autoHideDuration={3000}
        onClose={() => setIsMsg(false)}
      >
        <MuiAlert elevation={6} variant="filled" severity={msg.type}>
          {msg.msg}
        </MuiAlert>
      </Snackbar>
    </Page>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    loadProducts: (searchKey, page) =>
      dispatch(actionCreators.loadProducts(searchKey, page)),
    clearProducts: () => dispatch(actionCreators.clearProducts()),
    deleteProduct: body => dispatch(actionCreators.deleteProduct(body))
  };
};

const mapStateToProps = state => {
  // console.log('state :', state);
  return {
    products: state.products.products,
    filteredProducts: state.products.filteredProducts
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductList);
