import { Typography } from '@material-ui/core';
import React from 'react';
import PropType from 'prop-types';
const error = {
  color: '#f44336',
  margin: '6px'
};

const ErrorMessage = ({ message }) => (
  <Typography variant="caption" style={error}>
    {message}
  </Typography>
);

ErrorMessage.protoType = {
  message: PropType.string.isRequired
};

export default ErrorMessage;
