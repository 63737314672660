import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  LinearProgress,
  Switch
} from '@material-ui/core';
import { Trash2 as Delete } from 'react-feather';
import Loader from 'src/components/common/loader';
import ConfirmDialog from 'src/components/common/dialog';
import { connect } from 'react-redux';
import * as actionCreators from '../../../../store/actions';

const Results = ({
  loadCategories,
  changeCategoryStatus,
  filteredCategories,
  // Callback from Child to Parent
  onStatusChanged
}) => {
  const permissions = useSelector(state => state.permissions.permissions);

  useEffect(() => {
    init();
  }, []);

  const [loading, setLoading] = useState(false);
  const [itemToBeDeleted, setItemToBeDeleted] = useState('');
  const [open, setOpen] = useState(false);

  const init = () => {
    loadCategories('')
      .then(value => {})
      .catch(err => {});
  };

  const launchDialog = id => {
    setItemToBeDeleted(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleComfirm = () => {
    //Emit Event To Parent Component
    setOpen(false);
    changeStatus(itemToBeDeleted, '4');
  };

  const changeStatus = (cat_id, status) => {
    setLoading(true);
    const body = {
      category_id: cat_id,
      status
    };
    changeCategoryStatus(body)
      .then(message => {
        setLoading(false);
        onStatusChanged({ msg: message, type: 'success' });
      })
      .catch(err => {
        setLoading(false);
        onStatusChanged({
          msg: 'Error Deleting Category. Error: ' + err,
          type: 'error'
        });
      });
  };
  return filteredCategories?.length > 0 ? (
    <Card>
      <PerfectScrollbar>
        <Box>
          {loading && <LinearProgress />}
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Name (Arabic)</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody id="scrollableDiv">
              {filteredCategories?.length > 0 &&
                filteredCategories.map((category, index) => (
                  <TableRow hover key={index}>
                    <TableCell>{category.category_id}</TableCell>
                    <TableCell>{category.category_name}</TableCell>
                    <TableCell>{category.category_name_ar}</TableCell>
                    <TableCell>
                      <Switch
                        checked={category.status === '1' ? true : false}
                        onChange={e =>
                          changeStatus(
                            category.category_id,
                            e.target.checked ? '1' : '0'
                          )
                        }
                      />
                    </TableCell>
                     {permissions.content_manager == "1" || permissions.admin == "1"?<TableCell
                      style={{ cursor: 'pointer' }}
                      onClick={() => launchDialog(category.category_id)}
                    >
                      <Delete />
                    </TableCell>:""}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <ConfirmDialog
        open={open}
        message="Are you sure you want to Delete ? This process cannot be undone."
        onConfirmed={handleComfirm}
        handleClose={handleClose}
      />
    </Card>
  ) : (
    <Loader />
  );
};

const mapDispatchToProps = dispatch => {
  return {
    loadCategories: searchKey =>
      dispatch(actionCreators.loadPromoCategories(searchKey)),
    changeCategoryStatus: body =>
      dispatch(actionCreators.changePromoCategoryStatus(body))
  };
};

const mapStateToProps = state => {
  // console.log('state :', state);
  return {
    filteredCategories: state.promotion.filteredCategories,
    categories: state.promotion.categories
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Results);
