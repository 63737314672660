import axios from '../../services/axios';
import { Types } from 'src/store/actions/actionTypes';

/**
 * Load customers action
 */
export const loadProducts = (search = '', page) => {
  console.log('search :', search);
  return dispatch => {
    return new Promise((resolve, reject) => {
      // if (page == 1) dispatch(clearProducts());
      const body = {
        page: page,
        search: search
      };
      axios
        .post('get-products', JSON.stringify(body))
        .then(res => {
          if (res.data.success) {
            dispatch(setFilteredProducts(res.data.data));
            dispatch(setProducts(res.data.data));
            resolve(res.data.data);
          } else {
            reject();
          }
        })
        .catch(error => {
          console.log(error);
          if (error) {
            reject(new Error(error));
          }
        });
    });
  };
};

const uploadFiles = async files => {
  console.log(files);
  const fileUrls = [];

  for (let m = 0; m < files.length; m++) {
    try {
      const file = files[m];

      if (typeof file === 'string') {
        fileUrls.push({ file_type: 'image', file_url: file });
        continue;
      }
      console.log('Wont be logged');

      let formData = new FormData();
      formData.append('file', file);
      const resp = await axios.post(
        'https://api.ishro.com/v3/upload-file-google',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      );

      if (resp.data.success) {
        fileUrls.push(resp.data.results.files[0]);
        console.log(resp);
      } else {
        continue;
      }
    } catch (error) {
      console.log(error);
      continue;
    }
  }
  return fileUrls;
};

export const addProduct = (data, files) => async dispatch => {
  try {
    const fileResp = await uploadFiles(files);
    if (!fileResp) throw 'Error Uploading File';

    const resp = await axios.post(
      'add-product',
      JSON.stringify({
        ...data,
        files: fileResp
      })
    );
    if (resp.data.success) {
      return resp.data.message;
    } else {
      throw resp.data.message;
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const deleteProduct = body => async dispatch => {
  try {
    const resp = await axios.post(
      'change-product-status',
      JSON.stringify(body)
    );
    const { message, success } = resp.data;
    if (success) {
      return message;
    }
    throw message;
  } catch (error) {
    throw error;
  }
};

export const filterProducts = (search, products) => {
  console.log(products);

  const filteredList = products.filter(el =>
    el.title.toLowerCase().includes(search.toLowerCase())
  );
  console.log(filteredList);
  return dispatch => dispatch(setFilteredProducts(filteredList));
};

const setProducts = products => ({
  type: Types.SET_PRODUCTS,
  payload: { products }
});

const setFilteredProducts = filteredProducts => ({
  type: Types.SET_FILTERED_PRODUCTS,
  payload: { filteredProducts }
});

export const clearProducts = () => ({
  type: Types.CLEAR_PRODUCTS
});
