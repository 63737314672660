import { Types } from 'src/store/actions/actionTypes';

const initialState = {
  orders: [],
  filteredOrders: []
};

const orders = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case Types.SET_ORDERS:
      return {
        ...state,
        orders: state.orders.concat(payload.orders),
        filteredOrders: state.orders.concat(payload.orders)
      };
    case Types.SET_FILTERED_ORDERS:
      return {
        ...state,
        filteredNews: state.orders.filter(el =>
          el.order_status.toLowerCase().includes(payload.search.toLowerCase())
        )
      };
    case Types.CLER_ORDERS:
      return {
        ...state,
        orders: [],
        filteredOrders: []
      };
    default:
      return state;
  }
};

export default orders;
