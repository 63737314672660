import axios from '../../services/axios';
import { Types } from 'src/store/actions/actionTypes';

/**
 * Load customers action
 */
export const loadPromotions = (search = '', page) => {
  console.log('search :', search);
  return dispatch => {
    return new Promise((resolve, reject) => {
      // if (page == 1) dispatch(clearProducts());
      const body = {
        page: page,
        search: search
      };
      axios
        .post('get-promotions', JSON.stringify(body))
        .then(res => {
          if (res.data.success) {
            dispatch(setFilteredPromotions(res.data.data));
            dispatch(setPromotions(res.data.data));
            resolve(res.data.data);
          } else {
            reject(res.data.message);
          }
        })
        .catch(error => {
          console.log(error);
          if (error) {
            reject(new Error(error));
          }
        });
    });
  };
};

const uploadFiles = async files => {
  console.log(files);
  const fileUrls = [];

  for (let m = 0; m < files.length; m++) {
    try {
      const file = files[m];

      if (typeof file === 'string') {
        fileUrls.push({ file_type: 'image', file_url: file });
        continue;
      }
      console.log('Wont be logged');

      let formData = new FormData();
      formData.append('file', file);
      const resp = await axios.post(
        'https://api.ishro.com/v3/upload-file-google',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      );

      if (resp.data.success) {
        fileUrls.push(resp.data.results.files[0]);
        console.log(resp);
      } else {
        continue;
      }
    } catch (error) {
      console.log(error);
      continue;
    }
  }
  return fileUrls;
};

const uploadVideo = async files => {
  console.log('Files Uploaded', files);
  const fileUrls = [];

  for (let m = 0; m < files.length; m++) {
    try {
      const file = files[m];

      if (!file.blob && !file.url.includes('blob')) {
        fileUrls.push({ file_type: file.type, file_url: file.url });
        continue;
      }
      console.log("Won't be logged");

      let formData = new FormData();
      formData.append('file', file.blob);
      const resp = await axios.post(
        'https://api.ishro.com/v3/upload-file-google',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      );

      if (resp.data.success) {
        fileUrls.push(resp.data.results.files[0]);
        console.log(resp);
      } else {
        continue;
      }
    } catch (error) {
      console.log(error);
      continue;
    }
  }
  return fileUrls;
};

export const addPromotion = (
  data,
  draw_image,
  files,
  soldout_image,
  winner_image,
  action,
  promotion_id
) => async dispatch => {
  try {
    var videoFile;
    var soldout_imageFile;
    var winner_imageFile;
    const draw_imageFileResp = await uploadFiles(draw_image);
    if (!draw_imageFileResp) throw 'Error Uploading Draw Image File';

    const fileResp = await uploadFiles(files);
    if (!fileResp) throw 'Error Uploading Files';
    console.log(fileResp);
    console.log(draw_imageFileResp);

    if (soldout_image.length > 0) {
      soldout_imageFile = await uploadFiles(soldout_image);
      if (!soldout_imageFile) throw 'Error Uploading Draw Image File';
    }
    if (winner_image.length > 0) {
      winner_imageFile = await uploadFiles(winner_image);
      if (!winner_imageFile) throw 'Error Uploading Draw Image File';
    }
    const body = {
      action: action,
      promotion_id,
      ...data,
      draw_image: draw_imageFileResp[0].file_url,
      files: fileResp,
      soldout_image:
        soldout_imageFile != undefined ? soldout_imageFile[0].file_url : null,
      winner_image:
        winner_imageFile != undefined ? winner_imageFile[0].file_url : null
    };
    const resp = await axios.post('add-promotion', JSON.stringify(body));

    if (resp.data.success) {
      return resp.data.message;
    } else {
      throw resp.data.message;
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const deletePromotion = body => async dispatch => {
  try {
    const resp = await axios.post(
      'change-promotion-status',
      JSON.stringify(body)
    );
    const { message, success } = resp.data;
    if (success) {
      return message;
    }
    throw message;
  } catch (error) {
    throw error;
  }
};

export const markAsWinner = body => async dispatch => {
  try {
    const resp = await axios.post('mark-winner', JSON.stringify(body));
    const { message, success } = resp.data;
    if (success) {
      return message;
    }
    throw message;
  } catch (error) {
    throw error;
  }
};

export const filterPromotions = (search, promotions) => {
  console.log(promotions);

  const filteredList = promotions.filter(el =>
    el.title.toLowerCase().includes(search.toLowerCase())
  );
  console.log(filteredList);
  return dispatch => dispatch(setFilteredPromotions(filteredList));
};

const setPromotions = promotions => ({
  type: Types.SET_PROMOTIONS,
  payload: { promotions }
});

const setFilteredPromotions = filteredPromotions => ({
  type: Types.SET_FILTERED_PROMOTIONS,
  payload: { filteredPromotions }
});

export const clearPromotions = () => ({
  type: Types.CLEAR_PROMOTIONS
});
