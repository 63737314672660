import React, { useState, useRef, useEffect } from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  CardHeader,
  TextField
} from '@material-ui/core';
import Page from 'src/components/common/Page';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import AvatarPicker from './AvatarPicker';
import PhoneInput from 'react-phone-input-2';
import { useForm, Controller } from 'react-hook-form';
import 'react-phone-input-2/lib/material.css';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import axios from 'src/services/axios';
import { useSnackbar } from 'notistack';
import { saveToken, saveUserData, destroy } from 'src/services/authService';
import * as actionCreators from '../../../../store/actions';
import { useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  avatar: {
    height: 100,
    width: 100
  }
}));

const Phone = () => {
  const classes = useStyles();
  var user = JSON.parse(sessionStorage.getItem('ishro_user_data'));
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [mobile, setMobile] = useState();
  const [country_code, setCode] = useState();
  const [otp, setOtp] = useState();
  const [otpDiv, setOtpDiv] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onError = (errors, e) => {
    console.log(errors, e);
    setIsSubmitting(false);
  };

  const onVerify = async () => {
    setIsSubmitting(true);
    var startIndex = country_code.countryCode.length;
    var endIndex = mobile.length;
    var phone = mobile.substring(startIndex, endIndex);
    const body = {
      otp_code: otp,
      mobile: phone
    };
    try {
      const resp = await axios.post('verify-otp', JSON.stringify(body));
      const { success, message } = resp.data;
      if (success) {
        enqueueSnackbar(message, { variant: 'success' });
        saveUserData(resp.data.data);
        setOtpDiv(false);
        setIsSubmitting(false);
        return;
      }
      enqueueSnackbar(message, { variant: 'error' });
      setIsSubmitting(false);
      return;
    } catch (error) {
      enqueueSnackbar(error, { variant: 'error' });
      setIsSubmitting(false);
      return;
    }
  };

  const sendOtp = async () => {
    setOtp('');
    var startIndex = country_code.countryCode.length;
    var endIndex = mobile.length;
    var phone = mobile.substring(startIndex, endIndex);
    const body = {
      country_code: country_code.countryCode,
      mobile: phone
    };
    try {
      const resp = await axios.post('resend-otp', JSON.stringify(body));

      const { success, message } = resp.data;
      if (success) {
        enqueueSnackbar(message, { variant: 'success' });

        return;
      }
      enqueueSnackbar(message, { variant: 'error' });

      return;
    } catch (error) {
      enqueueSnackbar(error, { variant: 'error' });

      return;
    }
  };
  const onPhoneSubmit = async () => {
    setIsSubmitting(true);
    var startIndex = country_code.countryCode.length;
    var endIndex = mobile.length;
    var phone = mobile.substring(startIndex, endIndex);

    const body = {
      user_id: user.user_id,
      country_code: '+' + country_code.countryCode,
      mobile: phone
    };
    try {
      const resp = await axios.post('change-mobile', JSON.stringify(body));
      const { success, message } = resp.data;
      if (success) {
        enqueueSnackbar('Enter OTP', { variant: 'success' });
        setOtp('');
        setOtpDiv(true);
        setIsSubmitting(false);
        return;
      }
      enqueueSnackbar(message, { variant: 'error' });
      setIsSubmitting(false);
      return;
    } catch (error) {
      enqueueSnackbar(error, { variant: 'error' });
      setIsSubmitting(false);
      return;
    }
  };

  return (
    <div className={clsx(classes.root)}>
      <Card>
        <CardHeader title="Change Phone Number" />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
            style={{ display: otpDiv ? 'none' : 'block' }}
          >
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                label="Old Phone Number"
                name="oldPhone"
                value={user.country_code + ' ' + user.mobile}
                variant="outlined"
                disabled
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <PhoneInput
                country={'ae'}
                name="newPhone"
                value={mobile}
                onChange={phone => setMobile(phone)}
                inputStyle={{
                  marginTop: 'auto',
                  border: 'solid 1px #6d7d8b',
                  borderRadius: '10px',
                  height: '49px',
                  width: '100%',
                  outline: 'none'
                }}
                isValid={(value, country) => {
                  if (value.match(/12345/)) {
                    return 'Invalid value: ' + value + ', ' + country.name;
                  } else if (value.match(/1234/)) {
                    return false;
                  } else {
                    setCode(country);
                    return true;
                  }
                }}
              />
            </Grid>
          </Grid>

          <Grid
            container
            spacing={3}
            style={{ display: otpDiv ? 'block' : 'none' }}
          >
            <TextField
              fullWidth
              label="OTP"
              name="Otp"
              variant="outlined"
              value={otp}
              onChange={event => setOtp(event.target.value)}
            />
          </Grid>
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          {otpDiv ? (
            <>
              <Button
                color="primary"
                variant="contained"
                style={{ marginRight: '18px' }}
                onClick={onVerify}
              >
                Verify
              </Button>
              <Button
                onClick={() => sendOtp()}
                variant="contained"
                color="primary"
              >
                Resend OTP
              </Button>
            </>
          ) : (
            <Button color="primary" variant="contained" onClick={onPhoneSubmit}>
              Change
            </Button>
          )}
        </Box>
      </Card>
    </div>
  );
};

export default Phone;
