import { Types } from 'src/store/actions/actionTypes';

const initialState = {
  categories: [],
  filteredCategories: []
};

const categories = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case Types.SET_CATEGORIES:
      return {
        ...state,
        categories: payload.categories
      };
    case Types.SET_FILTERED_CATEGORIES:
      return {
        ...state,
        filteredCategories: payload.filteredCategories
      };
    case Types.CLEAR_CATEGORIES:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default categories;
