import thunk from 'redux-thunk';
import { createStore, applyMiddleware, combineReducers, compose } from 'redux';

import auth from './reducers/auth';
import progress from './reducers/progress';
import customer from './reducers/customer';
import reseller from './reducers/reseller';
import category from './reducers/categories';
import product from './reducers/products';
import promotion from './reducers/promotion';
import news from './reducers/news';
import orders from './reducers/orders';
import banners from './reducers/banners';
import ads from './reducers/ads';
import sponsors from './reducers/sponsors';
import dashboard from './reducers/dashboard';
import statistics from './reducers/statistics';
import countries from './reducers/countries';
import permissions from './reducers/permission';
import auction from './reducers/auction';

const reducer = combineReducers({
  user: auth,
  progress: progress,
  customers: customer,
  resellers: reseller,
  categories: category,
  products: product,
  promotion: promotion,
  news: news,
  orders: orders,
  banners: banners,
  ads: ads,
  sponsors: sponsors,
  dashboard: dashboard,
  statistics: statistics,
  countries: countries,
  permissions: permissions,
  auction:auction
});

const configureStore = () => {
  return createStore(
    reducer,
    compose(
      applyMiddleware(thunk)
      // window.__REDUX_DEVTOOLS_EXTENSION__ &&
      //   window.__REDUX_DEVTOOLS_EXTENSION__()
    )
  );
};

export const store = configureStore();
