import React, { useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Container,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Chip
} from '@material-ui/core';
import Page from 'src/components/common/Page';
import Toolbar from './Toolbar';
import InfiniteScroll from 'react-infinite-scroller';
import axios from 'src/services/axios';
import OrderCollapse from './OrderCollapse';
import moment from 'moment';
const ordersTable = () => {
  const [orders, setOrders] = useState({
    data: [],
    page: 0,
    hasMore: true,
    search: '',
    status: '',
    promotion_charity: '',
    from_date: '',
    to_date: '',
    user_id: '',
    total_orders: '0',
    total_revenue: '0'
  });
  // const [hasMore, setHasMore] = useState(true);

  //TODO: Implement useReducer for better results in refresh button.
  const loadOrders = async pageNumber => {
    const localPage = orders.page + 1;
    console.log(
      `Now Requesting Page ${localPage}, with search ${orders.search}`
    );
    const resp = await axios.post('get-orders', {
      page: localPage,
      order_status: orders.status,
      order_number: orders.search,
      promotion_charity: orders.promotion_charity,
      from_date: orders.from_date,
      to_date: orders.to_date,
      user_id: orders.user_id,
      payment_method_id: orders.payment_method_id
    });
    const { data, message, success, total_orders, total_revenue } = resp?.data;
    if (success) {
      setOrders({
        ...orders,
        data: [...orders.data, ...data],
        page: localPage,
        total_orders: total_orders,
        total_revenue: total_revenue
      });
      return;
    }
    setOrders({ ...orders, hasMore: false });
    console.log(message);
  };

  const handleSearch = (
    searchKey,
    status,
    promotionCharity,
    fromDate,
    toDate,
    userId,
    promotionMethod
  ) => {
    setOrders({
      data: [],
      hasMore: true,
      search: searchKey,
      status: status,
      promotion_charity: promotionCharity,
      page: 0,
      from_date: fromDate == null ? '' : moment(fromDate).format('YYYY-MM-DD'),
      to_date: toDate == null ? '' : moment(toDate).format('YYYY-MM-DD'),
      user_id: userId,
      payment_method_id: promotionMethod
    });
  };

  const callBack = (index, productIndex) => {
    if (productIndex != undefined) {
      var order = orders.data.filter(function(el, i) {
        return i == index;
      });
      order[0].products.splice(productIndex, 1);
    } else {
      orders.data.splice(index, 1);
    }
    setOrders({ ...orders });
  };

  return (
    <Page title="Customers">
      <Container maxWidth={false}>
        <Toolbar
          handleSearch={handleSearch}
          total_orders={orders.total_orders}
          total_revenue={orders.total_revenue}
        />
        <Box mt={3}>
          <Card>
            <PerfectScrollbar>
              <Box style={{ height: '80vh', overflow: 'auto' }}>
                {/* {orders.length <= 0 && <LinearProgress />} */}
                <InfiniteScroll
                  pageStart={0}
                  loadMore={loadOrders}
                  hasMore={orders.hasMore}
                  loader={
                    <center key={0}>
                      <Chip
                        style={{ margin: '24px 0px', textAlign: 'center' }}
                        size="small"
                        color="default"
                        label="Loading..."
                      />
                    </center>
                  }
                  useWindow={false}
                >
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Order No.</TableCell>
                        <TableCell style={{ minWidth: '164px' }}>
                          Order Status
                        </TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell>Grand Total</TableCell>
                        <TableCell>User Paid Amount</TableCell>
                        <TableCell>Discount Code</TableCell>
                        <TableCell>Payment Method</TableCell>
                        {/* <TableCell>Payment Ref. No.</TableCell> */}
                        <TableCell>Promotion Charity</TableCell>
                        <TableCell>Payment Status</TableCell>
                        <TableCell>Paid Via Point</TableCell>
                        <TableCell>User</TableCell>
                        <TableCell>Transfer</TableCell>
                        <TableCell>Quick Books</TableCell>
                      </TableRow>
                    </TableHead>
                    {/* {JSON.stringify(filteredOrders)} */}
                    <TableBody id="scrollableDiv">
                      {orders.data &&
                        orders.data.length > 0 &&
                        orders.data.map((order, id) => (
                          <OrderCollapse
                            key={id}
                            index={id}
                            order={order}
                            refresh={loadOrders}
                            callback={(index, productIndex) =>
                              callBack(index, productIndex)
                            }
                          />
                        ))}
                      {!orders.hasMore && (
                        <TableRow>
                          <TableCell colSpan={13}>
                            <center>
                              <Chip
                                style={{
                                  margin: '24px 0px',
                                  textAlign: 'center'
                                }}
                                size="small"
                                color="default"
                                label={
                                  orders.data.length > 0
                                    ? 'All Orders Have Been Loaded.'
                                    : 'No Orders Found'
                                }
                              />
                            </center>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </InfiniteScroll>
              </Box>
            </PerfectScrollbar>
          </Card>
        </Box>
      </Container>
    </Page>
  );
};

export default ordersTable;
