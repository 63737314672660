import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CustomerListView from './index';
const Favourite = () => { 
  return (
    <>     
     <CustomerListView userId={'4'}/>
   </>
  );
};

Favourite.propTypes = {
  className: PropTypes.string
};

export default Favourite;
