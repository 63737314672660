import { Types } from 'src/store/actions/actionTypes';

const initialState = {
  permissions: {}
};

const permissions = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case Types.SET_USER_PERMISSIONS:
      return {
        ...state,
        permissions: payload
      };
    case Types.CLEAR_USER_PERMISSIONS:
      return {
        ...state,
        permissions: {}
      };
    default:
      return state;
  }
};

export default permissions;
