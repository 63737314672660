import { Types } from 'src/store/actions/actionTypes';

const initialState = {
    statisticsDashboards: []
};

const statistics = (state = initialState, action) => {
  switch (action.type) {
    case Types.SET_STATISTICS_DASHBOARD:
      return {
        ...state,
        statisticsDashboards: action.payload.data
      };
    case Types.CLEAR_STATISTICS_DASHBOARD:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default statistics;
