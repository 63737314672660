import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { red, green } from '@material-ui/core/colors';
import {
  Typography,
  Paper,
  Grid,
  ButtonBase,
  LinearProgress,
  Box,
  Chip,
  TableBody,
  TableCell,
  Table,
  TableRow,
  Fab,
  Button,
  TextField
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import {
  DateRangeOutlined as DateIcon,
  CategoryRounded as CatIcon,
  SubtitlesRounded as TypeIcon,
  CreateRounded as EditIcon,
  DeleteForeverRounded as DeleteIcon,
  MovieCreationRounded as TicketIcon,
  NotificationsActiveRounded as NotifyIcon,
  RadioButtonCheckedRounded as ActiveIcon,
  WhatsApp,
  Message,
  Email,
  PhoneIphone,
  DoneAll,
  Close
} from '@material-ui/icons';
import GetAppIcon from '@material-ui/icons/GetApp';
import AttachMoney from '@material-ui/icons/AttachMoney';
import SmsIcon from '@material-ui/icons/Sms';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useConfirm } from 'material-ui-confirm';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useNavigate } from 'react-router';

import { decode, isValid } from 'js-base64';
import axios from 'src/services/axios';
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2
} from 'react-html-parser';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    margin: 'auto'
  },
  soldpaper: {
    padding: theme.spacing(2),
    margin: 'auto',
    border: '3px solid #dddddd',
    background: '#dddddd'
    // maxWidth: 500
  },
  image: {
    width: 300,
    height: 300
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%'
  },
  capitalize: {
    textTransform: 'capitalize',
    paddingRight: 4
  },
  chips: {
    marginRight: 6,
    marginTop: 6
  },
  redchips: {
    marginRight: 6,
    marginTop: 6,
    color: 'red',
    border: '1px solid red'
  },
  greenchips: {
    marginRight: 6,
    marginTop: 6,
    color: 'green',
    border: '1px solid green'
  },
  table: {
    width: 'max-content'
  },
  progressBar: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    borderRadius: 5,
    padding: 4
  },
  DeleteFab: {
    backgroundColor: red[300],
    color: 'white',
    width: '37px',
    height: '37px',
    '&:hover': {
      backgroundColor: red[500]
    }
  },
  CloseFab: {
    backgroundColor: red[300],
    color: 'white',
    width: '37px',
    height: '37px',
    '&:hover': {
      backgroundColor: red[500]
    }
  },
  DoneAllFab: {
    backgroundColor: green[500],
    color: 'white',
    width: '37px',
    height: '37px',
    '&:hover': {
      backgroundColor: green[500]
    }
  },
  EditFab: {
    color: 'white',
    width: '37px',
    height: '37px'
  },
  rightPanel: {
    borderLeft: '1.5px solid #e0e0e0',
    paddingLeft: 12
  },
  leftPanel: {
    paddingRight: 12
  },
  ticketsFab: {
    width: 'max-content',
    margin: 6
  }
}));

function AuctionCard({
  auction,
  index,
  onDeleteAuction,
  loadAuction,
  onNotification
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();
  const navigate = useNavigate();
  const permissions = useSelector(state => state.permissions.permissions);

  const progressData = () => {
    const per = parseInt(
      (parseInt(auction.sold_out_tickets) /
        parseInt(auction.total_tickets_alloted)) *
        100
    );
    return per;
  };

  const onEditHandler = () => {
    navigate(`/auction/addAuction/${auction.auction_id}`);
  };

  const onDeleteAuctionHandler = status => {
    confirm({
      description: `Are you sure you want to ${
        status !== '4' ? (status === '1' ? 'Activate' : 'Deactivate') : 'Delete'
      } this auction ?`
    })
      .then(msg => {
        console.log(msg);
        onDeleteAuction(auction.auction_id, status);
      })
      .catch(err => {
        console.log("User Cancelled Action. Don't delete Auction.", err);
      });
  };

  const bidsPage = e => {
    e.preventDefault();
    navigate(`/auction/bids/${auction.auction_id}`);
  };

  const markAsClosed = async auction_id => {
    try {
      confirm({
        description: `Are you sure you want to close this auction ?`
      })
        .then(msg => {
          console.log(msg);
          auctionClosed(auction_id);
        })
        .catch(err => {
          console.log("User Cancelled Action. Don't close Auction.", err);
        });
    } catch (error) {
      console.log(message);
      return;
    }
  };

  const auctionClosed = async auction_id => {
    {
      try {
        const resp = await axios.post(
          'mark-as-closed',
          JSON.stringify({
            auction_id: auction_id
          })
        );
        const { success, message } = resp.data;
        if (success) {
          enqueueSnackbar(message, { variant: 'success' });
          loadAuction();
          return;
        }
        console.log(message);
        return;
      } catch (error) {
        console.log(message);
        return;
      }
    }
  };

  const timeDiffCalc = (date1, auction_id) => {
    return (
      new Date(moment(date1).format('MM/DD/YYYY HH:mm:ss')).getTime() >
      new Date().getTime()
    );
  };
  return (
    <Draggable draggableId={auction?.position} index={index}>
      {provided => (
        <Paper
          elevation={3}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <Grid container spacing={2} zeroMinWidth style={{ padding: '20px' }}>
            <Grid item>
              <ButtonBase className={classes.image}>
                <img
                  className={classes.img}
                  alt="Draw Image"
                  src={auction?.auction_thumbnail_image}
                />
              </ButtonBase>
            </Grid>
            <Grid item xs={12} sm container>
              <Grid
                item
                md={8}
                container
                direction="column"
                className={classes.leftPanel}
              >
                <Typography gutterBottom variant="h3">
                  {auction?.title}
                </Typography>
                <Box>
                  <Grid container className={classes.chips}>
                    <Chip
                      className={classes.chips}
                      label={moment(auction?.draw_date).format('DD/MM/YYYY')}
                      variant="outlined"
                      color="primary"
                      icon={<DateIcon />}
                    />

                    <Chip
                      className={
                        timeDiffCalc(auction?.expiry_date, auction?.auction_id)
                          ? classes.greenchips
                          : classes.redchips
                      }
                      label={moment(auction?.expiry_date).format(
                        'DD/MM/YYYY HH:mm:ss'
                      )}
                      variant="outlined"
                      icon={
                        <DateIcon
                          style={
                            timeDiffCalc(
                              auction?.expiry_date,
                              auction?.auction_id
                            )
                              ? { color: 'green' }
                              : { color: 'red' }
                          }
                        />
                      }
                    />

                    <Chip
                      className={classes.chips}
                      color="primary"
                      variant="outlined"
                      label={auction?.category.category_name}
                      icon={<CatIcon />}
                    />
                  </Grid>
                </Box>
                <Box>
                  <Grid container className={classes.chips}>
                    {auction?.highlight === 'true' && (
                      <Chip
                        className={classes.chips}
                        label="Highlight"
                        size="small"
                      />
                    )}
                    {auction?.auction_charity === 'true' && (
                      <Chip
                        className={classes.chips}
                        label="Auction Charity"
                        size="small"
                      />
                    )}
                    {auction?.type != undefined && (
                      <Chip
                        className={classes.chips}
                        label={auction?.type}
                        size="small"
                      />
                    )}
                  </Grid>
                </Box>
                <Box mt={4}>
                  <Table
                    className={classes.table}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableBody>
                      <TableRow>
                        <TableCell align="left">
                          Min Price <br></br> (incl. Taxes)
                        </TableCell>
                        <TableCell align="left">
                          <Typography variant="h3">
                            {auction?.currency + ' ' + auction?.min_price}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">Min increment price </TableCell>
                        <TableCell align="left">
                          <Typography variant="h3">
                            {auction?.currency +
                              ' ' +
                              auction?.min_increment_price}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Box>
                <Grid item>
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.ticketsFab}
                    startIcon={<TicketIcon />}
                    onClick={bidsPage}
                  >
                    Bids
                  </Button>
                  <Button
                    variant="contained"
                    className={classes.ticketsFab}
                    style={{
                      backgroundColor:
                        auction?.status === '1' ? '#00e676' : 'secondary'
                    }}
                    startIcon={<ActiveIcon />}
                    onClick={() =>
                      onDeleteAuctionHandler(
                        auction?.status === '1' ? '0' : '1'
                      )
                    }
                  >
                    {auction?.status === '1' ? 'Active' : 'In-Active'}
                  </Button>
                </Grid>
              </Grid>
              <Grid
                item
                md={4}
                container
                direction="column"
                className={classes.rightPanel}
              >
                <Box mt={3}>
                  <Grid container justify="space-between">
                    <Typography gutterBottom variant="caption">
                      Auction Id
                    </Typography>
                    <Typography gutterBottom variant="subtitle2">
                      {auction?.auction_id}
                    </Typography>
                  </Grid>
                  <Grid container justify="space-between">
                    <Typography gutterBottom variant="caption">
                      Purchase Points
                    </Typography>
                    <Typography gutterBottom variant="subtitle2">
                      {auction?.purchase_points}
                    </Typography>
                  </Grid>
                  <Grid container justify="space-between">
                    <Typography gutterBottom variant="caption">
                      Total bids
                    </Typography>
                    <Typography gutterBottom variant="subtitle2">
                      {auction?.total_bids?.total}
                    </Typography>
                  </Grid>
                </Box>
                {permissions.content_manager == '1' ||
                permissions.admin == '1' ? (
                  <Box mt={8} className={classes.fabIcon}>
                    <Grid container justify="flex-end">
                      <Fab
                        color="primary"
                        aria-label="edit"
                        className={classes.EditFab}
                        onClick={onEditHandler}
                      >
                        <EditIcon />
                      </Fab>
                    </Grid>
                  </Box>
                ) : (
                  ''
                )}
                {permissions.content_manager == '1' ||
                permissions.admin == '1' ? (
                  <Box mt={2}>
                    <Grid container justify="flex-end">
                      <Fab
                        onClick={() => onDeleteAuctionHandler('4')}
                        color="primary"
                        aria-label="delete"
                        className={classes.DeleteFab}
                      >
                        <DeleteIcon />
                      </Fab>
                    </Grid>
                  </Box>
                ) : (
                  ''
                )}
                {permissions.content_manager == '1' ||
                permissions.admin == '1' ? (
                  <Box mt={2}>
                    <Grid container justify="flex-end">
                      {auction?.closed == '0' ? (
                        <Fab
                          onClick={() => markAsClosed(auction?.auction_id)}
                          className={classes.CloseFab}
                        >
                          <Close />
                        </Fab>
                      ) : (
                        <Fab className={classes.DoneAllFab}>
                          <DoneAll />
                        </Fab>
                      )}
                    </Grid>
                  </Box>
                ) : (
                  ''
                )}
                <Box mt={2}>
                  <Grid container justify="flex-end">
                    {/* <img
                      src={auction?.country?.icon}
                      height="34px"
                      width="34px"
                    /> */}
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        // </div>
      )}
    </Draggable>
  );
}

AuctionCard.propTypes = {
  className: PropTypes.string,
  auction: PropTypes.object,
  onDeleteAuction: PropTypes.func.isRequired
};
export default AuctionCard;
