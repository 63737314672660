import axios from '../../services/axios';
import { Types } from 'src/store/actions/actionTypes';

export const loadSponsors = (search, page) => {
  console.log('search :', search);
  return async dispatch => {
    try {
      const body = {
        page: page,
        search: search
      };
      const resp = await axios.post('get-sponsors', JSON.stringify(body));
      const { data, message, success } = resp?.data;
      if (success) {
        dispatch(setFilteredSponsors(data));
        dispatch(setSponsors(data));
        return { message, success, data };
      } else {
        dispatch(clearSponsors());
        dispatch(clearFilteredSponsors());
        throw { message, success };
      }
    } catch (error) {
      throw error;
    }
  };
};

const uploadFiles = async files => {
  console.log('Files Uploaded', files);
  const fileUrls = [];

  for (let m = 0; m < files.length; m++) {
    try {
      const file = files[m];

      if (typeof file === 'string') {
        fileUrls.push({ file_type: 'image', file_url: file });
        continue;
      }
      console.log('Wont be logged');

      let formData = new FormData();
      formData.append('file', file);
      const resp = await axios.post(
        'https://api.ishro.com/v3/upload-file-google',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      );

      if (resp.data.success) {
        fileUrls.push(resp.data.results.files[0]);
        console.log(resp);
      } else {
        continue;
      }
    } catch (error) {
      console.log(error);
      continue;
    }
  }
  return fileUrls;
};
export const addSponsor = (body, files, files_ar) => async dispatch => {
  try {
    const fileResp = await uploadFiles(files);
    if (!fileResp || fileResp.length <= 0)
      throw 'Error Uploading English Banner Image';
    const fileResp_ar = await uploadFiles(files_ar);
    if (!fileResp_ar || fileResp_ar.length <= 0)
      throw 'Error Uploading Arabic Banner Image';
    const resp = await axios.post(
      'add-sponsor',
      JSON.stringify({
        ...body,
        image: fileResp[0].file_url,
        file_type: fileResp[0].file_type,
        image_ar: fileResp_ar[0].file_url,
        file_type_ar: fileResp_ar[0].file_type
      })
    );
    const { message, success } = resp.data;
    if (success) {
      return message;
    }
    throw message;
  } catch (error) {
    throw error;
  }
};

export const changeSponsorStatus = body => async dispatch => {
  try {
    const resp = await axios.post(
      'change-sponsor-status',
      JSON.stringify(body)
    );
    const { message, success } = resp.data;
    if (success) {
      return message;
    } else {
      dispatch(clearSponsors());
      dispatch(clearFilteredSponsors());
      throw { message, success };
    }
    throw message;
  } catch (error) {
    console.log(error);
    if (error) {
      reject(new Error(error));
    }
  }
};

export const filterSponsors = (search, Sponsors) => {
  const filteredList = Sponsors.filter(el =>
    el.title.toLowerCase().includes(search.toLowerCase())
  );
  return dispatch => dispatch(setFilteredSponsors(filteredList));
};

const setFilteredSponsors = filteredSponsors => ({
  type: Types.SET_FILTERED_SPONSORS,
  payload: { filteredSponsors }
});

const setSponsors = sponsors => ({
  type: Types.SET_SPONSORS,
  payload: { sponsors }
});

export const clearSponsors = () => ({
  type: Types.CLEAR_SPONSORS
});

export const clearFilteredSponsors = () => ({
  type: Types.CLEAR_FILTERED_SPONSORS
});
