import axios from '../../services/axios';
import { Types } from 'src/store/actions/actionTypes';

/**
 * Load Categories action
 */
export const loadCategories = (search = '') => {
  console.log('search :', search);
  return dispatch => {
    return new Promise((resolve, reject) => {
      const body = {
        search: search
      };
      axios
        .post('get-product-category', JSON.stringify(body))
        .then(res => {
          const { data, success, message } = res.data;
          if (success) {
            dispatch(setCategories(data));
            dispatch(setFilteredCategories(data));
            resolve(message);
          } else {
            reject();
          }
        })
        .catch(error => {
          console.log(error);
          if (error) {
            reject(new Error(error));
          }
        });
    });
  };
};

export const changeCategoryStatus = body => async dispatch => {
  try {
    const resp = await axios.post(
      'change-product-category-status',
      JSON.stringify(body)
    );
    const { message, success } = resp.data;
    if (success) {
      dispatch(loadCategories(''));
      return message;
    }
    throw message;
  } catch (error) {
    throw error;
  }
};

export const addCategory = (name_en, name_ar) => async dispatch => {
  try {
    const resp = await axios.post(
      'add-product-category',
      JSON.stringify({ category_name_en: name_en, category_name_ar: name_ar })
    );
    const { message, success } = resp.data;
    if (success) {
      dispatch(loadCategories(''));
      return message;
    }
    throw message;
  } catch (error) {
    throw error;
  }
};

export const filterCategories = (search, categories) => {
  const filteredList = categories.filter(el =>
    el.category_name.toLowerCase().includes(search.toLowerCase())
  );
  // console.log(filteredList);
  return dispatch => dispatch(setFilteredCategories(filteredList));
};

export const setCategories = categories => {
  return {
    type: Types.SET_CATEGORIES,
    payload: { categories }
  };
};

const setFilteredCategories = filteredCategories => ({
  type: Types.SET_FILTERED_CATEGORIES,
  payload: { filteredCategories }
});

const clearCategories = () => ({
  type: Types.CLEAR_CATEGORIES,
  payload: {}
});
