import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import * as actionCreators from '../../../../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ColorPicker } from 'material-ui-color';
import DropZone from 'src/components/common/dropZone';

const useStyles = makeStyles(theme => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  }
}));

const validationSchema = Yup.object().shape({
  type_name: Yup.string()
    .matches(/^(?!(.)\1\1\1\1\1\1\1\1\1\1)/, {
      message: "Don't Enter Consecutive character",
      excludeEmptyString: true
    })
    .max(255)
    .required('Name is required'),
  tickets_per_unit: Yup.number().required('Tickets per Unit is Required')
});

const Toolbar = ({ className, onCatAdded, promotionType, ...rest }) => {
  const dispatch = useDispatch();
  const types = useSelector(state => state.promotion.types);
  const permissions = useSelector(state => state.permissions.permissions);
  const classes = useStyles();
  const [addCat, setAddCat] = useState(false);
  const [color, setColor] = useState('#000');
  const [action, setAction] = useState('add');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [imageEn, setImageEn] = React.useState([]);
  const [imageAr, setImageAr] = React.useState([]);
  const initialValues = {
    type_name: '',
    tickets_per_unit: '',
    image_en: '',
    image_ar: ''
  };

  const { handleSubmit, register, errors, control, setValue } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'onChange'
  });

  useEffect(() => {
    if (promotionType.type_id != undefined) {
      setAction('update');
      setAddCat(true);
      setTimeout(function() {
        setValue('type_name', promotionType.type_name);
        setValue('tickets_per_unit', promotionType.tickets_per_unit);
        setColor(promotionType.color_code);
        setImageEn([promotionType.image_en]);
        setImageAr([promotionType.image_ar]);
        setValue('image_en', [promotionType.image_en]);
        setValue('image_ar', [promotionType.image_ar]);
      }, 100);
    }
  }, [promotionType]);

  const handleSearch = e => {
    const searchKey = e.target.value;
    console.log(e.target.value);
    dispatch(actionCreators.filterPromoTypes(searchKey, types));
  };
  const openDialog = () => {
    setAction('add');
    setAddCat(true);
  };
  const handleClose = e => {
    console.log(e.target.value);
    setAddCat(false);
  };
  const onError = (errors, e) => console.log(errors, e);

  const onSubmit = (data, e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const body = { ...data };
    body.color_code = color;
    body.action = action;
    if (action == 'update') {
      body.type_id = promotionType.type_id;
    }
    dispatch(actionCreators.addPromoType(body))
      .then(msg => {
        setAddCat(false);
        setIsSubmitting(false);
        onCatAdded({ msg: msg, type: 'success' });
      })
      .catch(err => {
        console.log(err);
        setAddCat(false);
        setIsSubmitting(false);
        onCatAdded({
          msg: 'Error Adding Promotion Type. Error: ' + err,
          type: 'error'
        });
      });
  };
  const paletteObj = ['#00FFFF', '#0000ff', '#FFFFFF', '#800080', '#FFC0CB'];

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h3">Promotion Type</Typography>
        {permissions.content_manager == '1' || permissions.admin == '1' ? (
          <Button color="primary" variant="contained" onClick={openDialog}>
            Add Promotion Type
          </Button>
        ) : (
          ''
        )}
      </Box>

      <Box mt={3}>
        <Card>
          <CardContent>
            <Box maxWidth={500}>
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                placeholder="Search Promotion Types"
                variant="outlined"
                onChange={handleSearch}
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
      <Dialog
        open={addCat}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {action == 'add' ? 'Add' : 'Update'} Promotion Type
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <TextField
              error={Boolean(errors?.type_name?.message)}
              helperText={errors?.type_name?.message}
              inputRef={register}
              label="Promotion Type"
              name="type_name"
              type="text"
              variant="outlined"
              fullWidth
              style={{ paddingBottom: '18px' }}
              InputProps={{
                disableUnderline: true,
                autoComplete: 'off'
              }}
              InputLabelProps={{ shrink: true }}
            />

            <TextField
              error={Boolean(errors?.tickets_per_unit?.message)}
              helperText={errors.tickets_per_unit?.message}
              inputRef={register}
              label="Tickets per Unit"
              name="tickets_per_unit"
              type="number"
              variant="outlined"
              fullWidth
              style={{ paddingBottom: '18px' }}
              InputLabelProps={{ shrink: true }}
            />

            <Controller
              control={control}
              name="image_en"
              render={({ value, ref }) => (
                <DropZone
                  id="image_en"
                  col={1}
                  onFilesChange={filesArray =>
                    setValue('image_en', filesArray, {
                      shouldValidate: true,
                      shouldDirty: true
                    })
                  }
                  initialFiles={imageEn}
                  label="Upload English Images"
                  error={Boolean(errors?.image_en)}
                  inputRef={ref}
                />
              )}
            />

            <Controller
              control={control}
              name="image_ar"
              render={({ value, ref }) => (
                <DropZone
                  id="image_ar"
                  col={1}
                  onFilesChange={filesArray =>
                    setValue('image_ar', filesArray, {
                      shouldValidate: true,
                      shouldDirty: true
                    })
                  }
                  initialFiles={imageAr}
                  label="Upload Arabic Images"
                  error={Boolean(errors?.image_ar)}
                  inputRef={ref}
                />
              )}
            />
            <ColorPicker
              value={color}
              hideTextfield
              onChange={e => setColor(e.css.backgroundColor)}
              palette={paletteObj}
              name="color_code"
            />
            <DialogActions>
              <Button
                disabled={isSubmitting}
                onClick={handleClose}
                color="primary"
              >
                Cancel
              </Button>
              <Button
                disabled={isSubmitting}
                variant="contained"
                type="submit"
                color="primary"
              >
                {action == 'add' ? 'Add' : 'Update'}
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
  onCatAdded: PropTypes.func
};

export default Toolbar;
