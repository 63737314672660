import React from 'react';

// Material UI
import {
  Container,
  Box,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Page from 'src/components/common/Page';
import Toolbar from './Toolbar';
import BannersListing from './BannersListing';

const Banners = () => {
  return (
    <PerfectScrollbar>
      <Page title="Banners">
        <Container maxWidth={false}>
          <Toolbar />
          <Box mt={3}>
            <BannersListing />
          </Box>
        </Container>
      </Page>
    </PerfectScrollbar>
  );
};

export default Banners;
