import React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DashboardLayout from 'src/components/layouts/DashboardLayout';
import MainLayout from 'src/components/layouts/MainLayout';
import AccountView from 'src/components/pages/account/AccountView';
import ResellerView from 'src/components/pages/reseller';
import CategoriesView from 'src/components/pages/category/CategoryListView';
import DashboardView from 'src/components/pages/reports/DashboardView';
import LoginView from 'src/components/pages/auth/LoginView';
import NotFoundView from 'src/components/pages/errors/NotFoundView';
import ProductListView from 'src/components/pages/product/ProductListView';
import AddProduct from 'src/components/pages/product/addProduct';
import RegisterView from 'src/components/pages/auth/RegisterView';
import SettingsView from 'src/components/pages/settings/SettingsView';
import WebsiteOrder from 'src/components/pages/settings/SettingsView/WebsiteOrder';
// Promotion
import PromotionCategoryView from 'src/components/pages/promotion/category/index';
import PromotionTypeView from 'src/components/pages/promotion/type';
import PromotionCouponsView from 'src/components/pages/promotion/coupons';
import ManagePromotion from 'src/components/pages/promotion/managePromotion';
import AddPromotion from 'src/components/pages/promotion/managePromotion/addPromotion';
import TicketsList from 'src/components/pages/promotion/tickets/ticketsView';
// News
import NewsList from 'src/components/pages/news';
import AddNews from 'src/components/pages/news/addNews';
// Orders
import OrderListing from 'src/components/pages/orders/ordersTable';
// Banner
import Banners from 'src/components/pages/banner/Banners';
import AddBanner from 'src/components/pages/banner/addBanner';
// Banner
import SponsorsListing from 'src/components/pages/sponsor/SponsorsListing';
import AddSponsor from 'src/components/pages/sponsor/AddSponsor';
// Ads
import Ads from 'src/components/pages/ads/Ads';
import AddAd from 'src/components/pages/ads/AddAd';
// Statisticspermit
import Permit from 'src/components/pages/permit/Permit';
// Statistics
import Statistics from 'src/components/pages/statistics/Statistics';
// Marketing
import GoogleAds from 'src/components/pages/marketing/googleAds/GoogleAds';
import FacebookAds from 'src/components/pages/marketing/facebookAds/FacebookAds';
import InstagramAds from 'src/components/pages/marketing/instagramAds/InstagramAds';
import SnapchatAds from 'src/components/pages/marketing/snapchatAds/SnapchatAds';
import TikTokAds from 'src/components/pages/marketing/tiktokAds/TikTokAds';
import WhatsappAds from 'src/components/pages/marketing/whatsappAds/WhatsappAds';
// To be Removed Later in Production
import DropZone from 'src/components/common/dropZone';
// customers
import AllUsers from 'src/components/pages/customer/CustomerListView/AllUsers';
import CustomerListView from 'src/components/pages/customer/CustomerListView';
import Cart from 'src/components/pages/customer/CustomerListView/Cart';
import Favourite from 'src/components/pages/customer/CustomerListView/Favourite';
import IncompleteRegistration from 'src/components/pages/customer/CustomerListView/IncompleteRegistration';
import Customers from 'src/components/pages/customer/CustomerListView/Customers';
import ActiveCutomers from 'src/components/pages/customer/CustomerListView/ActiveCutomers';
import Resellers from 'src/components/pages/customer/CustomerListView/Resellers';
import NotACustomer from 'src/components/pages/customer/CustomerListView/NotACustomer';

// Aution
import AuctionCategoryView from 'src/components/pages/auction/category/index';
import ManageAuction from 'src/components/pages/auction/manageAuction';
import AddAuction from 'src/components/pages/auction/manageAuction/addAuction';
import BidsList from 'src/components/pages/auction/manageAuction/BidsList';
const routes = () => {
  const permissions = useSelector(state => state.permissions.permissions);

  const routesList = isLoggedIn => [
    {
      path: '/',
      element: isLoggedIn ? <DashboardLayout /> : <Navigate to="/login" />,
      children: [
        {
          path: 'account',
          element: <AccountView />
        },
        {
          path: 'permit',
          element: permissions.admin == '1' && <Permit />
        },
        {
          path: 'customers',
          element: (permissions.admin == '1' || permissions.social == '1') && (
            <CustomerListView />
          )
        },
        {
          path: 'users/allUsers',
          element: (permissions.admin == '1' || permissions.social == '1') && (
            <AllUsers />
          )
        },
        {
          path: 'users/Customers',
          element: (permissions.admin == '1' || permissions.social == '1') && (
            <Customers />
          )
        },
        {
          path: 'users/notBuyer',
          element: (permissions.admin == '1' || permissions.social == '1') && (
            <NotACustomer />
          )
        },
        {
          path: 'users/ActiveCutomers',
          element: (permissions.admin == '1' || permissions.social == '1') && (
            <ActiveCutomers />
          )
        },
        {
          path: 'users/Resellers',
          element: (permissions.admin == '1' || permissions.social == '1') && (
            <Resellers />
          )
        },
        {
          path: 'users/cart',
          element: (permissions.admin == '1' || permissions.social == '1') && (
            <Cart />
          )
        },
        {
          path: 'users/favorite',
          element: (permissions.admin == '1' || permissions.social == '1') && (
            <Favourite />
          )
        },
        {
          path: 'users/IncompleteRegistration',
          element: (permissions.admin == '1' || permissions.social == '1') && (
            <IncompleteRegistration />
          )
        },
        {
          path: 'resellers',
          element: (permissions.admin == '1' ||
            permissions.reseller == '1') && <ResellerView />
        },
        {
          path: 'resellers/:reseller_id',
          element: (permissions.admin == '1' ||
            permissions.reseller == '1') && <ResellerView />
        },
        {
          path: 'statistics',
          element: (permissions.admin == '1' || permissions.social == '1') && (
            <Statistics />
          )
        },
        {
          path: 'dashboard',
          element: <DashboardView />
        },
        {
          path: 'Marketing/googleAds',
          element:
            permissions.admin == '1' || permissions.social == '1' ? (
              <GoogleAds />
            ) : (
              ''
            )
        },
        {
          path: 'Marketing/facebookAds',
          element:
            permissions.admin == '1' || permissions.social == '1' ? (
              <FacebookAds />
            ) : (
              ''
            )
        },
        {
          path: 'Marketing/instagramAds',
          element:
            permissions.admin == '1' || permissions.social == '1' ? (
              <InstagramAds />
            ) : (
              ''
            )
        },
        {
          path: 'Marketing/tiktokAds',
          element:
            permissions.admin == '1' || permissions.social == '1' ? (
              <TikTokAds />
            ) : (
              ''
            )
        },
        {
          path: 'Marketing/snapchatAds',
          element:
            permissions.admin == '1' || permissions.social == '1' ? (
              <SnapchatAds />
            ) : (
              ''
            )
        },
        {
          path: 'Marketing/whatsappAds',
          element:
            permissions.admin == '1' || permissions.social == '1' ? (
              <WhatsappAds />
            ) : (
              ''
            )
        },
        {
          path: 'category',
          element: <CategoriesView />
        },
        {
          path: 'products',
          element: (permissions.admin == '1' || permissions.product == '1') && (
            <ProductListView />
          )
        },
        {
          path: 'products/addProduct',
          element: (permissions.admin == '1' || permissions.product == '1') && (
            <AddProduct />
          )
        },
        {
          path: 'products/addProduct/:product_id',
          element: (permissions.admin == '1' || permissions.product == '1') && (
            <AddProduct />
          )
        },
        {
          path: 'promotion/category',
          element: (permissions.admin == '1' ||
            permissions.promotion == '1') && <PromotionCategoryView />
        },
        {
          path: 'promotion/type',
          element: (permissions.admin == '1' ||
            permissions.promotion == '1') && <PromotionTypeView />
        },
        {
          path: 'promotion/coupon',
          element: (permissions.admin == '1' ||
            permissions.promotion == '1') && <PromotionCouponsView />
        },
        {
          path: 'promotion/manage',
          element: (permissions.admin == '1' ||
            permissions.promotion == '1') && <ManagePromotion />
        },
        {
          path: 'promotion/',
          element: (permissions.admin == '1' ||
            permissions.promotion == '1') && <AddPromotion />
        },
        {
          path: 'promotion/addPromotion/:promotion_id',
          element: (permissions.admin == '1' ||
            permissions.promotion == '1') && <AddPromotion />
        },
        {
          path: 'promotion/addPromotion/',
          element: (permissions.admin == '1' ||
            permissions.promotion == '1') && <AddPromotion />
        },
        {
          path: 'promotion/tickets/:promotion_id',
          element: <TicketsList />
        },
        {
          path: 'auction/category',
          element:
            permissions.admin == '1' || permissions.auction == '1' ? (
              <AuctionCategoryView />
            ) : (
              ''
            )
        },
        {
          path: 'auction/manage',
          element:
            permissions.admin == '1' || permissions.auction == '1' ? (
              <ManageAuction />
            ) : (
              ''
            )
        },
        {
          path: 'auction/',
          element:
            permissions.admin == '1' || permissions.auction == '1' ? (
              <AddAuction />
            ) : (
              ''
            )
        },
        {
          path: 'auction/addAuction/:auction_id',
          element:
            permissions.admin == '1' || permissions.auction == '1' ? (
              <AddAuction />
            ) : (
              ''
            )
        },
        {
          path: 'auction/addAuction/',
          element:
            permissions.admin == '1' || permissions.auction == '1' ? (
              <AddAuction />
            ) : (
              ''
            )
        },
        {
          path: 'auction/bids/:auction_id',
          element:
            permissions.admin == '1' || permissions.auction == '1' ? (
              <BidsList />
            ) : (
              ''
            )
        },
        {
          path: 'news',
          element: (permissions.admin == '1' ||
            permissions.content_manager == '1') && <NewsList />
        },
        {
          path: 'news/addNews',
          element: (permissions.admin == '1' ||
            permissions.content_manager == '1') && <AddNews />
        },
        {
          path: 'news/addNews/:news_id',
          element: (permissions.admin == '1' ||
            permissions.content_manager == '1') && <AddNews />
        },
        {
          path: 'orders',
          element:
            permissions.admin == '1' ||
            permissions.financial == '1' ||
            permissions.social == '1' ? (
              <OrderListing />
            ) : (
              ''
            )
        },
        {
          path: 'banners',
          element: (permissions.admin == '1' ||
            permissions.content_manager == '1') && <Banners />
        },
        {
          path: 'banners/addBanner',
          element: (permissions.admin == '1' ||
            permissions.content_manager == '1') && <AddBanner />
        },
        {
          path: 'banners/addBanner/:banner_id',
          element: (permissions.admin == '1' ||
            permissions.content_manager == '1') && <AddBanner />
        },
        {
          path: 'sponsors',
          element: (permissions.admin == '1' ||
            permissions.content_manager == '1') && <SponsorsListing />
        },
        {
          path: 'sponsors/addSponsor',
          element: (permissions.admin == '1' ||
            permissions.content_manager == '1') && <AddSponsor />
        },
        {
          path: 'sponsors/addSponsor/:id',
          element: (permissions.admin == '1' ||
            permissions.content_manager == '1') && <AddSponsor />
        },
        {
          path: 'ads',
          element: (permissions.admin == '1' ||
            permissions.content_manager == '1') && <Ads />
        },
        {
          path: 'ads/addAd',
          element: (permissions.admin == '1' ||
            permissions.content_manager == '1') && <AddAd />
        },
        {
          path: 'ads/addAd/:ad_id',
          element: (permissions.admin == '1' ||
            permissions.content_manager == '1') && <AddAd />
        },
        {
          path: 'settings',
          element: (permissions.admin == '1' ||
            permissions.content_manager == '1') && <SettingsView />
        },
        {
          path: 'websiteOrder',
          element: (permissions.admin == '1' ||
            permissions.content_manager == '1') && <WebsiteOrder />
        },
        { path: '*', element: <Navigate to="/404" /> },
        { path: 'drop', element: <DropZone></DropZone> }
      ]
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { path: 'login', element: <LoginView /> },
        { path: 'register', element: <RegisterView /> },
        { path: '404', element: <NotFoundView /> },
        { path: '/', element: <Navigate to="/dashboard" /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    }
  ];
  const user = JSON.parse(sessionStorage.getItem('ishro_user_data'));
  const token = sessionStorage.getItem('ishro_user_token');
  const isLoggedIn =
    (user?.user_type === 'admin' ||
      user?.user_type === 'reseller' ||
      user?.user_type === 'test') &&
    token;

  const routing = useRoutes(routesList(isLoggedIn));

  return routing;
};

export default routes;
