import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { red, green } from '@material-ui/core/colors';
import {
  Typography,
  Paper,
  Grid,
  ButtonBase,
  LinearProgress,
  Box,
  Chip,
  TableBody,
  TableCell,
  Table,
  TableRow,
  Fab,
  Button,
  TextField,
  IconButton,
  ButtonGroup
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import {
  DateRangeOutlined as DateIcon,
  CategoryRounded as CatIcon,
  SubtitlesRounded as TypeIcon,
  CreateRounded as EditIcon,
  DeleteForeverRounded as DeleteIcon,
  MovieCreationRounded as TicketIcon,
  NotificationsActiveRounded as NotifyIcon,
  RadioButtonCheckedRounded as ActiveIcon,
  WhatsApp,
  Message,
  Email,
  PhoneIphone,
  DoneAll,
  Close
} from '@material-ui/icons';
import GetAppIcon from '@material-ui/icons/GetApp';
import RemoveIcon from '@material-ui/icons/Remove';
import Plus from '@material-ui/icons/Add';
import AttachMoney from '@material-ui/icons/AttachMoney';
import SmsIcon from '@material-ui/icons/Sms';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useConfirm } from 'material-ui-confirm';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useNavigate } from 'react-router';
import NotifyDialog from './notifyDialog';
import WhatsAppDialog from './whatsAppDialog';
import MessageDialog from './messageDialog';
import SalesDialog from './SalesDialog';
import EmailDialog from './emailDialog';
import DownloadDialog from './downloadDialog';
import { decode, isValid } from 'js-base64';
import axios from 'src/services/axios';
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2
} from 'react-html-parser';
import ExportDialog from './ExportDialog';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    margin: 'auto'
  },
  soldpaper: {
    padding: theme.spacing(2),
    margin: 'auto',
    border: '3px solid #dddddd',
    background: '#dddddd'
    // maxWidth: 500
  },
  image: {
    width: 300,
    height: 300
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%'
  },
  capitalize: {
    textTransform: 'capitalize',
    paddingRight: 4
  },
  chips: {
    marginRight: 6,
    marginTop: 6
  },
  redchips: {
    marginRight: 6,
    marginTop: 6,
    color: 'red',
    border: '1px solid red'
  },
  greenchips: {
    marginRight: 6,
    marginTop: 6,
    color: 'green',
    border: '1px solid green'
  },
  table: {
    width: 'max-content'
  },
  progressBar: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    borderRadius: 5,
    padding: 4
  },
  DeleteFab: {
    backgroundColor: red[300],
    color: 'white',
    width: '37px',
    height: '37px',
    '&:hover': {
      backgroundColor: red[500]
    }
  },
  CloseFab: {
    backgroundColor: red[300],
    color: 'white',
    width: '37px',
    height: '37px',
    '&:hover': {
      backgroundColor: red[500]
    }
  },
  DoneAllFab: {
    backgroundColor: green[500],
    color: 'white',
    width: '37px',
    height: '37px',
    '&:hover': {
      backgroundColor: green[500]
    }
  },
  EditFab: {
    color: 'white',
    width: '37px',
    height: '37px'
  },
  rightPanel: {
    borderLeft: '1.5px solid #e0e0e0',
    paddingLeft: 12
  },
  leftPanel: {
    paddingRight: 12
  },
  ticketsFab: {
    width: 'max-content',
    margin: 6
  }
}));

function PromotionCard({
  promo,
  index,
  onDeletePromotion,
  loadPromotion,
  onNotification
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [displayCount, setDisplayCount] = useState(
    promo.display_sold_out_tickets
  );
  const [whatsAppOpen, setWhatsAppOpen] = useState(false);
  const [messageOpen, setMessageOpen] = useState(false);
  const [emailOpen, setEmailOpen] = useState(false);
  const [downloadOpen, setDownloadOpen] = useState(false);
  const [salesOpen, setSalesOpen] = useState(false);
  const [exportOpen, setExportOpen] = useState(false);
  const permissions = useSelector(state => state.permissions.permissions);

  const progressData = () => {
    const per = parseInt(
      (parseInt(promo.sold_out_tickets) /
        parseInt(promo.total_tickets_alloted)) *
        100
    );
    return per;
  };

  const onEditHandler = () => {
    navigate(`/promotion/addPromotion/${promo.promotion_id}`);
  };

  const onDeletePromotionHandler = status => {
    confirm({
      description: `Are you sure you want to ${
        status !== '4' ? (status === '1' ? 'Activate' : 'Deactivate') : 'Delete'
      } this promotion ?`
    })
      .then(msg => {
        console.log(msg);
        onDeletePromotion(promo.promotion_id, status);
      })
      .catch(err => {
        console.log("User Cancelled Action. Don't delete Promotion.", err);
      });
  };

  const ticketsPage = e => {
    e.preventDefault();
    navigate(`/promotion/tickets/${promo.promotion_id}`);
  };
  const promotionIdCal = e => {
    var str = '' + e;
    var pad = '0000';
    var ans = pad.substring(0, pad.length - str.length) + str;
    return ans;
  };
  const sendNotification = () => {
    confirm({
      description:
        'Are you sure you want to delete PROMOTION ? This process cannot be undone.'
    })
      .then(msg => {
        console.log(msg);
        onNotification(promo.promotion_id);
      })
      .catch(err => {
        console.log("User Cancelled Action. Don't delete Promotion.", err);
      });
  };

  const markAsClosed = async promotion_id => {
    try {
      confirm({
        description: `Are you sure you want to close this promotion ?`
      })
        .then(msg => {
          console.log(msg);
          promotionClosed(promotion_id);
        })
        .catch(err => {
          console.log("User Cancelled Action. Don't close Promotion.", err);
        });
    } catch (error) {
      console.log(message);
      return;
    }
  };

  const promotionClosed = async promotion_id => {
    {
      try {
        const resp = await axios.post(
          'mark-as-closed',
          JSON.stringify({
            promotion_id: promotion_id
          })
        );
        const { success, message } = resp.data;
        if (success) {
          enqueueSnackbar(message, { variant: 'success' });
          loadPromotion();
          return;
        }
        console.log(message);
        return;
      } catch (error) {
        console.log(message);
        return;
      }
    }
  };

  const ticketValChanged = async type => {
    {
      try {
        const resp = await axios.post(
          'promotion-boost-tickets',
          JSON.stringify({
            promotion_id: promo.promotion_id,
            type: type
          })
        );
        const { success, message } = resp.data;
        if (success) {
          if (type == 'increment') {
            setDisplayCount(displayCount + 1);
          } else {
            setDisplayCount(displayCount - 1);
          }
          enqueueSnackbar(message, { variant: 'success' });

          return;
        }
        console.log(message);
        return;
      } catch (error) {
        console.log(message);
        return;
      }
    }
  };

  const isBase64 = str => {
    if (str === '' || str.trim() === '') {
      return false;
    }
    try {
      return btoa(atob(str)) == str;
    } catch (err) {
      return false;
    }
  };

  const timeDiffCalc = (date1, promotion_id) => {
    return (
      new Date(moment(date1).format('MM/DD/YYYY HH:mm:ss')).getTime() >
      new Date().getTime()
    );
  };
  return (
    <Draggable draggableId={promo.position} index={index}>
      {provided => (
        <Paper
          className={promo.closed == '0' ? classes.paper : classes.soldpaper}
          elevation={3}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <Grid container spacing={2}>
            <Grid item>
              <ButtonBase className={classes.image}>
                <img
                  className={classes.img}
                  alt="Draw Image"
                  src={promo.draw_image}
                />
              </ButtonBase>
            </Grid>
            <Grid item xs={12} sm container>
              <Grid
                item
                md={8}
                container
                direction="column"
                className={classes.leftPanel}
              >
                <Typography
                  gutterBottom
                  variant="h3"
                  dangerouslySetInnerHTML={{
                    __html: isBase64(promo.title)
                      ? `${decode(promo.title).replace(/<[^>]+>/g, '')}`
                      : `${promo.title}`
                  }}
                ></Typography>
                <Box>
                  <Grid container className={classes.chips}>
                    <Chip
                      className={classes.chips}
                      label={moment(promo.draw_date).format('DD/MM/YYYY')}
                      variant="outlined"
                      color="primary"
                      icon={<DateIcon />}
                    />

                    <Chip
                      className={
                        timeDiffCalc(promo.expiry_date, promo.promotion_id)
                          ? classes.greenchips
                          : classes.redchips
                      }
                      label={moment(promo.expiry_date).format(
                        'DD/MM/YYYY HH:mm:ss'
                      )}
                      variant="outlined"
                      icon={
                        <DateIcon
                          style={
                            timeDiffCalc(promo.expiry_date, promo.promotion_id)
                              ? { color: 'green' }
                              : { color: 'red' }
                          }
                        />
                      }
                    />

                    <Chip
                      className={classes.chips}
                      color="primary"
                      variant="outlined"
                      label={promo?.category?.category_name}
                      icon={<CatIcon />}
                    />
                    <Chip
                      className={classes.chips}
                      color="primary"
                      variant="outlined"
                      label={promo?.category?.category_name}
                      icon={<TypeIcon />}
                    />
                  </Grid>
                </Box>
                <Box>
                  <Grid container className={classes.chips}>
                    {promo.highlight === 'true' && (
                      <Chip
                        className={classes.chips}
                        label="Highlight"
                        size="small"
                      />
                    )}
                    {promo.promotion_charity === 'true' && (
                      <Chip
                        className={classes.chips}
                        label="Promotion Charity"
                        size="small"
                      />
                    )}
                    {promo.ticket_type.type_name != undefined && (
                      <Chip
                        className={classes.chips}
                        label={promo.ticket_type.type_name}
                        size="small"
                      />
                    )}
                  </Grid>
                </Box>
                <Box mt={4}>
                  <Table
                    className={classes.table}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableBody>
                      <TableRow>
                        <TableCell align="left">
                          Ticket Price <br></br> (incl. Taxes)
                        </TableCell>
                        <TableCell align="left">
                          <Typography variant="h3">
                            {promo?.currency +
                              ' ' +
                              promo?.ticket_price_with_tax}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">Price Cost </TableCell>
                        <TableCell align="left">
                          <Typography variant="h3">
                            {promo?.currency + ' ' + promo?.worth_price}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <ButtonGroup
                          variant="contained"
                          className={classes.ticketsFab}
                          aria-label="outlined primary button group"
                        >
                          <IconButton
                            variant="contained"
                            style={{
                              backgroundColor: '#6d7d8b',
                              borderRadius: '0%'
                            }}
                            onClick={() => ticketValChanged('decrement')}
                          >
                            <RemoveIcon
                              style={{
                                color: 'white'
                              }}
                            />
                          </IconButton>
                          <Button>
                            {'Display Ticket Count '}
                            {'(' + displayCount + ')'}
                          </Button>

                          <IconButton
                            variant="contained"
                            style={{
                              backgroundColor: '#6d7d8b',
                              borderRadius: '0%'
                            }}
                            onClick={() => ticketValChanged('increment')}
                          >
                            <Plus
                              style={{
                                color: 'white'
                              }}
                            />
                          </IconButton>
                        </ButtonGroup>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Box>
                <Grid item>
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.ticketsFab}
                    startIcon={<TicketIcon />}
                    onClick={ticketsPage}
                  >
                    Tickets
                  </Button>
                  <Button
                    variant="contained"
                    className={classes.ticketsFab}
                    style={{
                      backgroundColor:
                        promo.status === '1' ? '#00e676' : 'secondary'
                    }}
                    startIcon={<ActiveIcon />}
                    onClick={() =>
                      onDeletePromotionHandler(promo.status === '1' ? '0' : '1')
                    }
                  >
                    {promo.status === '1' ? 'Active' : 'In-Active'}
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.ticketsFab}
                    startIcon={<PhoneIphone />}
                    onClick={() => setOpen(true)}
                  >
                    Notify
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.ticketsFab}
                    startIcon={<WhatsApp />}
                    onClick={() => setWhatsAppOpen(true)}
                  >
                    WhatsApp
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.ticketsFab}
                    startIcon={<SmsIcon />}
                    onClick={() => setMessageOpen(true)}
                  >
                    message
                  </Button>
                  {/* <Button
                    variant="contained"
                    color="secondary"
                    className={classes.ticketsFab}
                    startIcon={<Email />}
                    onClick={() => setEmailOpen(true)}
                  >
                    Email
                  </Button> */}
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.ticketsFab}
                    startIcon={<GetAppIcon />}
                    onClick={() => setDownloadOpen(true)}
                  >
                    Download
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.ticketsFab}
                    startIcon={<AttachMoney />}
                    onClick={() => setSalesOpen(true)}
                  >
                    Total Sales
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.ticketsFab}
                    startIcon={<GetAppIcon />}
                    onClick={() => setExportOpen(true)}
                  >
                    Export to Excel
                  </Button>
                </Grid>
              </Grid>
              <Grid
                item
                md={4}
                container
                direction="column"
                className={classes.rightPanel}
              >
                <LinearProgress
                  variant="determinate"
                  className={classes.progressBar}
                  value={progressData()}
                />
                <Grid container justify="space-between" alignItems="center">
                  <Typography gutterBottom variant="caption">
                    Tickets Sold
                  </Typography>
                  <Typography gutterBottom variant="subtitle2">
                    ({promo.sold_out_tickets}/{promo.total_tickets_alloted})
                  </Typography>
                </Grid>
                <Box mt={3}>
                  <Grid container justify="space-between">
                    <Typography gutterBottom variant="caption">
                      Promotion Id
                    </Typography>
                    <Typography gutterBottom variant="subtitle2">
                      {promotionIdCal(promo.promotion_id)}
                    </Typography>
                  </Grid>
                  <Grid container justify="space-between">
                    <Typography gutterBottom variant="caption">
                      Ishroins Awarded
                    </Typography>
                    <Typography gutterBottom variant="subtitle2">
                      {promo.purchase_points}
                    </Typography>
                  </Grid>
                  <Grid container justify="space-between">
                    <Typography gutterBottom variant="caption">
                      Tickets/units
                    </Typography>
                    <Typography gutterBottom variant="subtitle2">
                      {promo.tickets_per_unit}
                    </Typography>
                  </Grid>
                  <Grid container justify="space-between">
                    <Typography gutterBottom variant="caption">
                      Extra Tickets/units
                    </Typography>
                    <Typography gutterBottom variant="subtitle2">
                      {promo.extra_tickets}
                    </Typography>
                  </Grid>
                  <Grid container justify="space-between">
                    <Typography gutterBottom variant="caption">
                      Total Tickets For Printing
                    </Typography>
                    <Typography gutterBottom variant="subtitle2">
                      {promo.total_tickets_with_extra}
                    </Typography>
                  </Grid>
                </Box>
                {permissions.content_manager == '1' ||
                permissions.admin == '1' ? (
                  <Box mt={8} className={classes.fabIcon}>
                    <Grid container justify="flex-end">
                      <Fab
                        color="primary"
                        aria-label="edit"
                        className={classes.EditFab}
                        onClick={onEditHandler}
                      >
                        <EditIcon />
                      </Fab>
                    </Grid>
                  </Box>
                ) : (
                  ''
                )}
                {permissions.content_manager == '1' ||
                permissions.admin == '1' ? (
                  <Box mt={2}>
                    <Grid container justify="flex-end">
                      <Fab
                        onClick={() => onDeletePromotionHandler('4')}
                        color="primary"
                        aria-label="delete"
                        className={classes.DeleteFab}
                      >
                        <DeleteIcon />
                      </Fab>
                    </Grid>
                  </Box>
                ) : (
                  ''
                )}
                {permissions.content_manager == '1' ||
                permissions.admin == '1' ? (
                  <Box mt={2}>
                    <Grid container justify="flex-end">
                      {promo.closed == '0' ? (
                        <Fab
                          onClick={() => markAsClosed(promo.promotion_id)}
                          className={classes.CloseFab}
                        >
                          <Close />
                        </Fab>
                      ) : (
                        <Fab className={classes.DoneAllFab}>
                          <DoneAll />
                        </Fab>
                      )}
                    </Grid>
                  </Box>
                ) : (
                  ''
                )}
                <Box mt={2}>
                  <Grid container justify="flex-end">
                    <img
                      src={promo?.country?.icon}
                      height="34px"
                      width="34px"
                    />
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Grid>

          {open && promo && (
            <NotifyDialog
              promotion_id={promo.promotion_id}
              promotion_title={promo.title}
              open={open}
              onClose={() => setOpen(false)}
            ></NotifyDialog>
          )}
          {whatsAppOpen && promo && (
            <WhatsAppDialog
              promotion_id={promo.promotion_id}
              promotion_title={promo.title}
              open={whatsAppOpen}
              onClose={() => setWhatsAppOpen(false)}
            ></WhatsAppDialog>
          )}
          {messageOpen && promo && (
            <MessageDialog
              promotion_id={promo.promotion_id}
              promotion_title={promo.title}
              open={messageOpen}
              onClose={() => setMessageOpen(false)}
            ></MessageDialog>
          )}
          {emailOpen && promo && (
            <EmailDialog
              promotion_id={promo.promotion_id}
              promotion_title={promo.title}
              open={emailOpen}
              onClose={() => setEmailOpen(false)}
            ></EmailDialog>
          )}
          {downloadOpen && promo && (
            <DownloadDialog
              promotion_id={promo.promotion_id}
              promotion_title={promo.title}
              open={downloadOpen}
              onClose={() => setDownloadOpen(false)}
            ></DownloadDialog>
          )}
          {salesOpen && promo && (
            <SalesDialog
              promotion_id={promo.promotion_id}
              promotion_title={promo.title}
              currency={promo.currency}
              open={salesOpen}
              onClose={() => setSalesOpen(false)}
            ></SalesDialog>
          )}
          {exportOpen && promo && (
            <ExportDialog
              promotion_id={promo.promotion_id}
              promotion_title={promo.title}
              open={exportOpen}
              onClose={() => setExportOpen(false)}
            ></ExportDialog>
          )}
        </Paper>
        // </div>
      )}
    </Draggable>
  );
}

PromotionCard.propTypes = {
  className: PropTypes.string,
  promo: PropTypes.object,
  onDeletePromotion: PropTypes.func.isRequired
};
export default PromotionCard;
