import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Container,
  Chip,
  Grid,
  Card,
  makeStyles
} from '@material-ui/core';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSnackbar } from 'notistack';

import * as actionCreators from 'src/store/actions';
import Loader from 'src/components/common/loader';
import Page from 'src/components/common/Page';
import Toolbar from './Toolbar';
import useStyles from './styles';
import NewsCard from './newsCard';
import { SettingsSystemDaydream } from '@material-ui/icons';

const NewsList = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const allNews = useSelector(state => state.news.news);
  const filteredNews = useSelector(state => state.news.filteredNews);
  const { enqueueSnackbar } = useSnackbar();

  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [status, setStatus] = useState('Loading');
  useEffect(() => {
    init();
  }, []);

  const init = () => {
    loadMoreNews('refresh','');
  };

  const loadMoreNews = (type = '',searchKey='') => {
    let PageNo = page + 1;
    if (type === 'refresh') {
      PageNo = 1;
    }
    console.log('Loading News ' + JSON.stringify(PageNo));
    dispatch(actionCreators.loadNews(searchKey, PageNo))
      .then(value => {
        setPage(PageNo);
      })
      .catch(err => {
        PageNo === 1 && err == 'No news found'
          ? setStatus('No news found')
          : setStatus('No more news');
        console.log('Setting HAS MORE FALSE');
        setHasMore(false);
      });
  };

  const filterNewsHandler = search => {
    console.log(search);
    // dispatch(actionCreators.filterNews(search, allNews));
    loadMoreNews('refresh',search);
  };

  const onStatusChangeHandler = (news_id, status) => {
    dispatch(actionCreators.changeNewsStatus(news_id, status))
      .then(msg => {
        console.log(msg);
        enqueueSnackbar(msg, { variant: 'success' });
        init();
      })
      .catch(err => {
        console.error(err);
        enqueueSnackbar(err, { variant: 'error' });
      });
  };

  return (
    <Page className={classes.root} title="News">
      <Container maxWidth={false}>
        <Toolbar onFilter={filterNewsHandler} />
        <Box mt={3}>
          {filteredNews?.length > 0 ? (
            <InfiniteScroll
              style={{ overflow: 'hidden' }}
              dataLength={filteredNews.length}
              next={loadMoreNews}
              hasMore={hasMore}
              scrollableTarget="scroll"
              loader={
                <center>
                  <Chip
                    className={classes.infiniteMsg}
                    size="small"
                    color="default"
                    label="Loading..."
                  />
                </center>
              }
              endMessage={
                <center>
                  <Chip
                    className={classes.infiniteMsg}
                    size="small"
                    color="default"
                    label="All News Have Been Loaded. "
                  />
                </center>
              }
            >
              <Grid
                container
                spacing={3}
                id="scroll"
                style={{
                  overflowY: 'scroll',
                  maxHeight: '800px'
                }}
              >
                {filteredNews.map((news, id) => (
                  <Grid item key={id} lg={3} md={4} sm={12} xs={12}>
                    {news && (
                      <NewsCard
                        news={news}
                        onStatusChange={onStatusChangeHandler}
                      />
                    )}
                  </Grid>
                ))}
              </Grid>
            </InfiniteScroll>
          ) : (
            <Loader status={status === 'No news found' ? status : null} />
          )}
        </Box>
      </Container>
    </Page>
  );
};

export default NewsList;
