import axios from '../../services/axios';
import { Types } from 'src/store/actions/actionTypes';

/**
 * Load resellers action
 */
export const loadResellerOrder = (body) => {
  return dispatch => {
    dispatch(clearResellerUser());
    return new Promise((resolve, reject) => {      
      axios
        .post('resellers',  JSON.stringify(body))
        .then(res => {         
          if(body.page=="print"){
            resolve(res.data);
          }
          else{
            if (res.data.success) {
              dispatch(setResellerOrder(res.data.data));              
              dispatch(setTotalResellerOrder(res.data.highlights));
              dispatch(setResellerUser(res.data.user));  
              resolve(res.data)          
            } else {
              dispatch(setResellerUser(res.data.user));  
              dispatch(clearResellerOrder());
              dispatch(clearTotalResellerOrder());
            
            }
            resolve(res.data)
          }       
        })
        .catch(error => {
          dispatch(clearResellerOrder());
          dispatch(clearTotalResellerOrder());
          dispatch(clearResellerUser());
          console.log(error);
          if (error) {
            reject(new Error(error));
          }
        });
    });
  };
};



const setResellerOrder = users => ({
  type: Types.SET_RESELLER_ORDERS,
  payload: { users }
});

const clearResellerOrder = () => ({
  type: Types.CLEAR_RESELLER_ORDERS,
  payload: {}
});
const setResellerUser = user => ({
  type: Types.SET_RESELLER_USER,
  payload: { user }
});

const clearResellerUser = () => ({
  type: Types.CLEAR_RESELLER_USER,
  payload: {}
});
const setTotalResellerOrder = total => ({
  type: Types.SET_TOTAL_RESELLER_ORDERS,
  payload: { total }
});

const clearTotalResellerOrder = () => ({
  type: Types.CLEAR_TOTAL_RESELLER_ORDERS,
  payload: {}
});
