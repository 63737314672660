import React, { useEffect, useState, useRef } from 'react';
import {
  Container,
  Box,
  makeStyles,
  Button,
  Card,
  CardContent,
  CardHeader,
  LinearProgress,
  Divider,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Typography,
  InputAdornment,
  OutlinedInput,
  Select as MaterialSelect,
  FormControl,
  ListItemText,
  InputLabel,
  MenuItem,
  Chip
} from '@material-ui/core';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { useSnackbar } from 'notistack';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardDateTimePicker
} from '@material-ui/pickers';
import { useDispatch } from 'react-redux';
import DropZone from 'src/components/common/dropZone';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import Page from 'src/components/common/Page';
import DateFnsUtils from '@date-io/date-fns';
import { useNavigate, useParams } from 'react-router';
import GoBack from 'src/components/common/goBack';
import { encode, decode, isValid } from 'js-base64';
import { DevTool } from '@hookform/devtools';
import { connect } from 'react-redux';
import * as actionCreators from '../../../../store/actions';
import moment from 'moment';
import ReactHtmlParser from 'react-html-parser';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  selectBox: {
    menu: {
      zIndex: '2',
      backgroundColor: 'black',
      color: 'white'
    }
  },
  backFab: {
    margin: 0,
    top: 100,
    right: 20,
    bottom: 'auto',
    left: 'auto',
    position: 'fixed'
  },
  backFabIcon: {
    marginRight: theme.spacing(1)
  }
}));

const validationSchema = Yup.object().shape({
  title: Yup.string().required('Auction Title is Required'),
  title_ar: Yup.string().required('Auction Title is Required'),
  description: Yup.string()
    .min(13, 'Minimum 13 Digit is Required')
    .required('Description is Required'),
  description_ar: Yup.string()
    .min(13, 'Minimum 13 Digit s Required')
    .required('Description is Required'),
  level: Yup.object().required('Please Select User Level'),
  product_id: Yup.object().required('Please Select Product'),
  category_id: Yup.object().required('Please Select Category'),
  min_increment_price: Yup.string().required('Ticket Price is Required'),
  bid_limit: Yup.string().required('Worth Price is'),
  purchase_points: Yup.string().required('Purchase Points are Required'),
  draw_date: Yup.date().required('Draw Date is Required'),
  expiry_date: Yup.date().required('Draw Date is Required'),
  auction_thumbnail_image: Yup.array().required('Please Upload Draw Image'),
  files: Yup.array()
    .max(500)
    .required('Please Upload Atleast One File'),
  note: Yup.string().matches(/^(?!(.)\1\1\1\1\1\1\1\1\1\1)/, {
    message: "Don't Enter Consecutive character",
    excludeEmptyString: true
  })
});

// Data
const initialValues = {
  title: '',
  title_ar: '',
  description: '',
  description_ar: '',
  product_id: '',
  sponsor_id: '',
  category_id: '',
  type: { value: 'normal', label: 'Normal' },
  level: '',
  currency: { value: 'AE', label: 'AED' },
  bid_limit: '',
  min_increment_price: '',
  min_price: '',
  purchase_points: '',
  draw_date: new Date(),
  start_date: '',
  expiry_date: new Date(),
  note: '',
  highlight: true,
  show_timer: true,
  auction_thumbnail_image: [],
  auction_video: '',
  files: []
};

var categoriesOpt = [];
var typeOpt = [];
var productsOpt = [];
var sponsorsOpt = [];
var currencyOpt = [];
var levelOpt = [
  { value: '0', label: 'Normal' },
  { value: '1', label: 'VIP' }
];
const AddAuction = ({
  addAuction,
  loadProducts,
  loadSponsors,
  loadCountries,
  auctions,
  progress
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { auction_id } = useParams();
  const [drawImage, setDrawImage] = useState([]);
  const [files, setFiles] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [dealType, setDealTypeVal] = useState('normal');
  const [currencylbl, setCurrencyLbl] = useState();
  const dispatch = useDispatch();
  const [categories, setCategories] = useState([]);
  const [type, setType] = useState([]);
  const [level, setLevel] = useState([
    { value: '0', label: 'Normal' },
    { value: '1', label: 'VIP' }
  ]);
  const { handleSubmit, register, errors, control, setValue } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'onChange'
  });

  const promiseOptions = (inputValue = '') =>
    new Promise(resolve => {
      loadProducts(inputValue, '1')
        .then(options =>
          resolve(
            options.map(o => ({
              value: o?.product_id,
              label: decode(o?.title).replace(/<[^>]+>/g, '')
            }))
          )
        )
        .catch(err => console.log(err));
    });

  const sponsorsOptions = (inputValue = '') =>
    new Promise(resolve => {
      loadSponsors(inputValue, '1')
        .then(options =>
          resolve(
            options.data
              .filter(o => o.status === '1')
              .map(o => ({
                value: o?.id,
                label: o?.first_name+" "+o?.last_name
              }))
          )
        )
        .catch(err => console.log(err));
    });

  const currencyOptions = () =>
    new Promise(resolve => {
      loadCountries()
        .then(options => {
          resolve(
            options.data.map(o => ({
              value: o?.code,
              label: o?.currency
            }))
          );
        })
        .catch(err => console.log(err));
    });

  useEffect(() => {
    dispatch(actionCreators.loadAuctionCategories())
      .then(options => {
        categoriesOpt = [];
        options.map(o =>
          categoriesOpt.push({
            value: o?.category_id,
            label: o?.category_name
          })
        );
        setCategories(categoriesOpt);

        dispatch(actionCreators.loadProducts('', 1))
          .then(options => {
            productsOpt = [];
            options.map(p => {
              productsOpt.push({
                value: p?.product_id,
                label: ReactHtmlParser(decode(p?.title))
              });
            });
            dispatch(actionCreators.loadSponsors('', 1))
              .then(options => {
                sponsorsOpt = [];
                options.data
                  .filter(o => o.status === '1')
                  .map(p =>
                    sponsorsOpt.push({
                      value: p?.id,
                      label: p?.first_name+" "+p?.last_name
                    })
                  );
                dispatch(actionCreators.loadCountries())
                  .then(options => {
                    currencyOpt = [];
                    options.data.map(o =>
                      currencyOpt.push({
                        value: o?.code,
                        label: o?.currency
                      })
                    );
                    if (auction_id) {
                      prefillForm('dialog');
                    }
                  })
                  .catch(err => console.log(err));
              })
              .catch(err => console.log(err));
          })
          .catch(err => console.log(err));
      })
      .catch(err => console.log(err));

    typeOpt = [
      { value: 'upcoming', label: 'Upcoming' },
      { value: 'normal', label: 'Normal' },
      { value: 'bundle', label: 'Bundle' }
    ];
    setType(typeOpt);
    if (auction_id) {
      prefillForm();
    }
  }, []);

  //   Methods
  const onSubmit = (data, e) => {
    const body = { ...data };
    e.preventDefault();
    setIsSubmitting(true);

    var expiry_date = moment(body.expiry_date).format('YYYY-MM-DD HH:mm:ss');
    var start_date = moment(body.start_date).format('YYYY-MM-DD');
    body.expiry_date = expiry_date;
    body.start_date = start_date;
    body.level = body.level.value;
    delete body.auction_thumbnail_image;
    delete body.files;
    body.type = body.type.value;
    body.category_id = body.category_id.value;
    body.product_id = body.product_id.value;
    body.sponsor_id = body?.sponsor_id?.value;
    body.country = body?.currency?.value;
    body.currency = body?.currency?.label;
    body.highlight = body.highlight.toString();
    body.show_timer = body.show_timer.toString();

    addAuction(
      body,
      data.auction_thumbnail_image,
      data.files,
      auction_id ? 'update' : 'add',
      auction_id
    )
      .then(msg => {
        setIsSubmitting(false);
        console.log('Success');
        enqueueSnackbar(msg, {
          variant: 'success'
        });
        navigate(-1);
      })
      .catch(err => {
        setIsSubmitting(false);
        console.log('Error', err);
        enqueueSnackbar(err, {
          variant: 'error'
        });
      });
  };

  const onError = (errors, e) => {
    enqueueSnackbar('error', {
      variant: 'error'
    });
    console.log(errors, e);
  };

  const goBack = () => navigate(-1);
  const prefillForm = (load = '') => {
    const auction = auctions.find(el => el.auction_id == auction_id);
    if (!auction) {
      if (load == 'dialog') {
        enqueueSnackbar(
          'Error Editting the auction. Please go back and Try Again.',
          { variant: 'error' }
        );
        navigate(-1);
      }
      return;
    }

    // Start Filling up the prefill Values
    for (const field in initialValues) {
      if (field === 'type') {
        setValue(
          'type',
          typeOpt.find(el => el.value === auction.type)
        );

        setDealTypeVal(typeOpt.find(el => el.value === auction.type).value);
        continue;
      }
      if (field === 'level') {
        setValue(
          'level',
          levelOpt.find(el => el.value === auction.level)
        );
        continue;
      }
      if (field === 'category_id') {
        setValue(
          'category_id',
          categoriesOpt.find(el => el.value === auction.category_id)
        );
        continue;
      }

      if (field === 'product_id') {
        setValue(
          'product_id',
          productsOpt.find(el => el.value === auction.product_id)
        );
        continue;
      }
      if (field === 'sponsor_id') {
        setValue(
          'sponsor_id',
          sponsorsOpt.find(el => el.value === auction.sponsor_id)
        );
        continue;
      }
      if (field === 'currency') {
        setValue(
          'currency',
          currencyOpt.find(el => el.label === auction.currency)
        );
        setCurrencyLbl(auction.currency);
        continue;
      }
      if (field === 'description' || field === 'description_ar') {
        setValue(field, auction[field]);
        continue;
      }

      if (field === 'auction_thumbnail_image') {
        setDrawImage([auction.auction_thumbnail_image]);
        setValue('auction_thumbnail_image', [auction.auction_thumbnail_image]);
        continue;
      }

      if (field === 'files') {
        setFiles(auction.files.map(file => file.file));
        setValue(
          'files',
          auction.files.map(file => file.file)
        );
        continue;
      }
      if (field === 'highlight' || field === 'show_timer') {
        setValue(field, auction[field] === 'true' ? true : false);
        continue;
      }
      if (
        field === 'start_date' ||
        field === 'expiry_date' ||
        field === 'draw_date'
      ) {
        setValue(field, auction[field] ? new Date(auction[field]) : '');
        continue;
      }

      setValue(field, auction[field] ? auction[field] : '');
    }
  };
  const isBase64 = str => {
    if (str === '' || str.trim() === '') {
      return false;
    }
    try {
      return btoa(atob(str)) == str;
    } catch (err) {
      return false;
    }
  };

  const AuctionForm = (
    <Box mt={3}>
      <DevTool control={control} /> {/* set up the dev tool */}
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <Card>
          <CardHeader title="Auction Details" />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={12} xs={12}>
                <TextField
                  inputRef={register}
                  label="Auction Title"
                  name="title"
                  type="text"
                  variant="outlined"
                  fullWidth
                  error={Boolean(errors?.title?.message)}
                  helperText={errors.title?.message}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  inputRef={register}
                  label="Auction Title (Ar.)"
                  name="title_ar"
                  type="text"
                  variant="outlined"
                  fullWidth
                  error={Boolean(errors?.title_ar?.message)}
                  helperText={errors.title_ar?.message}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  inputRef={register}
                  label="Description *"
                  name="description"
                  type="text"
                  variant="outlined"
                  fullWidth
                  error={Boolean(errors?.description?.message)}
                  helperText={errors.description?.message}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  inputRef={register}
                  label="Description(Ar)"
                  name="description_ar"
                  type="text"
                  variant="outlined"
                  fullWidth
                  error={Boolean(errors?.description_ar?.message)}
                  helperText={errors.description_ar?.message}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <Typography>Type</Typography>
                <Controller
                  name="type"
                  control={control}
                  render={({ onChange, value, name, ref }) => (
                    <Select
                      styles={classes.selectBox}
                      inputRef={ref}
                      options={type}
                      value={type.find(c => c.value === dealType)}
                      onChange={val => setDealType(val)}
                    />
                  )}
                />
              </Grid>

              <Grid item md={4} xs={12}>
                <Typography>Auction Category</Typography>
                <Controller
                  name="category_id"
                  options={categories}
                  control={control}
                  as={Select}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <Typography>User level</Typography>
                <Controller
                  name="level"
                  options={level}
                  control={control}
                  as={Select}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <Typography>Select Product</Typography>
                <Controller
                  name="product_id"
                  cacheOptions
                  defaultOptions
                  loadOptions={promiseOptions}
                  control={control}
                  as={<AsyncSelect styles={classes.selectBox} />}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <Typography>Select Sponsor</Typography>
                <Controller
                  name="sponsor_id"
                  cacheOptions
                  defaultOptions
                  isClearable
                  loadOptions={sponsorsOptions}
                  control={control}
                  as={AsyncSelect}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <Typography>Select Currency</Typography>
                <Controller
                  name="currency"
                  cacheOptions
                  defaultOptions
                  isClearable
                  loadOptions={currencyOptions}
                  control={control}
                  as={AsyncSelect}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <Controller
                  name="highlight"
                  control={control}
                  render={({ value }) => (
                    <FormControlLabel
                      name="highlight"
                      onChange={e => setValue('highlight', e.target.checked)}
                      control={<Checkbox checked={value} />}
                      label="Highlight"
                    />
                  )}
                />
                <Controller
                  name="show_timer"
                  control={control}
                  render={({ value }) => (
                    <FormControlLabel
                      name="show_timer"
                      onChange={e => setValue('show_timer', e.target.checked)}
                      control={<Checkbox checked={value} />}
                      label="Show Timer"
                    />
                  )}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <br></br>
        <Card>
          <CardHeader title="Details" />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={4} xs={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Controller
                    name="start_date"
                    control={control}
                    render={({ ref, ...rest }) => (
                      <KeyboardDatePicker
                        fullWidth
                        inputVariant="outlined"
                        InputLabelProps={{ shrink: true }}
                        variant="dialog"
                        format="dd/MM/yyyy"
                        label="Start Date"
                        error={Boolean(errors?.start_date?.message)}
                        helperText={errors.start_date?.message}
                        KeyboardButtonProps={{
                          'aria-label': 'change date'
                        }}
                        {...rest}
                      />
                    )}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item md={4} xs={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Controller
                    name="draw_date"
                    control={control}
                    render={({ ref, ...rest }) => (
                      <KeyboardDatePicker
                        fullWidth
                        inputVariant="outlined"
                        variant="dialog"
                        minDate={new Date()}
                        format="dd/MM/yyyy"
                        label="Maximum Draw Date"
                        error={Boolean(errors?.draw_date?.message)}
                        helperText={errors.draw_date?.message}
                        KeyboardButtonProps={{
                          'aria-label': 'change date'
                        }}
                        {...rest}
                      />
                    )}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item md={4} xs={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Controller
                    name="expiry_date"
                    control={control}
                    render={({ ref, ...rest }) => (
                      <KeyboardDateTimePicker
                        fullWidth
                        ampm={false}
                        inputVariant="outlined"
                        variant="dialog"
                        minDate={new Date()}
                        format="dd/MM/yyyy HH:mm:ss"
                        label="Expiry Date"
                        error={Boolean(errors?.expiry_date?.message)}
                        helperText={errors.expiry_date?.message}
                        KeyboardButtonProps={{
                          'aria-label': 'change date'
                        }}
                        {...rest}
                      />
                    )}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item md={4} xs={6}>
                <TextField
                  error={Boolean(errors?.min_price?.message)}
                  helperText={errors.min_price?.message}
                  inputRef={register}
                  label="Min Price"
                  name="min_price"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {currencylbl}
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>{' '}
              <Grid item md={4} xs={6}>
                <TextField
                  error={Boolean(errors?.min_increment_price?.message)}
                  helperText={errors.min_increment_price?.message}
                  inputRef={register}
                  label="Min Increment Price"
                  name="min_increment_price"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {currencylbl}
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid item md={4} xs={6}>
                <TextField
                  error={Boolean(errors?.bid_limit?.message)}
                  helperText={errors.bid_limit?.message}
                  inputRef={register}
                  name="bid_limit"
                  variant="outlined"
                  fullWidth
                  label="Bid Limit"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {currencylbl}
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid item md={4} xs={6}>
                <TextField
                  error={Boolean(errors?.purchase_points?.message)}
                  helperText={errors.purchase_points?.message}
                  inputRef={register}
                  name="purchase_points"
                  type="number"
                  variant="outlined"
                  fullWidth
                  label="Purchase Points"
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <br></br>
        <Card>
          <CardHeader title="Auction Thumbnail Image" />
          <Divider />
          <CardContent>
            <Controller
              control={control}
              name="auction_thumbnail_image"
              render={({ value, ref }) => (
                <DropZone
                  col={1}
                  onFilesChange={filesArray =>
                    setValue('auction_thumbnail_image', filesArray, {
                      shouldValidate: true,
                      shouldDirty: true
                    })
                  }
                  initialFiles={drawImage}
                  label="Upload Auction Thumbnail Image"
                  error={errors?.auction_thumbnail_image?.message}
                  inputRef={ref}
                />
              )}
            />
          </CardContent>
        </Card>
        <br></br>
        <Card>
          <CardHeader title="Upload Files" />
          <Divider />
          <CardContent>
            <Controller
              control={control}
              name="files"
              render={({ value, ref }) => (
                <DropZone
                  id="file2"
                  col={1}
                  onFilesChange={filesArray =>
                    setValue('files', filesArray, {
                      shouldValidate: true,
                      shouldDirty: true
                    })
                  }
                  initialFiles={files}
                  label="Upload Images"
                  error={errors?.files?.message}
                  inputRef={ref}
                />
              )}
            />
          </CardContent>
        </Card>
        <br></br>
        <Card>
          <CardHeader title="Video Link" />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={12} xs={12}>
                <TextField
                  error={Boolean(errors?.auction_video?.message)}
                  helperText={errors.auction_video?.message}
                  inputRef={register}
                  label="Auction Video"
                  name="auction_video"
                  type="text"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <br></br>
        <Card>
          <CardHeader title="Note" />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={12} xs={12}>
                <TextField
                  inputRef={register}
                  label="Note"
                  name="note"
                  type="text"
                  variant="outlined"
                  fullWidth
                  error={Boolean(errors?.note?.message)}
                  helperText={errors.note?.message}
                />
              </Grid>
            </Grid>
          </CardContent>
          {progress || isSubmitting ? (
            <LinearProgress variant="determinate" progress={progress} />
          ) : null}
          <Box display="flex" justifyContent="flex-end" p={2}>
            <Button
              disabled={isSubmitting}
              variant="contained"
              type="submit"
              color="primary"
            >
              {progress
                ? 'Submitting'
                : auction_id
                ? 'Update Auction'
                : 'Add Auction'}
            </Button>
          </Box>
        </Card>
      </form>
    </Box>
  );

  return (
    <Page className={classes.root} title="Settings">
      <Container maxWidth="lg">
        <GoBack onClick={goBack} />
        {/* {auction_id ? (
          Object.keys(auctionEdit).length > 0 &&
          auctionEdit.constructor === Object ? (
            AuctionForm
          ) : (
            <Loader />
          )
        ) : (
          AuctionForm
        )} */}
        {AuctionForm}
      </Container>
    </Page>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    addAuction: (data, drawImage, files, action, auction_id) =>
      dispatch(
        actionCreators.addAuction(data, drawImage, files, action, auction_id)
      ),
    loadProducts: (searchKey, page) =>
      dispatch(actionCreators.loadProducts(searchKey, page)),
    loadSponsors: (searchKey, page) =>
      dispatch(actionCreators.loadSponsors(searchKey, page)),
    loadCountries: () => dispatch(actionCreators.loadCountries())
  };
};

const mapStateToProps = state => {
  console.log(state);
  return {
    progress: state.progress.fileProgress,
    auctions: state.auction.auctions,
    products: state.products.products.map(el => ({
      value: el.product_id,
      label: el?.title
    })),
    sponsors: state.sponsors.sponsors
      .filter(el => el.status === '1')
      .map(el => ({
        value: el.id,
        label: el.title
      })),
    currency: state.countries.Countries.map(el => ({
      value: el.code,
      label: el.currency
    }))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddAuction);
