import React, { useEffect } from 'react';
// Material UI
import {
  Grid,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  CardActions,
  Typography,
  Chip,
  makeStyles
} from '@material-ui/core';
import DeleteButton from 'src/components/common/DeleteButton';
import EditButton from 'src/components/common/EditButton';
import Loader from 'src/components/common/loader';
import { green } from '@material-ui/core/colors';
import {
  RadioButtonCheckedRounded as Active,
  RadioButtonUncheckedRounded as InActive
} from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useConfirm } from 'material-ui-confirm';
import * as actionCreators from 'src/store/actions';
import { useNavigate } from 'react-router';
import DOMPurify from 'dompurify';

const useStyles = makeStyles(theme => ({
  media: {
    height: '20em'
  }
}));

const BannersListing = props => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();
  const banners = useSelector(state => state.banners.banners);
  const permissions = useSelector(state => state.permissions.permissions);
  const classes = useStyles();

  // Init;
  useEffect(() => {
    dispatch(actionCreators.loadBanners());
    return;
  }, []);

  // Methods
  const changeStatus = (id, status) => {
    const body = {
      banner_id: id,
      status
    };
    dispatch(actionCreators.changeBannerStatus(body))
      .then(msg => {
        enqueueSnackbar(msg, {
          variant: 'success'
        });
        dispatch(actionCreators.loadBanners());
        return;
      })
      .catch(err => {
        console.error(err);
        enqueueSnackbar(err, { variant: 'error' });
      });
  };
  const editBanner = id => navigate(`/banners/addBanner/${id}`);
  const onStatusChangeHandler = (id, status) => {
    confirm({
      description: `Are you sure you want to ${
        status === '4' ? 'DELETE' : status === '1' ? 'ACTIVATE' : 'DE-ACTIVATE'
      } Product ?`
    })
      .then(() => {
        changeStatus(id, status);
      })
      .catch(err => {
        console.log("User Cancelled Action. Don't delete It.", err);
      });
  };
  var sanitizeHTML = function(str) {
    return str.replace(/[^\w. ]/gi, function(c) {
      return '&#' + c.charCodeAt(0) + ';';
    });
  };
  return banners.length > 0 ? (
    <Grid container spacing={2}>
      {banners.map((banner, index) => (
        <Grid item xs={12} md={6} key={index + 1}>
          <Card className={classes.root}>
            <CardActionArea>
              <CardMedia
                component={
                  banner.file_type == 'image' || banner.file_type == 'video'
                    ? banner.file_type
                    : 'image'
                }
                image={banner.file}
                title={sanitizeHTML(banner.title)}
                className={classes.media}
                controls
              ></CardMedia>
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  <Typography>
                    {sanitizeHTML(banner.title).length > 20
                      ? `${sanitizeHTML(banner.title).substring(0, 24)}...`
                      : sanitizeHTML(banner.title)}
                  </Typography>
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="p"
                  className={classes.desc}
                >
                  {sanitizeHTML(banner.description).length > 500
                    ? `${sanitizeHTML(banner.description).substring(0, 500)}...`
                    : sanitizeHTML(banner.description)}
                </Typography>
              </CardContent>
            </CardActionArea>

            <CardActions style={{ justifyContent: 'flex-end' }}>
              <Chip
                style={{
                  backgroundColor: banner.status === '1' ? green['A400'] : ''
                }}
                size="small"
                icon={banner.status === '1' ? <Active /> : <InActive />}
                label={banner.status === '1' ? 'Active' : 'In-Active'}
                onClick={() =>
                  onStatusChangeHandler(
                    banner.banner_id,
                    banner.status === '1' ? '0' : '1'
                  )
                }
              />
              {permissions.content_manager == '1' ||
              permissions.admin == '1' ? (
                <EditButton
                  size="small"
                  onClick={() => editBanner(banner.banner_id)}
                />
              ) : (
                ''
              )}
              {permissions.content_manager == '1' ||
              permissions.admin == '1' ? (
                <DeleteButton
                  size="small"
                  onClick={() => onStatusChangeHandler(banner.banner_id, '4')}
                />
              ) : (
                ''
              )}
            </CardActions>
          </Card>
        </Grid>
      ))}
    </Grid>
  ) : (
    <Loader />
  );
};

export default React.memo(BannersListing);
