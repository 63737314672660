import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  makeStyles,
  Button,
  Typography,
  Avatar,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import Loader from 'src/components/common/loader';
import { useLocation } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import * as actionCreators from '../../../../store/actions';
import {
  LicenseInfo,
  XGrid,
  GridToolbar,
  useGridApiRef
} from '@material-ui/x-grid';
import axios from 'src/services/axios';
import Select from 'react-select';
import { RadioButtonCheckedRounded as ActiveIcon } from '@material-ui/icons';
import { useConfirm } from 'material-ui-confirm';
import getInitials from 'src/utils/getInitials';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import QRCodeApp from '../../../common/QRCodeApp';
import CheckBoxes from '../../../common/checkBoxes';
import { useNavigate } from 'react-router';
import { useSnackbar } from 'notistack';
import OtpInput from 'react-otp-input';
LicenseInfo.setLicenseKey(
  '6391385253d2aae4e0d72df787edd9f0T1JERVI6MjYzNzAsRVhQSVJZPTE2NTYwNTc2OTgwMDAsS0VZVkVSU0lPTj0x'
);

const userStatusOptions = [
  { value: 'user', label: 'User' },
  { value: 'admin', label: 'Admin' },
  { value: 'reseller', label: 'Reseller' },
  { value: 'test', label: 'Test' }
];

const userLevelOptions = [
  { value: 'Vip', label: 'Vip' },
  { value: 'Bronze', label: 'Bronze' },
  { value: 'Silver', label: 'Silver' },
  { value: 'Gold', label: 'Gold' }
];

const selectStyles = {
  control: styles => ({
    ...styles,
    backgroundColor: 'white',
    width: '120px'
  }),
  menu: styles => ({
    ...styles,
    zIndex: '11',
    position: 'relative',
    height: '81px',
    overflowY: 'scroll'
  })
};
const resellerStyles = {
  control: styles => ({
    ...styles,
    backgroundColor: '#757ce8',
    width: '120px'
  }),
  singleValue: (styles, { data }) => ({ ...styles, color: 'white' })
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const CustomerListView = ({
  loadCustomers,
  customers,
  totalCustomers,
  userId
}) => {
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [pageLoader, setPageLoader] = useState(true);
  const searchCondition = useLocation();
  const apiRef = useGridApiRef();
  const [isLoading, setIsLoading] = useState(false);
  const [filterValue, setFilterValue] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [resellerUser, setrResellerUser] = React.useState({
    first_name: '',
    last_name: '',
    referral_code: ''
  });
  const [user, setUser] = React.useState();
  const [open, setOpen] = React.useState(false);
  const [permission, setPermission] = React.useState([]);
  const [permissionOpen, setPermissionOpen] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  const [userData, setUserData] = React.useState([]);
  const QRCodeRef = useRef();
  const [OTP, setOTP] = useState('');
  const [otpDialog, setOtpDialog] = useState(false);

  const navigate = useNavigate();
  const permissions = useSelector(state => state.permissions.permissions);
  useEffect(() => {
    if (searchCondition.state != undefined) {
      setFilterValue(searchCondition.state.filterValue);
      loadCustomer(searchCondition.state.userId);
    } else {
      loadCustomer(userId);
    }
  }, []);

  const loadCustomer = userId => {
    setLoading(true);
    loadCustomers('', page + 1, userId)
      .then(data => {
        if (data != 'Notfound users') {
          setRows(data);
          if (
            searchCondition.state != undefined &&
            searchCondition.state.filterValue.length != 0
          ) {
            setTimeout(function() {
              apiRef.current.setFilterModel(searchCondition.state.filterValue);
            }, 1000);
          }
        }
        setLoading(false);
        setPageLoader(false);
      })
      .catch(err => {
        setLoading(false);
        setPageLoader(false);
      });
  };

  const changeUserStatusHandler = (value, user_id) => {
    var userType = value.value;
    var index = customers.customers.users.findIndex(e => e.user_id == user_id);
    confirm({
      description: `Are you sure you want to change user type to ${value.label} ?`
    })
      .then(() => {
        setIsLoading(user_id);
        dispatch(
          actionCreators.changeCustomerType(
            customers.customers.users,
            index,
            userType
          )
        )
          .then(() => {
            setIsLoading(false);
          })
          .catch(err => {
            setIsLoading(false);
          });
      })
      .catch(err => {
        console.log('User Cancelled Action.', err);
      });
  };

  const changeUserLevel = (value, user_id) => {
    var userLevel = value.value;

    var index = customers.customers.users.findIndex(e => e.user_id == user_id);
    confirm({
      description: `Are you sure you want to change user level to ${value.label} ?`
    })
      .then(() => {
        setIsLoading(user_id);
        dispatch(
          actionCreators.changeCustomerLevel(
            customers.customers.users,
            index,
            userLevel
          )
        )
          .then(() => {
            setIsLoading(false);
          })
          .catch(err => {
            setIsLoading(false);
          });
      })
      .catch(err => {
        console.log('User Cancelled Action.', err);
      });
  };

  const onStatusCustomerHandler = (status, user_id, mobile) => {
    setUserData({ status, user_id, mobile });
    var index = customers.customers.users.findIndex(e => e.user_id == user_id);
    confirm({
      description: `Are you sure you want to ${
        status !== '4' ? (status === '1' ? 'Activate' : 'Deactivate') : 'Delete'
      } this user ?`
    })
      .then(() => {
        sendOtp(mobile);
      })
      .catch(err => {
        console.log('User Cancelled Action.', err);
      });
  };

  const sendOtp = async mobile => {
    setOTP('');
    const phone = mobile.split(' ');
    const body = {
      country_code: phone[0],
      mobile: phone[1]
    };
    try {
      const resp = await axios.post('resend-otp', JSON.stringify(body));

      const { success, message } = resp.data;
      if (success) {
        enqueueSnackbar(message, { variant: 'success' });
        setOtpDialog(true);
        return;
      }
      enqueueSnackbar(message, { variant: 'error' });

      return;
    } catch (error) {
      enqueueSnackbar(error, { variant: 'error' });

      return;
    }
  };
  const verifyOtpHandle = async () => {
    var index = customers.customers.users.findIndex(
      e => e.user_id == userData.user_id
    );
    dispatch(
      actionCreators.changeCustomerStatus(
        customers.customers.users,
        index,
        userData.status,
        OTP
      )
    )
      .then(res => {
        enqueueSnackbar(res.message, { variant: 'success' });
        setOTP('');
        setOtpDialog(false);
      })
      .catch(err => {
        enqueueSnackbar(err, { variant: 'success' });
      });
  };

  const handleClickOpen = (first_name, last_name, referral_code) => {
    setrResellerUser({
      first_name: first_name,
      last_name: last_name,
      referral_code: referral_code
    });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOtpClose = () => {
    setOTP('');
    setOtpDialog(false);
  };
  const generateQRCode = () => {
    QRCodeRef.current.downloadQRCode();
  };

  const handleChange = event => {
    setPermission({
      ...permission,
      [event.target.name]: event.target.checked == true ? '1' : '0'
    });
  };
  const handlePermissionsClose = () => {
    setPermissionOpen(false);
  };

  const handlePermissionsOpen = user => {
    setUser(user);
    dispatch(actionCreators.getUserPermission(user.user_id))
      .then(data => {
        setPermission(data);
        setPermissionOpen(true);
      })
      .catch(err => {
        setIsLoading(false);
      });
  };

  const setUserPermission = () => {
    dispatch(actionCreators.setUserPermission(user.user_id, permission))
      .then(msg => {
        enqueueSnackbar(msg, {
          variant: 'success'
        });
        setPermissionOpen(false);
      })
      .catch(err => {
        enqueueSnackbar(err, {
          variant: 'error'
        });
        setPermissionOpen(false);
      });
  };

  const goToReseller = id => {
    const url = window.location.href;
    const urlId = url.substring(url.lastIndexOf('/') + 1);
    var searchCondition = {
      userId: urlId,
      filterValue: filterValue
    };
    navigate(`../../resellers/${id}`, { state: searchCondition });
  };

  const onFilterChange = React.useCallback(params => {
    setFilterValue(params.filterModel);
  }, []);

  const columns = [
    {
      field: 'user_id',
      headerName: 'user Id',
      width: 150
    },
    {
      field: 'first_name',
      headerName: 'First Name',
      width: 220,
      renderCell: params => (
        <>
          <Avatar style={{ marginRight: '12px' }}>
            {getInitials(params.row.first_name)}
          </Avatar>
          {params.row.user_type === 'reseller' ? (
            <Typography color="textPrimary" variant="body1">
              <div
                style={{ color: 'blue', cursor: 'pointer' }}
                onClick={() => goToReseller(params.row.user_id)}
              >
                {params.row.first_name}
              </div>
            </Typography>
          ) : (
            <Typography color="textPrimary" variant="body1">
              {params.row.first_name}
            </Typography>
          )}
        </>
      )
    },
    {
      field: 'last_name',
      headerName: 'Last Name',
      width: 220
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 220
    },
    {
      field: 'phone',
      headerName: 'Phone Number',
      width: 220,
      valueGetter: params => {
        return params.row.mobile
          ? `${params.getValue(params.id, 'country_code') ||
              ''} ${params.getValue(params.id, 'mobile') || ''}`
          : `${params.getValue(params.id, 'temp_mobile')}`;
      }
    },
    {
      field: 'user_type',
      headerName: 'User Type',
      width: 150,
      renderCell: params => (
        <div style={{ lineHeight: '26px', position: 'absolute' }}>
          <Select
            isLoading={isLoading == params.value ? true : false}
            value={userStatusOptions.find(e => e.value == params.value)}
            options={userStatusOptions}
            onChange={e => changeUserStatusHandler(e, params.row.user_id)}
            styles={params.value === 'reseller' ? resellerStyles : selectStyles}
            // menuIsOpen={true}
          />
        </div>
      )
    },
    {
      field: 'user_level',
      headerName: 'User Level',
      width: 150,
      renderCell: params => (
        <div style={{ lineHeight: '26px', position: 'absolute' }}>
          <Select
            isLoading={isLoading == params.value ? true : false}
            value={userLevelOptions.find(e => e.value == params.value)}
            options={userLevelOptions}
            onChange={e => changeUserLevel(e, params.row.user_id)}
            styles={selectStyles}
            // menuIsOpen={true}
          />
        </div>
      )
    },
    {
      field: 'total_reward_points',
      headerName: 'Total Reward Points',
      width: 150
    },
    {
      field: 'created_at',
      headerName: userId == '1' ? 'Purchase date' : 'Registered date',
      type: 'date',
      width: 220
    },
    userId == '1'
      ? {
          field: 'sales',
          headerName: 'Sales',
          type: 'number',
          width: 220
        }
      : '',
    {
      field: 'status',
      headerName: 'Status',
      width: 180,
      renderCell: params => (
        <Button
          variant="contained"
          style={{
            backgroundColor: params.value === '1' ? '#00e676' : '#d5d5d5'
          }}
          startIcon={<ActiveIcon />}
          onClick={() =>
            onStatusCustomerHandler(
              params.value === '1' ? '0' : '1',
              params.row.user_id,
              params.row.mobile
                ? `${params.getValue(params.id, 'country_code') ||
                    ''} ${params.getValue(params.id, 'mobile') || ''}`
                : `${params.getValue(params.id, 'temp_mobile')}`
            )
          }
        >
          {params.value === '1' ? 'Active' : 'In-Active'}
        </Button>
      )
    },
    {
      field: 'referral_code',
      headerName: 'QR Code',
      width: 160,
      renderCell: params => (
        <img
          src="https://dashboard.ishro.com/static/ticket_qr.svg"
          style={{
            height: '42px',
            width: '42px',
            boxShadow:
              '0 0 1px 0 rgb(0 0 0 / 31%), 0 2px 2px -2px rgb(0 0 0 / 25%)',
            padding: '4px',
            borderRadius: '4px',
            cursor: 'pointer'
          }}
          onClick={() =>
            handleClickOpen(
              params.row.first_name,
              params.row.last_name,
              params.value
            )
          }
        />
      )
    },
    permissions.admin == '1'
      ? {
          field: 'permissions',
          headerName: 'Permissions',
          width: 160,
          renderCell: params => (
            <img
              src="https://dashboard.ishro.com/static/user-locked.svg"
              style={{
                height: '42px',
                width: '42px',
                boxShadow:
                  '0 0 1px 0 rgb(0 0 0 / 31%), 0 2px 2px -2px rgb(0 0 0 / 25%)',
                padding: '4px',
                borderRadius: '4px',
                cursor: 'pointer'
              }}
              onClick={() => handlePermissionsOpen(params.row)}
            />
          )
        }
      : ''
  ];

  const handlePageChange = params => {
    setPage(params.page);
  };

  return (
    <>
      <Box display="flex" m={3} mb={4}>
        <Box flexGrow={1}>
          <Typography variant="h3">Customers ({rows.length})</Typography>
        </Box>
      </Box>
      {!pageLoader ? (
        <div
          style={{
            display: 'flex',
            height: '88%',
            boxShadow:
              '0 0 0 1px rgb(63 63 68 / 5%), 0 1px 2px 0 rgb(63 63 68 / 15%)',
            color: '#263238',
            transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            backgroundColor: '#fff',
            borderRadius: '4px',
            margin: '1.5%'
          }}
        >
          <div style={{ flexGrow: 1 }}>
            <XGrid
              rows={rows}
              columns={columns}
              pageSize={100}
              components={{
                Toolbar: GridToolbar
              }}
              rowHeight={83}
              checkboxSelection
              disableSelectionOnClick
              apiRef={apiRef}
              pagination
              onFilterModelChange={onFilterChange}
              // pagination={true}
              // paginationMode="server"
              // filterMode="server"

              // onPageChange={handlePageChange}
              // loading={loading}
              // rowCount={totalCustomers}
            />
            {/* QR Code */}
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle
                id="alert-dialog-title"
                style={{ padding: '26px 32px 13px 61px' }}
              >
                <Typography color="textPrimary" variant="h3">
                  {'QR Code'}
                </Typography>
              </DialogTitle>
              <DialogContent style={{ padding: '20px 62px' }}>
                <QRCodeApp referralCode={resellerUser} ref={QRCodeRef} />
              </DialogContent>
              <DialogActions
                style={{ paddingBottom: '31px', paddingRight: '31px' }}
              >
                <Button
                  onClick={generateQRCode}
                  variant="contained"
                  color="primary"
                >
                  Download QR Code
                </Button>
                <Button
                  onClick={handleClose}
                  variant="contained"
                  style={{
                    backgroundColor: '#e2e2e2',
                    color: 'rgba(0, 0, 0, 0.87)'
                  }}
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={otpDialog}
              onClose={handleOtpClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle
                id="alert-dialog-title"
                style={{ padding: '26px 32px 13px 61px' }}
              >
                <Typography color="textPrimary" variant="h3">
                  {'OTP'}
                </Typography>
              </DialogTitle>
              <DialogContent style={{ padding: '20px 62px' }}>
                <OtpInput
                  value={OTP}
                  onChange={val => setOTP(val)}
                  numInputs={6}
                  inputStyle={{
                    width: '42px',
                    height: '42px',
                    textAlign: 'center',
                    outline: 'none',
                    marginRight: '10px',
                    border: '1px solid #6d7d8b',
                    borderRadius: '5px'
                  }}
                />
              </DialogContent>
              <DialogActions
                style={{ paddingBottom: '31px', paddingRight: '31px' }}
              >
                <Button
                  onClick={verifyOtpHandle}
                  variant="contained"
                  color="primary"
                >
                  Verify OTP
                </Button>
                <Button
                  onClick={() => sendOtp(userData.mobile)}
                  variant="contained"
                  color="primary"
                >
                  Resend OTP
                </Button>
                <Button
                  onClick={handleOtpClose}
                  variant="contained"
                  style={{
                    backgroundColor: '#e2e2e2',
                    color: 'rgba(0, 0, 0, 0.87)'
                  }}
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>

            {/* Permissions */}
            <Dialog
              open={permissionOpen}
              onClose={handlePermissionsClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle
                id="alert-dialog-title"
                style={{ padding: '26px 32px 13px 61px' }}
              >
                <Typography color="textPrimary" variant="h3">
                  {'Permissions'}
                </Typography>
              </DialogTitle>
              <DialogContent style={{ padding: '20px 62px' }}>
                {Object.entries(permission).map(([key, value], index) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={value == '0' ? false : true}
                        onChange={handleChange}
                        name={key}
                        color="primary"
                      />
                    }
                    label={
                      <Typography style={{ textTransform: 'capitalize' }}>
                        <strong>{key.replaceAll('_', ' ')}</strong>
                      </Typography>
                    }
                    className="percheck"
                  />
                ))}
              </DialogContent>
              <DialogActions
                style={{ paddingBottom: '31px', paddingRight: '31px' }}
              >
                <Button
                  onClick={setUserPermission}
                  variant="contained"
                  color="primary"
                >
                  Save
                </Button>
                <Button
                  onClick={handlePermissionsClose}
                  variant="contained"
                  style={{
                    backgroundColor: '#e2e2e2',
                    color: 'rgba(0, 0, 0, 0.87)'
                  }}
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      ) : (
        <div style={{ margin: '0% 0px' }}>
          <Loader />
        </div>
      )}
    </>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    loadCustomers: (searchKey, page, userId) =>
      dispatch(actionCreators.loadCustomers(searchKey, page, userId))
  };
};

const mapStateToProps = state => {
  return {
    customers: state.customers,
    totalCustomers: state?.customers?.totalCustomers.total
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerListView);

// useEffect(() => {
//  if(searchCondition.state!=undefined){
//     setFilterValue(searchCondition.state.filterValue);
//     loadCustomers('', 1)
//     .then((data) => {
//       setRows(data);
//       setPageLoader(false);
//       apiRef.current.setFilterModel(searchCondition.state.filterValue);
//     })
//     .catch(err => {
//       setPageLoader(false);
//     });
//   }
//   else{

//   }

// }, []);

// useEffect(() => {
//   loadCustomer(userId);
// }, [page,filterValue]);
