import React, { useRef, useState } from 'react';
import axios from '../../../../services/axios';
import Papa from 'papaparse';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Divider,
  Button,
  LinearProgress,
  Input,
  DialogContentText,
  Fab
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import 'emoji-mart/css/emoji-mart.css';
import '../../../../assets/styles/MainStyles.css';
import PrintTicket from './PrintTicket';
import ReactToPrint from 'react-to-print';
import { Printer } from 'react-feather';

const ImportDialog = ({ open, onClose, promotion_id }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [isSelected, setIsSelected] = useState(false);
  const [importData, setImportData] = useState(null);
  const [printTickets, setPrintTickets] = useState(null);
  const componentRef = useRef();

  const changeHandler = event => {
    setIsSelected(true);

    // Passing file data (event.target.files[0]) to parse using Papa.parse
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function(results) {
        setImportData(results.data);
      }
    });
  };

  const onSubmitHandler = async () => {
    try {
      setIsSubmitting(true);
      setIsSelected(true);
      await axios
        .post('print-manual-promotion-tickets', {
          promotion_id,
          tickets: importData
        })
        .then(resp => {
          setIsSubmitting(false);
          setIsSelected(false);
          if (resp?.data?.success) {
            setPrintTickets(resp.data.data);
            enqueueSnackbar('Imported Successfully', { variant: 'success' });
            return;
          } else {
            enqueueSnackbar(resp?.data?.message, { variant: 'error' });
            return;
          }
        })
        .catch(error => {
          enqueueSnackbar(error, { variant: 'error' });
          return;
        });
    } catch (error) {
      enqueueSnackbar(error, { variant: 'error' });
      return;
    }
  };

  const onClickHandler = () => {
    setIsSubmitting(false);
    setIsSelected(false);
    setPrintTickets(null);
    setImportData(null);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClickHandler}
      aria-labelledby="form-dialog-title"
      PaperProps={{ style: { overflowY: 'visible', width: '1000px' } }}
    >
      <DialogTitle id="form-dialog-title">Import Csv File</DialogTitle>
      <DialogContent dividers="paper">
        <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
          <PrintTicket
            ref={componentRef}
            className="print-preview"
            printTickets={printTickets}
          />
        </DialogContentText>
      </DialogContent>
      <Divider />
      <Divider />
      {isSubmitting && <LinearProgress />}
      <DialogActions>
        <Input
          type="file"
          name="file"
          accept=".csv"
          onChange={changeHandler}
          style={{ display: 'block', margin: '10px auto' }}
        />
        <Button
          disabled={isSubmitting}
          onClick={onClickHandler}
          color="primary"
        >
          {' '}
          Cancel
        </Button>
        {printTickets == null ? (
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={!isSelected}
            onClick={onSubmitHandler}
          >
            {' '}
            Import{' '}
          </Button>
        ) : (
          <ReactToPrint
            trigger={() => (
              <Fab variant="extended" color="secondary" size="small">
                <Printer style={{ marginRight: '12px' }} /> Print
              </Fab>
            )}
            content={() => componentRef.current}
          />
        )}
      </DialogActions>
    </Dialog>
  );
};

ImportDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  promotion_id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired
};

export default ImportDialog;
