import axios from '../../services/axios';
import { Types } from 'src/store/actions/actionTypes';

/**
 * Load Dashboards action
 */

export const loadDashboards = body => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      axios
        .post('dashboard', JSON.stringify({ year: body }))
        .then(res => {
          if (res.data.success) {
            dispatch(setDashboard(res.data.data));
          }
          resolve(res.data);
        })
        .catch(error => {
          console.log(error);
          if (error) {
            reject(new Error(error));
          }
        });
    });
  };
};

const setDashboard = data => ({
  type: Types.SET_DASHBOARD,
  payload: { data }
});

const clearDashboard = () => ({
  type: Types.CLEAR_DASHBOARD,
  payload: {}
});
