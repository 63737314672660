import axios from '../../services/axios';
import { Types } from 'src/store/actions/actionTypes';
import NewsCard from 'src/components/pages/news/newsCard';

export const loadNews = (search, page) => {
  console.log('search :', search);
  return dispatch => {
    return new Promise((resolve, reject) => {
      if (page == 0 || page == 1) {
        console.log('Clearing The News REFRESH');
        dispatch(clearNews());
      }
      const body = {
        page: page,
        search: search
      };
      axios
        .post('get-news', JSON.stringify(body))
        .then(res => {
          const { data, success, message } = res.data;
          if (success) {
            dispatch(setFilteredNews(data));
            dispatch(setNews(data));
            resolve(message);
          } else {
            dispatch(clearFilteredNews());
            dispatch(clearNews());
            reject(message);
          }
        })
        .catch(error => {
          console.log(error);
          if (error) {
            reject(new Error(error));
          }
        });
    });
  };
};

const uploadFiles = async files => {
  console.log(files);
  const fileUrls = [];

  for (let m = 0; m < files.length; m++) {
    try {
      const file = files[m];

      if (typeof file === 'string') {
        fileUrls.push({ file_type: 'image', file_url: file });
        continue;
      }
      console.log('Wont be logged');

      let formData = new FormData();
      formData.append('file', file);
      const resp = await axios.post(
        'https://api.ishro.com/v3/upload-file-google',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      );

      if (resp.data.success) {
        fileUrls.push(resp.data.results.files[0]);
        console.log(resp);
      } else {
        continue;
      }
    } catch (error) {
      console.log(error);
      continue;
    }
  }
  return fileUrls;
};

const uploadVideo = async files => {
  console.log('Files Uploaded', files);
  const fileUrls = [];

  for (let m = 0; m < files.length; m++) {
    try {
      const file = files[m];

      if (!file.blob && !file.url.includes('blob')) {
        fileUrls.push({ file_type: file.type, file_url: file.url });
        continue;
      }
      console.log("Won't be logged");

      let formData = new FormData();
      formData.append('file', file.blob);
      const resp = await axios.post(
        'https://api.ishro.com/v3/upload-file-google',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      );

      if (resp.data.success) {
        fileUrls.push(resp.data.results.files[0]);
        console.log(resp);
      } else {
        continue;
      }
    } catch (error) {
      console.log(error);
      continue;
    }
  }
  return fileUrls;
};

export const addNews = (body, files, files_video) => async dispatch => {
  try {
    const videoFile = await uploadVideo(files_video);
    if (!videoFile || videoFile.length <= 0) {
      throw 'Error Uploading Banner Image';
    } else {
      if (files.length > 0 && files[0] != '') {
        const fileResp = await uploadFiles(files);
        if (!fileResp) throw 'Error Uploading News Banner';
        videoFile[0].thumbnail = fileResp[0].file_url;
      }
    }
    const resp = await axios.post(
      'add-news',
      JSON.stringify({
        ...body,
        files: videoFile
      })
    );
    const { message, success } = resp.data;
    if (success) {
      return message;
    }
    throw message;
  } catch (error) {
    throw error;
  }
};

export const changeNewsStatus = (news_id, status) => async dispatch => {
  try {
    const resp = await axios.post(
      'change-news-status',
      JSON.stringify({
        news_id,
        status
      })
    );
    const { message, success } = resp.data;
    if (success) {
      return message;
    }
    throw message;
  } catch (error) {
    throw error;
  }
};

export const filterNews = (search, news) => {
  const filteredList = news.filter(el =>
    el.title.toLowerCase().includes(search.toLowerCase())
  );
  return dispatch => dispatch(setFilteredNews(filteredList));
};

const setNews = news => ({
  type: Types.SET_NEWS,
  payload: { news }
});

export const clearNews = () => ({
  type: Types.CLEAR_NEWS
});

const setFilteredNews = filteredNews => ({
  type: Types.SET_FILTERED_NEWS,
  payload: { filteredNews }
});

export const clearFilteredNews = () => ({
  type: Types.CLEAR_FILTERED_NEWS
});
