import React, { useEffect, useState } from 'react';
import {
  Container,
  Paper,
  Card,
  CardContent,
  CardHeader,
  Grid,
  TextField,
  Typography,
  Divider,
  Button,
  CardActions,
  LinearProgress
} from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Page from 'src/components/common/Page';
import GoBack from 'src/components/common/goBack';
import DropZoneBanner from 'src/components/common/dropZoneBanner';
import * as actionCreators from 'src/store/actions';
import { connect, useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { decode, isValid } from 'js-base64';
import ErrorMessage from 'src/components/common/errorMessage';
import Select from 'react-select';
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2
} from 'react-html-parser';

const validationSchema = Yup.object().shape({
  platform: Yup.object()
    .nullable()
    .required('Platform is required'),
  files: Yup.array()
    .min(1, 'Please Upload Atleast One File')
    .max(1)
    .required('Please Upload Atleast One File'),
  link: Yup.string().url(),
  title: Yup.string().matches(/^(?!(.)\1\1\1\1\1\1\1\1\1\1)/, {
    message: "Don't Enter Consecutive character",
    excludeEmptyString: true
  }),
  title_ar: Yup.string().matches(/^(?!(.)\1\1\1\1\1\1\1\1\1\1)/, {
    message: "Don't Enter Consecutive character",
    excludeEmptyString: true
  }),
  description_ar: Yup.string().matches(/^(?!(.)\1\1\1\1\1\1\1\1\1\1)/, {
    message: "Don't Enter Consecutive character",
    excludeEmptyString: true
  }),
  description: Yup.string().matches(/^(?!(.)\1\1\1\1\1\1\1\1\1\1)/, {
    message: "Don't Enter Consecutive character",
    excludeEmptyString: true
  })
});
const selectStyles = {
  control: styles => ({
    ...styles,
    backgroundColor: 'white',
    minHeight: '52px'
  }),
  menu: styles => ({ ...styles, zIndex: '11' })
};
const initialValues = {
  title: '',
  platform: null,
  title_ar: '',
  description: '',
  description_ar: '',
  link: '',
  files: [],
  filesAr: [],
  promotion_id: '',
  level: ''
};

const platforms = [
  { label: 'website', value: 'website' },
  { label: 'mobile', value: 'mobile' }
];

const count = 0;

const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    zIndex: '4'
  })
};
var promotionOpt = [];
const addBannerComponent = ({ addBanner, progress, banners }) => {
  const { banner_id } = useParams();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [files, setFiles] = useState([]);
  const [filesAr, setFilesAr] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [promotion, setPromotion] = React.useState('');
  const [promotions, setPromotions] = useState([]);
  const [level, setLevel] = useState([
    { value: '-1', label: 'All' },
    { value: '0', label: 'Normal' },
    { value: '1', label: 'VIP' }
  ]);

  console.log('RENDER :', count + 1);

  const { handleSubmit, register, errors, control, setValue } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'onChange'
  });
  const action = banner_id ? 'edit' : 'add';

  useEffect(() => {
    dispatch(actionCreators.loadPromotions('', 1))
      .then(options => {
        promotionOpt = [];
        options.map(p =>
          promotionOpt.push({
            value: p?.promotion_id,
            label: isBase64(p?.title)
              ? ReactHtmlParser(decode(p?.title).replace(/<[^>]+>/g, ''))
              : p?.title
          })
        );

        setPromotions(promotionOpt);
      })
      .catch(err => console.log(err));
    if (banner_id) {
      prefillForm();
    }
  }, []);

  const isBase64 = str => {
    if (str === '' || str.trim() === '') {
      return false;
    }
    try {
      return btoa(atob(str)) == str;
    } catch (err) {
      return false;
    }
  };

  const onSubmit = (data, e) => {
    if (data.files[0].type == data.filesAr[0].type) {
      setIsSubmitting(true);
      const body = {
        action,
        title: data.title,
        platform: data.platform.value,
        level: data.level.value,
        title_ar: data.title_ar,
        description: data.description,
        description_ar: data.description_ar,
        link: data.link,
        banner_id,
        promotion_id: promotion.value
      };

      console.log('Submitted Form Data', body);
      addBanner(body, data.files, data.filesAr)
        .then(msg => {
          console.log(msg);
          enqueueSnackbar(msg, {
            variant: 'success'
          });
          navigate(-1);
          setIsSubmitting(false);
          return;
        })
        .catch(err => {
          console.error(err);
          enqueueSnackbar(err, { variant: 'error' });
          setIsSubmitting(false);
        });
    } else {
      enqueueSnackbar('Both uploading file type not same', {
        variant: 'error'
      });
    }
  };

  const onError = (errors, e) => {
    console.log(errors, e);
    setIsSubmitting(false);
  };

  const prefillForm = () => {
    if (banners.length < 0) {
      enqueueSnackbar('No Banner Found. Please come-back again', {
        variant: 'error'
      });
      navigate(-1);
      return;
    }

    const bannerToBeEditted = banners.find(e => e.banner_id == banner_id);
    if (!bannerToBeEditted) {
      enqueueSnackbar('Error Editting the Banner. Please come-back again', {
        variant: 'error'
      });
      navigate(-1);
      return;
    }

    setValue('title', bannerToBeEditted.title);
    setValue(
      'platform',
      platforms.find(e => e.value === bannerToBeEditted.platform)
    );
    setValue('title_ar', bannerToBeEditted.title_ar);

    setValue(
      'level',
      level.find(el => el.value === bannerToBeEditted.level)
    );

    setValue('description', bannerToBeEditted.description);
    setValue('description_ar', bannerToBeEditted.description_ar);
    setValue('link', bannerToBeEditted.link);
    setFiles([
      {
        url: bannerToBeEditted.file,
        type: bannerToBeEditted.file_type,
        blob: null
      }
    ]);
    setValue('files', [
      {
        url: bannerToBeEditted.file,
        type: bannerToBeEditted.file_type,
        blob: null
      }
    ]);
    setFilesAr([
      {
        url: bannerToBeEditted.file_ar,
        type: bannerToBeEditted.file_type,
        blob: null
      }
    ]);
    setValue('filesAr', [
      {
        url: bannerToBeEditted.file_ar,
        type: bannerToBeEditted.file_type,
        blob: null
      }
    ]);
    setPromotion(bannerToBeEditted?.promotion_id);
  };

  const goBack = () => navigate(-1);
  return (
    <Page title={action == 'add' ? 'Add Banner' : 'Edit Banner'}>
      <Container>
        <GoBack onClick={goBack} />
        <Card raised>
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <CardHeader
              title={action == 'add' ? 'Add Banner' : 'Edit Banner'}
            />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <Typography>Platform</Typography>
                  <Controller
                    name="platform"
                    options={platforms}
                    control={control}
                    as={Select}
                    styles={customStyles}
                  />
                  <ErrorMessage message={errors?.platform?.message} />
                </Grid>

                <Grid item md={4} xs={12}>
                  <Typography>User level</Typography>
                  <Controller
                    name="level"
                    options={level}
                    control={control}
                    as={Select}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label="Banner Title"
                    type="text"
                    name="title"
                    variant="outlined"
                    fullWidth
                    error={Boolean(errors?.title?.message)}
                    helperText={errors.title?.message}
                    inputRef={register}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label="Banner Title (Arabic)"
                    type="text"
                    name="title_ar"
                    variant="outlined"
                    fullWidth
                    error={Boolean(errors?.title_ar?.message)}
                    helperText={errors.title_ar?.message}
                    inputRef={register}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography>Promotions</Typography>
                  <Controller
                    control={control}
                    name="promotion_id"
                    render={({ onChange, value, name, ref }) => (
                      <Select
                        inputRef={ref}
                        options={promotions}
                        value={promotions.find(c => c.value === promotion)}
                        onChange={val => setPromotion(val)}
                        styles={selectStyles}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Banner Link"
                    type="link"
                    name="link"
                    variant="outlined"
                    fullWidth
                    error={Boolean(errors?.link?.message)}
                    helperText={errors.link?.message}
                    inputRef={register}
                  />
                </Grid>
                <Grid item xs>
                  <TextField
                    label="Banner Description"
                    type="text"
                    name="description"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={4}
                    error={Boolean(errors?.description?.message)}
                    helperText={errors.description?.message}
                    inputRef={register}
                  />
                </Grid>
                <Grid item xs>
                  <TextField
                    label="Banner Description (Arabic) (Optional)"
                    type="text"
                    name="description_ar"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={4}
                    error={Boolean(errors?.description_ar?.message)}
                    helperText={errors.description_ar?.message}
                    inputRef={register}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="files"
                    render={({ value, ref }) => (
                      <DropZoneBanner
                        col={1}
                        onFilesChange={filesArray =>
                          setValue('files', filesArray, {
                            shouldValidate: true,
                            shouldDirty: true
                          })
                        }
                        initialFiles={files}
                        id="files"
                        label="Upload Banner Image(En)"
                        error={errors?.files?.message}
                        inputRef={ref}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="filesAr"
                    render={({ value, ref }) => (
                      <DropZoneBanner
                        col={1}
                        onFilesChange={filesArray =>
                          setValue('filesAr', filesArray, {
                            shouldValidate: true,
                            shouldDirty: true
                          })
                        }
                        id="filesAr"
                        initialFiles={filesAr}
                        label="Upload Banner Image(Ar)"
                        error={errors?.filesAr?.message}
                        inputRef={ref}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            {progress > 0 && (
              <LinearProgress variant="determinate" value={progress} />
            )}

            <CardActions>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={Boolean(progress > 0 || isSubmitting)}
              >
                {action === 'add' ? 'Add' : 'Update'} Banner
              </Button>
            </CardActions>
          </form>
        </Card>
      </Container>
    </Page>
  );
};

const mapStateToProps = state => {
  return {
    progress: state.progress.fileProgress,
    banners: state.banners.banners
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addBanner: (body, files, filesAr) =>
      dispatch(actionCreators.addBanner(body, files, filesAr))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(addBannerComponent);
