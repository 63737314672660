import React, { useEffect } from 'react';
import {
  Grid,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  CardActions,
  Typography,
  Chip,
  makeStyles
} from '@material-ui/core';
import DeleteButton from 'src/components/common/DeleteButton';
import EditButton from 'src/components/common/EditButton';
import Loader from 'src/components/common/loader';
import { green } from '@material-ui/core/colors';
import {
  RadioButtonCheckedRounded as Active,
  RadioButtonUncheckedRounded as InActive
} from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useConfirm } from 'material-ui-confirm';
import * as actionCreators from 'src/store/actions';
import { useNavigate } from 'react-router';

const useStyles = makeStyles(theme => ({
  media: {
    height: '20em'
  }
}));

const AdsListing = props => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();
  const ads = useSelector(state => state.ads.ads);
  const permissions = useSelector(state => state.permissions.permissions);
  const classes = useStyles();

  // Init;
  useEffect(() => {
    dispatch(actionCreators.loadAds());
    return;
  }, []);

  const changeStatus = (id, status) => {
    const body = {
      ad_id: id,
      status
    };
    dispatch(actionCreators.changeAdStatus(body))
      .then(msg => {
        enqueueSnackbar(msg, {
          variant: 'success'
        });
        dispatch(actionCreators.loadAds());
        return;
      })
      .catch(err => {
        console.error(err);
        enqueueSnackbar(err, { variant: 'error' });
      });
  };
  const editAd = id => navigate(`/ads/addAd/${id}`);
  const onStatusChangeHandler = (id, status) => {
    confirm({
      description: `Are you sure you want to ${
        status === '4' ? 'DELETE' : status === '1' ? 'ACTIVATE' : 'DE-ACTIVATE'
      } Ad ?`
    })
      .then(() => {
        changeStatus(id, status);
      })
      .catch(err => {
        console.log("User Cancelled Action. Don't delete It.", err);
      });
  };
  return ads.length > 0 ? (
    <Grid container spacing={2}>
      {ads.map((ad, index) => (
        <Grid item xs={12} md={6} key={index + 1}>
          <Card className={classes.root}>
            <CardActionArea>
              <CardMedia
                image={ad.file}
                title={ad.title}
                className={classes.media}
              ></CardMedia>
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  <Typography>
                    {ad.title.length > 20
                      ? `${ad.title.substring(0, 24)}...`
                      : ad.title}
                  </Typography>
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="p"
                  className={classes.desc}
                >
                  {ad?.description?.length > 500
                    ? `${ad?.description?.substring(0, 500)}...`
                    : ad?.description}{' '}
                </Typography>
              </CardContent>
            </CardActionArea>

            <CardActions style={{ justifyContent: 'flex-end' }}>
              <Chip
                style={{
                  backgroundColor: ad.status === '1' ? green['A400'] : ''
                }}
                size="small"
                icon={ad.status === '1' ? <Active /> : <InActive />}
                label={ad.status === '1' ? 'Active' : 'In-Active'}
                onClick={() =>
                  onStatusChangeHandler(ad.ad_id, ad.status === '1' ? '0' : '1')
                }
              />
              {permissions.content_manager == '1' ||
              permissions.admin == '1' ? (
                <EditButton size="small" onClick={() => editAd(ad.ad_id)} />
              ) : (
                ''
              )}
              {permissions.content_manager == '1' ||
              permissions.admin == '1' ? (
                <DeleteButton
                  size="small"
                  onClick={() => onStatusChangeHandler(ad.ad_id, '4')}
                />
              ) : (
                ''
              )}
            </CardActions>
          </Card>{' '}
        </Grid>
      ))}
    </Grid>
  ) : (
    <Loader />
  );
};

export default React.memo(AdsListing);
