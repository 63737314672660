import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Paper,
  Grid,
  IconButton
} from '@material-ui/core';
import * as actionCreators from 'src/store/actions';
import Select from 'react-select';
import { Search as SearchIcon } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {
  ArrowBack,
  Clear,
  PeopleAlt,
  AttachMoney,
  ShoppingCart
} from '@material-ui/icons';

import Autocomplete from '@material-ui/lab/Autocomplete';
import TotalCustomers from '../reports/DashboardView/TotalCustomers';
import CurrencyFormat from 'react-currency-format';
import axios from '../../../services/axios';
import MoreVert from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles(theme => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  input: {
    height: '41px'
  }
}));

const orderStatusOptions = [
  { value: '', label: 'Select Filter Status' },
  { value: 'New', label: 'New' },
  { value: 'Accepted', label: 'Accepted' },
  { value: 'Cancelled', label: 'Cancelled' }
];

const promotionCharityOptions = [
  { value: '', label: 'Select Promotion Charity' },
  { value: 'true', label: 'Donated' },
  { value: 'false', label: 'Not Donated' }
];

const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    zIndex: '4'
  }),
  control: (provided, state) => ({
    ...provided,
    height: '56px'
  })
};
const customStyles1 = {
  menu: (provided, state) => ({
    ...provided,
    zIndex: '4'
  }),
  control: (provided, state) => ({
    ...provided,
    height: '56px',
    width: '300px'
  })
};

const Toolbar = ({
  className,
  handleSearch,
  total_orders,
  total_revenue,
  ...rest
}) => {
  const dispatch = useDispatch();
  const categories = useSelector(state => state.promotion.categories);
  const classes = useStyles();
  const [searchKey, setSearchKey] = useState('');
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState({
    value: '',
    label: 'Select Filter Status'
  });
  const [promotionCharity, setpromotionCharity] = useState({
    value: '',
    label: 'Select Promotion Charity'
  });
  const [promotionMethod, setpromotionMethod] = useState({
    value: '',
    label: 'Select Payment Method'
  });
  const [isLoading, setIsLoading] = useState(false);
  const [fromDate, setFromDate] = React.useState(null);
  const [toDate, setToDate] = React.useState(null);
  const [fromMaxDate, setFromMaxDate] = React.useState(new Date(1 - 1 - 2001));
  const [toMaxDate, setToMaxDate] = React.useState(new Date());
  const [errFromDate, setErrFromDate] = useState('');
  const [errToDate, setErrToDate] = useState('');
  const [userId, setUserId] = React.useState('');
  const [userOptions, setUserOptions] = React.useState([]);
  const [userValue, setUserValue] = React.useState();
  const [paymentOptions, setPaymentOptions] = React.useState([]);
  const [userInputValue, setUserInputValue] = React.useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);

  useEffect(() => {
    getPaymentMethod();
  }, []);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getPaymentMethod = () => {
    axios
      .get('get-payment-methods')
      .then(res => {
        if (res.data.success) {
          var paymentoption = [{ value: '', label: 'Select Payment Method' }];
          res.data.data.map(o =>
            paymentoption.push({
              value: o?.id,
              label: o?.payment_method
            })
          );
          setPaymentOptions(paymentoption);
        }
      })
      .catch(error => {
        console.log(error);
        if (error) {
          reject(new Error(error));
        }
      });
  };
  const onChangeHandler = option => {
    setStatus(option);
    handleSearch(
      searchKey,
      option.value,
      promotionCharity.value,
      fromDate,
      toDate,
      userId,
      promotionMethod.value
    );
  };
  const onChangeProMethod = option => {
    setpromotionMethod(option);
    handleSearch(
      searchKey,
      status.value,
      promotionCharity.value,
      fromDate,
      toDate,
      userId,
      option.value
    );
  };
  const onChangeProCharity = option => {
    setpromotionCharity(option);
    handleSearch(
      searchKey,
      status.value,
      option.value,
      fromDate,
      toDate,
      userId,
      promotionMethod.value
    );
  };
  const onSearch = e => {
    setSearchKey(e.target.value);
    handleSearch(
      e.target.value,
      status.value,
      promotionCharity.value,
      fromDate,
      toDate,
      userId,
      promotionMethod.value
    );
  };

  const changeFromDate = date => {
    date == 'Invalid Date'
      ? setErrFromDate('Invalid Date Format')
      : setErrFromDate('');
    setFromDate(date);

    handleSearch(
      searchKey,
      status.value,
      promotionCharity.value,
      date,
      toDate,
      userId,
      promotionMethod.value
    );
    let maxdate = new Date(date);
    maxdate.setDate(maxdate.getDate() + 1);
    setFromMaxDate(maxdate);
  };

  const changeToDate = date => {
    date == 'Invalid Date'
      ? setErrToDate('Invalid Date Format')
      : setErrToDate('');
    setToDate(date);

    handleSearch(
      searchKey,
      status.value,
      promotionCharity.value,
      fromDate,
      date,
      userId,
      promotionMethod.value
    );
    let mindate = new Date(date);
    mindate.setDate(mindate.getDate() - 1);
    setToMaxDate(mindate);
  };

  const loadUser = searchKey => {
    setUserOptions([]);
    setUserInputValue(searchKey);
    dispatch(actionCreators.searchCustomers(searchKey))
      .then(res => {
        var useroption = [];
        res.map(o =>
          useroption.push({
            value: o?.user_id,
            title: o?.full_name
          })
        );
        setUserOptions(useroption);
      })
      .catch(err => {
        // setPageLoader(false);
      });
  };
  const searchUserOrder = user => {
    setUserId(user);
    handleSearch(
      searchKey,
      status.value,
      promotionCharity.value,
      fromDate,
      toDate,
      user,
      promotionMethod.value
    );
  };
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h3">Orders</Typography>
      </Box>
      <Box mt={3}>
        <Grid container spacing={3}>
          <Grid item lg={3} sm={4} xl={3} xs={12}>
            <TotalCustomers
              title={'TOTAL ORDERS'}
              value={
                total_orders != '' && total_orders != undefined
                  ? parseInt(total_orders)
                  : 0
              }
              icon={<ShoppingCart />}
            />
          </Grid>
          <Grid item lg={3} sm={4} xl={3} xs={12}>
            <TotalCustomers
              title={'REVENUE GENERATED'}
              value={
                <CurrencyFormat
                  value={
                    total_revenue != '' && total_revenue != undefined
                      ? parseInt(total_revenue)
                      : 0
                  }
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'AED '}
                  renderText={value => value}
                />
              }
              icon={<AttachMoney style={{ fontSize: 30 }} />}
            />
          </Grid>
        </Grid>
      </Box>
      <Box mt={3}>
        <Paper style={{ padding: '20px' }}>
          <Grid container spacing={3} alignItems="center" justify="center">
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                placeholder="Search Order No."
                variant="outlined"
                size="medium"
                onChange={onSearch}
                value={searchKey}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Autocomplete
                value={userValue}
                onChange={(event, newValue) => {
                  var user = userOptions.find(function(user, index) {
                    if (user.title == newValue) return user.value;
                  });
                  user != '' && user != undefined
                    ? searchUserOrder(user.value)
                    : searchUserOrder('');
                  setUserValue(newValue);
                }}
                inputValue={userInputValue}
                onInputChange={(event, newInputValue) => {
                  loadUser(newInputValue);
                }}
                id="controllable-states-demo"
                options={userOptions.map(option => option.title)}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="User Orders"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Select
                style={{ flexGrow: '1' }}
                placeholder="Search Order"
                isLoading={isLoading}
                value={promotionCharity}
                styles={customStyles}
                options={promotionCharityOptions}
                onChange={onChangeProCharity}
                size="small"
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Select
                style={{ flexGrow: '1' }}
                isLoading={isLoading}
                value={promotionMethod}
                styles={customStyles}
                options={paymentOptions}
                onChange={onChangeProMethod}
                size="small"
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Select
                style={{ flexGrow: '1' }}
                isLoading={isLoading}
                value={status}
                styles={customStyles}
                options={orderStatusOptions}
                onChange={onChangeHandler}
                size="small"
              />
            </Grid>
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
              onClick={handleClick}
            >
              <MoreVert />
            </IconButton>
          </Grid>
        </Paper>
      </Box>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              fullWidth
              inputVariant="outlined"
              variant="dialog"
              id="from"
              label="From"
              format="dd/MM/yyyy"
              maxDate={toMaxDate}
              value={fromDate}
              onChange={changeFromDate}
              KeyboardButtonProps={{
                'aria-label': 'change date'
              }}
              error={Boolean(errFromDate)}
              helperText={errFromDate}
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={() => changeFromDate(null)}
                    disabled={!fromDate}
                  >
                    <Clear />
                  </IconButton>
                )
              }}
              InputAdornmentProps={{
                position: 'start'
              }}
            />
          </MuiPickersUtilsProvider>
        </MenuItem>
        <MenuItem>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              fullWidth
              inputVariant="outlined"
              variant="dialog"
              id="to"
              label="To"
              format="dd/MM/yyyy"
              minDate={fromMaxDate}
              value={toDate}
              onChange={changeToDate}
              KeyboardButtonProps={{
                'aria-label': 'change date'
              }}
              error={Boolean(errToDate)}
              helperText={errToDate}
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={() => changeToDate(null)}
                    disabled={!toDate}
                  >
                    <Clear />
                  </IconButton>
                )
              }}
              InputAdornmentProps={{
                position: 'start'
              }}
            />
          </MuiPickersUtilsProvider>
        </MenuItem>
      </Menu>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
  onCatAdded: PropTypes.func
};

export default Toolbar;
