import React, { useState, useRef, useEffect } from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  CardHeader,
  TextField
} from '@material-ui/core';
import Page from 'src/components/common/Page';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import AvatarPicker from './AvatarPicker';
import PhoneInput from 'react-phone-input-2';
import { useForm, Controller } from 'react-hook-form';
import 'react-phone-input-2/lib/material.css';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import axios from 'src/services/axios';
import { useSnackbar } from 'notistack';
import { saveToken, saveUserData, destroy } from 'src/services/authService';
import * as actionCreators from '../../../../store/actions';
import { useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

const validationSchema = Yup.object().shape({
  oldPassword: Yup.string().required('Old Password  is required'),
  newPassword: Yup.string()
    .matches(
      /^(?:(?=.*[a-z])(?:(?=.*[A-Z])(?=.*[\d\W])|(?=.*\W)(?=.*\d))|(?=.*\W)(?=.*[A-Z])(?=.*\d)).{8,}$/,
      {
        message:
          'Password must be at least 8 characters long contain a number and an uppercase letter and a special character',
        excludeEmptyString: true
      }
    )
    .required('New Password  is required'),
  reEnterPassword: Yup.string().oneOf(
    [Yup.ref('newPassword'), null],
    'Passwords must match'
  )
});

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  avatar: {
    height: 100,
    width: 100
  }
}));

const initialValues = {
  oldPassword: '',
  newPassword: '',
  reEnterPassword: ''
};

const Password = () => {
  const classes = useStyles();
  var user = JSON.parse(sessionStorage.getItem('ishro_user_data'));
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { handleSubmit, register, errors, control, setValue } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'onChange'
  });

  const onError = (errors, e) => {
    console.log(errors, e);
    setIsSubmitting(false);
  };

  const onPasswordSubmit = async (data, e) => {
    setIsSubmitting(true);

    const body = {
      old_password: data.oldPassword,
      new_password: data.newPassword
    };

    try {
      const resp = await axios.post('change-password', JSON.stringify(body));
      const { success, message } = resp.data;
      if (success) {
        enqueueSnackbar(message, { variant: 'success' });
        setIsSubmitting(false);
        dispatch(actionCreators.logout());
        return;
      }
      enqueueSnackbar(message, { variant: 'error' });
      setIsSubmitting(false);
      return;
    } catch (error) {
      enqueueSnackbar(error, { variant: 'error' });
      setIsSubmitting(false);
      return;
    }
  };

  return (
    <div className={clsx(classes.root)}>
      <form onSubmit={handleSubmit(onPasswordSubmit, onError)}>
        <Card>
          <CardHeader title="Change Password" />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  label="Old Password"
                  name="oldPassword"
                  variant="outlined"
                  type="password"
                  error={Boolean(errors?.oldPassword?.message)}
                  helperText={errors.oldPassword?.message}
                  inputRef={register}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  label="New Password"
                  name="newPassword"
                  type="password"
                  variant="outlined"
                  error={Boolean(errors?.newPassword?.message)}
                  helperText={errors.newPassword?.message}
                  inputRef={register}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  label="Re-Enter"
                  name="reEnterPassword"
                  type="password"
                  variant="outlined"
                  error={Boolean(errors?.reEnterPassword?.message)}
                  helperText={errors.reEnterPassword?.message}
                  inputRef={register}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <Box display="flex" justifyContent="flex-end" p={2}>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              disabled={isSubmitting}
            >
              Change
            </Button>
          </Box>
        </Card>
      </form>
    </div>
  );
};

export default Password;
