import React from 'react';
import { Chip } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { useConfirm } from 'material-ui-confirm';

import {
  RadioButtonCheckedRounded as Active,
  RadioButtonUncheckedRounded as InActive
} from '@material-ui/icons';

const ActiveChip = ({ status, onStatusChange, ...rest }) => {
  const confirm = useConfirm();
  const statusChangeHandler = () => {
    confirm({
      description: `Are you sure you want to ${
        status === '4' ? 'DELETE' : status === '1' ? 'DE-ACTIVATE' : 'ACTIVATE'
      } Product ?`
    })
      .then(msg => {
        onStatusChange(status === '1' ? '0' : '1');
      })
      .catch(err => {
        console.log("User Cancelled Action. Don't delete It.", err);
      });
  };
  return (
    <Chip
      style={{
        backgroundColor: status === '1' ? green['A400'] : '',
        marginRight: 4
      }}
      size="small"
      icon={status === '1' ? <Active /> : <InActive />}
      label={status === '1' ? 'Active' : 'In-Active'}
      onClick={statusChangeHandler}
      {...rest}
    />
  );
};

export default ActiveChip;
