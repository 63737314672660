import { Types } from 'src/store/actions/actionTypes';

const initialState = {
  // Categories

  categories: [],
  filteredCategories: [],

  // Types

  filteredTypes: [],
  types: [],

  // COUPONS

  filteredCoupons: [],
  coupons: [],
  // Promotions

  promotions: [],
  filteredPromotions: [],

  //Promotion Ticket
  promoTickets: [],
  Tickets: [],
  printPromoTickets: []
};

const promotion = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    // Categories

    case Types.SET_PROMO_CATEGORIES:
      return {
        ...state,
        categories: payload.categories
      };
    case Types.SET_FILTERED_PROMO_CATEGORIES:
      return {
        ...state,
        filteredCategories: payload.filteredCategories
      };
    case Types.CLEAR_PROMO_CATEGORIES:
      return {
        ...initialState
      };

    // Types

    case Types.SET_PROMO_TYPES:
      return {
        ...state,
        types: payload.promoTypes
      };
    case Types.SET_FILTERED_PROMO_TYPES:
      return {
        ...state,
        filteredTypes: payload.filteredPromoTypes
      };
    case Types.CLEAR_PROMO_TYPES:
      return {
        ...initialState
      };

    // Types

    case Types.SET_PROMO_COUPONS:
      return {
        ...state,
        coupons: payload.promoCoupons
      };
    case Types.SET_FILTERED_PROMO_COUPONS:
      return {
        ...state,
        filteredCoupons: payload.filteredPromoCoupons
      };
    case Types.CLEAR_PROMO_COUPONS:
      return {
        ...initialState
      };
    // Manage Promotions

    case Types.SET_PROMOTIONS:
      return {
        ...state,
        promotions: state.promotions.concat(payload.promotions),
        filteredPromotions: state.promotions.concat(payload.promotions)
      };
    case Types.SET_FILTERED_PROMOTIONS:
      return {
        ...state,
        filteredPromotions: payload.filteredPromotions
      };
    case Types.CLEAR_PROMOTIONS:
      return {
        ...state,
        promotions: []
      };

    //Promotion Ticket
    case Types.SET_PROMO_TICKETS:
      return {
        ...state,
        promoTickets: state.promoTickets.concat(payload.promoTickets)
      };
    case Types.SET_PRINT_PROMO_TICKETS:
      return {
        ...state,
        printPromoTickets: payload.printPromoTickets
      };
    case Types.CLEAR_FILTERED_TICKETS:
      return {
        ...state,
        promoTickets: []
      };
    case Types.SET_TICKETS:
      return {
        ...state,
        Tickets: state.Tickets.concat(payload.Tickets)
      };
    case Types.CLEAR_TICKETS:
      return {
        ...state,
        Tickets: []
      };
    default:
      return state;
  }
};

export default promotion;
