import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import { useEffect, useState } from 'react';
import { FormHelperText, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
const styles = makeStyles(() => ({
  dropzone: {
    margin: '90px',
    border: '2px solid #f44336 !important'
  }
}));

const MyUploader = ({ onFileChange, error }) => {
  const [files, setFiles] = useState();
  const classes = styles();

  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, file }, status) => {
    if (status === 'done') {
      !files
        ? setFiles([{ file, meta }])
        : setFiles([...files, { file, meta }]);

      !files
        ? onFileChange([{ file, meta }])
        : onFileChange([...files, { file, meta }]);
    } else if (status === 'removed') {
      const newFiles = files.filter(f => f.meta.id !== meta.id);
      setFiles(newFiles);
      onFileChange(newFiles);
    }
    console.log(status, meta, file);
  };

  return (
    <>
      {' '}
      {error && (
        <FormHelperText style={{ color: '#f44336' }} variant="filled">
          Please Select At Least One File
        </FormHelperText>
      )}
      <Dropzone
        onChangeStatus={handleChangeStatus}
        SubmitButtonComponent={null}
        accept="image/*,audio/*,video/*"
      />
    </>
  );
};

export default MyUploader;
