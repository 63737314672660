import React, { useState, forwardRef, useImperativeHandle } from 'react';
import ReactDOM from 'react-dom';
import QRCode from 'qrcode.react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useSnackbar } from 'notistack';
import { Button, Link } from '@material-ui/core';
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  TelegramIcon,
  WhatsappIcon,
  EmailIcon
} from 'react-share';
import Helmet from 'react-helmet';
import html2canvas from 'html2canvas';

function QRCodeApp(props, ref) {
  const [qrValue, setQrValue] = useState(
    'https://ishro.com/register?referal=' + props.referralCode.referral_code
  );
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const data = 'Please join the link';
  useImperativeHandle(
    ref,
    () => ({
      downloadQRCode() {
        const div = document.getElementById('qr-gen');
        html2canvas(div).then(function(canvas) {
          var pngUrl = canvas.toDataURL('image/png', 1.0);
          let downloadLink = document.createElement('a');
          downloadLink.href = pngUrl;
          downloadLink.download = `QRCode(${props.referralCode.referral_code}).png`;
          // downloadLink.download = `${referralCode}.png`;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        });
      }
    }),
    []
  );
  const handleCopy = () => {
    enqueueSnackbar('Successfully Copied', { variant: 'success' });
  };
  return (
    <div style={{ textAlign: 'center' }}>
      <Helmet>
        <title> </title>
        <meta name="description" content={data} />
        <link rel="canonical" href={qrValue} />

        <meta property="og:title" content={data} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={qrValue} />

        <meta property="og:description" content={data} />

        <meta itemprop="name" content={data} />
        <meta itemprop="description" content={data} />
        <meta itemprop="image" content="" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:description" content={data} />
        <meta name="twitter:image:src" content="" />
      </Helmet>
      <div
        id="qr-gen"
        style={{
          border: '3px solid #e2e2e2',
          borderRadius: '8px',
          margin: '0px 46px'
        }}
      >
        <img
          src="https://dashboard.ishro.com/static/logo_card.png"
          style={{ height: '70px', width: '192px', marginTop: '15%' }}
        />
        <p
          style={{
            color: '#e62483',
            fontSize: '26px',
            padding: '17px 0px 0px 0px',
            textTransform: 'uppercase'
          }}
        >
          {' '}
          {props.referralCode.first_name + ' ' + props.referralCode.last_name}
        </p>
        <QRCode value={qrValue} size={290} level={'H'} includeMargin={true} />
        <p
          style={{ color: ' #24cde6', fontSize: '24px', paddingBottom: '15%' }}
        >
          WWW.ISHRO.COM
        </p>
      </div>
      <div style={{ display: 'flex', paddingTop: '5%' }}>
        <Link
          href={qrValue}
          style={{
            border: '3px solid #e2e2e2',
            borderRadius: '4px',
            padding: '9px'
          }}
        >
          {qrValue}
        </Link>
        <CopyToClipboard text={qrValue} onCopy={() => handleCopy()}>
          <Button
            variant="contained"
            style={{ backgroundColor: '#e2e2e2', color: 'rgba(0, 0, 0, 0.87)' }}
          >
            Copy Link
          </Button>
        </CopyToClipboard>
      </div>
      <p style={{ textAlign: 'left', paddingTop: '4%', fontWeight: 'bold' }}>
        Share Your QR Code{' '}
      </p>
      <div style={{ textAlign: 'left' }}>
        <FacebookShareButton
          url={qrValue}
          quote={data + ' \n' + qrValue}
          style={{ marginRight: '15px', marginTop: '15px' }}
        >
          <img
            src="https://dashboard.ishro.com/static/facebook.svg"
            style={{ height: '32px', width: '32px' }}
          />
        </FacebookShareButton>

        <TwitterShareButton
          url={data + ' \n' + qrValue}
          style={{ marginRight: '15px', marginTop: '15px' }}
        >
          <img
            src="https://dashboard.ishro.com/static/twitter.svg"
            style={{ height: '32px', width: '32px' }}
          />
        </TwitterShareButton>

        <TelegramShareButton
          url={data + ' \n' + qrValue}
          style={{ marginRight: '15px', marginTop: '15px' }}
        >
          <img
            src="https://dashboard.ishro.com/static/telegram.svg"
            style={{ height: '32px', width: '32px' }}
          />
        </TelegramShareButton>

        <WhatsappShareButton
          url={data + ' \n' + qrValue}
          separator=":: "
          style={{ marginRight: '15px', marginTop: '15px' }}
        >
          <img
            src="https://dashboard.ishro.com/static/whatspp.svg"
            style={{ height: '32px', width: '32px' }}
          />
        </WhatsappShareButton>

        <EmailShareButton
          url={data + ' \n' + qrValue}
          style={{ marginRight: '15px', marginTop: '15px' }}
        >
          <img
            src="https://dashboard.ishro.com/static/gmail.svg"
            style={{ height: '32px', width: '32px' }}
          />
        </EmailShareButton>
      </div>
    </div>
  );
}
export default forwardRef(QRCodeApp);
