import React, { useState } from 'react';
import axios from 'src/services/axios';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Divider,
  Button,
  LinearProgress
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import 'emoji-mart/css/emoji-mart.css';
import '../../../../assets/styles/MainStyles.css';
import { CSVLink } from 'react-csv';
import { decode, isValid } from 'js-base64';
const ExportDialog = ({ open, onClose, promotion_id, promotion_title }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [downloadData, setDownloadData] = useState('');

  const isBase64 = str => {
    if (str === '' || str.trim() === '') {
      return false;
    }
    try {
      return btoa(atob(str)) == str;
    } catch (err) {
      return false;
    }
  };

  const onSubmitHandler = async () => {
    try {
      setIsSubmitting(true);
      const resp = await axios.post('export-tickets', {
        promotion_id: promotion_id
      });
      setIsSubmitting(false);
      if (resp.data.success) {
        setDownloadData(resp.data.data);
        enqueueSnackbar(resp.data.message, { variant: 'success' });
        return;
      }
      enqueueSnackbar(resp.data.message, { variant: 'error' });
      return;
    } catch (error) {
      enqueueSnackbar(error, { variant: 'error' });
      return;
    }
  };

  const onClickHandler = () => {
    onClose();
  };

  console.log('downloadData :', downloadData);
  return (
    <Dialog
      open={open}
      onClose={onClickHandler}
      aria-labelledby="form-dialog-title"
      PaperProps={{ style: { overflowY: 'visible', width: '1000px' } }}
    >
      <DialogTitle id="form-dialog-title">Download Csv File</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item md={12} xs={12} style={{ padding: '0px 0px 0px 18px' }}>
            {(isBase64(promotion_title)
              ? decode(promotion_title).replace(/<[^>]+>/g, '')
              : promotion_title) + ' Ticket Holder'}
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      {isSubmitting && <LinearProgress />}
      <DialogActions>
        <Button
          disabled={isSubmitting}
          onClick={onClickHandler}
          color="primary"
        >
          {' '}
          Cancel
        </Button>
        {downloadData == '' ? (
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={isSubmitting}
            onClick={onSubmitHandler}
          >
            {' '}
            Process{' '}
          </Button>
        ) : (
          <CSVLink
            data={downloadData}
            filename={
              (isBase64(promotion_title)
                ? decode(promotion_title).replace(/<[^>]+>/g, '')
                : promotion_title) +
              ' Ticket Holder  - ' +
              new Date().toLocaleString()
            }
          >
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={false}
            >
              Download{' '}
            </Button>
          </CSVLink>
        )}
      </DialogActions>
    </Dialog>
  );
};

ExportDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  promotion_id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired
};

export default ExportDialog;
