import React, { useEffect, useState } from 'react';
import {
  Container,
  Grid,
  Box,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  CardActions,
  Typography,
  Chip,
  makeStyles,
  Button,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DeleteButton from 'src/components/common/DeleteButton';
import EditButton from 'src/components/common/EditButton';
import Loader from 'src/components/common/loader';
import { green } from '@material-ui/core/colors';
import Page from 'src/components/common/Page';
import {
  RadioButtonCheckedRounded as Active,
  RadioButtonUncheckedRounded as InActive
} from '@material-ui/icons';
import InfiniteScroll from 'react-infinite-scroller';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useConfirm } from 'material-ui-confirm';
import * as actionCreators from 'src/store/actions';
import { useNavigate } from 'react-router';
import Toolbar from './Toolbar';

const useStyles = makeStyles(theme => ({
  media: {
    height: '14em'
    // width: '100%'
  }
}));

const SponsorsListing = props => {
  const { searchKey } = props;
  const [status, setStatus] = useState('Loading');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();
  const [permission, setPermission] = React.useState([]);
  const [permissionOpen, setPermissionOpen] = React.useState(false);
  const permissions = useSelector(state => state.permissions.permissions);
  const filteredSponsors = useSelector(
    state => state.sponsors.filteredSponsors
  );
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [user, setUser] = React.useState();
  useEffect(() => {
    init();
  }, []);

  const init = () => {
    loadMoreSponsors('refresh');
  };

  const loadMoreSponsors = (type = '', search = '') => {
    let PageNo = page + 1;
    if (type === 'refresh') {
      PageNo = 1;
    }
    console.log('Loading Sponsors ' + JSON.stringify(PageNo));
    dispatch(actionCreators.loadSponsors(search, PageNo))
      .then(value => {
        setPage(PageNo);
      })
      .catch(err => {
        PageNo === 1 && err.message == 'No sponsor found'
          ? setStatus('No sponsors found')
          : setStatus('No more sponsors');
        console.log('Setting HAS MORE FALSE');
        setHasMore(false);
      });
  };

  // Methods
  const changeStatus = (id, status) => {
    const body = {
      id: id,
      status
    };
    dispatch(actionCreators.changeSponsorStatus(body))
      .then(msg => {
        enqueueSnackbar(msg, {
          variant: 'success'
        });
        init();
        return;
      })
      .catch(err => {
        console.error(err);
        enqueueSnackbar(err, { variant: 'error' });
      });
  };
  const editSponsor = id => navigate(`/sponsors/addSponsor/${id}`);
  const onStatusChangeHandler = (id, status) => {
    confirm({
      description: `Are you sure you want to ${
        status === '4' ? 'DELETE' : status === '1' ? 'ACTIVATE' : 'DE-ACTIVATE'
      } Product ?`
    })
      .then(() => {
        changeStatus(id, status);
      })
      .catch(err => {
        console.log("User Cancelled Action. Don't delete It.", err);
      });
  };

  const filterSponsorsHandler = search => {
    loadMoreSponsors('refresh', search);
  };
  const handleChange = event => {
    setPermission({
      ...permission,
      [event.target.name]: event.target.checked == true ? '1' : '0'
    });
  };
  const handlePermissionsClose = () => {
    setPermissionOpen(false);
  };

  const handlePermissionsOpen = user => {
    setUser(user);
    dispatch(actionCreators.getUserPermission(user.user_id))
      .then(data => {
        setPermission(data);
        setPermissionOpen(true);
      })
      .catch(err => {});
  };

  const setUserPermission = () => {
    dispatch(actionCreators.setUserPermission(user.user_id, permission))
      .then(msg => {
        enqueueSnackbar(msg, {
          variant: 'success'
        });
        setPermissionOpen(false);
      })
      .catch(err => {
        enqueueSnackbar(err, {
          variant: 'error'
        });
        setPermissionOpen(false);
      });
  };

  return (
    <Page className={classes.root} title="Sponsors">
      <Container maxWidth={false}>
        <Toolbar onFilter={filterSponsorsHandler} />
        <Box mt={3}>
          {filteredSponsors.length > 0 ? (
            <InfiniteScroll
              style={{ overflow: 'hidden' }}
              dataLength={filteredSponsors.length}
              next={loadMoreSponsors}
              hasMore={hasMore}
              scrollableTarget="scroll"
              loader={
                <center>
                  <Chip
                    className={classes.infiniteMsg}
                    size="small"
                    color="default"
                    label="Loading..."
                  />
                </center>
              }
              endMessage={
                <center>
                  <Chip
                    className={classes.infiniteMsg}
                    size="small"
                    color="default"
                    label="All Sponsors Have Been Loaded. "
                  />
                </center>
              }
            >
              <Grid container spacing={2}>
                {filteredSponsors.map((sponsor, index) => (
                  <Grid item xs={12} md={3} key={index + 1}>
                    <Card style={{ padding: '20px' }}>
                      <Typography gutterBottom variant="h5" component="h2">
                        <Typography>
                          {sponsor.first_name + ' ' + sponsor.last_name}
                        </Typography>
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                        className={classes.desc}
                      >
                        {sponsor.email}
                      </Typography>

                      <CardActions style={{ justifyContent: 'flex-end' }}>
                        <img
                          src="https://dashboard.ishro.com/static/user-locked.svg"
                          style={{
                            height: '42px',
                            width: '42px',
                            boxShadow:
                              '0 0 1px 0 rgb(0 0 0 / 31%), 0 2px 2px -2px rgb(0 0 0 / 25%)',
                            padding: '4px',
                            borderRadius: '4px',
                            cursor: 'pointer'
                          }}
                          onClick={() => handlePermissionsOpen(sponsor)}
                        />
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </InfiniteScroll>
          ) : (
            <Loader status={status === 'No sponsors found' ? status : null} />
          )}
        </Box>
      </Container>{' '}
      {/* Permissions */}
      <Dialog
        open={permissionOpen}
        onClose={handlePermissionsClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ padding: '26px 32px 13px 61px' }}
        >
          <Typography color="textPrimary" variant="h3">
            {'Permissions'}
          </Typography>
        </DialogTitle>
        <DialogContent style={{ padding: '20px 62px' }}>
          {Object.entries(permission).map(([key, value], index) => (
            <FormControlLabel
              control={
                <Checkbox
                  checked={value == '0' ? false : true}
                  onChange={handleChange}
                  name={key}
                  color="primary"
                />
              }
              label={
                <Typography style={{ textTransform: 'capitalize' }}>
                  <strong>{key.replaceAll('_', ' ')}</strong>
                </Typography>
              }
              className="percheck"
            />
          ))}
        </DialogContent>
        <DialogActions style={{ paddingBottom: '31px', paddingRight: '31px' }}>
          <Button
            onClick={setUserPermission}
            variant="contained"
            color="primary"
          >
            Save
          </Button>
          <Button
            onClick={handlePermissionsClose}
            variant="contained"
            style={{
              backgroundColor: '#e2e2e2',
              color: 'rgba(0, 0, 0, 0.87)'
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
};

export default React.memo(SponsorsListing);
