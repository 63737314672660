import axios from '../../services/axios';
import { Types } from 'src/store/actions/actionTypes';

/**
 * Load Categories action
 */
export const loadAuctionCategories = (search = '') => {
  
  console.log('search :', search);
  return dispatch => {
    return new Promise((resolve, reject) => {
      const body = {
        search: search
      };
      axios
        .post('get-auction-category', JSON.stringify(body))
        .then(res => {
          console.log(res);
          if (res?.data?.success) {
            dispatch(setAuctionCategories(res.data.data));            
            dispatch(setFilteredAuctionCategories(res.data.data));
            resolve(res.data.data);
                     
          } else {
            reject();
          }
        })
        .catch(error => {
          console.log(error);
          if (error) {
            reject(new Error(error));
          }
        });
    });
  };
};

export const changeAuctionCategoryStatus = body => async dispatch => {
  try {
    const resp = await axios.post(
      'change-auction-category-status',
      JSON.stringify(body)
    );
    const { message, success } = resp.data;
    if (success) {
      dispatch(loadAuctionCategories(''));
      return message;
    }
    throw message;
  } catch (error) {
    throw error;
  }
};

export const addAuctionCategory = body => async dispatch => {
  try {
    const resp = await axios.post(
      'add-auction-category',
      JSON.stringify(body)
    );
    const { message, success } = resp.data;
    if (success) {
      dispatch(loadAuctionCategories(''));
      return message;
    }
    throw message;
  } catch (error) {
    throw error;
  }
};

export const filterAuctionCategories = (search, categories) => {
  const filteredList = categories.filter(el =>
    el.category_name.toLowerCase().includes(search.toLowerCase())
  );
  // console.log(filteredList);
  return dispatch => dispatch(setFilteredAuctionCategories(filteredList));
};

export const setAuctionCategories = categories => {
  return {
    type: Types.SET_AUCTION_CATEGORIES,
    payload: { categories }
  };
};

const setFilteredAuctionCategories = filteredCategories => ({    
  type: Types.SET_FILTERED_AUCTION_CATEGORIES,
  payload: { filteredCategories }
});

const clearAuctionCategories = () => ({
  type: Types.CLEAR_AUCTION_CATEGORIES,
  payload: {}
});
