import { Types } from 'src/store/actions/actionTypes';

const initialState = {
  customers: [],
  totalCustomers: []
};

const customer = (state = initialState, action) => {
  switch (action.type) {
    case Types.SET_CUSTOMERS:
      return {
        ...state,
        customers: action.payload
      };
    case Types.SET_TOTAL_CUSTOMERS:
      return {
        ...state,
        totalCustomers: action.payload
      };
    case Types.CLEAR_CUSTOMERS:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default customer;
