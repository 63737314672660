import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import * as actionCreators from '../../../../store/actions';
import FileUploader from 'src/components/common/FileUploader';
import { connect, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  selectField: {
    margin: '15px 0px'
  }
}));

const validationSchema = Yup.object().shape({
  title: Yup.string()
    .max(255)
    .required('Name is required'),
  category: Yup.string()
    .max(255)
    .required('Select Category'),
  price: Yup.number(),
  stock: Yup.number().required('Enter Available Stock'),
  desc: Yup.string()
    .max(500)
    .required('Description is required'),
  files: Yup.array()
    .max(500)
    .required('Please Upload Atleast One File')
});

const Toolbar = ({
  className,
  filterProducts,
  loadProducts,
  addProduct,
  products,
  loadCategories,
  categories,
  // Callback Function from Child to Parent
  onFormSubmitted,
  onError,
  onFilter,
  ...rest
}) => {
  const classes = useStyles();
  const [addCat, setAddCat] = useState(false);
  const navigate = useNavigate();
  const permissions = useSelector(state => state.permissions.permissions);

  useEffect(() => init(), []);

  const init = () => {
    loadCategories();
  };

  const handleSearch = e => {
    console.log(e.target.value);
    onFilter(e.target.value);
  };

  const openDialog = () => {
    navigate('/promotion/addPromotion');
    // setAddCat(true);
  };

  const handleClose = e => {
    console.log(e.target.value);
    setAddCat(false);
  };

  const addProductHandler = (values, { setSubmitting }) => {
    console.log(values);
    addProduct(values)
      .then(() => {
        onFormSubmitted();
        setAddCat(false);
        setSubmitting(false);
      })
      .catch(err => {
        console.log(err);
        setAddCat(false);
        setSubmitting(false);
        onError('Error Adding Product. Error: ' + err);
      });
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box display="flex" justifyContent="flex-end">
        {permissions.content_manager == '1' || permissions.admin == '1' ? (
          <Button color="primary" variant="contained" onClick={openDialog}>
            Add Promotion
          </Button>
        ) : (
          ''
        )}
      </Box>
      <Box mt={3}>
        <Card>
          <CardContent>
            <Box maxWidth={500}>
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                placeholder="Search Product or Category"
                variant="outlined"
                onChange={handleSearch}
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
  onFilter: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => {
  return {
    loadCategories: () => dispatch(actionCreators.loadCategories('')),
    filterProducts: (searchKey, products) =>
      dispatch(actionCreators.filterProducts(searchKey, products)),
    loadProducts: () => dispatch(actionCreators.loadProducts('')),
    addProduct: data => dispatch(actionCreators.addProduct(data))
  };
};

const mapStateToProps = state => {
  // console.log('state :', state);
  return {
    categories: state.categories.categories,
    products: state.products.products
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Toolbar);
