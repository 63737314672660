import React, { useState, useEffect } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  LinearProgress,
  Switch,
  Fab
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { Trash2 as Delete } from 'react-feather';
import Loader from 'src/components/common/loader';
import ConfirmDialog from 'src/components/common/dialog';
import { ColorButton } from 'material-ui-color';
import { connect, useSelector } from 'react-redux';
import * as actionCreators from '../../../../store/actions';
import { CreateRounded as EditIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from 'react-router';

const useStyles = makeStyles(theme => ({
  DeleteFab: {
    backgroundColor: red[300],
    color: 'white',
    width: '37px',
    height: '37px',
    marginRight: '29px',
    '&:hover': {
      backgroundColor: red[500]
    }
  },
  EditFab: {
    color: 'white',
    width: '37px',
    height: '37px'
  }
}));

const Results = ({
  loadPromoTypes,
  changePromoTypeStatus,
  filteredPromoTypes,
  onStatusChanged,
  editPromotion
}) => {
  useEffect(() => {
    init();
  }, []);

  const [loading, setLoading] = useState(false);
  const [itemToBeDeleted, setItemToBeDeleted] = useState('');
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const permissions = useSelector(state => state.permissions.permissions);

  const navigate = useNavigate();
  const init = () => {
    loadPromoTypes('')
      .then(value => {})
      .catch(err => {});
  };

  const launchDialog = id => {
    setItemToBeDeleted(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleComfirm = () => {
    //Emit Event To Parent Component
    setOpen(false);
    changeStatus(itemToBeDeleted, '4');
  };

  const changeStatus = (type_id, status) => {
    setLoading(true);
    const body = {
      type_id,
      status
    };
    changePromoTypeStatus(body)
      .then(message => {
        setLoading(false);
        onStatusChanged({ msg: message, type: 'success' });
      })
      .catch(err => {
        setLoading(false);
        onStatusChanged({
          msg: 'Error Deleting Promo Type. Error: ' + err,
          type: 'error'
        });
      });
  };
  const onEditHandler = promoType => {
    editPromotion(promoType);
    // navigate(`/promotion/type/${id}`);
  };
  return filteredPromoTypes?.length > 0 ? (
    <Card>
      <PerfectScrollbar>
        <Box>
          {loading && <LinearProgress />}
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Ticket Per Unit</TableCell>
                <TableCell>English Image</TableCell>
                <TableCell>Arabic Image</TableCell>
                <TableCell>Color</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody id="scrollableDiv">
              {filteredPromoTypes?.length > 0 &&
                filteredPromoTypes.map((promo, index) => (
                  <TableRow hover key={index}>
                    <TableCell>{promo.type_id}</TableCell>
                    <TableCell>{promo.type_name}</TableCell>
                    <TableCell>{promo.tickets_per_unit}</TableCell>
                    <TableCell>
                      <img src={promo.image_en} width="63px" />
                    </TableCell>
                    <TableCell>
                      <img src={promo.image_ar} width="63px" />
                    </TableCell>
                    <TableCell>
                      {' '}
                      <ColorButton color={promo.color_code} />
                    </TableCell>
                    <TableCell>
                      <Switch
                        checked={promo.status === '1' ? true : false}
                        onChange={e =>
                          changeStatus(
                            promo.type_id,
                            e.target.checked ? '1' : '0'
                          )
                        }
                      />
                    </TableCell>
                    <TableCell>
                      {(permissions.content_manager == '1' ||
                        permissions.admin == '1') &&
                      promo.type_id != '32' ? (
                        <Fab
                          onClick={() => launchDialog(promo.type_id)}
                          color="primary"
                          aria-label="delete"
                          className={classes.DeleteFab}
                        >
                          <Delete />
                        </Fab>
                      ) : (
                        ''
                      )}
                      {permissions.content_manager == '1' ||
                      permissions.admin == '1' ? (
                        <Fab
                          color="primary"
                          aria-label="edit"
                          className={classes.EditFab}
                          onClick={() => onEditHandler(promo)}
                        >
                          <EditIcon />
                        </Fab>
                      ) : (
                        ''
                      )}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <ConfirmDialog
        open={open}
        message="Are you sure you want to Delete ? This process cannot be undone."
        onConfirmed={handleComfirm}
        handleClose={handleClose}
      />
    </Card>
  ) : (
    <Loader />
  );
};

const mapDispatchToProps = dispatch => {
  return {
    loadPromoTypes: searchKey =>
      dispatch(actionCreators.loadPromoTypes(searchKey)),
    changePromoTypeStatus: body =>
      dispatch(actionCreators.changePromoTypeStatus(body))
  };
};

const mapStateToProps = state => {
  console.log('state :', state);
  return {
    filteredPromoTypes: state.promotion.filteredTypes
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Results);
