import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import axios from 'src/services/axios';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  ListItemText,
  Divider,
  Button,
  Checkbox,
  OutlinedInput,
  LinearProgress,
  FormControlLabel,
  Select,
  FormGroup,
  FormControl,
  FormLabel,
  Input,
  InputLabel,
  MenuItem,
  Chip
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';
import 'emoji-mart/css/emoji-mart.css';
import '../../../../assets/styles/MainStyles.css';
import { Picker } from 'emoji-mart';
import InputEmoji from 'react-input-emoji';
import * as Yup from 'yup';
import { makeStyles, Typography } from '@material-ui/core';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import DropZone from 'src/components/common/dropZone';
import { decode, isValid } from 'js-base64';
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2
} from 'react-html-parser';
const useStyles = makeStyles(theme => ({
  root: {
    overflowY: 'auto',
    height: '600px'
  }
}));

const theme = createMuiTheme({
  overrides: {
    MuiTypography: {
      body2: {
        fontSize: [12, '!important'],
        paddingLeft: '22px'
      }
    }
  }
});

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 48 * 4.5 + 8,
      width: 350
    }
  }
};

const initialValues = {
  email_title: '',
  message: '',
  is_name: false,
  is_image: false
};

const EmailDialog = ({ open, onClose, promotion_id, promotion_title }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [email_title, setTitle] = useState('');
  const [errTitle, setErrTitle] = useState('');
  const [message, setMessage] = useState('');
  const [errMessage, setErrMessage] = useState('');
  const [isName, setName] = useState(false);
  const [isImage, setImage] = useState(false);
  const [text, setText] = useState('');
  const [isBusiness, setBusiness] = useState('');
  const [promotionSugg, setPromotionSugg] = React.useState([]);
  const [offerImage, setOfferImage] = React.useState([]);
  const [labelWidth, setlabelWidth] = React.useState(160);
  const InputLabelRef = useRef();
  const promotionList = useSelector(state => state.promotion.promotions);

  useEffect(() => {
    for (let m = 0; m < promotionList.length; m++) {
      promotionList[m].draw_detail = '';
      promotionList[m].description = '';
      promotionList[m].description_ar = '';
      promotionList[m].bundle_ids = '';
    }
  }, [promotionList]);
  const handleChange = event => {
    setBusiness(event.target.value);
  };

  const handleTitle = value => {
    if (value == '') {
      setErrTitle('Email Title is required');
    } else {
      setErrTitle('');
      setTitle(value);
    }
  };

  const handleMessage = value => {
    if (value == '') {
      setErrMessage('Email Message is required');
    } else {
      setMessage(value);
      setErrMessage('');
    }
  };

  const onSubmitHandler = async () => {
    var offer_imageFile;
    try {
      if (offerImage.length > 0) {
        offer_imageFile = await uploadFiles(offerImage);
        if (!offer_imageFile) throw 'Error Uploading Draw Image File';
      }

      if (email_title != '' && message != '') {
        const data = {
          title: email_title,
          message: message,
          is_name: isName.toString(),
          is_image: isImage.toString(),
          is_users: isBusiness.toString(),
          suggestions: promotionSugg,
          offer_image:
            offer_imageFile != undefined ? offer_imageFile[0].file_url : null
        };
        setIsSubmitting(true);
        const resp = await axios.post('send-email-promotion', {
          promotion_id: promotion_id,
          ...data
        });
        setIsSubmitting(false);
        if (resp.data.success) {
          enqueueSnackbar(resp.data.message, { variant: 'success' });
          onClose();
          return;
        }
        enqueueSnackbar(resp.data.message, { variant: 'error' });
        return;
      } else {
        handleTitle(email_title);
        handleMessage(message);
      }
    } catch (error) {
      enqueueSnackbar(error, { variant: 'error' });
      return;
    }
  };

  const onClickHandler = () => {
    onClose();
  };

  const onError = (errors, e) => console.log(errors, e);
  const classes = useStyles();

  const uploadFiles = async files => {
    console.log(files);
    const fileUrls = [];
    for (let m = 0; m < files.length; m++) {
      try {
        const file = files[m];
        if (typeof file === 'string') {
          fileUrls.push({ file_type: 'image', file_url: file });
          continue;
        }
        console.log('Wont be logged');
        let formData = new FormData();
        formData.append('file', file);
        const resp = await axios.post(
          'https://api.ishro.com/v3/upload-file-google',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        );
        if (resp.data.success) {
          fileUrls.push(resp.data.results.files[0]);
          console.log(resp);
        } else {
          continue;
        }
      } catch (error) {
        console.log(error);
        continue;
      }
    }
    return fileUrls;
  };

  const isBase64 = str => {
    if (str === '' || str.trim() === '') {
      return false;
    }
    try {
      return btoa(atob(str)) == str;
    } catch (err) {
      return false;
    }
  };
  return (
    <Dialog
      open={open}
      onClose={onClickHandler}
      aria-labelledby="form-dialog-title"
      maxWidth="md"
      PaperProps={{ style: { overflowY: 'visible' } }}
    >
      <DialogTitle id="form-dialog-title">Send Email</DialogTitle>
      <DialogContent className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12} className={errTitle == '' ? '' : 'errormessage'}>
            <InputEmoji
              value={email_title}
              name="email_title"
              id="email_title"
              onChange={handleTitle}
              placeholder="Email Title"
            />
            {errTitle && (
              <ThemeProvider theme={theme}>
                {' '}
                <Typography color="error" variant="body2">
                  {' '}
                  {errTitle}{' '}
                </Typography>{' '}
              </ThemeProvider>
            )}
          </Grid>

          <Grid
            item
            xs={12}
            className={
              errMessage == '' ? 'notifymessage' : 'notifymessage errormessage'
            }
          >
            <InputEmoji
              value={message}
              type="text"
              name="message"
              id="message"
              onChange={handleMessage}
              placeholder="Email Message"
            />
            {errMessage && (
              <ThemeProvider theme={theme}>
                {' '}
                <Typography color="error" variant="body2">
                  {' '}
                  {errMessage}{' '}
                </Typography>{' '}
              </ThemeProvider>
            )}
          </Grid>

          <Grid item md={12} xs={12} style={{ padding: '0px 0px 0px 18px' }}>
            <div>
              <DropZone
                id="file2"
                col={1}
                onFilesChange={filesArray => setOfferImage(filesArray)}
                label="Upload Offer Images"
              />
            </div>

            <FormControl
              variant="outlined"
              fullWidth
              style={{ marginTop: '26px' }}
            >
              <InputLabel ref={InputLabelRef} htmlFor="members">
                Promotion Suggestion
              </InputLabel>
              <Select
                multiple
                value={promotionSugg}
                onChange={event => setPromotionSugg(event.target.value)}
                input={
                  <OutlinedInput
                    labelWidth={labelWidth}
                    name="members"
                    id="members"
                  />
                }
                renderValue={selected => (
                  <div className={classes.chips}>
                    {selected.map(value => (
                      <Chip
                        key={value}
                        label={
                          isBase64(value.title)
                            ? ReactHtmlParser(
                                decode(value.title).replace(/<[^>]+>/g, '')
                              )
                            : value.title
                        }
                        className={classes.chip}
                      />
                    ))}
                  </div>
                )}
                MenuProps={MenuProps}
              >
                {promotionList.map(promotion => (
                  <MenuItem key={promotion.promotion_id} value={promotion}>
                    <Checkbox checked={promotionSugg.indexOf(promotion) > -1} />
                    <ListItemText
                      primary={
                        isBase64(promotion?.title)
                          ? ReactHtmlParser(
                              decode(promotion?.title).replace(/<[^>]+>/g, '')
                            )
                          : promotion?.title
                      }
                    />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControlLabel
              name="is_name"
              control={
                <Checkbox
                  checked={isName}
                  onChange={e => setName(e.target.checked)}
                />
              }
              label="Hi Joe,"
            />
            <FormControlLabel
              name="is_image"
              control={
                <Checkbox
                  checked={isImage}
                  onChange={e => setImage(e.target.checked)}
                />
              }
              label="Included Image"
            />
            <FormControl
              variant="outlined"
              style={{ marginTop: '12px' }}
              fullWidth
            >
              <InputLabel id="demo-simple-select-outlined-label">
                Users
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={isBusiness}
                onChange={handleChange}
                label="Users"
              >
                <MenuItem value="-1">Test</MenuItem>
                <MenuItem value="0">All</MenuItem>
                <MenuItem value="1">Customers</MenuItem>
                <MenuItem value="2">
                  {(isBase64(promotion_title)
                    ? decode(promotion_title).replace(/<[^>]+>/g, '')
                    : promotion_title) + ' Ticket Holder'}
                </MenuItem>
                <MenuItem value="3">
                  {(isBase64(promotion_title)
                    ? decode(promotion_title).replace(/<[^>]+>/g, '')
                    : promotion_title) + ' In Cart'}
                </MenuItem>
                <MenuItem value="4">
                  {(isBase64(promotion_title)
                    ? decode(promotion_title).replace(/<[^>]+>/g, '')
                    : promotion_title) + ' In Favorite'}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      {isSubmitting && <LinearProgress />}
      <DialogActions>
        <Button
          disabled={isSubmitting}
          onClick={onClickHandler}
          color="primary"
        >
          {' '}
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={isSubmitting}
          onClick={onSubmitHandler}
        >
          {' '}
          Send Email{' '}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

EmailDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  promotion_id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired
};

export default EmailDialog;
