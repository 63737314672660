import { Types } from '../actions/actionTypes';

const initialState = {
  default: 0,
  fileProgress: 0
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.SET_PROGRESS:
      // console.log(
      //   `Progress for ${action.payload.actionName} is ${action.payload.progress}`
      // );
      return {
        ...state,
        fileProgress: action.payload.progress,
        formSubmitted: false // after update user formsubmition reset
      };

    default:
      return state;
  }
};

export default reducer;
