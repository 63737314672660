import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  makeStyles
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import axios from 'src/services/axios';
const useStyles = makeStyles({
  root: {}
});

const QuickBooks = ({ className, ...rest }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [values, setValues] = useState({
    access_token: '',
    refresh_token: ''
  });

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const updateToken = async () => {
    
    if (values.access_token != '' || values.refresh_token != '') {
      const body = {
        access_token: values.access_token,
        refresh_token: values.refresh_token
      };
      try {
        const resp = await axios.post(
          'quickbook/update-token',
          JSON.stringify(body)
        );
        const { success, message } = resp.data;

        if (success) {
          enqueueSnackbar(message, { variant: 'success' });
          return;
        }
        enqueueSnackbar(message, { variant: 'error' });
        return;
      } catch (error) {
        enqueueSnackbar(error, { variant: 'error' });
        return;
      }
    } else {
      enqueueSnackbar('enter token value', { variant: 'error' });
      return;
    }
  };
  return (
    <form className={clsx(classes.root, className)} {...rest}>
      <Card>
        <CardHeader subheader="Quick books Token update" title="Quick books" />
        <Divider />
        <CardContent>
          <TextField
            fullWidth
            label="Access Token"
            margin="normal"
            name="access_token"
            onChange={handleChange}
            type="text"
            value={values.access_token}
            variant="outlined"
          />
          <TextField
            fullWidth
            label="Refresh Token"
            margin="normal"
            name="refresh_token"
            onChange={handleChange}
            type="text"
            value={values.refresh_token}
            variant="outlined"
          />
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => updateToken()}
          >
            Update
          </Button>
        </Box>
      </Card>
    </form>
  );
};

QuickBooks.propTypes = {
  className: PropTypes.string
};

export default QuickBooks;
