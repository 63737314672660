import React from 'react';
// Material UI
import { Container, Box, Card, CardContent, Grid } from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Page from 'src/components/common/Page';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2
} from 'react-html-parser';
import { decode, isValid } from 'js-base64';
import './print.css';

const isBase64 = str => {
  if (str === '' || str.trim() === '') {
    return false;
  }
  try {
    return btoa(atob(str)) == str;
  } catch (err) {
    return false;
  }
};
const checkundefined = ticket => {
  if (ticket.user == undefined || ticket.user == null) {
    alert('user is undefind  ' + ticket.ticket_number);
    console.log(ticket);
  }
};
class PrintTicket extends React.PureComponent {
  render() {
    return (
      <Grid container alignItems="center" style={{ justifyContent: 'center' }}>
        {this.props.printTickets?.map((ticket, index) => {
          checkundefined(ticket);
          return (
            <Grid key={index}>
              <div
                style={{
                  height: '58mm',
                  width: '90mm',
                  border: '1px solid rgb(255 255 255)',
                  marginRight: '3mm',
                  padding: '3mm 5mm',
                  display: 'flex',
                  justifyConten: 'center',
                  alignItems: 'center'
                }}
              >
                <div style={{ width: '100%' }}>
                  <div>
                    <div style={{ float: 'left' }}>
                      <img
                        src="https://dashboard.ishro.com/static/ticket_logo.svg"
                        style={{ height: '31px' }}
                      />
                      <h3
                        style={{
                          fontSize: '12px',
                          margin: '4px 0px'
                        }}
                      >
                        {isBase64(ticket.promotion.title)
                          ? ReactHtmlParser(
                              decode(ticket.promotion.title).replace(
                                /<[^>]+>/g,
                                ''
                              )
                            )
                          : ticket.promotion.title}{' '}
                      </h3>
                    </div>
                    <div style={{ float: 'right' }}>
                      <img
                        src={ticket.promotion.files.file}
                        style={{ width: '72px' }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      float: 'left',
                      width: '100%',
                      height: '1px',
                      backgroundColor: 'rgb(12 11 11)',
                      marginBottom: '15px'
                    }}
                  ></div>
                  <div>
                    <div
                      style={{
                        float: 'left',
                        width: '100%'
                      }}
                    >
                      <h5
                        style={{
                          fontSize: '8px',
                          marginTop: '2px',
                          marginBottom: '0px'
                        }}
                      >
                        {'Purchased by'}
                      </h5>
                      <h4
                        style={{
                          fontSize: '13px',
                          marginTop: '0px',
                          fontWeight: '700',
                          marginBottom: '5px'
                        }}
                      >
                        {ticket.user.first_name} {ticket.user.last_name}
                      </h4>
                      <h5
                        style={{
                          fontSize: '8px',
                          marginTop: '0px',
                          marginBottom: '0px'
                        }}
                      >
                        {'Ticket Number'}
                      </h5>
                      <h4
                        style={{
                          fontSize: '11px',
                          marginTop: '0px',
                          fontWeight: '700',
                          marginBottom: '5px'
                        }}
                      >
                        {ticket.ticket_number}
                      </h4>
                      <div>
                        <div
                          style={{
                            float: 'left',
                            paddingRight: '89px'
                          }}
                        >
                          <h5
                            style={{
                              fontSize: '8px',
                              marginTop: '0px',
                              marginBottom: '0px'
                            }}
                          >
                            {'Mobile Number'}
                          </h5>
                          <h4
                            style={{
                              fontSize: '11px',
                              marginTop: '0px',
                              fontWeight: '700',
                              marginBottom: '0px'
                            }}
                          >
                            {ticket.user.country_code} {ticket.user.mobile}
                          </h4>
                        </div>
                        <div style={{ float: 'right' }}>
                          <h5
                            style={{
                              fontSize: '8px',
                              marginTop: '0px',
                              marginBottom: '0px'
                            }}
                          >
                            {'Purchased on'}
                          </h5>
                          <h4
                            style={{
                              fontSize: '11px',
                              marginTop: '0px',
                              fontWeight: '700',
                              marginBottom: '0px'
                            }}
                          >
                            {moment(ticket.created_at).format(
                              'DD.MM.YYYY HH:MM'
                            )}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {index % 10 == 8 && index % 10 == 9 && (
                <div className="page-break"></div>
              )}
            </Grid>
          );
        })}
      </Grid>
    );
  }
}

export default PrintTicket;
