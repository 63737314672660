import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,

} from '@material-ui/core';
import { useConfirm } from 'material-ui-confirm';
import * as actionCreators from '../../../store/actions';
import CurrencyFormat from 'react-currency-format';

const useStyles = makeStyles(theme => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  },
  greenColor: {
    color: "#10981a"
  },redColor: {
    color: "#d42a0f"
  },
}));


const Results = ({
  searchKey,
  currentPage,
  className,
  resellerOrders,
  totalResellerOrders,
  ...rest
}) => {
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const classes = useStyles();
  const [selectedResellerIds, setSelectedResellerIds] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedResellerOrders, setSelectedResellerOrders] = useState([]);
  const handleLimitChange = event => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    const body = {
      "page": newPage + 1,
      "search": searchKey,       
      "from_date":'',
      "to_date":'',
      "paid_reseller":'',
      "user_id":''
    };
    dispatch(actionCreators.loadResellerOrder(body));
  };

  const handleSelectAll = event => {
    let newSelectedResellerOrders;
    if (event.target.checked) {
      newSelectedResellerOrders = resellerOrders.map(order => order.order_number);
    } else {
      newSelectedResellerOrders = [];
    }
    setSelectedResellerOrders(newSelectedResellerOrders);
  };


  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedResellerOrders.indexOf(id);
    let  newSelectedResellerOrders = [];
    if (selectedIndex === -1) {
       newSelectedResellerOrders =  newSelectedResellerOrders.concat(
        selectedResellerOrders,
        id
      );
    } else if (selectedIndex === 0) {
       newSelectedResellerOrders =  newSelectedResellerOrders.concat(
        selectedResellerOrders.slice(1)
      );
    } else if (selectedIndex === selectedResellerOrders.length - 1) {
       newSelectedResellerOrders =  newSelectedResellerOrders.concat(
        selectedResellerOrders.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
       newSelectedResellerOrders =  newSelectedResellerOrders.concat(
        selectedResellerOrders.slice(0, selectedIndex),
        selectedResellerOrders.slice(selectedIndex + 1)
      );
    }
    setSelectedResellerOrders( newSelectedResellerOrders);
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>  
                 <TableCell padding="checkbox">
                    <Checkbox
                    color="primary"
                    indeterminate={
                      selectedResellerOrders.length > 0 &&
                      selectedResellerOrders.length < resellerOrders.length
                    }
                    onChange={handleSelectAll}
                    />
                </TableCell>              
                <TableCell>Order No.</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>From</TableCell>
                <TableCell>Payment Status</TableCell>
                <TableCell>Revenue</TableCell>
                <TableCell>commission</TableCell>
                <TableCell>Settlement</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {resellerOrders?.length &&
                resellerOrders.map((reseller, index) => (                 
                  reseller.order_number!=null &&  
                  <TableRow hover key={index} selected={selectedResellerOrders.indexOf(reseller.order_number) !== -1 }  > 
                     <TableCell padding="checkbox">
                      <Checkbox
                        checked={
                          selectedResellerOrders.indexOf(reseller.order_number) !== -1
                        }
                        onChange={event =>
                          handleSelectOne(event, reseller.order_number)
                        }
                        value="true"
                      />
                    </TableCell>                
                    <TableCell> {reseller.order_number} </TableCell>
                    <TableCell>{moment(reseller.created_at).format('DD/MM/YYYY')} </TableCell>
                    <TableCell> {reseller?.user?.first_name} {reseller?.user?.last_name}  </TableCell>
                    <TableCell>{reseller.payment_status}  </TableCell>
                    <TableCell> 
                    <CurrencyFormat
                    value={reseller.grand_total ? reseller.grand_total : 0 }
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'AED '}
                    renderText={value => value}
                    />
                    </TableCell>
                    <TableCell className={classes.greenColor} >{"+ "}
                    <CurrencyFormat
                    value={reseller.percentage_profit ? reseller.percentage_profit.toFixed(2) : 0 }
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'AED '}
                    renderText={value => value}
                    />
                    </TableCell>
                    <TableCell className={reseller?.paid_reseller =="0" ?classes.redColor:classes.greenColor }>{reseller?.paid_reseller =="0" ?"Pending":"Settled"}  </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={totalResellerOrders}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[10]}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  resellerOrders: PropTypes.array.isRequired
};

export default Results;
