import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles,
  Typography,
  Container,
  Grid,
  Paper,
  Fab,
  AppBar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Toolbar
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import * as actionCreators from '../../../store/actions';
import { useDispatch } from 'react-redux';
import TotalCustomers from '../reports/DashboardView/TotalCustomers';
import CurrencyFormat from 'react-currency-format';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import { Printer } from 'react-feather';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker
} from '@material-ui/pickers';
import Select from 'react-select';
import PrintOrder from './PrintOrder';
import ReactToPrint from 'react-to-print';
import {
  ArrowBack,
  Clear,
  PeopleAlt,
  AttachMoney,
  ShoppingCart
} from '@material-ui/icons';
import '../../../assets/styles/MainStyles.css';
import QRCodeApp from '../../common/QRCodeApp';
import { useNavigate } from 'react-router';

const useStyles = makeStyles(theme => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  }
}));
const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    zIndex: '4'
  }),
  control: (provided, state) => ({
    ...provided,
    height: '56px'
  })
};
const settlementOptions = [
  { value: '', label: 'Select Settlement' },
  { value: '0', label: 'Pending' },
  { value: '1', label: 'Settled' }
];

const ResellerToolbar = ({
  setPageLoader,
  totalResellerOrders,
  user,
  resellerOrders,
  customerSearch,
  className,
  ...rest
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  // const user = JSON.parse(sessionStorage.getItem('ishro_user_data'));
  const [fromDate, setFromDate] = React.useState(null);
  const [toDate, setToDate] = React.useState(null);
  const [errFromDate, setErrFromDate] = useState('');
  const [errToDate, setErrToDate] = useState('');
  const [searchKey, setSearchKey] = useState('');

  const [fromMaxDate, setFromMaxDate] = React.useState(new Date(1 - 1 - 2001));
  const [toMaxDate, setToMaxDate] = React.useState(new Date());

  const [isLoading, setIsLoading] = useState(false);
  const [settlement, setSettlement] = useState({
    value: '',
    label: 'Select Settlement'
  });
  const [open, setOpen] = React.useState(false);
  const [openQRCode, setOpenQRCode] = React.useState(false);
  const descriptionElementRef = React.useRef(null);
  const componentRef = useRef();
  const [resellerData, setResellerData] = useState([]);
  const QRCodeRef = useRef();

  const changeFromDate = date => {
    date == 'Invalid Date'
      ? setErrFromDate('Invalid Date Format')
      : setErrFromDate('');
    setFromDate(date);
    moment(date).format('YYYY-MM-DD');
    resellerOrderLoad(searchKey, date, toDate, settlement.value);
    let maxdate = new Date(date);
    maxdate.setDate(maxdate.getDate() + 1);
    setFromMaxDate(maxdate);
  };

  const changeToDate = date => {
    date == 'Invalid Date'
      ? setErrToDate('Invalid Date Format')
      : setErrToDate('');
    setToDate(date);
    moment(date).format('YYYY-MM-DD');
    resellerOrderLoad(searchKey, fromDate, date, settlement.value);
    let mindate = new Date(date);
    mindate.setDate(mindate.getDate() - 1);
    setToMaxDate(mindate);
  };
  const onChangeHandler = option => {
    setSettlement(option);
    resellerOrderLoad(searchKey, fromDate, toDate, option.value);
  };
  const handleSearch = value => {
    setSearchKey(value);
    resellerOrderLoad(value, fromDate, toDate, settlement.value);
  };

  const resellerOrderLoad = (searchKey, fromDate, toDate, settlement) => {
    setPageLoader(true);

    setTimeout(function() {
      const body = {
        page: 1,
        search: searchKey,
        from_date: fromDate,
        to_date: toDate,
        paid_reseller: settlement,
        user_id: ''
      };
      dispatch(actionCreators.loadResellerOrder(body))
        .then(() => {
          setPageLoader(false);
        })
        .then(() => {
          setPageLoader(false);
        });
    }, 1000);
  };

  const printResellerOrder = () => {
    const body = {
      page: 'print',
      search: searchKey,
      from_date: fromDate,
      to_date: toDate,
      paid_reseller: settlement.value,
      user_id: ''
    };
    dispatch(actionCreators.loadResellerOrder(body))
      .then(res => {
        setResellerData(res);
        setOpen(true);
      })
      .then(() => {});
  };
  const backToCustomer = () => {
    const url = window.location.href;
    const winnerId = url.substring(url.lastIndexOf('/') + 1);
    if (winnerId == 'resellers') {
      //  navigate('../customers', { state: customerSearch });
    } else {
      navigate(`../../users/${customerSearch.userId}`, {
        state: customerSearch
      });
    }
  };
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen = () => {
    setOpenQRCode(true);
  };
  const generateQRCode = () => {
    QRCodeRef.current.downloadQRCode();
  };

  const handleCloseQRCode = () => {
    setOpenQRCode(false);
  };
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box display="flex" ml={3} mt={3}>
        <Box flexGrow={1}>
          <Typography variant="h3">{user?.first_name}'s Reports</Typography>
        </Box>
        <Box>
          <IconButton
            onClick={backToCustomer}
            className="backbtn"
            style={{
              height: '36px',
              width: '42px',
              boxShadow:
                '0 0 3px 0 rgb(0 0 0 / 31%), 0 2px 2px -2px rgb(0 0 0 / 25%)',
              padding: '4px',
              borderRadius: '4px',
              cursor: 'pointer',
              marginRight: '15px',
              backgroundColor: 'white'
            }}
          >
            <ArrowBack style={{ color: 'black', fontSize: 24 }} />
          </IconButton>
        </Box>
        <Box>
          <img
            src="https://dashboard.ishro.com/static/ticket_qr.svg"
            style={{
              height: '36px',
              width: '42px',
              boxShadow:
                '0 0 3px 0 rgb(0 0 0 / 31%), 0 2px 2px -2px rgb(0 0 0 / 25%)',
              padding: '4px',
              borderRadius: '4px',
              cursor: 'pointer',
              marginRight: '15px',
              backgroundColor: 'white'
            }}
            onClick={() => handleClickOpen()}
          />
        </Box>
        <Box>
          <Button
            variant="contained"
            color="default"
            onClick={printResellerOrder}
            startIcon={<Printer />}
          >
            {' '}
            Print{' '}
          </Button>
        </Box>
      </Box>
      <Box mt={3} ml={3}>
        <Grid container spacing={3}>
          <Grid item lg={2} sm={4} xl={2} xs={12}>
            <TotalCustomers
              title={'TOTAL USERS'}
              value={
                totalResellerOrders?.total_users
                  ? parseInt(totalResellerOrders.total_users)
                  : 0
              }
              icon={<PeopleAlt />}
            />
          </Grid>
          <Grid item lg={2} sm={4} xl={2} xs={12}>
            <TotalCustomers
              title={'TOTAL ORDERS'}
              value={
                totalResellerOrders?.total_orders
                  ? parseInt(totalResellerOrders.total_orders)
                  : 0
              }
              icon={<ShoppingCart />}
            />
          </Grid>
          <Grid item lg={2} sm={4} xl={2} xs={12}>
            <TotalCustomers
              title={'REVENUE GENERATED'}
              value={
                <CurrencyFormat
                  value={
                    totalResellerOrders?.total_sales
                      ? parseInt(totalResellerOrders.total_sales)
                      : 0
                  }
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'AED '}
                  renderText={value => value}
                />
              }
              icon={<AttachMoney style={{ fontSize: 30 }} />}
            />
          </Grid>
          <Grid item lg={2} sm={4} xl={2} xs={12}>
            <TotalCustomers
              title={'TOTAL COMMISSION'}
              value={
                <CurrencyFormat
                  value={
                    totalResellerOrders?.total_profit
                      ? totalResellerOrders.total_profit.toFixed(2)
                      : 0
                  }
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'AED '}
                  renderText={value => value}
                />
              }
              color={'greenColor'}
              icon={<AttachMoney style={{ fontSize: 30 }} />}
            />
          </Grid>
          <Grid item lg={2} sm={4} xl={2} xs={12}>
            <TotalCustomers
              title={'PENDING PAYMENTS'}
              value={
                <CurrencyFormat
                  value={
                    totalResellerOrders?.pending_profit
                      ? totalResellerOrders.pending_profit.toFixed(2)
                      : 0
                  }
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'AED '}
                  renderText={value => value}
                />
              }
              color={'redColor'}
              icon={<AttachMoney style={{ fontSize: 30 }} />}
            />
          </Grid>
        </Grid>
      </Box>
      {/* <Box mt={3}>
      <Paper style={{padding:"20px"}}> 
      <Grid container spacing={3}  alignItems="center"  justify="center">  
          <Grid item xs={12}  md={3}>
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                placeholder="Search Order No."
                variant="outlined"
                onChange={event => handleSearch(event.target.value)}
                value={searchKey}
              />
            </Grid>
          <Grid item xs={12}  md={3}>
            <Select
              style={{ flexGrow: '1' }}
              isLoading={isLoading}
              value={settlement}
              styles={customStyles}
              options={settlementOptions}
              onChange={onChangeHandler} size="small"
            />
        </Grid>
          <Grid item xs={12}  md={3}>          
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                       <KeyboardDatePicker
                         fullWidth
                         inputVariant="outlined"
                         variant="dialog"                        
                         id="from"
                         label="From"
                         format="dd/MM/yyyy"   
                         maxDate={toMaxDate}
                         value={fromDate}
                         onChange={changeFromDate}   
                         KeyboardButtonProps={{
                         'aria-label': 'change date',
                         }}
                         error={Boolean(errFromDate)}
                         helperText={errFromDate}                       
                         InputProps={{
                          endAdornment: (                            
                            <IconButton onClick={() => changeFromDate(null)} disabled={!fromDate}>
                              <Clear />
                            </IconButton>
                          )
                        }}
                        InputAdornmentProps={{
                          position: "start"
                        }}
                          />  
            </MuiPickersUtilsProvider>       
            </Grid>
          <Grid item xs={12}  md={3}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                       <KeyboardDatePicker
                         fullWidth
                         inputVariant="outlined"
                         variant="dialog"                       
                         id="to"
                         label="To"
                         format="dd/MM/yyyy" 
                         minDate={fromMaxDate}
                         value={toDate}
                         onChange={changeToDate}      
                         KeyboardButtonProps={{
                         'aria-label': 'change date',
                         }} 
                        error={Boolean(errToDate)}
                        helperText={errToDate}                        
                          InputProps={{
                            endAdornment: (
                              <IconButton onClick={() => changeToDate(null)} disabled={!toDate}>
                                <Clear />
                              </IconButton>
                            )
                          }}
                          InputAdornmentProps={{
                            position: "start"
                          }}
                         />  
                         </MuiPickersUtilsProvider> 
            </Grid>
         </Grid>
      </Paper>
      </Box> */}

      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullScreen
      >
        <DialogContent dividers="paper" ref={componentRef}>
          <AppBar>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="close"
                onClick={handleClose}
              >
                <ArrowBack style={{ color: 'aliceblue', fontSize: 30 }} />
              </IconButton>
              <Typography
                variant="h3"
                style={{ color: 'aliceblue', flexGrow: 1 }}
              >
                {user?.first_name}'s Reports For{' '}
                {moment(totalResellerOrders?.start_date?.created_at).format(
                  'DD MMM YYYY'
                )}
                {' - '}{' '}
                {moment(totalResellerOrders?.end_date?.created_at).format(
                  'DD MMM YYYY'
                )}
              </Typography>
              <img
                src="https://dashboard.ishro.com/static/logo_white.svg"
                style={{ height: '64px' }}
              />
            </Toolbar>
          </AppBar>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{ padding: '4% 0%' }}
          >
            <PrintOrder
              resellerOrders={resellerData.data}
              totalResellerOrders={resellerData.highlights}
              classes={classes}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="primary">
            Cancel
          </Button>
          <ReactToPrint
            trigger={() => (
              <Button
                variant="contained"
                color="default"
                startIcon={<Printer />}
              >
                {' '}
                Print{' '}
              </Button>
            )}
            content={() => componentRef.current}
          />
        </DialogActions>
      </Dialog>

      <Dialog
        open={openQRCode}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ padding: '26px 32px 13px 61px' }}
        >
          <Typography color="textPrimary" variant="h3">
            {'QR Code'}
          </Typography>
        </DialogTitle>
        <DialogContent style={{ padding: '20px 62px' }}>
          <QRCodeApp referralCode={user} ref={QRCodeRef} />
        </DialogContent>
        <DialogActions style={{ paddingBottom: '31px', paddingRight: '31px' }}>
          <Button
            onClick={handleCloseQRCode}
            variant="contained"
            color="primary"
          >
            Close
          </Button>
          <Button
            onClick={generateQRCode}
            variant="contained"
            style={{ backgroundColor: '#e2e2e2', color: 'rgba(0, 0, 0, 0.87)' }}
          >
            Download QR Code
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

ResellerToolbar.propTypes = {
  className: PropTypes.string
};

export default ResellerToolbar;
