import React, { useEffect, useState } from 'react';
import {
  Container,
  Card,
  CardContent,
  CardHeader,
  Grid,
  TextField,
  Typography,
  Divider,
  Button,
  CardActions,
  LinearProgress,
  InputAdornment
} from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import Select from 'react-select';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { toolbarControlTypes } from 'react-mui-draft-wysiwyg';
import { DevTool } from '@hookform/devtools';
// Link ->https://www.npmjs.com/package/react-simple-wysiwyg
import Page from 'src/components/common/Page';
import GoBack from 'src/components/common/goBack';
import DropZone from 'src/components/common/dropZone';
import ErrorMessage from 'src/components/common/errorMessage';
import * as actionCreators from 'src/store/actions';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { makeStyles } from '@material-ui/styles';
import { encode, decode, isValid } from 'js-base64';
import SunEditor, { buttonList } from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import { AlertTitle } from '@material-ui/lab';

const ButtonCustomList = [
  ['undo', 'redo'],
  ['font', 'fontSize'],
  ['paragraphStyle', 'blockquote'],
  ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
  ['fontColor', 'hiliteColor'],
  ['align', 'list', 'lineHeight'],
  ['outdent', 'indent'],
  ['table', 'horizontalRule'],
  ['fullScreen', 'showBlocks', 'codeView'],
  ['preview', 'print'],
  ['removeFormat']
];
const ButtonCustomList1 = [
  ['undo', 'redo'],
  ['paragraphStyle', 'blockquote'],
  ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
  ['fontColor', 'hiliteColor'],
  ['align', 'list', 'lineHeight'],
  ['fullScreen', 'showBlocks', 'codeView'],
  ['preview', 'print'],
  ['removeFormat']
];
const sortedFontOptions = [
  'Almarai'
  // 'Almarai-Light',
  // 'Almarai-Medium',
  // 'Almarai-SemiBold',
  // 'Almarai-Bold'
].sort();
const validationSchema = Yup.object().shape({
  title: Yup.string()
    .max(255)
    .required('Name is required'),
  category_id: Yup.object()
    .nullable()
    .required('Select Category'),
  price: Yup.number().min(1),
  max_stock: Yup.number()
    .min(1)
    .required('Enter Available Stock'),
  description: Yup.string()
    .min(13, 'Description is required')
    .max(10000)
    .required('Description is required'),
  files: Yup.array()
    .nullable()
    .required('Please Upload Atleast One File')
});

const initialValues = {
  title: '',
  category_id: '',
  price: 0,
  max_stock: 0,
  description: '',
  files: ''
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const count = 0;
const validConsecutiveChar = new RegExp(
  '^(?!\\b([a-zA-Z0-9])\\1\\1\\1\\1\\1\\1\\1\\1\\1+\\b)'
);
const addProductComponent = ({
  addProduct,
  loadCategories,
  progress,
  products,
  categories
}) => {
  const { product_id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const classes = useStyles();
  const [files, setFiles] = useState([]);
  const [rawContent, setRawContent] = React.useState();
  const [rawContentDesc, setRawContentDesc] = React.useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [title, setTitle] = useState();
  const [title_ar, setTitleAr] = useState();
  const { handleSubmit, register, errors, control, setValue } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'onChange'
  });

  useEffect(() => {
    register('description');
    register('description_ar');
    loadCategories();
    if (product_id) {
      prefillForm();
    } else {
      setRawContentDesc('<p></p>');
      setRawContent('<p></p>');
    }
  }, []);

  const onSubmit = (data, e) => {
    if (
      !validConsecutiveChar.test(decode(data.title)?.replace(/<[^>]+>/g, '')) ||
      !validConsecutiveChar.test(
        decode(data.title_ar)?.replace(/<[^>]+>/g, '')
      ) ||
      !validConsecutiveChar.test(
        decode(data.description)?.replace(/<[^>]+>/g, '')
      ) ||
      !validConsecutiveChar.test(
        decode(data.description_ar)?.replace(/<[^>]+>/g, '')
      )
    ) {
      return enqueueSnackbar('Remove Consecutive character', {
        variant: 'error'
      });
    }
    console.log(data);
    setIsSubmitting(true);
    const body = {
      action: product_id ? 'edit' : 'add',
      title: data.title,
      title_ar: data.title_ar,
      price: data.price,
      max_stock: data.max_stock,
      category_id: data.category_id.value,
      description: data.description,
      description_ar: data.description_ar,
      product_id
    };
    addProduct(body, data.files)
      .then(msg => {
        setIsSubmitting(false);
        console.log(msg);
        enqueueSnackbar(msg, {
          variant: 'success'
        });
        navigate(-1);
        return;
      })
      .catch(err => {
        setIsSubmitting(false);
        console.error(err);
        enqueueSnackbar(err, { variant: 'error' });
      });
  };

  const onError = (errors, e) => console.log(errors, e);

  const prefillForm = () => {
    if (products.length < 0) {
      enqueueSnackbar('No News Found. Please come-back again', {
        variant: 'error'
      });
      navigate(-1);
      return;
    }
    const productToBeEditted = products.find(e => e.product_id == product_id);
    if (!productToBeEditted) {
      enqueueSnackbar('Error Editting Product. Please come-back again', {
        variant: 'error'
      });
      navigate(-1);
      return;
    }
    setValue('title', productToBeEditted.title);
    setValue('title_ar', productToBeEditted.title_ar);
    setValue('description', productToBeEditted.description);
    setValue('description_ar', productToBeEditted.description_ar);
    setRawContentDesc(
      isValid(productToBeEditted.description)
        ? decode(productToBeEditted.description)
        : productToBeEditted.description
    );
    setRawContent(
      isValid(productToBeEditted.description_ar)
        ? decode(productToBeEditted.description_ar)
        : productToBeEditted.description_ar
    );

    setTitle(
      isValid(productToBeEditted.title)
        ? decode(productToBeEditted.title)
        : decode(productToBeEditted.title)
    );
    setTitleAr(
      isValid(productToBeEditted.title_ar)
        ? decode(productToBeEditted.title_ar)
        : decode(productToBeEditted.title_ar)
    );
    setValue('max_stock', productToBeEditted.max_stock);
    setValue('price', productToBeEditted.price);
    setValue(
      'category_id',
      categories.find(c => c.value === productToBeEditted.category.category_id)
    );
    setFiles(productToBeEditted.files.map(f => f.file));
    setValue(
      'files',
      productToBeEditted.files.map(f => f.file)
    );
  };

  const goBack = () => navigate(-1);
  const dataCheck = e => {
    console.log(e.data);
  };

  const editorConfig = {
    toolbar: {
      controls: [
        toolbarControlTypes.bold,
        toolbarControlTypes.italic,
        toolbarControlTypes.underline,
        toolbarControlTypes.fontColor,
        toolbarControlTypes.fontBackgroundColor,
        toolbarControlTypes.fontColor,
        toolbarControlTypes.blockType,
        toolbarControlTypes.fontSize,
        toolbarControlTypes.orderedList,
        toolbarControlTypes.textAlign
      ]
    }
  };

  return (
    <Page className={classes.root} title="Add Product">
      <Container>
        <GoBack onClick={goBack} />
        <Card raised>
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <CardHeader title="Add Product" />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <h4 style={{ paddingBottom: '14px' }}>Product Title</h4>
                  <Controller
                    control={control}
                    name="title"
                    render={({ onChange }) => (
                      <SunEditor
                        setContents={title}
                        onChange={data => {
                          var sectionTitle = data.replace(/<[^>]+>/g, '');
                          if (!validConsecutiveChar.test(sectionTitle)) {
                            enqueueSnackbar(
                              "Don't Enter Consecutive character",
                              {
                                variant: 'error'
                              }
                            );
                          }
                          onChange(encode(data));
                        }}
                        setDefaultStyle="font-family: Almarai; font-size: 14px; color:#F5F8F8"
                        setOptions={{
                          buttonList: ButtonCustomList1,
                          defaultTag: 'div',
                          minHeight: '100px',
                          showPathLabel: false,
                          font: sortedFontOptions
                        }}
                      />
                    )}
                  />
                  {errors?.title?.message && (
                    <Typography
                      variant="caption"
                      component="p"
                      style={{ color: 'red' }}
                    >
                      {errors?.title?.message}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <h4 style={{ paddingBottom: '14px' }}>Product Title (Ar.)</h4>
                  <Controller
                    control={control}
                    name="title_ar"
                    render={({ onChange }) => (
                      <SunEditor
                        setContents={title_ar}
                        onChange={data => {
                          var sectionTitle = data.replace(/<[^>]+>/g, '');
                          if (!validConsecutiveChar.test(sectionTitle)) {
                            enqueueSnackbar(
                              "Don't Enter Consecutive character",
                              {
                                variant: 'error'
                              }
                            );
                          }
                          onChange(encode(data));
                        }}
                        setDefaultStyle="font-family: Almarai; font-size: 14px; color:#F5F8F8"
                        setOptions={{
                          buttonList: ButtonCustomList1,
                          defaultTag: 'div',
                          minHeight: '100px',
                          showPathLabel: false,
                          font: sortedFontOptions,
                          rtl: true
                        }}
                      />
                    )}
                  />
                  {errors?.title_ar?.message && (
                    <Typography
                      variant="caption"
                      component="p"
                      style={{ color: 'red' }}
                    >
                      {errors?.title_ar?.message}
                    </Typography>
                  )}
                </Grid>

                <Grid item xs>
                  <TextField
                    error={Boolean(errors?.price?.message)}
                    helperText={errors.price?.message}
                    inputRef={register}
                    fullWidth
                    label="Product price"
                    margin="normal"
                    name="price"
                    type="number"
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">AED</InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs>
                  <TextField
                    error={Boolean(errors?.max_stock?.message)}
                    helperText={errors.max_stock?.message}
                    inputRef={register}
                    fullWidth
                    label="Max. Product Stock"
                    margin="normal"
                    name="max_stock"
                    type="number"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs>
                  <Typography>Category</Typography>
                  <Controller
                    name="category_id"
                    options={categories}
                    control={control}
                    as={Select}
                  />
                  {errors?.category_id?.message && (
                    <ErrorMessage message={errors.category_id.message} />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <h4 style={{ paddingBottom: '14px' }}>
                    Product Description *
                  </h4>
                  <Controller
                    name="description"
                    control={control}
                    render={({ onChange }) => (
                      <SunEditor
                        setContents={rawContentDesc}
                        onChange={data => {
                          var sectionTitle = data.replace(/<[^>]+>/g, '');
                          if (!validConsecutiveChar.test(sectionTitle)) {
                            enqueueSnackbar(
                              "Don't Enter Consecutive character",
                              {
                                variant: 'error'
                              }
                            );
                          }
                          onChange(
                            encode(
                              data
                                .replaceAll(
                                  '<table>',
                                  '<table style="border-spacing: 0px;width: 100%;">'
                                )
                                .replaceAll(
                                  '<td>',
                                  '<td style="border-bottom: 1px solid #827373;padding: 12px 0px;font-size: 14px;">'
                                )
                            )
                          );
                        }}
                        setDefaultStyle="font-family: Almarai; font-size: 14px; color:#F5F8F8"
                        setOptions={{
                          buttonList: ButtonCustomList,
                          defaultTag: 'div',
                          minHeight: '200px',
                          showPathLabel: false,
                          font: sortedFontOptions
                        }}
                      />
                    )}
                  />
                  {errors?.description?.message && (
                    <Typography
                      variant="caption"
                      component="p"
                      style={{ color: 'red' }}
                    >
                      {errors?.description?.message}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <h4 style={{ paddingBottom: '14px' }}>
                    Product Description (Arabic) (Optional)
                  </h4>
                  <Controller
                    name="description_ar"
                    control={control}
                    render={({ onChange }) => (
                      <SunEditor
                        setContents={rawContent}
                        onChange={data => {
                          var sectionTitle = data.replace(/<[^>]+>/g, '');
                          if (!validConsecutiveChar.test(sectionTitle)) {
                            enqueueSnackbar(
                              "Don't Enter Consecutive character",
                              {
                                variant: 'error'
                              }
                            );
                          }
                          onChange(
                            encode(
                              data
                                .replaceAll(
                                  '<table>',
                                  '<table style="border-spacing: 0px;width: 100%;">'
                                )
                                .replaceAll(
                                  '<td>',
                                  '<td style="border-bottom: 1px solid #827373;padding: 12px 0px;font-size: 14px;">'
                                )
                            )
                          );
                        }}
                        setDefaultStyle="font-family: Almarai; font-size: 14px; color:#F5F8F8"
                        setOptions={{
                          buttonList: ButtonCustomList,
                          defaultTag: 'div',
                          minHeight: '200px',
                          showPathLabel: false,
                          font: sortedFontOptions,
                          rtl: true
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="files"
                    render={({ value, ref }) => (
                      <DropZone
                        id="file2"
                        col={1}
                        onFilesChange={filesArray =>
                          setValue('files', filesArray, {
                            shouldValidate: true,
                            shouldDirty: true
                          })
                        }
                        initialFiles={files}
                        label="Upload Images"
                        error={errors?.files?.message}
                        inputRef={ref}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12}></Grid>
              </Grid>
            </CardContent>
            <Divider />
            {progress > 0 || isSubmitting ? (
              <LinearProgress variant="determinate" value={progress} />
            ) : null}

            <CardActions>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isSubmitting}
              >
                {product_id ? 'Update' : 'Add'} Product
              </Button>
            </CardActions>
          </form>
        </Card>
      </Container>
      <DevTool control={control} /> {/* set up the dev tool */}
    </Page>
  );
};

const mapStateToProps = state => {
  return {
    progress: state.progress.fileProgress,
    categories: state.categories.categories.map(el => ({
      value: el.category_id,
      label: el.category_name_en
    })),
    products: state.products.products
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addProduct: (data, files) =>
      dispatch(actionCreators.addProduct(data, files)),
    loadCategories: searchKey =>
      dispatch(actionCreators.loadCategories(searchKey))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(addProductComponent);
