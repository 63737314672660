import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles,
  Fab,
  Typography,
  Grid
} from '@material-ui/core';
import {
  Search as SearchIcon,
  ArrowLeft as BackIcon,
  Printer
} from 'react-feather';
import * as actionCreators from '../../../../store/actions';
import FileUploader from 'src/components/common/FileUploader';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { decode, isValid } from 'js-base64';
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2
} from 'react-html-parser';
import ShowMoreText from 'react-show-more-text';
import { useDispatch, useSelector } from 'react-redux';
import PrintTicket from './PrintTicket';
import moment from 'moment';
import ReactToPrint from 'react-to-print';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { PrintDisabledOutlined } from '@material-ui/icons';
import axios from 'src/services/axios';
import './print.css';
import ImportDialog from './ImportDialog';

const useStyles = makeStyles(theme => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  selectField: {
    margin: '15px 0px'
  },
  capitalize: {
    textTransform: 'capitalize'
  },
  readmore: {
    color: '#26cde6'
  }
}));

const Toolbar = ({
  className,
  onFilter,
  promotion_id,
  title,
  promotion,
  description,
  search,
  ...rest
}) => {
  const componentRef = useRef();
  const dispatch = useDispatch();
  const classes = useStyles();
  const printTickets = useSelector(state => state.promotion.printPromoTickets);
  const [searchKey, setSearchKey] = useState(search);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [importOpen, setImportOpen] = React.useState(false);
  const navigate = useNavigate();
  const [totalPromoTickets, setPrintTickets] = useState(printTickets);
  const descriptionElementRef = React.useRef(null);

  const handleSearch = value => {
    setSearchKey(value);
    onFilter(value);
  };

  const goBack = () => navigate(-1);

  const printPromoTicket = () => {
    dispatch(actionCreators.printPromoTickets(promotion_id));
    setTimeout(function() {
      setOpen(true);
    }, 600);
  };
  const importPromoTicket = () => {
    // dispatch(actionCreators.importPromoTickets(promotion_id));
  };

  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const handleClose = () => {
    setOpen(false);
  };

  const isBase64 = str => {
    if (str === '' || str.trim() === '') {
      return false;
    }
    try {
      return btoa(atob(str)) == str;
    } catch (err) {
      return false;
    }
  };
  const timeDiffCalc = () => {
    var dateNow = new Date();
    var dateFuture1 = moment(promotion.expiry_date).format(
      'YYYY/MM/DD HH:mm:ss'
    );
    var dateFuture = new Date(dateFuture1);
    var dif = dateFuture - dateNow;
    var minutes = Math.round(dif / 1000 / 60);
    var milesec = minutes * 60;
    var hours = minutes / 60;
    return (
      hours < 49 ||
      promotion.sold_out_tickets == promotion.total_tickets_alloted
    );
  };
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box display="flex" p={1} justifyContent="space-between">
        <Box p={1} flexGrow={1}>
          <Typography variant="h3" style={{ marginBottom: '12px' }}>
            {isBase64(title) ? decode(title).replace(/<[^>]+>/g, '') : title}
          </Typography>
          <Typography
            variant="subtitle2"
            className={classes.capitalize}
            color="textSecondary"
          >
            <ShowMoreText
              lines={1}
              more="Show more"
              less="Show less"
              className="content-css"
              //anchorClass="my-anchor-css-class"
              expanded={false}
              width={1150}
              anchorClass={classes.readmore}
            >
              {' '}
              {ReactHtmlParser(decode(description))}{' '}
            </ShowMoreText>
          </Typography>
        </Box>
        <Box p={1}>
          <Fab
            color="primary"
            variant="extended"
            aria-label="edit"
            onClick={goBack}
            style={{ width: '126px' }}
          >
            <BackIcon style={{ marginRight: '5px' }} />
            Go Back
          </Fab>
        </Box>
      </Box>
      <Box mt={3}>
        <Card>
          <CardContent>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box maxWidth={500} width="100%">
                <TextField
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon fontSize="small" color="action">
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    )
                  }}
                  value={searchKey}
                  placeholder="Search Ticket Number or User"
                  variant="outlined"
                  onChange={event => handleSearch(event.target.value)}
                />
              </Box>
              <Box
                width="15%"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                {timeDiffCalc() && (
                  <Fab
                    variant="extended"
                    color="secondary"
                    size="small"
                    onClick={printPromoTicket}
                  >
                    <Printer style={{ marginRight: '12px' }} /> Print
                  </Fab>
                )}
                {timeDiffCalc() && (
                  <Fab
                    variant="extended"
                    color="secondary"
                    size="small"
                    onClick={() => setImportOpen(true)}
                  >
                    <Printer style={{ marginRight: '12px' }} /> Import
                  </Fab>
                )}
              </Box>
            </Box>
          </CardContent>
        </Card>
        <Dialog
          open={open}
          onClose={handleClose}
          scroll="paper"
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          maxWidth="md"
        >
          <DialogTitle id="scroll-dialog-title">
            <Typography variant="h3">{'Print Ticket'}</Typography>
          </DialogTitle>
          <DialogContent dividers="paper">
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              <PrintTicket
                ref={componentRef}
                className="print-preview"
                printTickets={printTickets}
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <ReactToPrint
              trigger={() => (
                <Fab variant="extended" color="secondary" size="small">
                  <Printer style={{ marginRight: '12px' }} /> Print
                </Fab>
              )}
              content={() => componentRef.current}
              // onAfterPrint={() => printDone()}
            />
          </DialogActions>
        </Dialog>
        <ImportDialog
          promotion_id={promotion.promotion_id}
          promotion_title={promotion.title}
          open={importOpen}
          onClose={() => setImportOpen(false)}
        ></ImportDialog>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
  onFilter: PropTypes.func.isRequired
};

export default Toolbar;
