import axios from '../../services/axios';
import { Types } from 'src/store/actions/actionTypes';

/**
 * Load customers action
 */
export const loadAuctions = (search = '', page) => {
  console.log('search :', search);
  return dispatch => {
    return new Promise((resolve, reject) => {
      const body = {
        page: page,
        search: search
      };
      axios
        .post('get-auctions', JSON.stringify(body))
        .then(res => {
          
          if (res.data.success) {
            dispatch(setFilteredAuctions(res.data.data));
            dispatch(setAuctions(res.data.data));
            resolve(res.data.data);
          } else {
            reject(res.data.message);
          }
        })
        .catch(error => {
          console.log(error);
          if (error) {
            reject(new Error(error));
          }
        });
    });
  };
};

const uploadFiles = async files => {
  console.log(files);
  const fileUrls = [];

  for (let m = 0; m < files.length; m++) {
    try {
      const file = files[m];

      if (typeof file === 'string') {
        fileUrls.push({ file_type: 'image', file_url: file });
        continue;
      }
      console.log('Wont be logged');

      let formData = new FormData();
      formData.append('file', file);
      const resp = await axios.post(
        'https://api.ishro.com/v3/upload-file-google',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      );

      if (resp.data.success) {
        fileUrls.push(resp.data.results.files[0]);
        console.log(resp);
      } else {
        continue;
      }
    } catch (error) {
      console.log(error);
      continue;
    }
  }
  return fileUrls;
};

export const addAuction = (
  data,
  draw_image,
  files,
  action,
  auction_id
) => async dispatch => {
  try {
    var videoFile;
    const draw_imageFileResp = await uploadFiles(draw_image);
    if (!draw_imageFileResp) throw 'Error Uploading Draw Image File';

    const fileResp = await uploadFiles(files);
    if (!fileResp) throw 'Error Uploading Files';
 
 
    const body = {
      action: action,
      auction_id,
      ...data,
      auction_thumbnail_image: draw_imageFileResp[0].file_url,
      files: fileResp, 
    };
    
    const resp = await axios.post('add-auction', JSON.stringify(body));
    
    if (resp.data.success) {
      return resp.data.message;
    } else {
      throw resp.data.message;
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const deleteAuction = body => async dispatch => {
  try {
    const resp = await axios.post(
      'change-auction-status',
      JSON.stringify(body)
    );
    const { message, success } = resp.data;
    if (success) {
      return message;
    }
    throw message;
  } catch (error) {
    throw error;
  }
};



export const filterAuctions = (search, auctions) => {
  console.log(auctions);

  const filteredList = auctions.filter(el =>
    el.title.toLowerCase().includes(search.toLowerCase())
  );
  console.log(filteredList);
  return dispatch => dispatch(setFilteredAuctions(filteredList));
};

const setAuctions = auctions => ({
  type: Types.SET_AUCTIONS,
  payload: { auctions }
});

const setFilteredAuctions = filteredAuctions => ({
  type: Types.SET_FILTERED_AUCTIONS,
  payload: { filteredAuctions }
});

export const clearAuctions = () => ({
  type: Types.CLEAR_AUCTIONS
});
