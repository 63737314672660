import React, { useState, useEffect } from 'react';
import { Box, Container, makeStyles, Snackbar } from '@material-ui/core';
import Page from 'src/components/common/Page';
import Results from './Results';
import Toolbar from './Toolbar';
// import data from './data';
import MuiAlert from '@material-ui/lab/Alert';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const PromotionTypesView = () => {
  const classes = useStyles();

  const [isMsg, setIsMsg] = useState(false);
  const [msg, setmsg] = useState(false);
  const [promotionType, setPromotionType] = useState([]);
  const launchSnackBar = data => {
    const { msg, type } = data;
    setmsg({
      msg,
      type
    });
    setIsMsg(true);
  };

  const editPromotionType =data=>{
    setPromotionType(data);
  }
  return (
    <Page className={classes.root} title="Customers">
      <Container maxWidth={false}>
        <Toolbar onCatAdded={launchSnackBar} promotionType={promotionType} />
        <Box mt={3}>
          <Results onStatusChanged={launchSnackBar} editPromotion={editPromotionType}/>
        </Box>
      </Container>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={isMsg}
        autoHideDuration={3000}
        onClose={() => setIsMsg(false)}
      >
        <MuiAlert elevation={6} variant="filled" severity={msg.type}>
          {msg.msg}
        </MuiAlert>
      </Snackbar>
    </Page>
  );
};

export default PromotionTypesView;
