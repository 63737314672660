import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CustomerListView from './index';
const AllUsers = () => { 
  return (
    <>     
     <CustomerListView userId={'7'}/>
   </>
  );
};

AllUsers.propTypes = {
  className: PropTypes.string
};

export default AllUsers;
