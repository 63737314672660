import axios from '../../services/axios';
import { Types } from 'src/store/actions/actionTypes';
import NewsCard from 'src/components/pages/news/newsCard';

export const loadOrders = (search, page) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const body = {
        page: page,
        search: search
      };
      axios
        .post('get-orders', JSON.stringify(body))
        .then(res => {
          if (res.data.success) {
            // dispatch(setFilteredOrders(res.data.data));
            dispatch(setOrders(res.data.data));
            resolve(res);
          } else {
            reject();
          }
        })
        .catch(error => {
          console.log(error);
          if (error) {
            reject(new Error(error));
          }
        });
    });
  };
};

export const changeOrderStatus = (order_id, order_status) => async dispatch => {
  try {
    const resp = await axios.post(
      'change-order-status',
      JSON.stringify({
        order_id,
        order_status
      })
    );
    const { message, success } = resp.data;
    if (success) {
      return message;
    }
    throw message;
  } catch (error) {
    throw error;
  }
};
export const changeOrderProductStatus = body => async dispatch => {
  try {
    const resp = await axios.post(
      'change-order-product-status',
      JSON.stringify(body)
    );
    const { message, success } = resp.data;
    if (success) {
      return message;
    }
    throw message;
  } catch (error) {
    throw error;
  }
};

export const filterOrders = search => {
  //   const filteredList = orders.filter(el =>
  //     el.title.toLowerCase().includes(search.toLowerCase())
  //   );
  return dispatch => dispatch(setFilteredOrders(search));
};

const setOrders = orders => ({
  type: Types.SET_ORDERS,
  payload: { orders }
});

const setFilteredOrders = search => ({
  type: Types.SET_FILTERED_ORDERS,
  payload: { search }
});

export const clearOrders = () => ({
  type: Types.CLEAR_ORDERS
});
