import React, { useState, useRef, useEffect } from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  CardHeader,
  TextField
} from '@material-ui/core';
import Page from 'src/components/common/Page';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import AvatarPicker from './AvatarPicker';
import PhoneInput from 'react-phone-input-2';
import { useForm, Controller } from 'react-hook-form';
import 'react-phone-input-2/lib/material.css';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import axios from 'src/services/axios';
import { useSnackbar } from 'notistack';
import { saveToken, saveUserData, destroy } from 'src/services/authService';
import * as actionCreators from '../../../../store/actions';
import { useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Password from './Password';
import Phone from './Phone';

const validationSchema = Yup.object().shape({
  first_name: Yup.string()
    .matches(/^(?!(.)\1\1\1\1\1\1\1\1\1\1)/, {
      message: "Don't Enter Consecutive character",
      excludeEmptyString: true
    })
    .required('First Name  is required'),
  last_name: Yup.string()
    .matches(/^(?!(.)\1\1\1\1\1\1\1\1\1\1)/, {
      message: "Don't Enter Consecutive character",
      excludeEmptyString: true
    })
    .required('Last Name  is required'),
  email: Yup.string()
    .email('Must be a valid email')
    .max(255)
    .required('Email is required'),

  city: Yup.string().matches(/^(?!(.)\1\1\1\1\1\1\1\1\1\1)/, {
    message: "Don't Enter Consecutive character",
    excludeEmptyString: true
  }),
  country: Yup.string().matches(/^(?!(.)\1\1\1\1\1\1\1\1\1\1)/, {
    message: "Don't Enter Consecutive character",
    excludeEmptyString: true
  }),
  address: Yup.string().matches(/^(?!(.)\1\1\1\1\1\1\1\1\1\1)/, {
    message: "Don't Enter Consecutive character",
    excludeEmptyString: true
  })
});

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  avatar: {
    height: 100,
    width: 100
  }
}));

const initialValues = {
  first_name: '',
  last_name: '',
  email: '',
  oldPassword: '',
  newPassword: '',
  reEnterPassword: ''
};

const Account = () => {
  const classes = useStyles();
  var user = JSON.parse(sessionStorage.getItem('ishro_user_data'));
  const fileInput = useRef(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [avatarImage, setAvatarImage] = useState();
  const [file, setFile] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { handleSubmit, register, errors, control, setValue } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'onChange'
  });

  const onError = (errors, e) => {
    console.log(errors, e);
    setIsSubmitting(false);
  };

  const handleImageChange = imageFile => {
    setAvatarImage(imageFile);
  };

  useEffect(() => {
    prefillForm();
  }, []);

  const prefillForm = () => {
    user = JSON.parse(sessionStorage.getItem('ishro_user_data'));
    setValue('first_name', user.first_name);
    setValue('last_name', user.last_name);
    setValue('email', user.email);
    setValue('city', user.city);
    setValue('country', user.country);
    setValue('address', user.address);
    setAvatarImage(user.user_photo);
  };

  const onPasswordSubmit = async (data, e) => {
    setIsSubmitting(true);

    const body = {
      old_password: data.oldPassword,
      new_password: data.newPassword
    };

    try {
      const resp = await axios.post('change-password', JSON.stringify(body));
      const { success, message } = resp.data;
      if (success) {
        enqueueSnackbar(message, { variant: 'success' });
        setIsSubmitting(false);
        dispatch(actionCreators.logout());
        return;
      }
      enqueueSnackbar(message, { variant: 'error' });
      setIsSubmitting(false);
      return;
    } catch (error) {
      enqueueSnackbar(error, { variant: 'error' });
      setIsSubmitting(false);
      return;
    }
  };
  const onSubmit = async (data, e) => {
    var fileResp;
    if (avatarImage != undefined && avatarImage.name != undefined) {
      fileResp = await uploadFiles(avatarImage);
      if (!fileResp) throw 'Error Uploading Image';
    }
    setIsSubmitting(true);
    const body = {
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      city: data.city,
      country: data.country,
      address: data.address,
      user_photo: fileResp != undefined ? fileResp[0].file_url : ''
    };

    try {
      const resp = await axios.post('update-profile', JSON.stringify(body));
      const { success, message } = resp.data;
      if (success) {
        saveUserData(resp.data.data);
        enqueueSnackbar(message, { variant: 'success' });
        prefillForm();
        setIsSubmitting(false);
        return;
      }
      enqueueSnackbar(message, { variant: 'error' });
      setIsSubmitting(false);
      return;
    } catch (error) {
      enqueueSnackbar(error, { variant: 'error' });
      setIsSubmitting(false);
      return;
    }
  };

  const uploadFiles = async file => {
    console.log('Files Uploaded', file);
    const fileUrls = [];
    try {
      console.log('Wont be logged');
      let formData = new FormData();
      formData.append('file', file);
      const resp = await axios.post(
        'https://api.ishro.com/v3/upload-file-google',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      );

      if (resp.data.success) {
        fileUrls.push(resp.data.results.files[0]);
        console.log(resp);
      }
    } catch (error) {
      console.log(error);
    }

    return fileUrls;
  };

  return (
    <Page className={classes.root} title="Account">
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item lg={12} md={12} xs={12}>
            <form onSubmit={handleSubmit(onSubmit, onError)}>
              <Card>
                <CardHeader title="Profile" />
                <Divider />
                <CardContent>
                  <Grid container>
                    <Grid lg={4} md={4} xs={12}>
                      <div>
                        <Box
                          alignItems="center"
                          display="flex"
                          flexDirection="column"
                        >
                          <AvatarPicker
                            handleChangeImage={handleImageChange}
                            avatarImage={avatarImage}
                          />
                          <Typography
                            color="textPrimary"
                            gutterBottom
                            variant="h3"
                          >
                            {user.first_name + ' ' + user.last_name}
                          </Typography>
                          <Typography
                            color="textSecondary"
                            variant="body1"
                            gutterBottom
                          >
                            {`${user.city} ${user.country}`}
                          </Typography>
                          <Typography
                            className={classes.dateText}
                            color="textSecondary"
                            variant="body1"
                            gutterBottom
                          >
                            {`${moment(user.last_logged_date).format(
                              'MM-DD-YYYY hh:mm A'
                            )} `}
                          </Typography>
                        </Box>
                      </div>
                    </Grid>
                    <Grid
                      container
                      md={8}
                      lg={8}
                      xs={12}
                      style={{ marginTop: '20px' }}
                      spacing={3}
                    >
                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          label="First name"
                          name="first_name"
                          error={Boolean(errors?.first_name?.message)}
                          helperText={errors.first_name?.message}
                          inputRef={register}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          label="Last name"
                          name="last_name"
                          error={Boolean(errors?.last_name?.message)}
                          helperText={errors.last_name?.message}
                          inputRef={register}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          label="Email Address"
                          name="email"
                          error={Boolean(errors?.email?.message)}
                          helperText={errors.email?.message}
                          inputRef={register}
                          variant="outlined"
                          InputProps={{
                            disableUnderline: true,
                            autoComplete: 'off'
                          }}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}></Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          label="City"
                          name="city"
                          error={Boolean(errors?.city?.message)}
                          helperText={errors.city?.message}
                          inputRef={register}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          label="Country"
                          name="country"
                          error={Boolean(errors?.country?.message)}
                          helperText={errors.country?.message}
                          inputRef={register}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <TextField
                          fullWidth
                          label="Address"
                          name="address"
                          error={Boolean(errors?.address?.message)}
                          helperText={errors.address?.message}
                          inputRef={register}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />

                <Box display="flex" justifyContent="flex-end" p={2}>
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Save details
                  </Button>
                </Box>
              </Card>
            </form>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item lg={6} md={6} xs={6}>
            <Phone />
          </Grid>
          <Grid item lg={6} md={6} xs={6}>
            <Password />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Account;
