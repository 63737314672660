import React from 'react';
import { Fab, makeStyles } from '@material-ui/core';
import { ArrowBackIosRounded } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  backFab: {
    margin: 0,
    top: 100,
    right: 20,
    bottom: 'auto',
    left: 'auto',
    position: 'fixed'
  },
  backFabIcon: {
    marginRight: theme.spacing(1)
  }
}));

export default ({ ...props }) => {
  const classes = useStyles();
  return (
    <Fab
      color="primary"
      size="small"
      variant="extended"
      aria-label="edit"
      className={classes.backFab}
      {...props}
    >
      <ArrowBackIosRounded fontSize="small" /> Go Back
    </Fab>
  );
};
