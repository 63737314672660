import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import Image from 'material-ui-image';

import {
  Button,
  Box,
  Grid,
  GridList,
  GridListTile,
  GridListTileBar,
  IconButton,
  makeStyles,
  FormControl,
  Fab,
  Typography
} from '@material-ui/core';
import { CloudUploadRounded, DeleteForeverRounded } from '@material-ui/icons';
import ErrorMessage from 'src/components/common/errorMessage';
const container = {
  padding: 12
};

const img = {
  border: '2px dashed #546e7a',
  display: 'block',
  width: 'auto',
  // width: '20%',
  height: '100%'
};

const inputArea = {
  height: '100%',
  borderRadius: 12,
  border: '2px dashed #546e7a',
  padding: 24
};

const useStyles = makeStyles(theme => ({
  gridList: {
    maxHeight: 250
  },

  fab: {
    color: 'white',
    marginRight: 5
  },

  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)'
  },
  input: {
    display: 'none'
  },
  error: {
    color: 'red'
  }
}));

let count = 0;

const RenderPreview = function({ file }) {
  if (file.type && file.type.includes('image')) {
    return <img src={file.url} style={img} />;
  } else if (file.type && file.type.includes('video')) {
    return <video src={file.url} type={file.type} style={img} controls />;
  } else {
    return <img src={file} style={img} />;
  }
};

const Previews = ({
  error,
  initialFiles,
  refreshFiles,
  id = 'fileInput',
  label = 'Upload File',
  multiple = true,
  col,
  inputProps,
  buttonProps,
  defaultValue,
  // callbacks
  onFilesChange
}) => {
  const classes = useStyles();
  const [files, setFiles] = useState([]);

  useEffect(() => {
    console.log('Initial Files with id');
    console.log('Initial Files', initialFiles);
    if (initialFiles?.length > 0) {
      setFiles(initialFiles);
    }
  }, [initialFiles]);

  // useEffect(() => {
  //   console.log('REFRESH FILES');
  //   setFiles(refreshFiles);
  // }, [refreshFiles]);

  console.log('COmponent Rerendered. Id', id, 'Count', count + 1);
  // console.log('Initial FIles', initialFiles);

  const handleFileInput = e => {
    console.log('HANDLE FILE');
    e.preventDefault();
    const filesUploaded = Object.values(e.target.files);

    const filesArrayWithUrls = filesUploaded.map(file => ({
      url: URL.createObjectURL(file),
      type: file.type,
      blob: file
    }));
    setFiles(filesArrayWithUrls);
    onFilesChange(filesArrayWithUrls);
  };

  const deleteFile = async id => {
    console.log('Local File', files);
    console.log(id, 'id');
    let buffer = [];
    for (var x = 0; x < files?.length; x++) {
      console.log(x, 'x');
      if (x != id) {
        buffer.push(files[x]);
      }
    }
    setFiles(buffer);
    onFilesChange(buffer);
  };

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      style={inputArea}
    >
      <FormControl>
        <input
          id={id}
          accept="[image/*, video/*]"
          className={classes.input}
          multiple={multiple}
          type="file"
          onChange={handleFileInput}
          {...inputProps}
        />
        <label htmlFor={id}>
          <Button
            variant="contained"
            component="span"
            color="primary"
            startIcon={<CloudUploadRounded />}
          >
            {label}
          </Button>
        </label>
      </FormControl>
      {error && <ErrorMessage message={error} />}
      <Box className={classes.root} mt={3}>
        {/* {JSON.stringify(files)} */}
        {files?.length > 0 && (
          <GridList spacing={2} className={classes.gridList} cols={col}>
            {files?.length > 0 &&
              files &&
              files?.map((file, id) => {
                return (
                  <GridListTile className={classes.tile} key={id}>
                    <RenderPreview file={file} />
                    <GridListTileBar
                      classes={{
                        root: classes.titleBar,
                        title: classes.title
                      }}
                      actionIcon={
                        <Fab
                          color="primary"
                          size="small"
                          className={classes.fab}
                          aria-label="add"
                          onClick={() => deleteFile(id)}
                        >
                          <DeleteForeverRounded className={classes.icon} />
                        </Fab>
                      }
                    />
                  </GridListTile>
                );
              })}
          </GridList>
        )}
      </Box>
    </Grid>
  );
};

export default React.memo(Previews);
