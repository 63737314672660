import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputLabel,
  Select,
  FormControl,
  FormHelperText,
  MenuItem,
  InputAdornment,
  SvgIcon,
  makeStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import * as actionCreators from '../../../../store/actions';
import FileUploader from 'src/components/common/FileUploader';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router';

const useStyles = makeStyles(theme => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  selectField: {
    margin: '15px 0px'
  }
}));

const validationSchema = Yup.object().shape({
  title: Yup.string()
    .max(255)
    .required('Name is required'),
  category: Yup.string()
    .max(255)
    .required('Select Category'),
  price: Yup.number(),
  stock: Yup.number().required('Enter Available Stock'),
  desc: Yup.string()
    .max(500)
    .required('Description is required'),
  files: Yup.array()
    .max(500)
    .required('Please Upload Atleast One File')
});

const initialValues = {
  title: '',
  category: '',
  price: '',
  stock: '',
  desc: '',
  files: ''
};

const Toolbar = ({
  className,
  filterProducts,
  loadProducts,
  addProduct,
  products,
  loadCategories,
  categories,

  // Callback Function from Child to Parent
  onFormSubmitted,
  onError,
  ...rest
}) => {
  const classes = useStyles();
  const [addCat, setAddCat] = useState(false);
  const permissions = useSelector(state => state.permissions.permissions);
  const navigate = useNavigate();

  const { handleSubmit, register, errors, control, setValue } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'onChange'
  });

  useEffect(() => init(), []);

  const init = () => {
    loadCategories();
  };

  const handleSearch = e => {
    console.log(products);
    filterProducts(e.target.value, products);
  };
  const goTo = () => {
    navigate('/products/addProduct');
  };
  const handleClose = e => {
    console.log(e.target.value);
    setAddCat(false);
  };

  const addProductHandler = (values, { setSubmitting }) => {
    console.log(values);
    addProduct(values)
      .then(() => {
        onFormSubmitted();
        setAddCat(false);
        setSubmitting(false);
      })
      .catch(err => {
        console.log(err);
        setAddCat(false);
        setSubmitting(false);
        onError('Error Adding Product. Error: ' + err);
      });
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box display="flex" justifyContent="flex-end">
        {permissions.content_manager == '1' || permissions.admin == '1' ? (
          <Button color="primary" variant="contained" onClick={goTo}>
            Add Product
          </Button>
        ) : (
          ''
        )}
      </Box>
      <Box mt={3}>
        <Card>
          <CardContent>
            <Box maxWidth={500}>
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                placeholder="Search Product or Category"
                variant="outlined"
                onChange={handleSearch}
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
      <Dialog
        open={addCat}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Add New Product</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={addProductHandler}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              setFieldValue
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      error={Boolean(touched.title && errors.title)}
                      helperText={touched.title && errors.title}
                      label="Product Title"
                      margin="normal"
                      name="title"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.title}
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <FormControl
                      className={classes.selectField}
                      fullWidth
                      variant="outlined"
                      error={Boolean(touched.category && errors.category)}
                    >
                      <InputLabel id="category">Category</InputLabel>
                      <Select
                        name="category"
                        value={values.category}
                        onChange={handleChange}
                        label="category"
                      >
                        {categories
                          ? categories.map((el, id) => (
                              <MenuItem key={id} value={el.category_id}>
                                {el.category_name_en}
                              </MenuItem>
                            ))
                          : null}
                      </Select>
                      <FormHelperText>
                        {touched.category && errors.category}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <TextField
                      error={Boolean(touched.price && errors.price)}
                      fullWidth
                      helperText={touched.price && errors.price}
                      label="Product price"
                      margin="normal"
                      name="price"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="number"
                      value={values.price}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      error={Boolean(touched.stock && errors.stock)}
                      fullWidth
                      helperText={touched.stock && errors.stock}
                      label="Product Stock"
                      margin="normal"
                      name="stock"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="number"
                      value={values.stock}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      error={Boolean(touched.desc && errors.desc)}
                      fullWidth
                      multiline
                      rows="4"
                      helperText={touched.desc && errors.desc}
                      label="Product Description"
                      margin="normal"
                      name="desc"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.desc}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FileUploader
                      error={Boolean(touched.files && errors.files)}
                      onFileChange={files => setFieldValue('files', files)}
                    ></FileUploader>
                  </Grid>
                </Grid>

                <DialogActions>
                  <Button
                    disabled={isSubmitting}
                    onClick={handleClose}
                    color="primary"
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={isSubmitting}
                    variant="contained"
                    type="submit"
                    color="primary"
                  >
                    {isSubmitting ? 'Submitting' : 'Add'}
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
  onFormSubmitted: PropTypes.func,
  onFormSubmitted: PropTypes.func
};

const mapDispatchToProps = dispatch => {
  return {
    loadCategories: () => dispatch(actionCreators.loadCategories('')),
    filterProducts: (searchKey, products) =>
      dispatch(actionCreators.filterProducts(searchKey, products)),
    loadProducts: () => dispatch(actionCreators.loadProducts('')),
    addProduct: data => dispatch(actionCreators.addProduct(data))
  };
};

const mapStateToProps = state => {
  // console.log('state :', state);
  return {
    categories: state.categories.categories,
    products: state.products.products
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Toolbar);
