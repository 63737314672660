import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  Link,
  TextField,
  Snackbar,
  Typography,
  makeStyles
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import Page from 'src/components/common/Page';
import { login, setUserData } from 'src/store/actions/auth';
import OtpInput from 'react-otp-input';
import axios from 'src/services/axios';
import { saveToken, saveUserData, destroy } from 'src/services/authService';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  otpdiv: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '65vh'
  }
}));

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Must be a valid email')
    .max(255)
    .required('Email is required'),
  password: Yup.string()
    .max(255)
    .required('Password is required')
});

const LoginView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const user = JSON.parse(sessionStorage.getItem('ishro_user_data'));
  const initialValues = {
    email: '',
    password: ''
  };
  const [errorMessage, setErrorMessage] = useState('');
  const [showError, setShowError] = useState(false);
  const [showOtp, setShowOtp] = useState(false);
  const [OTP, setOTP] = useState('');
  const [user, setUser] = useState([]);
  const loginHandler = (values, { setSubmitting }) => {
    dispatch(login({ email: values.email, password: values.password }))
      .then(user => {
        setUser(user);
        setSubmitting(false);
        setShowOtp(true);
      })
      .catch(error => {
        console.log(error, 'Login');
        setSubmitting(false);
        setErrorMessage(error);
        setShowError(true);
      });
  };

  const onVerify = async () => {
    const body = {
      otp_code: OTP,
      mobile: user.mobile
    };
    try {
      const resp = await axios.post('verify-otp', JSON.stringify(body));
      const { success, message } = resp.data;
      if (success) {
        saveToken(resp.data.token);
        dispatch(setUserData(user));
        saveUserData(user);
        user?.user_type === 'admin' || user?.user_type === 'test'
          ? navigate('/dashboard')
          : '';
        user?.user_type === 'reseller' ? navigate('/resellers') : '';
        return;
      }
      setErrorMessage(message);
      setShowError(true);
      return;
    } catch (error) {
      setErrorMessage(error);
      setShowError(true);
      return;
    }
  };
  return (
    <Page className={classes.root} title="Login">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={loginHandler}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                {showOtp ? (
                  <div className={classes.otpdiv}>
                    <Typography color="textPrimary" variant="h2">
                      Verification Code
                    </Typography>
                    <Typography
                      color="textSecondary"
                      gutterBottom
                      variant="body2"
                      style={{ margin: '24px 0px' }}
                    >
                      Please type the verification code sent to
                    </Typography>

                    <OtpInput
                      value={OTP}
                      onChange={val => setOTP(val)}
                      numInputs={6}
                      inputStyle={{
                        width: '42px',
                        height: '42px',
                        textAlign: 'center',
                        outline: 'none',
                        marginRight: '10px',
                        border: '1px solid #6d7d8b',
                        borderRadius: '5px'
                      }}
                    />

                    <Button
                      color="primary"
                      fullWidth
                      size="large"
                      variant="contained"
                      style={{ width: '55%', marginTop: '24px' }}
                      onClick={onVerify}
                    >
                      Verify
                    </Button>
                  </div>
                ) : (
                  <>
                    <Box mb={3}>
                      <Typography color="textPrimary" variant="h2">
                        Sign in
                      </Typography>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="body2"
                      >
                        Sign in to ISHRO
                      </Typography>
                    </Box>
                    <TextField
                      error={Boolean(touched.email && errors.email)}
                      fullWidth
                      helperText={touched.email && errors.email}
                      label="Email Address"
                      margin="normal"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="email"
                      value={values.email}
                      InputProps={{
                        disableUnderline: true,
                        autoComplete: 'off'
                      }}
                      variant="outlined"
                    />
                    <TextField
                      error={Boolean(touched.password && errors.password)}
                      fullWidth
                      helperText={touched.password && errors.password}
                      label="Password"
                      margin="normal"
                      name="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="password"
                      value={values.password}
                      variant="outlined"
                    />
                    <Box my={2}>
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        Sign in now
                      </Button>
                    </Box>
                    {/* <Typography color="textSecondary" variant="body1">
                      Don&apos;t have an account?{' '}
                      <Link component={RouterLink} to="/register" variant="h6">
                        Sign up
                      </Link>
                    </Typography> */}
                  </>
                )}
              </form>
            )}
          </Formik>
        </Container>
      </Box>
      <Snackbar
        open={showError}
        autoHideDuration={6000}
        onClose={() => setShowError(false)}
      >
        <MuiAlert elevation={6} variant="filled" severity="error">
          {errorMessage}
        </MuiAlert>
      </Snackbar>
    </Page>
  );
};

export default LoginView;
