import { Types } from 'src/store/actions/actionTypes';

const initialState = {
  news: [],
  filteredNews: []
};

const news = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case Types.SET_NEWS:
      return {
        ...state,
        news: state.news.concat(payload.news),
        filteredNews: state.news.concat(payload.news)
      };
    case Types.SET_FILTERED_NEWS:
      return {
        ...state,
        filteredNews: payload.filteredNews
      };
    case Types.CLEAR_NEWS:
      return {
        ...state,
        news: [],       
      };
      case Types.CLEAR_FILTERED_NEWS:
      return {
        ...state,
        filteredNews: []
      };
    default:
      return state;
  }
};

export default news;
