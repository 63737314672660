import axios from '../../services/axios';
import { Types } from 'src/store/actions/actionTypes';

/**
 * Load Dashboards action
 */
export const loadCountries = () => {
  return async dispatch => {
    try {
      const body = {
        currency: 'true'
      };
      const resp = await axios.post('countries', JSON.stringify(body));
      const { data, message, success } = resp?.data;
      if (success) {
        dispatch(setCountries(data));
        return { message, success, data };
      } else {
        throw { message, success };
      }
    } catch (error) {
      console.log(error);
      if (error) {
        reject(new Error(error));
      }
    }
  };
};

const setCountries = data => ({
  type: Types.SET_COUNTRIES,
  payload: { data }
});

const clearCountries = () => ({
  type: Types.CLEAR_COUNTRIES,
  payload: {}
});
