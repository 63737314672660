import { Types } from 'src/store/actions/actionTypes';

const initialState = {
  resellerOrders: [],
  totalResellerOrders:[],
  user:[]
};

const reseller = (state = initialState, action) => {
  switch (action.type) {
    case Types.SET_RESELLER_ORDERS:
      return {
        ...state,
        resellerOrders: action.payload
      };    
    case Types.CLEAR_RESELLER_ORDERS:
      return {
        ...state,
        resellerOrders: []
      };
      case Types.SET_RESELLER_USER:
        return {
          ...state,
          user: action.payload
        };    
      case Types.CLEAR_RESELLER_USER:
        return {
          ...state,
          user:[]
        };
      case Types.SET_TOTAL_RESELLER_ORDERS:
        return {
          ...state,
          totalResellerOrders: action.payload
        };    
      case Types.CLEAR_TOTAL_RESELLER_ORDERS:
        return {
          ...state,
          totalResellerOrders: []
        };
    default:
      return state;
  }
};

export default reseller;
