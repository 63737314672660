import React, { useState } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  Chip,
  TableRow,
  makeStyles,
  Collapse,
  Typography,
  IconButton,
  Fab,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  LinearProgress
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { purple } from '@material-ui/core/colors';
import {
  KeyboardArrowUpRounded as Up,
  KeyboardArrowDownRounded as Down,
  Cancel as Close
} from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { UserCheck, X, Send, ExternalLink } from 'react-feather';
import Select from 'react-select';
import { useSnackbar } from 'notistack';
import ErrorMessage from '../../../components/common/errorMessage';
import moment from 'moment';
import ProductCollapse from './productsCollapse';
import axios from 'src/services/axios';
import ConfirmDialog from 'src/components/common/dialog';
import * as actionCreators from '../../../store/actions';
import CurrencyFormat from 'react-currency-format';

const orderStatusOptions = [
  { value: 'New', label: 'New' },
  { value: 'Accepted', label: 'Accepted' },
  { value: 'Cancelled', label: 'Cancelled' }
];

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset'
    }
  },
  dialog: {
    height: '120px'
  }
});

const OrderCollapse = props => {
  const { order } = props;
  const classes = useRowStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [paymentOpen, setPaymentOpen] = useState(false);
  const [dialogOpen, setDialogeOpen] = useState(false);
  const [userOpen, setUserOpen] = useState(false);
  const [status, setStatus] = useState(() =>
    orderStatusOptions.find(e => e.value == order.order_status)
  );
  const [paymentDialog, setPaymentDialog] = useState(false);
  const [promotionCharity, setPromotionCharity] = useState(
    order.promotion_charity
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [userValue, setUserValue] = React.useState();
  const [userId, setUserId] = React.useState('');
  const [userInputValue, setUserInputValue] = React.useState('');
  const [userOptions, setUserOptions] = React.useState([]);
  const [userError, setUserError] = React.useState(false);
  const permissions = useSelector(state => state.permissions.permissions);
  const dispatch = useDispatch();

  const changeOrderStatus = async (order_id, order_status) => {
    setStatus(order_status);
    setIsLoading(true);
    try {
      const resp = await axios.post(
        'change-order-status',
        JSON.stringify({ order_id, order_status: order_status.value })
      );
      const { success, message } = resp.data;
      setIsLoading(false);
      if (success) {
        enqueueSnackbar(message, { variant: 'success' });
        return;
      }
      setStatus(orderStatusOptions.find(e => e.value == order.order_status));
      enqueueSnackbar(message, { variant: 'error' });
      return;
    } catch (error) {
      setIsLoading(false);
      setStatus(orderStatusOptions.find(e => e.value == order.order_status));
      enqueueSnackbar(error, { variant: 'error' });
      return;
    }
  };

  const sendQuickBook = async (order_id, user_id) => {
    setIsLoading(true);
    try {
      const resp = await axios.post(
        'quickbook/push',
        JSON.stringify({ order_id, user_id })
      );
      const { success, message } = resp.data;
      setIsLoading(false);
      if (success) {
        enqueueSnackbar(message, { variant: 'success' });
        return;
      }
      enqueueSnackbar(message, { variant: 'error' });
      return;
    } catch (error) {
      setIsLoading(false);
      enqueueSnackbar(error, { variant: 'error' });
      return;
    }
  };

  const changeOrderCharity = async () => {
    try {
      const resp = await axios.post(
        'change-order-donate',
        JSON.stringify({ order_id: order.order_id })
      );
      const { success, message } = resp.data;
      setDialogeOpen(false);
      if (success) {
        setPromotionCharity('true');
        enqueueSnackbar(message, { variant: 'success' });
        return;
      }
      enqueueSnackbar(message, { variant: 'error' });
      return;
    } catch (error) {
      setDialogeOpen(false);
      window.location.reload();
      enqueueSnackbar(error, { variant: 'error' });
      return;
    }
  };

  const openUserDialog = () => {
    setUserOpen(true);
  };
  const openPaymentDialog = () => {
    setPaymentDialog(true);
  };
  const closePaymentDialog = () => {
    setPaymentDialog(false);
  };
  const closeUsersDialog = () => {
    setUserOpen(false);
  };
  const donateDialog = () => {
    setDialogeOpen(true);
  };
  const handleClose = () => {
    setDialogeOpen(false);
  };

  const loadUser = searchKey => {
    setUserOptions([]);
    setUserInputValue(searchKey);
    dispatch(actionCreators.searchCustomers(searchKey))
      .then(res => {
        var useroption = [];
        res.map(o =>
          useroption.push({
            value: o?.user_id,
            title: o?.full_name
          })
        );
        setUserOptions(useroption);
      })
      .catch(err => {
        // setPageLoader(false);
      });
  };

  const onSubmitHandler = async () => {
    try {
      if (userId != '' && userId != undefined) {
        const data = {
          type: 'order',
          order_id: order.order_id,
          user_id: userId.value
        };
        setIsSubmitting(true);
        const resp = await axios.post('transfer-order', {
          ...data
        });
        setIsSubmitting(false);
        if (resp.data.success) {
          enqueueSnackbar(resp.data.message, { variant: 'success' });
          setPaymentDialog(false);
          props.callback(props.index);
          return;
        }
        enqueueSnackbar(resp.data.message, { variant: 'error' });

        return;
      } else {
        setUserError(true);
      }
    } catch (error) {
      enqueueSnackbar(error, { variant: 'error' });
      return;
    }
  };

  const callBack = productIndex => {
    props.callback(props.index, productIndex);
  };

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <Up /> : <Down />}
          </IconButton>
        </TableCell>
        <TableCell>
          {order.order_number}
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setPaymentOpen(!paymentOpen)}
          >
            {paymentOpen ? <Up /> : <Down />}
          </IconButton>
          {paymentOpen ? <p> {order.payment_reference_no} </p> : ''}
        </TableCell>
        <TableCell>
          <Select
            isLoading={isLoading}
            value={status}
            options={orderStatusOptions}
            onChange={data => changeOrderStatus(order.order_id, data)}
          />
        </TableCell>
        <TableCell>
          {moment(order.created_at).format('DD/MM/YY HH:mm:ss')}
        </TableCell>
        <TableCell>
          <CurrencyFormat
            value={
              order.grand_total != '' && order.grand_total != undefined
                ? parseInt(order.grand_total)
                : 0
            }
            displayType={'text'}
            thousandSeparator={true}
            prefix={order.currency + ' '}
            renderText={value => value}
          />
        </TableCell>
        <TableCell>{order.user_paid_amount}</TableCell>
        <TableCell>
          {order?.discount_code != null && order?.discount_code != '' ? (
            <Chip
              size="small"
              style={{ backgroundColor: 'rgb(0, 230, 118)' }}
              label={order?.discount_code + 'code Applied'}
            />
          ) : (
            <Chip
              size="small"
              style={{ backgroundColor: 'rgb(20 2 5 / 71%)', color: 'white' }}
              label="Not Applied"
              deleteIcon={<Close style={{ color: 'white' }} />}
            />
          )}
        </TableCell>
        <TableCell>{order?.payment_method?.name}</TableCell>
        {/* <TableCell>  {order.payment_reference_no}</TableCell> */}
        <TableCell>
          {promotionCharity == 'true' ? (
            <Chip
              size="small"
              style={{ backgroundColor: 'rgb(0, 230, 118)' }}
              label="Donated"
            />
          ) : (
            <Chip
              size="small"
              style={{ backgroundColor: 'rgb(230, 0, 42)', color: 'white' }}
              label="Not Donated"
              deleteIcon={<Close style={{ color: 'white' }} />}
              onDelete={donateDialog}
            />
          )}
        </TableCell>
        <TableCell>{order.payment_status}</TableCell>
        <TableCell>{order.paid_via_point}</TableCell>
        <TableCell>
          <Fab
            color="primary"
            style={{
              margin: '12px 0px',
              color: 'white',
              backgroundColor: purple[300]
            }}
            aria-label=""
            size="small"
            onClick={openUserDialog}
          >
            <UserCheck />
          </Fab>
        </TableCell>
        <TableCell>
          <Fab
            color="primary"
            style={{
              margin: '12px 0px',
              color: 'white',
              backgroundColor: purple[300]
            }}
            aria-label=""
            size="small"
            onClick={openPaymentDialog}
          >
            <Send />
          </Fab>
        </TableCell>
        <TableCell>
          <Fab
            color="primary"
            style={{
              margin: '12px 0px',
              color: 'white',
              backgroundColor: purple[300]
            }}
            aria-label=""
            size="small"
            onClick={data => sendQuickBook(order.order_id, order.user_id)}
          >
            <ExternalLink />
          </Fab>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={13}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h4" gutterBottom component="div">
                Products
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell align="left">Title</TableCell>
                    <TableCell align="left">Status</TableCell>
                    <TableCell align="left">Quantity</TableCell>
                    <TableCell align="left">No Of Tickets</TableCell>
                    <TableCell align="left">Files</TableCell>
                    <TableCell align="left">Transfer</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {order.products.length > 0 &&
                    order.products.map((product, id) => (
                      <ProductCollapse
                        key={id}
                        index={id}
                        product={product}
                        orderId={order.order_id}
                        donate={Boolean(order.promotion_charity == 'true')}
                        showTransferBtn={
                          order.products.length >= 2 ? true : false
                        }
                        callback={index => callBack(index)}
                        onRefresh={() => loadOrders()}
                      ></ProductCollapse>
                    ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      {/* --------------------Users Dialog ------------------------------*/}
      <Dialog
        onClose={closeUsersDialog}
        open={userOpen}
        maxWidth="md"
        keepMounted
      >
        <DialogTitle id="customized-dialog-title">
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h3">User Details</Typography>
            <Fab
              color="primary"
              aria-label="close"
              size="small"
              onClick={closeUsersDialog}
            >
              <X />
            </Fab>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          {order?.user &&
            Object.entries(order.user).map(([key, value], index) => (
              <Table size="small" aria-label="a dense table" key={index}>
                <TableBody>
                  <TableRow>
                    <TableCell align="left">
                      <Typography
                        gutterBottom
                        style={{ textTransform: 'capitalize' }}
                      >
                        <strong>{key.replaceAll('_', ' ')}</strong>
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography style={{ textTransform: 'capitalize' }}>
                        {value}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            ))}
        </DialogContent>
      </Dialog>

      {/* --------------------Transfer Order Dialog ------------------------------*/}
      <Dialog onClose={closePaymentDialog} open={paymentDialog} maxWidth="md">
        <DialogTitle id="customized-dialog-title">
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h3">Transfer Order</Typography>
          </Box>
        </DialogTitle>
        <DialogContent className={classes.dialog}>
          <Autocomplete
            value={userValue}
            onChange={(event, newValue) => {
              var user = userOptions.find(function(user, index) {
                if (user.title == newValue) return user.value;
              });
              user != '' ? setUserId(user) : setUserId('');
              setUserValue(newValue);
              setUserError(false);
            }}
            inputValue={userInputValue}
            onInputChange={(event, newInputValue) => {
              loadUser(newInputValue);
            }}
            id="controllable-states-demo"
            options={userOptions.map(option => option.title)}
            style={{ width: 300 }}
            renderInput={params => (
              <TextField
                {...params}
                label="User"
                variant="outlined"
                error={userError}
                required={userError}
              />
            )}
          />
          {userError && <ErrorMessage message="User is required" />}
        </DialogContent>
        {isSubmitting && <LinearProgress />}
        <DialogActions>
          <Button
            color="primary"
            disabled={isSubmitting}
            onClick={closePaymentDialog}
          >
            Cancel
          </Button>
          {permissions.admin == '1' ? (
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isSubmitting}
              onClick={onSubmitHandler}
            >
              Transfer
            </Button>
          ) : (
            ''
          )}
        </DialogActions>
      </Dialog>

      {/* --------------------Confirm Dialog ------------------------------*/}
      <ConfirmDialog
        open={dialogOpen}
        message="Are you sure you want to convert to Donate ? This process cannot be undone."
        onConfirmed={changeOrderCharity}
        handleClose={handleClose}
      />
    </React.Fragment>
  );
};

export default OrderCollapse;
