import axios from '../../services/axios';
import { Types } from 'src/store/actions/actionTypes';

/**
 * Load Dashboards action
 */
export const loadStatistics = () => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      axios.get('statistics')
        .then(res => {
          
          if (res.data.success) {
            dispatch(setStatisticDashboard(res.data.data));
          }
          resolve(res.data);
        })
        .catch(error => {
          console.log(error);
          if (error) {
            reject(new Error(error));
          }
        });
    });
  };
};

const setStatisticDashboard = data => ({
  type: Types.SET_STATISTICS_DASHBOARD,
  payload: { data }
});

const clearStatisticDashboard = () => ({
  type: Types.CLEAR_STATISTICS_DASHBOARD,
  payload: {}
});
