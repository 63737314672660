import { Grid, Typography, Paper } from '@material-ui/core';
import React from 'react';
import Image from 'material-ui-image';
import { TicketDateFormate } from '../../common/TicketDateFormate';
import { decode, isValid } from 'js-base64';
const ticketResponsive = {
  maxWidth: '34em',
  width: '100%'
};

const ticketCard = ({ ticket, product, price, image, date }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const winnerHandler = e => {
    e.preventDefault();
    console.log(ticket);
    markedAsWinner(ticket.promotion.promotion_id, ticket.user.first_name);
  };

  const isBase64 = str => {
    if (str === '' || str.trim() === '') {
      return false;
    }
    try {
      return btoa(atob(str)) == str;
    } catch (err) {
      return false;
    }
  };

  return (
    <Paper elevation={13} style={ticketResponsive}>
      <Grid
        container
        spacing={1}
        direction="row"
        justify="center"
        alignContent="center"
        wrap="wrap"
        style={{ margin: '12px 0px' }}
      >
        <Grid
          item
          xs={2}
          md={1}
          style={{
            justifyContent: 'center',
            alignItems: 'flex-end',
            backgroundColor: '#24cde6',
            display: 'flex',
            paddingBottom: '12px',
            borderRadius: '6px 0px 0px 6px'
          }}
        >
          <img
            style={{
              width: '31px',
              aspectRatio: '245 / 648',
              marginTop: '60%'
            }}
            src="https://dashboard.ishro.com/static/images/logos/logo_whte.png"
          ></img>
        </Grid>
        <Grid item container xs={10} md={11}>
          <Grid
            item
            container
            xs={12}
            md={12}
            style={{ padding: '12px 12px 0px 12px' }}
          >
            <Grid item xs={8} md={8}>
              <Typography variant="body2" style={{ paddingBottom: '9px' }}>
                Product:
                {isBase64(product)
                  ? decode(product).replace(/<[^>]+>/g, '')
                  : product}
              </Typography>
              <Typography variant="body2" style={{ paddingBottom: '9px' }}>
                Prize:{' '}
                {isBase64(price)
                  ? decode(price).replace(/<[^>]+>/g, '')
                  : price}
              </Typography>
              <Typography variant="body2" style={{ paddingBottom: '9px' }}>
                Purchased Date: {TicketDateFormate(date)}
              </Typography>
            </Grid>
            <Grid
              item
              xs={4}
              md={4}
              style={{ justifyContent: 'center', display: 'flex' }}
            >
              <img
                style={{
                  width: '67%'
                }}
                src={image}
              ></img>
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            md={12}
            style={{ padding: '0px 12px 12px 12px' }}
          >
            <Grid
              item
              style={{ alignItems: 'center', display: 'flex' }}
              xs={8}
              md={8}
            >
              <img
                style={{
                  width: '80%'
                }}
                src="https://dashboard.ishro.com/static/images/barcode.png"
              ></img>
            </Grid>
            <Grid item xs={4} md={4} style={{ paddingLeft: '12px' }}>
              <Typography variant="body2">Ticket Number:</Typography>
              <Typography variant="body2">{ticket.ticket_number}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ticketCard;
