import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import { green } from '@material-ui/core/colors';
import {
  RadioButtonCheckedRounded as Active,
  RadioButtonUncheckedRounded as InActive
} from '@material-ui/icons';
import { useConfirm } from 'material-ui-confirm';
import moment from 'moment';
import { Chip } from '@material-ui/core';
import DeleteButton from 'src/components/common/DeleteButton';
import EditButton from 'src/components/common/EditButton';
import { useNavigate } from 'react-router';
import { isValid, decode } from 'js-base64';

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 345
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
    backgroundSize: 'contain'
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  avatar: {
    backgroundColor: '#24cde6'
  }
}));

export default function NewsCard({ news, onStatusChange }) {
  const classes = useStyles();
  const permissions = useSelector(state => state.permissions.permissions);
  const confirm = useConfirm();
  const navigate = useNavigate();

  const onStatusChangeHandler = (news_id, status) => {
    confirm({
      description: `Are you sure you want to ${
        status === '4' ? 'DELETE' : status === '1' ? 'ACTIVATE' : 'DE-ACTIVATE'
      } News ?`
    })
      .then(msg => {
        onStatusChange(news_id, status);
      })
      .catch(err => {
        console.log("User Cancelled Action. Don't delete Promotion.", err);
      });
  };

  const editNews = e => {
    e.preventDefault();
    navigate(`/news/addNews/${news.news_id}`);
  };
  const isBase64 = str => {
    if (str === '' || str.trim() === '') {
      return false;
    }
    try {
      return btoa(atob(str)) == str;
    } catch (err) {
      return false;
    }
  };
  return (
    <Card className={classes.root}>
      <CardHeader
        title={
          <Typography
            dangerouslySetInnerHTML={{
              __html: isBase64(news.title)
                ? news.title.length > 20
                  ? `${decode(news.title).substring(0, 100)}`
                  : `${decode(news.title)}`
                : `${news.title.substring(0, 100)}`
            }}
          ></Typography>
        }
        subheader={moment(news.created_at).format('Do MMM, YYYY')}
      />
      <CardMedia
        className={classes.media}
        image={
          news.files[0]?.thumbnail
            ? news.files[0]?.thumbnail
            : news.files[0]?.file
        }
        title="Paella dish"
      />
      <CardContent>
        <Typography
          variant="body2"
          component="p"
          color="textSecondary"
          gutterBottom
          dangerouslySetInnerHTML={{
            __html: isValid(news.description)
              ? `${decode(news.description).substring(0, 100)} ...`
              : `${decode(news.description).substring(0, 100)} ...`
          }}
        ></Typography>
      </CardContent>
      <CardActions style={{ justifyContent: 'flex-end' }}>
        <Chip
          style={{
            backgroundColor: news.status === '1' ? green['A400'] : ''
          }}
          size="small"
          icon={news.status === '1' ? <Active /> : <InActive />}
          label={news.status === '1' ? 'Active' : 'In-Active'}
          onClick={() =>
            onStatusChangeHandler(news.news_id, news.status === '1' ? '0' : '1')
          }
        />
        {permissions.content_manager == '1' || permissions.admin == '1' ? (
          <EditButton size="small" onClick={editNews} />
        ) : (
          ''
        )}
        {permissions.content_manager == '1' || permissions.admin == '1' ? (
          <DeleteButton
            size="small"
            onClick={() => onStatusChangeHandler(news.news_id, '4')}
          />
        ) : (
          ''
        )}
      </CardActions>
    </Card>
  );
}
