// import Config from 'react-native-config'
import axios from 'axios';
import { getToken } from 'src/services/authService';
import { store } from 'src/store';
import * as actionCreators from 'src/store/actions';

// console.log("ddddd", getToken());
var instance = axios.create({
  baseURL: 'https://api.ishro.com/v3/admin/', // Config.API_URL
  timeout: 120000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=utf-8'
  }
});

// Request interceptor
instance.interceptors.request.use(
  config => {
    config.headers.Authorization = getToken();
    config.onUploadProgress = ProgressEvent => {
      store.dispatch(actionCreators.setProgress(ProgressEvent));
    };
    return config;
  },
  request => request,
  error => new Error(error)
);

// Response interceptor
instance.interceptors.response.use(
  response => response,
  error => new Error(error)
);

export default instance;
