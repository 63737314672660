import React, { useEffect, useState, useRef } from 'react';
import axios from 'src/services/axios';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Divider,
  Button,
  LinearProgress,
  TextField,
  IconButton
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';
import 'emoji-mart/css/emoji-mart.css';
import '../../../../assets/styles/MainStyles.css';
import { Picker } from 'emoji-mart';
import InputEmoji from 'react-input-emoji';
import * as Yup from 'yup';
import { makeStyles, Typography } from '@material-ui/core';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {
  ArrowBack,
  Clear,
  PeopleAlt,
  AttachMoney,
  ShoppingCart
} from '@material-ui/icons';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {
    overflowY: 'visible',
    height: '202px'
  }
}));

const theme = createMuiTheme({
  overrides: {
    MuiTypography: {
      body2: {
        fontSize: [12, '!important'],
        paddingLeft: '22px'
      }
    }
  }
});

const initialValues = {
  message: '',
  is_name: false
};

const SalesDialog = ({
  open,
  onClose,
  promotion_id,
  promotion_title,
  currency
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [price, setPrice] = useState('0');
  const [quantity, setQuantity] = useState('0');
  const componentRef = useRef();
  const [isBusiness, setBusiness] = useState('');
  const [fromDate, setFromDate] = React.useState(null);
  const [toDate, setToDate] = React.useState(null);
  const [fromMaxDate, setFromMaxDate] = React.useState(new Date(1 - 1 - 2001));
  const [toMaxDate, setToMaxDate] = React.useState(new Date());
  const [errFromDate, setErrFromDate] = useState('');
  const [errToDate, setErrToDate] = useState('');

  const changeFromDate = date => {
    date == 'Invalid Date'
      ? setErrFromDate('Invalid Date Format')
      : setErrFromDate('');
    setFromDate(date);
    moment(date).format('YYYY-MM-DD');
    handleSearch(date, toDate);
    let maxdate = new Date(date);
    maxdate.setDate(maxdate.getDate() + 1);
    setFromMaxDate(maxdate);
  };

  const changeToDate = date => {
    date == 'Invalid Date'
      ? setErrToDate('Invalid Date Format')
      : setErrToDate('');
    setToDate(date);
    moment(date).format('YYYY-MM-DD');
    handleSearch(fromDate, date);
    let mindate = new Date(date);
    mindate.setDate(mindate.getDate() - 1);
    setToMaxDate(mindate);
  };
  useEffect(() => {
    handleSearch(null, null);
  }, []);
  const handleSearch = async (start_date, end_date) => {
    try {
      const data = {
        start_date:
          start_date != null ? moment(start_date).format('YYYY-MM-DD') : '',
        end_date: end_date != null ? moment(end_date).format('YYYY-MM-DD') : ''
      };
      setIsSubmitting(true);
      const resp = await axios.post('promotion-business', {
        promotion_id: promotion_id,
        ...data
      });
      setIsSubmitting(false);
      if (resp.data.success) {
        resp.data.data.total == null
          ? setPrice('0')
          : setPrice(resp.data.data.total);
          resp.data.data.total_quantity == null
          ? setQuantity('0')
          : setQuantity(resp.data.data.total_quantity);
        return;
      }
      enqueueSnackbar(resp.data.message, { variant: 'error' });
      return;
    } catch (error) {
      enqueueSnackbar(error, { variant: 'error' });
      return;
    }
  };

  const onClickHandler = () => {
    onClose();
  };

  const onError = (errors, e) => console.log(errors, e);
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={onClickHandler}
      aria-labelledby="form-dialog-title"
      PaperProps={{ style: { overflowY: 'visible', width: '1000px' } }}
    >
      <DialogTitle id="form-dialog-title">
        {'Total Sales Per Quantity (Paid Order Only) '}
      </DialogTitle>
      <DialogContent className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={6} md={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                fullWidth
                inputVariant="outlined"
                variant="dialog"
                id="from"
                label="From"
                format="dd/MM/yyyy"
                maxDate={toMaxDate}
                value={fromDate}
                onChange={changeFromDate}
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
                error={Boolean(errFromDate)}
                helperText={errFromDate}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      onClick={() => changeFromDate(null)}
                      disabled={!fromDate}
                    >
                      <Clear />
                    </IconButton>
                  )
                }}
                InputAdornmentProps={{
                  position: 'start'
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={6} md={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                fullWidth
                inputVariant="outlined"
                variant="dialog"
                id="to"
                label="To"
                format="dd/MM/yyyy"
                minDate={fromMaxDate.setDate(fromMaxDate.getDate() - 1)}
                value={toDate}
                onChange={changeToDate}
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
                error={Boolean(errToDate)}
                helperText={errToDate}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      onClick={() => changeToDate(null)}
                      disabled={!toDate}
                    >
                      <Clear />
                    </IconButton>
                  )
                }}
                InputAdornmentProps={{
                  position: 'start'
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} md={12}>
            <div
              style={{
                fontSize: '45px',
                color: '#24cde6',
                textAlign: 'center',
                paddingTop: '19px'
              }}
            >
              {currency + ' ' + price} / {quantity} {"QTY"}
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      {isSubmitting && <LinearProgress />}
      <DialogActions>
        <Button
          disabled={isSubmitting}
          onClick={onClickHandler}
          color="primary"
        >
          {' '}
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

SalesDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  promotion_id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired
};

export default SalesDialog;
