import React, { useState, useEffect } from 'react';
// Material UI
import {
  Container,
  Box, Card,
  CardContent,Grid
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Page from 'src/components/common/Page';
import Toolbar from './Toolbar';
import SocialStats from './SocialStats';
import UsersByDevice from './UsersByDevice';
import TotalDownloads from './TotalDownloads';
import AndroidIcon from '@material-ui/icons/Android';
import AppleIcon from '@material-ui/icons/Apple';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { connect } from 'react-redux';
import * as actionCreators from '../../../store/actions';
import Loader from 'src/components/common/loader';
const Statistics = ({ loadStatistics, dashboard}) => {

const [pageLoader, setPageLoader] = useState(true);

  useEffect(() => {
    
    loadStatistics()
      .then(() => {
        setPageLoader(false);
      })
      .catch(err => {
        setPageLoader(false);
      });
    
  }, []);
  return (
    <PerfectScrollbar>
      <Page title="Statistics">
        <Container maxWidth={false} >
          <Toolbar />
          {!pageLoader ? (
          <Grid container spacing={3} style={{marginTop:"12px"}}>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <TotalDownloads
              title={'PlayStore - Downloads'}
              value={dashboard?.downloads?.android + "+"}
              icon={<AndroidIcon />}
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <TotalDownloads
              title={'AppStore - Reviews'}
              value={dashboard?.downloads?.ios}
              icon={<AppleIcon />}
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <TotalDownloads
              title={'Coming soon'}
              value={"Coming soon"}
              icon={<DateRangeIcon />}
            />
          </Grid>
          <Grid item lg={6} md={6} xl={6} xs={12}>
            <UsersByDevice title={'Regsiter By Device'}  value={dashboard?.userByDevice}/>
          </Grid>
          <Grid item lg={6} md={6} xl={6} xs={12}>
              <SocialStats  title={'Social Media Stats'}   value={dashboard?.socialStats}   />
          </Grid>                 
          </Grid>           
          ) : (
          <Loader />
        )}
        </Container>
      </Page>
    </PerfectScrollbar>
  );
};


const mapDispatchToProps = dispatch => {
  return {    
    loadStatistics: () => dispatch(actionCreators.loadStatistics())
  };
};

const mapStateToProps = state => {  
  return {    
    dashboard: state.statistics?.statisticsDashboards,  
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Statistics);

