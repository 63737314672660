import axios from '../../services/axios';
import { Types } from 'src/store/actions/actionTypes';

export const loadAds = (search, page) => {
  console.log('search :', search);

  return async dispatch => {
    try {
      const resp = await axios.post('get-ads');
      const { data, message, success } = resp?.data;
      if (success) {
        dispatch(setFilteredAds(data));
        dispatch(setAds(data));
        return { message, success };
      } else {
        throw { message, success };
      }
    } catch (error) {
      throw error;
    }
  };
};

const uploadFiles = async files => {
  console.log('Files Uploaded', files);
  const fileUrls = [];

  for (let m = 0; m < files.length; m++) {
    try {
      const file = files[m];

      if (typeof file === 'string') {
        fileUrls.push({ file_type: 'image', file_url: file });
        continue;
      }
      console.log('Wont be logged');

      let formData = new FormData();
      formData.append('file', file);
      const resp = await axios.post(
        'https://api.ishro.com/v3/upload-file-google',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      );

      if (resp.data.success) {
        fileUrls.push(resp.data.results.files[0]);
        console.log(resp);
      } else {
        continue;
      }
    } catch (error) {
      console.log(error);
      continue;
    }
  }
  return fileUrls;
};

export const addAd = (body, files, files_ar) => async dispatch => {
  try {
    const fileResp = await uploadFiles(files);
    if (!fileResp) throw 'Error Uploading English Banner Image';
    const fileResp_ar = await uploadFiles(files_ar);
    if (!fileResp_ar) throw 'Error Uploading Arabic Banner Image';

    const resp = await axios.post(
      'add-ads',
      JSON.stringify({
        ...body,
        file: fileResp[0].file_url,
        file_type: fileResp[0].file_type,
        file_ar: fileResp_ar[0].file_url,
        file_type_ar: fileResp_ar[0].file_type
      })
    );
    const { message, success } = resp.data;
    if (success) {
      return message;
    }
    throw message;
  } catch (error) {
    throw error;
  }
};

export const changeAdStatus = body => async dispatch => {
  try {
    const resp = await axios.post('change-ads-status', JSON.stringify(body));
    const { message, success } = resp.data;
    if (success) {
      return message;
    }
    throw message;
  } catch (error) {
    throw error;
  }
};

const setFilteredAds = ads => ({
  type: Types.SET_FILTERED_ADS,
  payload: { ads }
});

const setAds = ads => ({
  type: Types.SET_ADS,
  payload: { ads }
});

export const clearAds = () => ({
  type: Types.CLEAR_ADS
});
