import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Chip,
  Container,
  Grid,
  makeStyles,
  Snackbar,
  Fab
} from '@material-ui/core';

import { useConfirm } from 'material-ui-confirm';
import InfiniteScroll from 'react-infinite-scroll-component';
import Page from 'src/components/common/Page';
import Toolbar from './Toolbar';
import PromotionCard from '../managePromotion/PromotionCard';
import TicketCard from './ticket';
import Loader from 'src/components/common/loader';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import * as actionCreators from '../../../../store/actions';
import axios from 'src/services/axios';
import { useNavigate, useParams } from 'react-router';
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  productCard: {
    height: '100%'
  },
  infiniteMsg: {
    margin: '24px 0px',
    textAlign: 'center'
  }
}));

const TicketsList = ({
  loadPromotions,
  filterPromotions,
  promotions,
  deletePromotion,
  filteredPromotions
}) => {
  const classes = useStyles();
  const { promotion_id } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [tickets, setTickets] = useState([]);
  const [searchKey, setSearchKey] = useState('');
  const filteredTickets = useSelector(state => state.promotion.promoTickets);
  const Tickets = useSelector(state => state.promotion.Tickets);
  const dispatch = useDispatch();
  const [status, setStatus] = useState('');
  useEffect(() => {
    if (promotion_id) {
      init();
    }
  }, []);

  const init = () => {
    dispatch(actionCreators.clearFilteredTickets());
    dispatch(actionCreators.clearTickets());
    loadTickets('refresh', searchKey);
  };

  // Callbacks from Component
  const loadTickets = async (type, searchKey) => {
    try {
      let localPage = page + 1;
      if (type === 'refresh') {
        localPage = 1;
      }
      var data = {
        page: localPage,
        promotion_id,
        search: searchKey
      };

      dispatch(actionCreators.loadPromoTickets(data))
        .then(data => {
          if (!data.success) {
            setStatus('No Tickets found');
          } else {
            setPage(localPage);
          }
        })
        .catch(err => {
          filteredTickets?.length == 0 && err == 'Notfound tickets'
            ? setStatus('No Tickets found')
            : setStatus('All Tickets Have Been Loaded.');
          setHasMore(false);
        });
    } catch (error) {
      setHasMore(false);
      navigate(-1);
      enqueueSnackbar('Error Listing Tickets', {
        variant: 'error'
      });
    }
  };

  const filterPromotionHandler = search => {
    dispatch(actionCreators.clearFilteredTickets());
    setSearchKey(search);
    loadTickets('refresh', search);
  };

  const markAsWinner = async (user_id, promotion_id, ticket_id, name) => {
    try {
      const cnfirm = await confirm({
        description: `You are about to choose a Winner. Are you sure, you  as Winner ?`
      });
    } catch (err) {
      console.log('User Cancelled Operqation');
      return;
    }
    try {
      const body = { promotion_id, ticket_id, status: '2' };
      const resp = await axios.post('mark-winner', JSON.stringify(body));
      const { message, success } = resp.data;
      if (success) {
        enqueueSnackbar(message, { variant: 'success' });
        init();
        return message;
      }
      enqueueSnackbar(message, { variant: 'error' });
      return;
    } catch (error) {
      enqueueSnackbar(error, { variant: 'error' });
      return;
    }
  };

  return (
    <Page className={classes.root} title="Products">
      <Container maxWidth={false}>
        {Tickets?.length > 0 && (
          <Toolbar
            promotion_id={Tickets[0].promotion.promotion_id}
            promotion={Tickets[0].promotion}
            title={Tickets[0].promotion.title}
            description={Tickets[0].promotion.description}
            search={searchKey}
            onFilter={filterPromotionHandler}
          />
        )}

        <Box mt={3}>
          {filteredTickets?.length > 0 ? (
            <InfiniteScroll
              style={{ overflow: 'hidden' }}
              dataLength={filteredTickets.length}
              next={loadTickets}
              hasMore={hasMore}
              scrollableTarget="scroll"
              loader={
                <center>
                  <Chip
                    className={classes.infiniteMsg}
                    size="small"
                    color="default"
                    label="Loading..."
                  />
                </center>
              }
              endMessage={
                <center>
                  <Chip
                    className={classes.infiniteMsg}
                    size="small"
                    color="default"
                    label={status}
                  />
                </center>
              }
            >
              <Grid
                container
                spacing={3}
                id="scroll"
                style={{
                  overflowY: 'scroll',
                  maxHeight: '800px'
                }}
              >
                {filteredTickets.map((tk, id) => (
                  <Grid item key={id} lg={6} md={6} sm={12} xs={12}>
                    {tk && (
                      <TicketCard
                        ticket={tk}
                        markedAsWinner={markAsWinner}
                      ></TicketCard>
                    )}
                  </Grid>
                ))}
              </Grid>
            </InfiniteScroll>
          ) : status != '' ? (
            <div style={{ textAlign: 'center' }}>
              <Chip
                className={classes.infiniteMsg}
                size="small"
                color="default"
                label={status}
              />
            </div>
          ) : (
            <Loader />
          )}
        </Box>
      </Container>
    </Page>
  );
};

export default TicketsList;
