import { Types } from 'src/store/actions/actionTypes';

const initialState = {
    dashboards: []
};

const dashboard = (state = initialState, action) => {
  switch (action.type) {
    case Types.SET_DASHBOARD:
      return {
        ...state,
        dashboards: action.payload.data
      };
    case Types.CLEAR_DASHBOARD:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default dashboard;
