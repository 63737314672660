import {
  Typography,
  Grid,
  Paper,
  Box,
  Fab,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Table,
  TableBody,
  TableRow,
  TableCell
} from '@material-ui/core';
import React from 'react';
import Image from 'material-ui-image';
import { blue, pink, purple } from '@material-ui/core/colors';
import { Award, UserCheck, Printer, X } from 'react-feather';
import { encode, decode, isValid } from 'js-base64';

const ticketCard = ({ ticket, markedAsWinner }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const winnerHandler = e => {
    e.preventDefault();
    console.log(ticket);
    markedAsWinner(
      ticket.user.user_id,
      ticket.promotion.promotion_id,
      ticket.ticket_id,
      ticket.user.first_name
    );
  };

  const dateFormate = date => {
    const d = new Date(date);
    const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
    const mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d);
    const da = new Intl.DateTimeFormat('en', { day: 'numeric' }).format(d);
    return `${da} ${mo} ${ye}`;
  };

  console.log('ticket: ', ticket);

  const isBase64 = str => {
    if (str === '' || str.trim() === '') {
      return false;
    }
    try {
      return btoa(atob(str)) == str;
    } catch (err) {
      return false;
    }
  };

  return (
    <Paper elevation={13}>
      <Grid
        container
        spacing={1}
        direction="row"
        justify="center"
        alignContent="center"
        wrap="wrap"
        style={{ margin: '12px 0px' }}
      >
        <Grid
          item
          xs={2}
          md={1}
          justify="center"
          alignItems="flex-end"
          style={{
            backgroundColor: '#24cde6',
            display: 'flex',
            paddingBottom: '12px',
            borderRadius: '6px 0px 0px 6px'
          }}
        >
          <img
            style={{
              width: '31px',
              aspectRatio: '245 / 648',
              marginTop: '60%'
            }}
            src="https://dashboard.ishro.com/static/images/logos/logo_whte.png"
          ></img>
        </Grid>
        <Grid item container xs={7} md={9}>
          <Grid item xs={12}>
            <Typography
              style={{ padding: '0px 12px', marginTop: '25px' }}
              variant="h2"
            >
              {ticket.ticket_number}
              {ticket.promotion.status === '2' ? '(😍 Winner)' : ''}
            </Typography>
          </Grid>
          <Grid
            item
            direction="column"
            xs={12}
            md={6}
            style={{ padding: '12px' }}
          >
            <Typography>
              <strong>Prize:</strong>
              {isBase64(ticket?.promotion?.title)
                ? decode(ticket?.promotion?.title).replace(/<[^>]+>/g, '')
                : ticket?.promotion?.title}
            </Typography>
            <Typography>
              <strong>Purchased by:</strong>{' '}
              {ticket?.user?.first_name + ' ' + ticket?.user?.last_name}
            </Typography>
            <Typography>
              <strong>Date Purchased:</strong> {dateFormate(ticket.created_at)}
            </Typography>
            <img
              src="https://dashboard.ishro.com/static/images/barcode.png"
              style={{
                aspectRatio: '270/46',
                marginTop: '50px'
              }}
            ></img>
          </Grid>
          <Grid item xs={12} md={6}>
            <img
              src={ticket?.promotion?.draw_image}
              style={{
                width: '150px',
                height: '150px',
                objectFit: 'contain'
              }}
            ></img>
          </Grid>
        </Grid>
        <Grid
          item
          xs={3}
          md={2}
          direction="column"
          justify="center"
          alignItems="center"
          style={{ display: 'flex', paddingRight: '12px' }}
          spacing={2}
        >
          {ticket?.status == '1' ? (
            <Fab
              color="primary"
              style={{
                margin: '12px 0px',
                color: 'white',
                backgroundColor: pink[300]
              }}
              onClick={winnerHandler}
              aria-label=""
              size="small"
            >
              <Award />
            </Fab>
          ) : (
            <img
              src="https://dashboard.ishro.com/static/trophy.svg"
              style={{ height: '94px', margin: '12px 0px' }}
            />
          )}

          <Fab
            color="primary"
            style={{
              margin: '12px 0px',
              color: 'white',
              backgroundColor: purple[300]
            }}
            aria-label=""
            size="small"
            onClick={handleClickOpen}
          >
            <UserCheck />
          </Fab>
          <Fab
            color="secondary"
            style={{
              margin: '12px 0px'
            }}
            aria-label=""
            size="small"
          >
            <Printer />
          </Fab>
        </Grid>
      </Grid>

      {/* --------------------Users Dialog ------------------------------*/}
      <Dialog onClose={handleClose} open={open} maxWidth="md" keepMounted>
        <DialogTitle id="customized-dialog-title">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h3">User Details</Typography>
            <Fab
              color="primary"
              aria-label="close"
              size="small"
              onClick={handleClose}
            >
              <X />
            </Fab>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          {Object.entries({
            ticket_number: ticket.ticket_number,
            ...ticket.user
          }).map(([key, value]) => (
            <Table
              // className={classes.table}
              size="small"
              aria-label="a dense table"
            >
              <TableBody>
                <TableRow>
                  <TableCell align="left">
                    <Typography
                      gutterBottom
                      style={{ textTransform: 'capitalize' }}
                    >
                      <strong>{key.replaceAll('_', ' ')}</strong>
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography style={{ textTransform: 'capitalize' }}>
                      {value}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          ))}
        </DialogContent>
      </Dialog>
    </Paper>
  );
};

export default ticketCard;
