import axios from '../../services/axios';
import { Types } from 'src/store/actions/actionTypes';

export const loadBanners = (search, page) => {
  console.log('search :', search);

  return async dispatch => {
    try {
      const resp = await axios.post('get-banners');
      const { data, message, success } = resp?.data;
      if (success) {
        dispatch(setFilteredBanners(data));
        dispatch(setBanners(data));
        return { message, success };
      } else {
        throw { message, success };
      }
    } catch (error) {
      throw error;
    }
  };
};

const uploadFiles = async files => {
  console.log('Files Uploaded', files);
  const fileUrls = [];

  for (let m = 0; m < files.length; m++) {
    try {
      const file = files[m];

      if (!file.blob && !file.url.includes('blob')) {
        fileUrls.push({ file_type: file.type, file_url: file.url });
        continue;
      }
      console.log("Won't be logged");

      let formData = new FormData();
      formData.append('file', file.blob);
      const resp = await axios.post(
        'https://api.ishro.com/v3/upload-file-google',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      );

      if (resp.data.success) {
        fileUrls.push(resp.data.results.files[0]);
        console.log(resp);
      } else {
        continue;
      }
    } catch (error) {
      console.log(error);
      continue;
    }
  }
  return fileUrls;
};

export const addBanner = (body, files, filesAr) => async dispatch => {
  try {
    const fileResp = await uploadFiles(files);
    if (!fileResp || fileResp.length <= 0) throw 'Error Uploading Banner Image';

    const fileRespAr = await uploadFiles(filesAr);
    if (!fileRespAr || fileRespAr.length <= 0)
      throw 'Error Uploading Banner Image';

    if (fileResp[0].file_type != fileRespAr[0].file_type) {
      throw 'Both uploading file type not same';
    }
    const resp = await axios.post(
      'add-banner',
      JSON.stringify({
        ...body,
        file: fileResp[0].file_url,
        file_type: fileResp[0].file_type,
        file_ar: fileRespAr[0].file_url
      })
    );

    const { message, success } = resp.data;
    if (success) {
      return message;
    }
    throw message;
  } catch (error) {
    throw error;
  }
};

export const changeBannerStatus = body => async dispatch => {
  try {
    const resp = await axios.post('change-banner-status', JSON.stringify(body));
    const { message, success } = resp.data;
    if (success) {
      return message;
    }
    throw message;
  } catch (error) {
    throw error;
  }
};

const setFilteredBanners = banners => ({
  type: Types.SET_FILTERED_BANNERS,
  payload: { banners }
});

const setBanners = banners => ({
  type: Types.SET_BANNERS,
  payload: { banners }
});

export const clearBanners = () => ({
  type: Types.CLEAR_BANNERS
});
