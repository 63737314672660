import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import * as actionCreators from '../../../../store/actions';
import { useDispatch, useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  }
}));

const validationSchema = Yup.object().shape({
  name_en: Yup.string()
    .max(255)
    .matches(/^(?!(.)\1\1\1\1\1\1\1\1\1\1)/, {
      message: "Don't Enter Consecutive character",
      excludeEmptyString: true
    })
    .required('Category Name (English) is required'),
  name_ar: Yup.string()
    .max(255)
    .matches(/^(?!(.)\1\1\1\1\1\1\1\1\1\1)/, {
      message: "Don't Enter Consecutive character",
      excludeEmptyString: true
    })
    .required('Category Name (Arabic) is required')
});

const Toolbar = ({ className, onCatAdded, ...rest }) => {
  const dispatch = useDispatch();
  const categories = useSelector(state => state.categories.categories);
  const classes = useStyles();
  const [searchKey, setSearchKey] = useState('');
  const [addCat, setAddCat] = useState(false);

  const initialValues = {
    name_en: '',
    name_ar: ''
  };

  const handleSearch = e => {
    const searchKey = e.target.value;
    console.log(e.target.value);
    setSearchKey(searchKey);
    dispatch(actionCreators.filterCategories(searchKey, categories));
  };
  const openDialog = () => {
    setAddCat(true);
  };
  const handleClose = e => {
    console.log(e.target.value);
    setAddCat(false);
  };
  const addCategory = (values, { setSubmitting }) => {
    // alert('Cat is', values.name);
    dispatch(actionCreators.addCategory(values.name_en, values.name_ar))
      .then(msg => {
        setAddCat(false);
        setSubmitting(false);
        onCatAdded({ msg: msg, type: 'success' });
      })
      .catch(err => {
        console.log(err);
        setAddCat(false);
        setSubmitting(false);
        onCatAdded({
          msg: 'Error Adding Category. Error: ' + err,
          type: 'error'
        });
      });
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box display="flex" justifyContent="flex-end">
        <Button color="primary" variant="contained" onClick={openDialog}>
          Add Category
        </Button>
      </Box>
      <Box mt={3}>
        <Card>
          <CardContent>
            <Box maxWidth={500}>
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                placeholder="Search Category"
                variant="outlined"
                onChange={handleSearch}
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
      <Dialog
        open={addCat}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Add Category</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={addCategory}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <TextField
                  error={Boolean(touched.name_en && errors.name_en)}
                  fullWidth
                  helperText={touched.name_en && errors.name_en}
                  label="Category Name (English)"
                  margin="normal"
                  name="name_en"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.name_en}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.name_ar && errors.name_ar)}
                  fullWidth
                  helperText={touched.name_ar && errors.name_ar}
                  label="Category Name (Arabic)"
                  margin="normal"
                  name="name_ar"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.name_ar}
                  variant="outlined"
                />
                <DialogActions>
                  <Button
                    disabled={isSubmitting}
                    onClick={handleClose}
                    color="primary"
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={isSubmitting}
                    variant="contained"
                    type="submit"
                    color="primary"
                  >
                    Add
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
  onCatAdded: PropTypes.func
};

export default Toolbar;
