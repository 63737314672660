import React from 'react';
// Material UI
import {
  Container,
  Box, Card,
  CardContent,
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Page from 'src/components/common/Page';
import Toolbar from './Toolbar';
import Loader from 'src/components/common/loader';

const Banners = () => {
  return (
    <PerfectScrollbar>
      <Page title="Snapchat Ads">
        <Container maxWidth={false}>
          <Toolbar />
          <Box mt={3}>
          <Card>
          <CardContent>
             <Loader status='Coming Soon' />
            </CardContent>
           </Card>
          </Box>
        </Container>
      </Page>
    </PerfectScrollbar>
  );
};

export default Banners;
