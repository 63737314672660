import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import {
  Box,
  Container,
  makeStyles,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Paper,
  IconButton,
  TextField
} from '@material-ui/core';
import Page from 'src/components/common/Page';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import axios from 'src/services/axios';
import { useSnackbar } from 'notistack';
import Edit from '@material-ui/icons/Edit';
import Done from '@material-ui/icons/Done';
import Close from '@material-ui/icons/Close';
const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1)
    }
  }
}));
const validConsecutiveChar = new RegExp(
  '^(?!\\b([a-zA-Z0-9])\\1\\1\\1\\1\\1\\1\\1\\1\\1+\\b)'
);

const grid = 8;
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  console.log(result);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  result.map((k, index) => {
    k.position = index + 1;
  });
  return result;
};

function Quote({ quote, index, quotes }) {
  const [edit_en, setEditEn] = useState(false);
  const [sectionTitle_en, setSectionTitleEn] = useState(quote.section_title_en);

  const [edit_ar, setEditAr] = useState(false);
  const [sectionTitle_ar, setSectionTitleAr] = useState(quote.section_title_ar);
  const { enqueueSnackbar } = useSnackbar();

  const updateSection = (quotes, index, sectionTitle, lang) => {
    if (!validConsecutiveChar.test(sectionTitle)) {
      enqueueSnackbar("Don't Enter Consecutive character", {
        variant: 'error'
      });
    } else {
      updateSectionName(quotes, index, sectionTitle, lang);
      lang == 'en' ? setEditEn(false) : setEditAr(false);
    }
  };

  const updateSectionName = (list, valIndex, value, lang) => {
    const result = Array.from(list);
    result.map((k, index) => {
      if (index == valIndex) {
        if (lang == 'en') {
          k.section_title_en = value;
        } else {
          k.section_title_ar = value;
        }
      }
    });
    updateSectionOrder(result);
  };

  const updateSectionOrder = async data => {
    const body = {
      sections: data
    };
    try {
      const resp = await axios.post(
        'update-home-sections',
        JSON.stringify(body)
      );
      const { success, message } = resp.data;
      if (success) {
        enqueueSnackbar(message, { variant: 'success' });
        return;
      }
      enqueueSnackbar(message, { variant: 'error' });
      return;
    } catch (error) {
      enqueueSnackbar(error, { variant: 'error' });
      return;
    }
  };

  return (
    <Draggable draggableId={quote.id} index={index}>
      {provided => (
        <Paper
          elevation={3}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <div style={{ padding: '1px', marginBottom: '12px' }}>
            <Box
              display="flex"
              p={1}
              bgcolor="background.paper"
              style={{ alignItems: 'center' }}
            >
              <Box p={1}>
                {edit_en ? (
                  <>
                    <TextField
                      id="standard-basic"
                      value={sectionTitle_en}
                      onChange={event => setSectionTitleEn(event.target.value)}
                    />
                    <IconButton
                      color="primary"
                      component="span"
                      onClick={() =>
                        updateSection(quotes, index, sectionTitle_en, 'en')
                      }
                    >
                      <Done />
                    </IconButton>
                    <IconButton
                      color="primary"
                      component="span"
                      onClick={() => setEditEn(false)}
                    >
                      <Close />
                    </IconButton>
                  </>
                ) : (
                  quote.section_title_en
                )}
              </Box>
              <Box p={1}>
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  onClick={() => setEditEn(true)}
                >
                  <Edit />
                </IconButton>
              </Box>

              <Box p={1}>
                {edit_ar ? (
                  <>
                    <TextField
                      id="standard-basic"
                      value={sectionTitle_ar}
                      onChange={event => setSectionTitleAr(event.target.value)}
                    />
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                      onClick={() =>
                        updateSection(quotes, index, sectionTitle_ar, 'ar')
                      }
                    >
                      <Done />
                    </IconButton>
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                      onClick={() => setEditAr(false)}
                    >
                      <Close />
                    </IconButton>
                  </>
                ) : (
                  quote.section_title_ar
                )}
              </Box>
              <Box p={1}>
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  onClick={() => setEditAr(true)}
                >
                  <Edit />
                </IconButton>
              </Box>
            </Box>
          </div>
        </Paper>
      )}
    </Draggable>
  );
}

const QuoteList = React.memo(function QuoteList({ quotes }) {
  return quotes != undefined && quotes?.length > 0 ? (
    quotes.map((quote, index) => (
      <Quote quote={quote} index={index} key={quote.id} quotes={quotes} />
    ))
  ) : (
    <></>
  );
});

const WebsiteOrder = () => {
  const classes = useStyles();
  const [state, setState] = useState({ quotes: [] });
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    axios
      .get('home-sections')
      .then(res => {
        if (res.data.success) {
          var sectionOption = [];
          res.data.data.map(k =>
            sectionOption.push({
              id: k.id,
              section_title_en: k.section_title_en,
              section_title_ar: k.section_title_ar,
              section: k.section,
              position: k.position
            })
          );
          setState({ quotes: sectionOption });
        }
      })
      .catch(error => {
        console.log(error);
        if (error) {
          reject(new Error(error));
        }
      });
  }, []);

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }
    const quotes = reorder(
      state.quotes,
      result.source.index,
      result.destination.index
    );
    setState({ quotes });
    updateSectionOrder(quotes);
  }

  const updateSectionOrder = async data => {
    const body = {
      sections: data
    };
    try {
      const resp = await axios.post(
        'update-home-sections',
        JSON.stringify(body)
      );
      const { success, message } = resp.data;
      if (success) {
        enqueueSnackbar(message, { variant: 'success' });
        return;
      }
      enqueueSnackbar(message, { variant: 'error' });
      return;
    } catch (error) {
      enqueueSnackbar(error, { variant: 'error' });
      return;
    }
  };

  return (
    <Page title="Settings">
      <Container maxWidth="lg">
        <Card>
          <CardHeader title="Website Sections" />
          <Divider />
          <CardContent>
            <div className={classes.root}>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="list">
                  {provided => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      <QuoteList quotes={state.quotes} />
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default WebsiteOrder;
