import { Types } from 'src/store/actions/actionTypes';

const initialState = {
  banners: [],
  filteredBanners: []
};

const banners = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case Types.SET_BANNERS:
      return {
        ...state,
        banners: payload.banners,
        filteredBanners: payload.banners
      };
    case Types.SET_FILTERED_BANNERS:
      return {
        ...state,
        filteredBanners: payload.filteredBanners
      };
    case Types.CLEAR_BANNERS:
      return {
        ...state,
        banners: [],
        filteredBanners: []
      };

    default:
      return state;
  }
};

export default banners;
