import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
  AppBar,
  Toolbar,
  IconButton
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useConfirm } from 'material-ui-confirm';
import * as actionCreators from '../../../store/actions';
import CurrencyFormat from 'react-currency-format';
import Page from 'src/components/common/Page';
import '../../../assets/styles/MainStyles.css';

class PrintOrder extends React.PureComponent {
  render() {
    const { classes } = this.props.classes;
    const totalResellerOrders = this.props.totalResellerOrders;
    const user = JSON.parse(sessionStorage.getItem('ishro_user_data'));
    return (
      <Card>
        <PerfectScrollbar>
          <Box minWidth={1050}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={7}>
                    <Typography variant="subtitle1" gutterBottom>
                      Revenue Generated{': '}
                      <CurrencyFormat
                        value={
                          totalResellerOrders?.total_sales
                            ? totalResellerOrders.total_sales
                            : 0
                        }
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'AED '}
                        renderText={value => value}
                      />
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom>
                      Total Commission{': '}
                      <CurrencyFormat
                        value={
                          totalResellerOrders?.total_profit
                            ? totalResellerOrders.total_profit.toFixed(2)
                            : 0
                        }
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'AED '}
                        renderText={value => value}
                      />
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom>
                      Pending Payments{': '}
                      <CurrencyFormat
                        value={
                          totalResellerOrders?.pending_profit
                            ? totalResellerOrders.pending_profit.toFixed(2)
                            : 0
                        }
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'AED '}
                        renderText={value => value}
                      />
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Order No.</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>From</TableCell>
                  <TableCell>Payment Status</TableCell>
                  <TableCell>Revenue</TableCell>
                  <TableCell>Comission</TableCell>
                  <TableCell>Settlement</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.resellerOrders?.length &&
                  this.props.resellerOrders.map((reseller, index) => (
                    <TableRow hover key={index}>
                      <TableCell> {reseller.order_number} </TableCell>
                      <TableCell>
                        {moment(reseller.created_at).format('DD/MM/YYYY')}{' '}
                      </TableCell>
                      <TableCell>
                        {' '}
                        {reseller.user.first_name} {reseller.user.last_name}{' '}
                      </TableCell>
                      <TableCell>{reseller.payment_status} </TableCell>
                      <TableCell>
                        <CurrencyFormat
                          value={
                            reseller.grand_total ? reseller.grand_total : 0
                          }
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={'AED '}
                          renderText={value => value}
                        />
                      </TableCell>
                      <TableCell style={{ color: '#10981a' }}>
                        {'+ '}
                        <CurrencyFormat
                          value={
                            reseller.percentage_profit
                              ? reseller.percentage_profit.toFixed(2)
                              : 0
                          }
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={'AED '}
                          renderText={value => value}
                        />
                      </TableCell>
                      <TableCell
                        className={
                          reseller?.paid_reseller == '0'
                            ? 'redColor'
                            : 'greenColor'
                        }
                      >
                        {reseller?.paid_reseller == '0' ? 'Pending' : 'Settled'}{' '}
                      </TableCell>
                    </TableRow>
                  ))}
                <TableRow>
                  <TableCell colSpan={4}>
                    <Box fontWeight="fontWeightBold" m={1}>
                      Total
                    </Box>
                  </TableCell>
                  <TableCell>
                    <CurrencyFormat
                      value={
                        totalResellerOrders?.total_sales
                          ? totalResellerOrders.total_sales
                          : 0
                      }
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'AED '}
                      renderText={value => value}
                    />
                  </TableCell>
                  <TableCell colSpan={2}>
                    <CurrencyFormat
                      value={
                        totalResellerOrders?.total_profit
                          ? totalResellerOrders.total_profit.toFixed(2)
                          : 0
                      }
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'AED '}
                      renderText={value => value}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
      </Card>
    );
  }
}

export default PrintOrder;
