export const Types = {
  // Axios Progress Bar
  SET_PROGRESS: 'SET_PROGRESS',
  // permission
  SET_USER_PERMISSIONS: 'SET_USER_PERMISSIONS',
  CLEAR_USER_PERMISSIONS: 'CLEAR_USER_PERMISSIONS',
  //Ads
  SET_DASHBOARD: 'SET_DASHBOARD',
  CLEAR_DASHBOARD: 'CLEAR_DASHBOARD',
  SET_USER_DATA: 'SET_USER_DATA',
  SET_ERROR_MSG: 'SET_ERROR_MSG',
  //STATISTICS
  SET_STATISTICS_DASHBOARD: 'SET_STATISTICS_DASHBOARD',
  CLEAR_STATISTICS_DASHBOARD: 'CLEAR_STATISTICS_DASHBOARD',
  //Countries
  SET_COUNTRIES: 'SET_COUNTRIES',
  CLEAR_COUNTRIES: 'CLEAR_COUNTRIES',
  // Manage Customers -> Manage Customers
  SET_CUSTOMERS: 'SET_CUSTOMERS',
  CLEAR_CUSTOMERS: 'CLEAR_CUSTOMERS',
  // Manage Total Customers -> Manage Total Customers
  SET_TOTAL_CUSTOMERS: 'SET_TOTAL_CUSTOMERS',
  CLEAR_TOTAL_CUSTOMERS: 'CLEAR_TOTAL_CUSTOMERS',
  //Manage Reseller -> Manage Reseller
  SET_RESELLER_ORDERS: 'SET_RESELLER_ORDERS',
  CLEAR_RESELLER_ORDERS: 'CLEAR_RESELLER_ORDERS',
  SET_RESELLER_USER: 'SET_RESELLER_USER',
  CLEAR_RESELLER_USER: 'CLEAR_RESELLER_USER',
  // Manage Total Reseller -> Manage Total Reseller
  SET_TOTAL_RESELLER_ORDERS: 'SET_TOTAL_RESELLER_ORDERS',
  CLEAR_TOTAL_RESELLER_ORDERS: 'CLEAR_TOTAL_RESELLER_ORDERS',
  // Manage Products -> Manage Categories
  SET_CATEGORIES: 'SET_CATEGORIES',
  GET_CATEGORIES: 'GET_CATEGORIES',
  SET_FILTERED_CATEGORIES: 'SET_FILTERED_CATEGORIES',
  ADD_CATEGORY: 'ADD_CATEGORY',
  // Manage Products -> Add Products
  SET_PRODUCTS: 'SET_PRODUCTS',
  GET_PRODUCTS: 'GET_PRODUCTS',
  SET_FILTERED_PRODUCTS: 'SET_FILTERED_PRODUCTS',
  ADD_PRODUCT: 'ADD_PRODUCT',
  CLEAR_PRODUCTS: 'CLEAR_PRODUCTS',
  // Promotion
  SET_PROMO_CATEGORIES: 'SET_PROMO_CATEGORIES',
  GET_PROMO_CATEGORIES: 'GET_PROMO_CATEGORIES',
  SET_FILTERED_PROMO_CATEGORIES: 'SET_FILTERED_PROMO_CATEGORIES',
  ADD_PROMO_CATEGORY: 'ADD_PROMO_CATEGORY',
  // Promotion Types
  SET_PROMO_TYPES: 'SET_PROMO_TYPES',
  GET_PROMO_TYPES: 'GET_PROMO_TYPES',
  SET_FILTERED_PROMO_TYPES: 'SET_FILTERED_PROMO_TYPES',
  ADD_PROMO_TYPE: 'ADD_PROMO_TYPE',
  // Promotion Coupons
  SET_PROMO_COUPONS: 'SET_PROMO_COUPONS',
  SET_FILTERED_PROMO_COUPONS: 'SET_FILTERED_PROMO_COUPONS',
  // Promotion Ticket
  SET_PROMO_TICKETS: 'SET_PROMO_TICKETS',
  SET_PRINT_PROMO_TICKETS: 'SET_PRINT_PROMO_TICKETS',
  CLEAR_FILTERED_TICKETS: 'CLEAR_FILTERED_TICKETS',
  SET_TICKETS: 'SET_TICKETS',
  CLEAR_TICKETS: 'CLEAR_TICKETS',
  // Manage Products -> Manage Categories
  SET_PROMOTIONS: 'SET_PROMOTIONS',
  GET_PROMOTIONS: 'GET_PROMOTIONS',
  SET_FILTERED_PROMOTIONS: 'SET_FILTERED_PROMOTIONS',
  ADD_PROMOTION: 'ADD_PROMOTION',
  CLEAR_PROMOTIONS: 'CLEAR_PROMOTIONS',
  // News
  SET_NEWS: 'SET_NEWS',
  GET_NEWS: 'GET_NEWS',
  SET_FILTERED_NEWS: 'SET_FILTERED_NEWS',
  ADD_NEWS: 'ADD_NEWS',
  CLEAR_NEWS: 'CLEAR_NEWS',
  CLEAR_FILTERED_NEWS: 'CLEAR_FILTERED_NEWS',
  // Orders
  SET_ORDERS: 'SET_ORDERS',
  SET_FILTERED_ORDERS: 'SET_FILTERED_ORDERS',
  CLEAR_ORDERS: 'CLEAR_ORDERS',
  //Banners
  SET_BANNERS: 'SET_BANNERS',
  SET_FILTERED_BANNERS: 'SET_FILTERED_BANNERS',
  CLEAR_BANNERS: 'CLEAR_BANNERS',
  //Ads
  SET_ADS: 'SET_ADS',
  SET_FILTERED_ADS: 'SET_FILTERED_ADS',
  CLEAR_ADS: 'CLEAR_ADS',

  //Sponsors
  SET_SPONSORS: 'SET_SPONSORS',
  SET_FILTERED_SPONSORS: 'SET_FILTERED_SPONSORS',
  CLEAR_SPONSORS: 'CLEAR_SPONSORS',
  CLEAR_FILTERED_SPONSORS: 'CLEAR_FILTERED_SPONSORS',

  //auction
  SET_AUCTION_CATEGORIES: 'SET_AUCTION_CATEGORIES',
  SET_FILTERED_AUCTION_CATEGORIES: 'SET_FILTERED_AUCTION_CATEGORIES',
  CLEAR_AUCTION_CATEGORIES: 'CLEAR_AUCTION_CATEGORIES',
  // Manage Products -> Manage Categories
  SET_AUCTIONS: 'SET_AUCTIONS',
  GET_AUCTIONS: 'GET_AUCTIONS',
  SET_FILTERED_AUCTIONS: 'SET_FILTERED_AUCTIONS',
  ADD_AUCTION: 'ADD_AUCTION',
  CLEAR_AUCTIONS: 'CLEAR_AUCTIONS'
};
