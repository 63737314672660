import { Types } from 'src/store/actions/actionTypes';

const initialState = {
    Countries: []
};

const countries = (state = initialState, action) => {
  switch (action.type) {
    case Types.SET_COUNTRIES:
      return {
        ...state,
        Countries: action.payload.data
      };
    case Types.CLEAR_COUNTRIES:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default countries;
