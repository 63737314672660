import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  makeStyles
} from '@material-ui/core';
import {
  BarChart as BarChartIcon,
  Settings as SettingsIcon,
  ShoppingBag as ShoppingBagIcon,
  User as UserIcon,
  Users as UsersIcon,
  ShoppingCart,
  LogOut,
  Pocket,
  AlignJustify,
  Airplay,
  Radio,
  DollarSign,
  Facebook,
  BarChart2,
  Globe,
  Command,
  Instagram,
  Camera,
  Music,
  FileText,
  MessageCircle
} from 'react-feather';
import { useIdleTimer } from 'react-idle-timer';
import { useSelector, useDispatch } from 'react-redux';
import NavItem from './NavItem';
import * as actionCreators from '../../../../store/actions';

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const location = useLocation();
  const isLoggedIn = useSelector(state => state.user.isLoggedIn);
  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    dispatch(actionCreators.loadPermission())
      .then(res => {
        var menus = [];
        menus.push({
          href: '//dashboard',
          icon: BarChartIcon,
          title: 'Dashboard'
        });
        if (res.admin == '1') {
          menus.push({
            href: '/permit',
            icon: FileText,
            title: 'Permit'
          });
        }

        if (res.admin == '1' || res.social == '1') {
          menus.push({
            href: '#',
            icon: UsersIcon,
            title: 'Users',
            children: [
              {
                href: '/users/allUsers',
                icon: UsersIcon,
                title: 'All Users',
                desc: 'All Ishro Users'
              },
              {
                href: '/users/Customers',
                icon: UsersIcon,
                title: 'Customers',
                desc: 'All User who purchase Ishro product '
              },
              {
                href: '/users/notBuyer',
                icon: UsersIcon,
                title: 'Not a Buyer',
                desc: 'All User who NOT purchase Ishro product'
              },
              {
                href: '/users/ActiveCutomers',
                icon: UsersIcon,
                title: 'Last Month Buyer',
                desc: 'All User who purchase Ishro product Last Month'
              },
              {
                href: '/users/Resellers',
                icon: UsersIcon,
                title: 'Resellers',
                desc:
                  'All User whoes type is reseller.Reseller means person who sell Ishro Product'
              },
              {
                href: '/users/cart',
                icon: UsersIcon,
                title: 'Cart',
                desc: 'All User who add Ishro product in cart'
              },
              {
                href: '/users/favorite',
                icon: UsersIcon,
                title: 'Favorite',
                desc: 'All User who like our Promotions'
              },
              {
                href: '/users/Incompleteregistration',
                icon: UsersIcon,
                title: 'Incomplete Registration',
                desc: 'Registration not completed User'
              }
            ]
          });
        }
        if (res.admin == '1' || res.reseller == '1') {
          menus.push({
            href: '/resellers',
            icon: DollarSign,
            title: 'Resellers'
          });
        }
        if (
          res.admin == '1' ||
          res.financial == '1' ||
          res.social == '1' ||
          res.orders == '1'
        ) {
          menus.push({
            href: '/orders',
            icon: ShoppingCart,
            title: 'Orders'
          });
        }

        if (res.admin == '1' || res.social == '1') {
          menus.push({
            href: '/statistics',
            icon: BarChart2,
            title: 'Statistics'
          });
          menus.push({
            href: '#',
            icon: Globe,
            title: 'Marketing',
            children: [
              {
                href: '/marketing/googleAds',
                icon: Globe,
                title: 'Google Ads'
              },
              {
                href: '/marketing/facebookAds',
                icon: Facebook,
                title: 'Facebook Ads'
              },
              {
                href: '/marketing/instagramAds',
                icon: Instagram,
                title: 'Instagram Ads'
              },
              {
                href: '/marketing/tiktokAds',
                icon: Music,
                title: 'TikTok Ads'
              },
              {
                href: '/marketing/snapchatAds',
                icon: Camera,
                title: 'Snapchat Ads'
              },
              {
                href: '/marketing/whatsappAds',
                icon: MessageCircle,
                title: 'Whatsapp Ads'
              }
            ]
          });
        }
        if (res.admin == '1' || res.product == '1') {
          menus.push({
            href: '#',
            icon: ShoppingBagIcon,
            title: 'Products',
            children: [
              {
                href: '/category',
                icon: ShoppingBagIcon,
                title: 'Manage Category'
              },
              {
                href: '/products',
                icon: ShoppingBagIcon,
                title: 'Manage products'
              }
            ]
          });
        }
        if (res.admin == '1' || res.promotion == '1') {
          menus.push({
            href: '#',
            icon: Pocket,
            title: 'Promotion',
            children: [
              {
                href: '/promotion/coupon',
                icon: Pocket,
                title: 'Promotion Discount'
              },
              {
                href: '/promotion/type',
                icon: Pocket,
                title: 'Promotion Type'
              },
              {
                href: '/promotion/category',
                icon: Pocket,
                title: 'Promotion Category'
              },
              {
                href: '/promotion/manage',
                icon: Pocket,
                title: 'Manage Promotion'
              }
            ]
          });
        }
        if (res.admin == '1' || res.content_manager == '1') {
          menus.push({
            href: '/news',
            icon: AlignJustify,
            title: 'News'
          });
          menus.push({
            href: '/banners',
            icon: Airplay,
            title: 'Banners'
          });
          menus.push({
            href: '/ads',
            icon: Radio,
            title: 'Ads'
          });

          menus.push({
            href: '/sponsors',
            icon: DollarSign,
            title: 'Sponsors'
          });
          menus.push({
            href: '/websiteOrder',
            icon: SettingsIcon,
            title: 'Website Sections Order'
          });
          menus.push({
            href: '/settings',
            icon: SettingsIcon,
            title: 'Settings'
          });
        }
        if (res.admin == '1' || res.auction == '1') {
          menus.push({
            href: '#',
            icon: Command,
            title: 'Auction',
            children: [
              {
                href: '/auction/category',
                icon: Command,
                title: 'Auction Categories'
              },
              {
                href: '/auction/manage',
                icon: Command,
                title: 'Auction Manage'
              }
            ]
          });
        }

        menus.push({
          href: '/account',
          icon: UserIcon,
          title: 'Account'
        });

        setMenuItems(menus);
      })
      .then(error => {
        // console.log('error :', error);
      });
  }, [location.pathname]);

  const handleOnIdle = event => {
    dispatch(actionCreators.logout());
  };

  const handleOnActive = event => {
    console.log('user is active', event);
  };

  const handleOnAction = event => {
    console.log('user did something', event);
  };

  useIdleTimer({
    timeout: 28800000,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500
  });

  const logoutHandler = () => {
    dispatch(actionCreators.logout());
  };

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Divider />
      <Box p={2}>
        <List>
          {menuItems.length
            ? menuItems.map(item => (
                <NavItem
                  href={item.href}
                  key={item.title}
                  title={item.title}
                  icon={item.icon}
                  children={item?.children}
                />
              ))
            : ''}
          {isLoggedIn ? (
            <NavItem
              href="/login"
              onClick={logoutHandler}
              key="Logout"
              title="Logout"
              icon={LogOut}
            />
          ) : (
            ''
          )}
        </List>
      </Box>
      <Box flexGrow={1} />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default NavBar;
