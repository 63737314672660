import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CustomerListView from './index';
const IncompleteRegistration = () => { 
  return (
    <>     
     <CustomerListView userId={'5'}/>
   </>
  );
};

IncompleteRegistration.propTypes = {
  className: PropTypes.string
};

export default IncompleteRegistration;
