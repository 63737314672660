import { Types } from 'src/store/actions/actionTypes';

const initialState = {
  ads: [],
  filteredAds: []
};

const ads = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case Types.SET_ADS:
      return {
        ...state,
        ads: payload.ads,
        filteredAds: payload.ads
      };
    case Types.SET_FILTERED_ADS:
      return {
        ...state,
        filteredAds: payload.filteredAds
      };
    case Types.CLEAR_ADS:
      return {
        ...state,
        ads: [],
        filteredAds: []
      };

    default:
      return state;
  }
};

export default ads;
