import axios from '../../services/axios';
import { Types } from 'src/store/actions/actionTypes';

/**
 * Load Categories action
 */
export const loadPromoCoupons = (search = '') => {
  console.log('search :', search);
  return dispatch => {
    return new Promise((resolve, reject) => {
      const body = {
        search: search
      };
      axios
        .post('get-discounts', JSON.stringify(body))
        .then(res => {
          console.log(res);
          if (res?.data?.success) {
            dispatch(setPromoCoupons(res.data.data));
            dispatch(setFilteredPromoCoupons(res.data.data));
            resolve(res.data.data);
          } else {
            reject();
          }
        })
        .catch(error => {
          console.log(error);
          if (error) {
            reject(new Error(error));
          }
        });
    });
  };
};

export const changePromoCouponStatus = body => async dispatch => {
  try {
    const resp = await axios.post(
      'change-discount-status',
      JSON.stringify(body)
    );
    const { message, success } = resp.data;
    if (success) {
      dispatch(loadPromoCoupons(''));
      return message;
    }
    throw message;
  } catch (error) {
    throw error;
  }
};

export const addPromoCoupon = body => async dispatch => {
  try {
    const resp = await axios.post('add-discount', JSON.stringify(body));
    const { message, success } = resp.data;
    if (success) {
      dispatch(loadPromoCoupons(''));
      return message;
    }
    throw message;
  } catch (error) {
    throw error;
  }
};

export const filterPromoCoupons = (search, Coupons) => {
  const filteredList = Coupons.filter(el =>
    el.discount_code.toLowerCase().includes(search.toLowerCase())
  );
  return dispatch => dispatch(setFilteredPromoCoupons(filteredList));
};

const uploadFiles = async files => {
  console.log(files);
  const fileUrls = [];
  for (let m = 0; m < files.length; m++) {
    try {
      const file = files[m];
      if (typeof file === 'string') {
        fileUrls.push({ file_type: 'image', file_url: file });
        continue;
      }
      console.log('Wont be logged');
      let formData = new FormData();
      formData.append('file', file);
      const resp = await axios.post(
        'https://api.ishro.com/v3/upload-file-google',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      );
      if (resp.data.success) {
        fileUrls.push(resp.data.results.files[0]);
        console.log(resp);
      } else {
        continue;
      }
    } catch (error) {
      console.log(error);
      continue;
    }
  }
  return fileUrls;
};

export const setPromoCoupons = promoCoupons => {
  return {
    type: Types.SET_PROMO_COUPONS,
    payload: { promoCoupons }
  };
};

const setFilteredPromoCoupons = filteredPromoCoupons => ({
  type: Types.SET_FILTERED_PROMO_COUPONS,
  payload: { filteredPromoCoupons }
});
