import React, { useEffect, useState } from 'react';
import {
  Container,
  Paper,
  Card,
  CardContent,
  CardHeader,
  Grid,
  TextField,
  Typography,
  Divider,
  Button,
  CardActions,
  LinearProgress
} from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { useSnackbar } from 'notistack';

import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import Page from 'src/components/common/Page';
import GoBack from 'src/components/common/goBack';
import DropZone from 'src/components/common/dropZone';
import * as actionCreators from 'src/store/actions';
import { connect, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { decode, isValid } from 'js-base64';

const validationSchema = Yup.object().shape({
  title: Yup.string()
    .matches(/^(?!(.)\1\1\1\1\1\1\1\1\1\1)/, {
      message: "Don't Enter Consecutive character",
      excludeEmptyString: true
    })
    .required('Ads Title is required'),
  title_ar: Yup.string().matches(/^(?!(.)\1\1\1\1\1\1\1\1\1\1)/, {
    message: "Don't Enter Consecutive character",
    excludeEmptyString: true
  }),
  description: Yup.string()
    .matches(/^(?!(.)\1\1\1\1\1\1\1\1\1\1)/, {
      message: "Don't Enter Consecutive character",
      excludeEmptyString: true
    })
    .required('Ads Description is required'),
  description_ar: Yup.string().matches(/^(?!(.)\1\1\1\1\1\1\1\1\1\1)/, {
    message: "Don't Enter Consecutive character",
    excludeEmptyString: true
  }),
  link: Yup.string()
    .url()
    .required('Please Add Link'),
  link_ar: Yup.string().url(),
  files: Yup.array()
    .min(1, 'Please Upload Atleast One File')
    .max(1)
    .required('Please Upload Atleast One File')
});

const initialValues = {
  title: '',
  title_ar: '',
  description: '',
  description_ar: '',
  link: '',
  link_ar: '',
  files: [],
  files_ar: []
};

const count = 0;

const addAdComponent = ({ addAd, progress, ads }) => {
  const { ad_id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [files, setFiles] = useState([]);
  const [files_ar, setFilesAr] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  console.log('RENDER :', count + 1);

  const { handleSubmit, register, errors, control, setValue } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'onChange'
  });
  const action = ad_id ? 'edit' : 'add';

  useEffect(() => {
    if (ad_id) {
      prefillForm();
    }
  }, []);

  const onSubmit = (data, e) => {
    setIsSubmitting(true);
    const body = {
      action,
      title: data.title,
      title_ar: data.title_ar,
      description: data.description,
      description_ar: data.description_ar,
      link: data.link,
      link_ar: data.link_ar,
      ad_id
    };

    console.log('Submitted Form Data', body);
    addAd(body, data.files, data.files_ar)
      .then(msg => {
        console.log(msg);
        enqueueSnackbar(msg, {
          variant: 'success'
        });
        navigate(-1);
        setIsSubmitting(false);
        return;
      })
      .catch(err => {
        console.error(err);
        enqueueSnackbar(err, { variant: 'error' });
        setIsSubmitting(false);
      });
  };

  const onError = (errors, e) => {
    console.log(errors, e);
    setIsSubmitting(false);
  };

  const prefillForm = () => {
    if (ads.length < 0) {
      enqueueSnackbar('No Ad Found. Please come-back again', {
        variant: 'error'
      });
      navigate(-1);
      return;
    }

    const adToBeEditted = ads.find(e => e.ad_id == ad_id);
    if (!adToBeEditted) {
      enqueueSnackbar('Error Editting the Ad. Please come-back again', {
        variant: 'error'
      });
      navigate(-1);
      return;
    }

    setValue('title', adToBeEditted.title);
    setValue('title_ar', adToBeEditted.title_ar);
    setValue('description', adToBeEditted.description);
    setValue('description_ar', adToBeEditted.description_ar);
    setValue('link', adToBeEditted.link);
    setValue('link_ar', adToBeEditted.link_ar);
    setFiles([adToBeEditted.file]);
    setValue('files', [adToBeEditted.file]);
    setFilesAr([adToBeEditted.file_ar]);
    setValue('files_ar', [adToBeEditted.file_ar]);
  };

  const goBack = () => navigate(-1);
  return (
    <Page title={action == 'add' ? 'Add Ad' : 'Edit Ad'}>
      <Container>
        <GoBack onClick={goBack} />
        <Card raised>
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <CardHeader title={action == 'add' ? 'Add Ad' : 'Edit Ad'} />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    label="Ad Title"
                    type="text"
                    name="title"
                    variant="outlined"
                    fullWidth
                    error={Boolean(errors?.title?.message)}
                    helperText={errors.title?.message}
                    inputRef={register}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Ad Title (Arabic)"
                    type="text"
                    name="title_ar"
                    variant="outlined"
                    fullWidth
                    error={Boolean(errors?.title_ar?.message)}
                    helperText={errors.title_ar?.message}
                    inputRef={register}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Ad Link"
                    type="url"
                    name="link"
                    variant="outlined"
                    fullWidth
                    error={Boolean(errors?.link?.message)}
                    helperText={errors.link?.message}
                    inputRef={register}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Ad Link (Arabic)"
                    type="url"
                    name="link_ar"
                    variant="outlined"
                    fullWidth
                    error={Boolean(errors?.link_ar?.message)}
                    helperText={errors.link_ar?.message}
                    inputRef={register}
                  />
                </Grid>
                <Grid item xs>
                  <TextField
                    label="Ad Description"
                    type="text"
                    name="description"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={4}
                    error={Boolean(errors?.description?.message)}
                    helperText={errors.description?.message}
                    inputRef={register}
                  />
                </Grid>
                <Grid item xs>
                  <TextField
                    label="Ad Description (Arabic) (Optional)"
                    type="text"
                    name="description_ar"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={4}
                    error={Boolean(errors?.description_ar?.message)}
                    helperText={errors.description_ar?.message}
                    inputRef={register}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="files"
                    render={({ value, ref }) => (
                      <DropZone
                        id="files"
                        col={1}
                        onFilesChange={filesArray =>
                          setValue('files', filesArray, {
                            shouldValidate: true,
                            shouldDirty: true
                          })
                        }
                        initialFiles={files}
                        label="Upload English Banner"
                        error={errors?.files?.message}
                        inputRef={ref}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="files_ar"
                    render={({ value, ref }) => (
                      <DropZone
                        id="files_ar"
                        col={1}
                        onFilesChange={filesArray =>
                          setValue('files_ar', filesArray, {
                            shouldValidate: true,
                            shouldDirty: true
                          })
                        }
                        initialFiles={files_ar}
                        label="Upload Arabic Banner"
                        error={Boolean(errors?.files_ar)}
                        inputRef={ref}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            {progress > 0 && (
              <LinearProgress variant="determinate" value={progress} />
            )}

            <CardActions>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={Boolean(progress > 0 || isSubmitting)}
              >
                {action === 'add' ? 'Add' : 'Update'} Ad
              </Button>
            </CardActions>
          </form>
        </Card>
      </Container>
    </Page>
  );
};

const mapStateToProps = state => {
  return {
    progress: state.progress.fileProgress,
    ads: state.ads.ads
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addAd: (body, files, files_ar) =>
      dispatch(actionCreators.addAd(body, files, files_ar))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(addAdComponent);
