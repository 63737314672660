import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  Chip,
  TableRow,
  makeStyles,
  Collapse,
  Typography,
  IconButton,
  Fab,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  LinearProgress
} from '@material-ui/core';
import Loader from 'src/components/common/loader';
import {
  LicenseInfo,
  XGrid,
  GridToolbar,
  useGridApiRef
} from '@material-ui/x-grid';
import { purple } from '@material-ui/core/colors';
import axios from 'src/services/axios';
import { RadioButtonCheckedRounded as ActiveIcon } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import { useConfirm } from 'material-ui-confirm';
import CurrencyFormat from 'react-currency-format';
import { UserCheck, X, Send, ExternalLink } from 'react-feather';
import Select from 'react-select';
LicenseInfo.setLicenseKey(
  '6391385253d2aae4e0d72df787edd9f0T1JERVI6MjYzNzAsRVhQSVJZPTE2NTYwNTc2OTgwMDAsS0VZVkVSU0lPTj0x'
);
const selectStyles = {
  control: styles => ({
    ...styles,
    backgroundColor: 'white',
    width: '120px'
  }),
  menu: styles => ({
    ...styles,
    zIndex: '11',
    position: 'relative',
    height: '81px',
    overflowY: 'scroll'
  })
};
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));
const statusOptions = [
  { value: 'Pending', label: 'Pending' },
  { value: 'Accepted', label: 'Accepted' },
  { value: 'Refunded', label: 'Refunded' }
];
const BidsList = () => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [pageLoader, setPageLoader] = useState(false);
  const apiRef = useGridApiRef();
  const [rows, setRows] = React.useState([]);
  const [userOpen, setUserOpen] = useState(false);
  const [user, setUser] = useState([]);
  const confirm = useConfirm();
  useEffect(() => {
    loadBids();
  }, []);

  const loadBids = async () => {
    try {
      const url = window.location.href;
      const auctionId = url.substring(url.lastIndexOf('/') + 1);
      const localPage = 1;
      axios
        .post(
          'get-auction-bids',
          JSON.stringify({
            page: localPage,
            auction_id: auctionId
          })
        )
        .then(res => {
          debugger;
          if (res.data.success) {
            console.log(res.data.data);
            setRows(res.data.data);
          }
        })
        .catch(err => {
          console.log(err);
        });
    } catch (error) {
      enqueueSnackbar(error, { variant: 'error' });
      setIsSubmitting(false);
    }
  };

  const onFilterChange = React.useCallback(params => {
    setFilterValue(params.filterModel);
  }, []);

  const onStatusBidHandler = (id, status) => {
    confirm({
      description: `Are you sure you want to ${status} this bid ?`
    })
      .then(() => {
        axios
          .post(
            'change-bid-status',
            JSON.stringify({
              id: id,
              bid_status: status
            })
          )
          .then(res => {
            enqueueSnackbar(res.data.message, {
              variant: 'success'
            });
            loadBids();
          })
          .catch(err => {
            console.log(err);
          });
      })
      .catch(err => {
        console.log('User Cancelled Action.', err);
      });
  };
  const openUserDialog = user => {
    setUser(user);
    setUserOpen(true);
  };
  const closeUsersDialog = () => {
    setUserOpen(false);
  };
  const columns = [
    {
      field: 'id',
      headerName: 'Bid id',
      width: 150
    },
    {
      field: 'bid_number',
      headerName: 'Bid Number',
      width: 150
    },
    {
      field: 'bid_amount',
      headerName: 'Bid Amount',
      width: 150,
      renderCell: params => (
        <>
          {
            <CurrencyFormat
              value={params.value}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'AED '}
              renderText={value => value}
            />
          }
        </>
      )
    },
    {
      field: 'user_paid_amount',
      headerName: 'User Paid Amount',
      width: 150,
      renderCell: params => (
        <>
          {
            <CurrencyFormat
              value={params.value}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'AED '}
              renderText={value => value}
            />
          }
        </>
      )
    },
    {
      field: 'discount_amount',
      headerName: 'Discount Amount',
      width: 150,
      renderCell: params => (
        <>
          {
            <CurrencyFormat
              value={params.value}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'AED '}
              renderText={value => value}
            />
          }
        </>
      )
    },
    {
      field: 'paid_via_point',
      headerName: 'Paid Via Points',
      width: 150,
      renderCell: params => (
        <>
          {
            <CurrencyFormat
              value={params.value}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'AED '}
              renderText={value => value}
            />
          }
        </>
      )
    },
    {
      field: 'balance payment',
      headerName: 'Balance Payment',
      width: 180,
      renderCell: params => (
        <>
          {
            <CurrencyFormat
              value={
                parseInt(params.row.bid_amount) -
                (parseInt(params.row.discount_amount) +
                  parseInt(params.row.paid_via_point) +
                  parseInt(params.row.user_paid_amount))
              }
              displayType={'text'}
              thousandSeparator={true}
              prefix={'AED '}
              renderText={value => value}
            />
          }
        </>
      )
    },

    {
      field: 'discount_code',
      headerName: 'Discount code',
      width: 150
    },

    {
      field: 'created_at',
      headerName: 'Created Date',
      type: 'date',
      width: 220
    },
    {
      field: 'payment_status',
      headerName: 'Payment Status',
      width: 220
    },
    {
      field: 'bid_status',
      headerName: 'Bid Status',
      width: 150,
      renderCell: params => (
        <div style={{ lineHeight: '26px', position: 'absolute' }}>
          <Select
            value={statusOptions.find(e => e.value == params.value)}
            options={statusOptions}
            onChange={e => onStatusBidHandler( params.row.id,e.value)}
            styles={selectStyles}
            // menuIsOpen={true}
          />
        </div>
      )
    },
    // {
    //   field: 'bid_status',
    //   headerName: 'Bid Status',
    //   width: 180,
    //   renderCell: params => (
    //     <Button
    //       variant="contained"
    //       style={{
    //         backgroundColor: params.value === 'Accepted' ? '#00e676' : '#d5d5d5'
    //       }}
    //       startIcon={<ActiveIcon />}
    //       onClick={() =>
    //         onStatusBidHandler(
    //           params.row.id,
    //           params.value === 'Pending' ? 'Accepted' : 'Pending'
    //         )
    //       }
    //     >
    //       {params.value === 'Accepted' ? 'Accepted' : 'Pending'}
    //     </Button>
    //   )
    // },
    {
      field: 'user',
      headerName: 'User',
      width: 180,
      renderCell: params => (
        <Fab
          color="primary"
          style={{
            margin: '12px 0px',
            color: 'white',
            backgroundColor: purple[300]
          }}
          aria-label=""
          size="small"
          onClick={() => openUserDialog(params.value)}
        >
          <UserCheck />
        </Fab>
      )
    }
  ];

  return (
    <>
      <Box display="flex" m={3} mb={4}>
        <Box flexGrow={1}>
          <Typography variant="h3">Bids ({rows.length})</Typography>
        </Box>
      </Box>
      {!pageLoader ? (
        <div
          style={{
            display: 'flex',
            height: '88%',
            boxShadow:
              '0 0 0 1px rgb(63 63 68 / 5%), 0 1px 2px 0 rgb(63 63 68 / 15%)',
            color: '#263238',
            transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            backgroundColor: '#fff',
            borderRadius: '4px',
            margin: '1.5%'
          }}
        >
          <div style={{ flexGrow: 1 }}>
            <XGrid
              rows={rows}
              columns={columns}
              pageSize={100}
              components={{
                Toolbar: GridToolbar
              }}
              rowHeight={83}
              checkboxSelection
              disableSelectionOnClick
              apiRef={apiRef}
              pagination
              onFilterModelChange={onFilterChange}
            />
          </div>
        </div>
      ) : (
        <div style={{ margin: '0% 0px' }}>
          <Loader />
        </div>
      )}
      {/* --------------------Users Dialog ------------------------------*/}
      <Dialog
        onClose={closeUsersDialog}
        open={userOpen}
        maxWidth="md"
        keepMounted
      >
        <DialogTitle id="customized-dialog-title">
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h3">User Details</Typography>
            <Fab
              color="primary"
              aria-label="close"
              size="small"
              onClick={closeUsersDialog}
            >
              <X />
            </Fab>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          {user &&
            Object.entries(user).map(([key, value], index) => (
              <Table size="small" aria-label="a dense table" key={index}>
                <TableBody>
                  <TableRow>
                    <TableCell align="left">
                      <Typography
                        gutterBottom
                        style={{ textTransform: 'capitalize' }}
                      >
                        <strong>{key.replaceAll('_', ' ')}</strong>
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography style={{ textTransform: 'capitalize' }}>
                        {value}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            ))}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default BidsList;
