import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  colors,
  makeStyles
} from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  avatar: {
    backgroundColor: "#24cde6",
    height: 56,
    width: 56
  },
  differenceIcon: {
    color: colors.green[900]
  },
  greenColor: {
    color: "#10981a"
  },
  blackColor: {
    color: '#263238'
  },
  differenceValue: {
    color: colors.green[900],
    marginRight: theme.spacing(1)
  }
}));

const TotalCustomers = (props, { className, ...rest }) => {
  const classes = useStyles();

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardContent>
        <Grid container justify="space-between" spacing={2}>
          <Grid item>
            <Typography color="textSecondary" gutterBottom variant="h6">
              {props?.title}
            </Typography>
            <Typography className={props?.color? props.color:classes.blackColor}  variant="h4">
            {props?.value}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              {props?.icon}
            </Avatar>
          </Grid>
        </Grid>
        <Box mt={2} display="flex" alignItems="center">         
         { props?.subvalue && <ArrowUpwardIcon className={classes.differenceIcon} /> } 
          <Typography className={classes.differenceValue} variant="body2">
          {props?.subvalue}
          </Typography>
          <Typography color="textSecondary" variant="caption">
            {props?.subtitle}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

TotalCustomers.propTypes = {
  className: PropTypes.string
};

export default TotalCustomers;
