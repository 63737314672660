export const TicketDateFormate = (date) => {
    const d = new Date(date);
    const time = new Intl.DateTimeFormat('en', { hour: 'numeric', minute: 'numeric',timeZone: 'Asia/Dubai',hour12: false }).format(d);
    const day = new Intl.DateTimeFormat('en', { weekday: 'short' }).format(d);
    const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
    const mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d);
    const da = new Intl.DateTimeFormat('en', { day: 'numeric' }).format(d);  
    return `${time} ${day}, ${da} ${mo} ${ye}`;
  };

 