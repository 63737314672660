import React, { useState, useEffect, useRef } from 'react';
import { Box, Container, Grid, makeStyles, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Page from 'src/components/common/Page';
import Toolbar from './Toolbar';
import AuctionCard from './AuctionCard';
import Loader from 'src/components/common/loader';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import * as actionCreators from '../../../../store/actions';
import axios from 'src/services/axios';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  productCard: {
    height: '100%'
  },
  infiniteMsg: {
    margin: '24px 0px',
    textAlign: 'center'
  }
}));

const AuctionList = ({
  loadAuctions,
  clearAuctions,
  filterAuctions,
  auctions,
  deleteAuction,
  filteredAuctions
}) => {
  const classes = useStyles();
  const [state, setState] = useState({ auctions: auctions });
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [isMsg, setIsMsg] = useState(false);
  const [msg, setMsg] = useState({
    msg: '',
    type: ''
  });
  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    debugger;
    setState({ auctions: auctions });
  }, [auctions]);

  // Callbacks from Component

  const newProductAdded = msg => {
    setMsg({
      msg: 'New Product Added',
      type: 'success'
    });
    setIsMsg(true);
    console.log('New Product Added');
    init();
  };

  const handleOnError = msg => {
    console.log(msg, 'From Index');
    setMsg({
      msg,
      type: 'error'
    });
    setIsMsg(true);
  };

  const init = () => {
    clearAuctions();
    loadMoreAuctions('refresh');
  };
  const loadMoreAuctions = (type = '') => {
    let PageNo = page + 1;
    if (type === 'refresh') {
      PageNo = 1;
      setPage(0);
    }
    console.log('Loading Products ' + JSON.stringify(PageNo));
    loadAuctions('', PageNo)
      .then(value => {
        setPage(PageNo);
      })
      .catch(err => {
        console.log('Setting HAS MORE FALSE');
      });
  };

  const filterAuctionHandler = search => {
    filterAuctions(search, auctions);
  };

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }
    const auctions = reorder(
      state.auctions,
      result.source.index,
      result.destination.index
    );
    setState({ auctions });
    var sectionOption = [];
    auctions.map(k =>
      sectionOption.push({
        auction_id: k.auction_id,
        position: k.position
      })
    );
    updateSectionOrder(sectionOption);
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    console.log(result);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    result.map((k, index) => {
      k.position = (index + 1).toString();
    });
    return result;
  };

  const updateSectionOrder = async data => {
    const body = {
      auctions: data
    };
    try {
      const resp = await axios.post('sort-auctions', JSON.stringify(body));
      const { success, message } = resp.data;
      if (success) {
        enqueueSnackbar(message, { variant: 'success' });
        return;
      }
      enqueueSnackbar(message, { variant: 'error' });
      return;
    } catch (error) {
      enqueueSnackbar(error, { variant: 'error' });
      return;
    }
  };
  return (
    <Page className={classes.root} title="Products">
      <Container maxWidth={false}>
        <Toolbar
          onFormSubmitted={newProductAdded}
          onError={handleOnError}
          onFilter={filterAuctionHandler}
        />
        <Box mt={3}>
          {auctions?.length > 0 ? (
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable
                droppableId="list"
                style={{
                  overflowY: 'scroll',
                  maxHeight: '700px'
                }}
              >
                {provided => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    <Grid container spacing={3} id="scroll">
                      <PromoList auctions={state.auctions} />
                    </Grid>
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          ) : (
            <Loader />
          )}
        </Box>
      </Container>
      <Snackbar
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        open={isMsg}
        autoHideDuration={3000}
        onClose={() => setIsMsg(false)}
      >
        <MuiAlert elevation={6} variant="filled" severity={msg.type}>
          {msg.msg}
        </MuiAlert>
      </Snackbar>
    </Page>
  );
};

const PromoList = React.memo(function PromoList({ auctions }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const onNotificationHandler = async auction_id => {
    try {
      const resp = await axios.post('send-auction-notification', {
        auction_id,
        title: 'Launched Win: Galaxy s21 Bundle',
        message:
          'Buy a Nero set and get a chance to win our samsung galaxy s21 Ultra Bundle!'
      });
      if (resp?.data?.success) {
        enqueueSnackbar(resp.data.message, {
          variant: 'success'
        });
        return;
      } else {
        enqueueSnackbar(resp.data.message, {
          variant: 'error'
        });
      }
    } catch (error) {
      enqueueSnackbar(error, {
        variant: 'error'
      });
    }
  };

  const init = () => {
    dispatch(actionCreators.clearAuctions());
    dispatch(actionCreators.loadAuctions('', 1));
  };

  const deleteAuctionHandler = (auction_id, status) => {
    var body = {
      auction_id: auction_id,
      status: status
    };

    dispatch(actionCreators.deleteAuction(body)).then(msg => {
      enqueueSnackbar(msg, {
        variant: 'success'
      });
      init();
    });
  };
  return auctions != undefined && auctions?.length > 0 ? (
    auctions.map((auction, index) => (
      <Grid item key={index} lg={12} md={12} sm={12} xs={12}>
        <AuctionCard
          auction={auction}
          index={index}
          key={index}
          className={classes.productCard}
          onDeleteAuction={deleteAuctionHandler}
          loadAuction={init}
          onNotification={onNotificationHandler}
        />
      </Grid>
    ))
  ) : (
    <></>
  );
});

const mapDispatchToProps = dispatch => {
  return {
    loadAuctions: (searchKey, page) =>
      dispatch(actionCreators.loadAuctions(searchKey, page)),
    clearAuctions: () => dispatch(actionCreators.clearAuctions()),
    deleteAuction: body => dispatch(actionCreators.deleteAuction(body)),
    filterAuctions: (searchKey, auctions) =>
      dispatch(actionCreators.filterAuctions(searchKey, auctions))
  };
};

const mapStateToProps = state => {
  console.log('state :', state);
  
  return {
    auctions: state.auction.auctions,
    filteredAuctions: state.auction.filteredAuctions
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuctionList);
