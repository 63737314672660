import axios from '../../services/axios';
import { Types } from 'src/store/actions/actionTypes';

/**
 * Load Categories action
 */
export const loadPromoCategories = (search = '') => {
  console.log('search :', search);
  return dispatch => {
    return new Promise((resolve, reject) => {
      const body = {
        search: search
      };
      axios
        .post('get-promotion-category', JSON.stringify(body))
        .then(res => {
          console.log(res);
          if (res?.data?.success) {
            dispatch(setPromoCategories(res.data.data));
            dispatch(setFilteredPromoCategories(res.data.data));
            resolve(res.data.data);
                     
          } else {
            reject();
          }
        })
        .catch(error => {
          console.log(error);
          if (error) {
            reject(new Error(error));
          }
        });
    });
  };
};

export const changePromoCategoryStatus = body => async dispatch => {
  try {
    const resp = await axios.post(
      'change-promotion-category-status',
      JSON.stringify(body)
    );
    const { message, success } = resp.data;
    if (success) {
      dispatch(loadPromoCategories(''));
      return message;
    }
    throw message;
  } catch (error) {
    throw error;
  }
};

export const addPromoCategory = body => async dispatch => {
  try {
    const resp = await axios.post(
      'add-promotion-category',
      JSON.stringify(body)
    );
    const { message, success } = resp.data;
    if (success) {
      dispatch(loadPromoCategories(''));
      return message;
    }
    throw message;
  } catch (error) {
    throw error;
  }
};

export const filterPromoCategories = (search, categories) => {
  const filteredList = categories.filter(el =>
    el.category_name.toLowerCase().includes(search.toLowerCase())
  );
  // console.log(filteredList);
  return dispatch => dispatch(setFilteredPromoCategories(filteredList));
};

export const setPromoCategories = categories => {
  return {
    type: Types.SET_PROMO_CATEGORIES,
    payload: { categories }
  };
};

const setFilteredPromoCategories = filteredCategories => ({
  type: Types.SET_FILTERED_PROMO_CATEGORIES,
  payload: { filteredCategories }
});

const clearPromoCategories = () => ({
  type: Types.CLEAR_PROMO_CATEGORIES,
  payload: {}
});
