import React, { useState, useEffect } from 'react';
import { Box, Chip, Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/common/Page';
import Results from './Results';
import ResellerToolbar from './ResellerToolbar';
import Loader from 'src/components/common/loader';
import { useLocation, useNavigate } from "react-router-dom";
import * as actionCreators from '../../../store/actions';
import { useDispatch, useSelector,connect } from 'react-redux';
import { LicenseInfo,XGrid,GridToolbar ,useGridApiRef } from '@material-ui/x-grid';
import CurrencyFormat from 'react-currency-format';

LicenseInfo.setLicenseKey(
  '6391385253d2aae4e0d72df787edd9f0T1JERVI6MjYzNzAsRVhQSVJZPTE2NTYwNTc2OTgwMDAsS0VZVkVSU0lPTj0x',
);

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  greenColor: {
    color: "#10981a"
  },redColor: {
    color: "#d42a0f"
  },
}));


const ResellerView = ({ loadResellerOrder, resellerOrders,totalResellerOrders,user }) => {
  const classes = useStyles();
  const [pageLoader, setPageLoader] = useState(true);
  const [searchKey, setSearchKey] = useState('');
  const dispatch = useDispatch();
  const [rows, setRows] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const searchCondition = useLocation();
  const apiRef = useGridApiRef();
  const [loading, setLoading] = React.useState(false);
  const [filterValue, setFilterValue] = React.useState([]);
  useEffect(() => {  
    const url = window.location.href;
    const userId = url.substring(url.lastIndexOf("/") + 1);
    if(userId=='resellers'){
      loadOrder('');      
    }
    else{
      loadOrder(userId);
    }    
  }, []);
 const loadOrder =  (userId) => {
    try {
      setLoading(true);
      const body = {
        "page": 1,
        "search": '',       
        "from_date":'',
        "to_date":'',
        "paid_reseller":'',
        "user_id":userId
      };
      loadResellerOrder(body)      
      .then((data) => {
        ;
        setRows(data.data);
        setPageLoader(false);
        setLoading(false);
      })
      .catch(err => {
        setPageLoader(false);
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      return;
    }
  };

  const onFilterChange = React.useCallback((params) => {  
    setFilterValue(params.filterModel);
  }, []);

const columns = [ 
  {
    field: 'order_number',
    headerName: 'Order No.',
    width: 220,     
  },
  {
    field: 'created_at',
    headerName: 'Date',
    type: 'date',
    width: 220,     
  },
  {
    field: 'full_name',
    headerName: 'From',       
    width: 280,
    valueGetter:(params) => {
      return `${params?.row.user?.first_name|| ''} ${
        params?.row.user?.last_name || ''
      }`;
    },
    renderCell: (params) => (
        <>     
         {params?.row.user?.first_name} {params?.row.user?.last_name}
         </>
      ),
  }, 
  {
    field: 'payment_status',
    headerName: 'Payment Status',
    width: 220,     
  },
  {
    field: 'grand_total',
    headerName: 'Revenue',
    width: 220, 
    renderCell: (params) => (
      <>     
      {      
        <CurrencyFormat
        value={params.value ? params.value : 0 }
        displayType={'text'}
        thousandSeparator={true}
        prefix={'AED '}
        renderText={value => value}
        />
        }
  </>
    ),    
  },
  {
    field: 'percentage_profit',
    headerName: 'Commission',
    width: 220,   
    renderCell: (params) => (
      <div className={classes.greenColor}>     
      {"+ "}
      <CurrencyFormat
      value={params.value ? params.value.toFixed(2) : 0 }
      displayType={'text'}
      thousandSeparator={true}
      prefix={'AED '}
      renderText={value => value}
      />        
  </div>
    ),   
  },
  {
    field: 'paid_reseller',
    headerName: 'Settlement',
    width: 220, 
    valueGetter:(params) => {
      return `${params.value =="0" ?"Pending":"Settled"}`;
    }, 
    renderCell: (params) => (
      <div className={params.value =="Pending" ?classes.redColor:classes.greenColor }>     
     {params.value =="Pending" ?"Pending":"Settled"}        
    </div>
    ),     
  },
 
]; 
const handlePageChange = (params) => {  
  setPage(params.page);
};
  return (
     <>          
        <ResellerToolbar setPageLoader={setPageLoader}  totalResellerOrders={totalResellerOrders} resellerOrders={resellerOrders.users} user={user} customerSearch={searchCondition.state}/>
        {!pageLoader ? (
           <div style={{ display: 'flex', height: '88%',boxShadow: '0 0 0 1px rgb(63 63 68 / 5%), 0 1px 2px 0 rgb(63 63 68 / 15%)',
                color: '#263238',
                transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                backgroundColor: '#fff',
                borderRadius: '4px' ,margin: '1.5%'}}>   
                <div style={{ flexGrow: 1 }}>
                  <XGrid
                    rows={rows}
                    columns={columns}
                    pageSize={100}
                    components={{
                      Toolbar: GridToolbar,
                    }}
                    rowHeight={83}
                    checkboxSelection
                    disableSelectionOnClick
                    apiRef={apiRef}
                    onFilterModelChange={onFilterChange}
                    rowCount={totalResellerOrders?.total_orders ? parseInt(totalResellerOrders.total_orders) : 0}
                    onPageChange={handlePageChange}
                    pagination
                    paginationMode="server"     
                    loading={loading}   
                    />    
                    </div>
                    </div>         
            ) : (
          <Loader />
        )}
     
    </>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    loadResellerOrder: (body) =>  dispatch(actionCreators.loadResellerOrder(body))
  };
};

const mapStateToProps = state => {
  return {
    resellerOrders: state.resellers.resellerOrders,
    totalResellerOrders: state.resellers.totalResellerOrders.total,
    user: state.resellers.user.user
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResellerView);
