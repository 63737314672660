import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  Typography
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

const Toolbar = ({ onFilter }) => {
  const permissions = useSelector(state => state.permissions.permissions);
  const navigate = useNavigate();

  const goTo = () => {
    navigate('/news/addNews');
  };
  const handleSearch = e => {
    onFilter(e.target.value);
  };

  return (
    <div>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h3">News</Typography>
        {permissions.content_manager == '1' || permissions.admin == '1' ? (
          <Button color="primary" variant="contained" onClick={goTo}>
            Add News
          </Button>
        ) : (
          ''
        )}
      </Box>
      <Box mt={3}>
        <Card>
          <CardContent>
            <Box maxWidth={500}>
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                placeholder="Search News Title"
                variant="outlined"
                onChange={handleSearch}
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
  onFilter: PropTypes.func
};

export default Toolbar;
