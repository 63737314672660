import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CustomerListView from './index';
const Cart = () => { 
  return (
    <>     
     <CustomerListView userId={'3'}/>
   </>
  );
};

Cart.propTypes = {
  className: PropTypes.string
};

export default Cart;
