import React, { useState, useEffect } from 'react';
// Material UI
import {
  Container,
  Box,
  Card,
  CardContent,
  Typography,
  Button
} from '@material-ui/core';
import CardHeader from '@material-ui/core/CardHeader';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Page from 'src/components/common/Page';
import Toolbar from './Toolbar';
import Loader from 'src/components/common/loader';
import useStyles from './style';
import {
  LicenseInfo,
  XGrid,
  GridToolbar,
  useGridApiRef
} from '@material-ui/x-grid';
import { useDispatch } from 'react-redux';
import TemplateListView from './components/TemplateListView';

const sandbox_url =
  'https://6187b0d4057b9b00177f9a71.mockapi.io/api/v1/templates';

const WhatsappAds = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <PerfectScrollbar>
      <Page title="Whatsapp Ads">
        <Container maxWidth={false}>
          <Toolbar />
          <Box mt={3}>
            <Card>
              <Box className={classes.toolbar}>
                <Button variant="contained" color="primary">
                  Add a template
                </Button>
              </Box>
              <CardContent>
                <TemplateListView />
              </CardContent>
            </Card>
          </Box>
        </Container>
      </Page>
    </PerfectScrollbar>
  );
};

export default WhatsappAds;
