import React, { useEffect } from 'react';
import {
  LicenseInfo,
  XGrid,
  GridToolbar,
  useGridApiRef
} from '@material-ui/x-grid';
import axios from '../../../../../services/axios';

LicenseInfo.setLicenseKey(
  '6391385253d2aae4e0d72df787edd9f0T1JERVI6MjYzNzAsRVhQSVJZPTE2NTYwNTc2OTgwMDAsS0VZVkVSU0lPTj0x'
);
export default function TemplateListView() {
  const [rows, setRows] = React.useState([]);
  const apiRef = useGridApiRef();
  const columns = [
    {
      field: 'name',
      headerName: 'Template',
      width: 220
    },
    {
      field: 'createdAt',
      headerName: 'Created date',
      type: 'date',
      width: 220
    }
    // {
    //   field: 'status',
    //   headerName: 'Status',
    //   width: 180,
    //   renderCell: params => (
    //     <Button
    //       variant="contained"
    //       style={{
    //         backgroundColor: params.value === '1' ? '#00e676' : '#d5d5d5'
    //       }}
    //       startIcon={<ActiveIcon />}
    //       onClick={() =>
    //         onStatusCustomerHandler(
    //           params.value === '1' ? '0' : '1',
    //           params.row.user_id
    //         )
    //       }
    //     >
    //       {params.value === '1' ? 'Active' : 'In-Active'}
    //     </Button>
    //   )
    // }
  ];

  useEffect(() => {
    axios
      .get('https://6187b0d4057b9b00177f9a71.mockapi.io/api/v1/templates')
      .then(res => {
        console.log(res.data);
        setRows(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  return (
    <div style={{ flexGrow: 1 }}>
      <XGrid
        rows={rows}
        columns={columns}
        pageSize={100}
        rowHeight={83}
        checkboxSelection
        disableSelectionOnClick
        apiRef={apiRef}
        pagination
      />
    </div>
  );
}
