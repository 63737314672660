import React, { useEffect, useState, useRef } from 'react';
import axios from 'src/services/axios';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Divider,
  Button,
  Checkbox,
  LinearProgress,
  FormControlLabel,
  FormHelperText,
  FormGroup,
  FormControl,
  FormLabel,
  InputLabel,
  MenuItem,
  Select
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';
import 'emoji-mart/css/emoji-mart.css';
import '../../../../assets/styles/MainStyles.css';
import { Picker } from 'emoji-mart';
import InputEmoji from 'react-input-emoji';
import * as Yup from 'yup';
import { makeStyles, Typography } from '@material-ui/core';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { decode, isValid } from 'js-base64';

const useStyles = makeStyles(theme => ({
  root: {
    overflowY: 'visible',
    height: '278px'
  }
}));

const theme = createMuiTheme({
  overrides: {
    MuiTypography: {
      body2: {
        fontSize: [12, '!important'],
        paddingLeft: '22px'
      }
    }
  }
});

const initialValues = {
  message: '',
  is_name: false
};

const messageDialog = ({ open, onClose, promotion_id, promotion_title }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [message, setMessage] = useState('');
  const [errMessage, setErrMessage] = useState('');
  const [isName, setName] = useState(false);
  const [text, setText] = useState('');
  const componentRef = useRef();
  const [isBusiness, setBusiness] = useState('');
  const handleChange = event => {
    setBusiness(event.target.value);
  };

  function handleMessage(value) {
    if (value == '') {
      setErrMessage('Notification Message is required');
    } else {
      setMessage(value);
      setErrMessage('');
    }
  }

  const onSubmitHandler = async () => {
    try {
      if (message != '') {
        const data = {
          message: message,
          is_name: isName.toString(),
          is_users: isBusiness.toString()
        };
        setIsSubmitting(true);
        const resp = await axios.post('send-sms-message', {
          promotion_id: promotion_id,
          ...data
        });
        setIsSubmitting(false);
        if (resp.data.success) {
          enqueueSnackbar(resp.data.message, { variant: 'success' });
          onClose();
          return;
        }
        enqueueSnackbar(resp.data.message, { variant: 'error' });
        return;
      } else {
        handleMessage(message);
      }
    } catch (error) {
      enqueueSnackbar(error, { variant: 'error' });
      return;
    }
  };

  const onClickHandler = () => {
    onClose();
  };
  const isBase64 = str => {
    if (str === '' || str.trim() === '') {
      return false;
    }
    try {
      return btoa(atob(str)) == str;
    } catch (err) {
      return false;
    }
  };
  const onError = (errors, e) => console.log(errors, e);
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={onClickHandler}
      aria-labelledby="form-dialog-title"
      PaperProps={{ style: { overflowY: 'visible', width: '1000px' } }}
    >
      <DialogTitle id="form-dialog-title">Send Message</DialogTitle>
      <DialogContent className={classes.root}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            className={
              errMessage == '' ? 'notifymessage' : 'notifymessage errormessage'
            }
          >
            <InputEmoji
              value={message}
              type="text"
              name="message"
              id="message"
              onChange={e => handleMessage(e)}
              placeholder="Message"
            />
            {errMessage && (
              <ThemeProvider theme={theme}>
                {' '}
                <Typography color="error" variant="body2">
                  {' '}
                  {errMessage}{' '}
                </Typography>{' '}
              </ThemeProvider>
            )}
          </Grid>

          <Grid item md={12} xs={12} style={{ padding: '0px 0px 0px 18px' }}>
            <FormControlLabel
              name="is_name"
              control={
                <Checkbox
                  checked={isName}
                  onChange={e => setName(e.target.checked)}
                />
              }
              label="Hi Joe,"
            />
            <FormControl
              variant="outlined"
              style={{ marginTop: '12px' }}
              fullWidth
            >
              <InputLabel id="demo-simple-select-outlined-label">
                Users
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={isBusiness}
                onChange={handleChange}
                label="Users"
              >
                <MenuItem value="-1">Test</MenuItem>
                <MenuItem value="0">All</MenuItem>
                <MenuItem value="1">Customers</MenuItem>
                <MenuItem value="2">
                  {(isBase64(promotion_title)
                    ? decode(promotion_title).replace(/<[^>]+>/g, '')
                    : promotion_title) + ' Ticket Holder'}
                </MenuItem>
                <MenuItem value="3">
                  {(isBase64(promotion_title)
                    ? decode(promotion_title).replace(/<[^>]+>/g, '')
                    : promotion_title) + ' In Cart'}
                </MenuItem>
                <MenuItem value="4">
                  {(isBase64(promotion_title)
                    ? decode(promotion_title).replace(/<[^>]+>/g, '')
                    : promotion_title) + ' In Favorite'}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      {isSubmitting && <LinearProgress />}
      <DialogActions>
        <Button
          disabled={isSubmitting}
          onClick={onClickHandler}
          color="primary"
        >
          {' '}
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={isSubmitting}
          onClick={onSubmitHandler}
        >
          {' '}
          Send Message{' '}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

messageDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  promotion_id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired
};

export default messageDialog;

// http://chat-api.phphive.info/login/gui
