import React, { useEffect, useState, useRef } from 'react';
import {
  Container,
  Box,
  makeStyles,
  Button,
  Card,
  CardContent,
  CardHeader,
  LinearProgress,
  Divider,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Typography,
  InputAdornment,
  OutlinedInput,
  Select as MaterialSelect,
  FormGroup,
  FormControl,
  ListItemText,
  FormLabel,
  Input,
  InputLabel,
  MenuItem,
  Chip
} from '@material-ui/core';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { useSnackbar } from 'notistack';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardDateTimePicker
} from '@material-ui/pickers';
import { useDispatch, useSelector } from 'react-redux';
import DropZone from 'src/components/common/dropZone';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import Page from 'src/components/common/Page';
import DateFnsUtils from '@date-io/date-fns';
import { useNavigate, useParams } from 'react-router';
import GoBack from 'src/components/common/goBack';
import { encode, decode, isValid } from 'js-base64';
import { DevTool } from '@hookform/devtools';
import { connect } from 'react-redux';
import * as actionCreators from '../../../../store/actions';
import moment from 'moment';
import SunEditor, { buttonList } from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2
} from 'react-html-parser';
import { ExposureNeg1 } from '@material-ui/icons';

const ButtonCustomList = [
  ['undo', 'redo'],
  ['font', 'fontSize'],
  ['paragraphStyle', 'blockquote'],
  ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
  ['fontColor', 'hiliteColor'],
  ['align', 'list', 'lineHeight'],
  ['outdent', 'indent'],
  ['table', 'horizontalRule'],
  ['fullScreen', 'showBlocks', 'codeView'],
  ['preview', 'print'],
  ['removeFormat']
];

const ButtonCustomList1 = [
  ['undo', 'redo'],
  ['paragraphStyle', 'blockquote'],
  ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
  ['fontColor', 'hiliteColor'],
  ['align', 'list', 'lineHeight'],
  ['outdent', 'indent'],
  ['fullScreen', 'showBlocks', 'codeView'],
  ['removeFormat']
];
const sortedFontOptions = [
  'Almarai'
  // 'Almarai-Light',
  // 'Almarai-Medium',
  // 'Almarai-SemiBold',
  // 'Almarai-Bold'
].sort();

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 48 * 4.5 + 8,
      width: 350
    }
  }
};
const validConsecutiveChar = new RegExp(
  '^(?!\\b([a-zA-Z0-9])\\1\\1\\1\\1\\1\\1\\1\\1\\1+\\b)'
);
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  selectBox: {
    menu: {
      zIndex: '2',
      backgroundColor: 'black',
      color: 'white'
    }
  },
  backFab: {
    margin: 0,
    top: 100,
    right: 20,
    bottom: 'auto',
    left: 'auto',
    position: 'fixed'
  },
  backFabIcon: {
    marginRight: theme.spacing(1)
  }
}));

const validationSchema = Yup.object().shape({
  title: Yup.string().required('Promotion Title is Required'),
  title_ar: Yup.string().required('Promotion Title is Required'),
  category_id: Yup.object().required('Please Select Category'),
  level: Yup.object().required('Please Select User Level'),
  type_id: Yup.string().required('Please Select Promotion Type'),
  product_id: Yup.object().required('Please Select Product'),
  description: Yup.string()
    .min(13, 'Minimum 13 Digit is Required')
    .required('Description is Required'),
  description_ar: Yup.string()
    .min(13, 'Minimum 13 Digit s Required')
    .required('Description is Required'),
  total_tickets_alloted: Yup.string().required(
    'Total tickets Alloted is Required'
  ),
  // points_per_ticket: Yup.number(),
  tickets_per_unit: Yup.string().required('Tickets per Unit is Required'),
  ticket_price_with_tax: Yup.string().required('Ticket Price is Required'),
  worth_price: Yup.string().required('Worth Price is'),
  // max_stock: Yup.number(),
  purchase_points: Yup.string().required('Purchase Points are Required'),
  // Draw
  draw_title: Yup.string().required('Draw Title is Required'),
  draw_title_ar: Yup.string().required('Draw Title is Required'),
  draw_date: Yup.date().required('Draw Date is Required'),
  expiry_date: Yup.date().required('Draw Date is Required'),
  draw_detail: Yup.string().required('Draw Details are Required'),
  draw_detail_ar: Yup.string().required('Draw Details are Required'),
  draw_image: Yup.array().required('Please Upload Draw Image'),
  soldout_image: Yup.array().required('Please Upload Sold Out Image'),
  files: Yup.array()
    .max(500)
    .required('Please Upload Atleast One File'),
  note: Yup.string().matches(/^(?!(.)\1\1\1\1\1\1\1\1\1\1)/, {
    message: "Don't Enter Consecutive character",
    excludeEmptyString: true
  })
});

// Data
const initialValues = {
  title: '',
  title_ar: '',
  winner_title: '',
  winner_title_ar: '',
  type: { value: 'normal', label: 'Normal' },
  category_id: '',
  level: '',
  product_id: '',
  sponsor_id: '',
  currency: { value: 'AE', label: 'AED' },
  type_id: { value: '1', label: 'Regular' },
  total_tickets_alloted: '',
  points_per_ticket: '',
  tickets_per_unit: '1',
  extra_tickets: '',
  fresh_catch: '',
  promotion_charity: true,
  worth_price: '',
  ticket_price_with_tax: '',
  original_price: '',
  max_stock: '',
  purchase_points: '',
  draw_title: '',
  draw_title_ar: '',
  draw_detail: '',
  draw_detail_ar: '',
  draw_note: '',
  draw_note_ar: '',
  description: '',
  description_ar: '',
  winner_detail: '',
  winner_detail_ar: '',
  draw_date: new Date(),
  expiry_date: new Date(),
  winner_announced_on: '',
  start_date: '',
  draw_image: [],
  soldout_image: [],
  winner_image: '',
  highlight: true,
  show_timer: true,
  discount: false,
  files: [],
  draw_video: '',
  note: ''
};

var categoriesOpt = [];
var typeOpt = [];
var typesOpt = [];
var productsOpt = [];
var sponsorsOpt = [];
var currencyOpt = [];
var levelOpt = [
  { value: '0', label: 'Normal' },
  { value: '1', label: 'VIP' }
];
const AddPromotion = ({
  addPromotion,
  loadProducts,
  loadSponsors,
  loadCountries,
  promotions,
  progress
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { promotion_id } = useParams();
  const [drawImage, setDrawImage] = useState([]);
  const [soldoutImage, setSoldOutImage] = useState([]);
  const [files, setFiles] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [desc, setDesc] = useState();
  const [descAr, setDescAr] = useState();
  const [drawDesc, setDrawDesc] = useState();
  const [drawDescAr, setDrawDescAr] = useState();
  const [draw_note, setDrawNote] = useState();
  const [draw_note_ar, setDrawNoteAr] = useState();
  const [winnerDetail, setWinnerDetail] = useState();
  const [winnerDetailAr, setWinnerDetailAr] = useState();
  const [promotionSugg, setPromotionSugg] = React.useState([]);
  const [title, setTitle] = useState();
  const [title_ar, setTitleAr] = useState();
  const [draw_title, setDrawTitle] = useState();
  const [draw_title_ar, setDrawTitleAr] = useState();
  const [winner_title, setWinnerTitle] = useState();
  const [winner_title_ar, setWinnerTitleAr] = useState();
  const [dealType, setDealTypeVal] = useState('normal');
  const [currencylbl, setCurrencyLbl] = useState();
  const dispatch = useDispatch();
  const [categories, setCategories] = useState([]);
  const [level, setLevel] = useState([
    { value: '0', label: 'Normal' },
    { value: '1', label: 'VIP' }
  ]);
  const [type, setType] = useState([]);
  const [types, setTypes] = useState([]);
  const InputLabelRef = useRef();
  const [winnerImage, setWinnerImage] = useState([]);

  const { handleSubmit, register, errors, control, setValue } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'onChange'
  });

  const promiseOptions = (inputValue = '') =>
    new Promise(resolve => {
      loadProducts(inputValue, '1')
        .then(options =>
          resolve(
            options.map(o => ({
              value: o?.product_id,
              label: decode(o?.title).replace(/<[^>]+>/g, '')
            }))
          )
        )
        .catch(err => console.log(err));
    });

  const sponsorsOptions = (inputValue = '') =>
    new Promise(resolve => {
      loadSponsors(inputValue, '1')
        .then(options =>
          resolve(
            options.data
              .filter(o => o.status === '1')
              .map(o => ({
                value: o?.id,
                label: o?.first_name+" "+o?.last_name
              }))
          )
        )
        .catch(err => console.log(err));
    });

  const currencyOptions = () =>
    new Promise(resolve => {
      loadCountries()
        .then(options => {
          resolve(
            options.data.map(o => ({
              value: o?.code,
              label: o?.currency
            }))
          );
        })
        .catch(err => console.log(err));
    });

  useEffect(() => {
    dispatch(actionCreators.loadPromoCategories())
      .then(options => {
        categoriesOpt = [];
        options.map(o =>
          categoriesOpt.push({
            value: o?.category_id,
            label: o?.category_name
          })
        );
        setCategories(categoriesOpt);
        dispatch(actionCreators.loadPromoTypes())
          .then(options => {
            typesOpt = [];
            options.map(el =>
              typesOpt.push({
                value: el.type_id,
                label: el.type_name,
                tickets_per_unit: el.tickets_per_unit
              })
            );
            setTypes(typesOpt);
            dispatch(actionCreators.loadProducts('', 1))
              .then(options => {
                productsOpt = [];
                options.map(p => {
                  productsOpt.push({
                    value: p?.product_id,
                    label: ReactHtmlParser(decode(p?.title))
                  });
                });
                dispatch(actionCreators.loadSponsors('', 1))
                  .then(options => {
                    sponsorsOpt = [];
                    options.data
                      .filter(o => o.status === '1')
                      .map(p =>
                        sponsorsOpt.push({
                          value: p?.id,
                          label: p?.first_name+" "+p?.last_name
                        })
                      );
                    dispatch(actionCreators.loadCountries())
                      .then(options => {
                        currencyOpt = [];
                        options.data.map(o =>
                          currencyOpt.push({
                            value: o?.code,
                            label: o?.currency
                          })
                        );
                        if (promotion_id) {
                          prefillForm('dialog');
                        } else {
                          setValue('type_id', '1');
                        }
                      })
                      .catch(err => console.log(err));
                  })
                  .catch(err => console.log(err));
              })
              .catch(err => console.log(err));
          })
          .catch(err => console.log(err));
      })
      .catch(err => console.log(err));
    typeOpt = [
      { value: 'upcoming', label: 'Upcoming' },
      { value: 'normal', label: 'Normal' },
      { value: 'bundle', label: 'Bundle' }
    ];
    setType(typeOpt);
    if (promotion_id) {
      prefillForm();
    } else {
      setDesc('<p></p>');
      setDescAr('<p></p>');
      setDrawDesc('<p></p>');
      setDrawDescAr('<p></p>');
      setWinnerDetail('<p></p>');
      setWinnerDetailAr('<p></p>');
      setTitle('<p></p>');
      setTitleAr('<p></p>');
      setDrawTitle('<p></p>');
      setDrawTitleAr('<p></p>');
      setWinnerTitle('<p></p>');
      setWinnerTitleAr('<p></p>');
    }
  }, []);

  //   Methods
  const onSubmit = (data, e) => {
    const body = { ...data };

    try {
      if (
        !validConsecutiveChar.test(
          decode(data?.title)?.replace(/<[^>]+>/g, '')
        ) ||
        !validConsecutiveChar.test(
          decode(data?.title_ar)?.replace(/<[^>]+>/g, '')
        ) ||
        !validConsecutiveChar.test(
          decode(data?.description)?.replace(/<[^>]+>/g, '')
        ) ||
        !validConsecutiveChar.test(
          decode(data?.description_ar)?.replace(/<[^>]+>/g, '')
        ) ||
        !validConsecutiveChar.test(
          decode(data?.draw_title)?.replace(/<[^>]+>/g, '')
        ) ||
        !validConsecutiveChar.test(
          decode(data?.draw_title_ar)?.replace(/<[^>]+>/g, '')
        ) ||
        !validConsecutiveChar.test(
          decode(data?.draw_detail)?.replace(/<[^>]+>/g, '')
        ) ||
        !validConsecutiveChar.test(
          decode(data?.draw_detail_ar)?.replace(/<[^>]+>/g, '')
        ) ||
        !validConsecutiveChar.test(
          decode(data?.draw_note)?.replace(/<[^>]+>/g, '')
        ) ||
        !validConsecutiveChar.test(
          decode(data?.draw_note_ar)?.replace(/<[^>]+>/g, '')
        ) ||
        !validConsecutiveChar.test(
          data?.winner_title != undefined
            ? decode(data?.winner_title)?.replace(/<[^>]+>/g, '')
            : ''
        ) ||
        !validConsecutiveChar.test(
          data?.winner_title_ar != undefined
            ? decode(data?.winner_title_ar)?.replace(/<[^>]+>/g, '')
            : ''
        ) ||
        !validConsecutiveChar.test(
          data?.winner_detail != undefined
            ? decode(data?.winner_detail)?.replace(/<[^>]+>/g, '')
            : ''
        ) ||
        !validConsecutiveChar.test(
          data?.winner_detail_ar != undefined
            ? decode(data?.winner_detail_ar)?.replace(/<[^>]+>/g, '')
            : ''
        )
      ) {
        return enqueueSnackbar('Remove Consecutive character', {
          variant: 'error'
        });
      }
    } catch (e) {
      alert(e);
    }
    e.preventDefault();
    setIsSubmitting(true);
    var proSugg = [];

    promotionSugg.map(promo => {
      proSugg.push(promo.promotion_id);
    });
    // console.log('Submitted Form Data -> ', data);
    var expiry_date = moment(body.expiry_date).format('YYYY-MM-DD HH:mm:ss');
    var start_date = moment(body.start_date).format('YYYY-MM-DD');

    body.expiry_date = expiry_date;
    body.start_date = start_date;
    console.log('DATA :', body);

    delete body.draw_image;
    delete body.files;
    // console.log('Submitted Form Data -> ', body);
    body.type = body.type.value;
    body.category_id = body.category_id.value;
    body.level = body.level.value;
    body.product_id = body.product_id.value;
    body.sponsor_id = body?.sponsor_id?.value;
    body.country = body?.currency?.value;
    body.currency = body?.currency?.label;
    body.promotion_charity = body.promotion_charity.toString();
    body.highlight = body.highlight.toString();
    body.show_timer = body.show_timer.toString();
    body.discount = body.discount.toString();
    body.bundle_ids = proSugg.length > 0 ? proSugg : '';

    addPromotion(
      body,
      data.draw_image,
      data.files,
      data.soldout_image,
      data.winner_image,
      promotion_id ? 'update' : 'add',
      promotion_id
    )
      .then(msg => {
        setIsSubmitting(false);
        console.log('Success');
        enqueueSnackbar(msg, {
          variant: 'success'
        });
        navigate(-1);
      })
      .catch(err => {
        setIsSubmitting(false);
        console.log('Error', err);
        enqueueSnackbar(err, {
          variant: 'error'
        });
      });
  };

  const onError = (errors, e) => {
    enqueueSnackbar('error', {
      variant: 'error'
    });
    console.log(errors, e);
  };

  const goBack = () => navigate(-1);
  const prefillForm = (load = '') => {
    const promotion = promotions.find(el => el.promotion_id == promotion_id);
    if (!promotion) {
      if (load == 'dialog') {
        enqueueSnackbar(
          'Error Editting the promotion. Please go back and Try Again.',
          { variant: 'error' }
        );
        navigate(-1);
      }
      return;
    }
    setEditorVal(promotion);

    var proSug = [];
    promotion?.bundle_ids?.map(promoId => {
      proSug.push(promotions.filter(el => el.promotion_id == promoId)[0]);
    });

    setPromotionSugg(proSug);
    // Start Filling up the prefill Values
    for (const field in initialValues) {
      if (field === 'type') {
        setValue(
          'type',
          typeOpt.find(el => el.value === promotion.type)
        );

        setDealTypeVal(typeOpt.find(el => el.value === promotion.type).value);
        continue;
      }
      if (field === 'level') {
        setValue(
          'level',
          levelOpt.find(el => el.value === promotion.level)
        );
        continue;
      }
      if (field === 'category_id') {
        setValue(
          'category_id',
          categoriesOpt.find(el => el.value === promotion.category.category_id)
        );
        continue;
      }
      if (field === 'type_id') {
        setValue('type_id', promotion.type_id);
        continue;
      }
      if (field === 'product_id') {
        setValue(
          'product_id',
          productsOpt.find(el => el.value === promotion.product_id)
        );
        continue;
      }
      if (field === 'sponsor_id') {
        setValue(
          'sponsor_id',
          sponsorsOpt.find(el => el.value === promotion.sponsor_id)
        );
        continue;
      }
      if (field === 'currency') {
        setValue(
          'currency',
          currencyOpt.find(el => el.label === promotion.currency)
        );
        setCurrencyLbl(promotion.currency);
        continue;
      }
      if (
        field === 'description' ||
        field === 'description_ar' ||
        field === 'draw_detail' ||
        field === 'draw_detail_ar' ||
        field === 'draw_note' ||
        field === 'draw_note_ar'
      ) {
        setValue(field, promotion[field]);
        continue;
      }
      if (field === 'winner_detail') {
        setValue(field, promotion.winner_details?.winner_detail);
        continue;
      }
      if (field === 'winner_detail_ar') {
        setValue(field, promotion.winner_details?.winner_detail_ar);
        continue;
      }
      if (field === 'winner_title') {
        setValue(field, promotion.winner_details?.winner_title);
        continue;
      }
      if (field === 'winner_title_ar') {
        setValue(field, promotion.winner_details?.winner_title_ar);
        continue;
      }
      if (field === 'draw_image') {
        setDrawImage([promotion.draw_image]);
        setValue('draw_image', [promotion.draw_image]);
        continue;
      }
      if (
        field === 'soldout_image' &&
        promotion.soldout_image != null &&
        promotion.soldout_image != ''
      ) {
        setSoldOutImage([promotion.soldout_image]);
        setValue('soldout_image', [promotion.soldout_image]);
        continue;
      }
      if (
        field === 'winner_image' &&
        promotion.winner_details?.winner_image != null &&
        promotion.winner_details?.winner_image != ''
      ) {
        setWinnerImage([promotion.winner_details?.winner_image]);
        setValue('winner_image', [promotion.winner_details?.winner_image]);
        continue;
      }
      if (field === 'files') {
        setFiles(promotion.files.map(file => file.file));
        setValue(
          'files',
          promotion.files.map(file => file.file)
        );
        continue;
      }
      if (
        field === 'promotion_charity' ||
        field === 'highlight' ||
        field === 'show_timer' ||
        field === 'discount'
      ) {
        setValue(field, promotion[field] === 'true' ? true : false);
        continue;
      }
      if (field === 'draw_date') {
        setValue(
          field,
          promotion[field] ? new Date(promotion[field]) : new Date()
        );
        continue;
      }
      if (field === 'start_date') {
        setValue(field, promotion[field] ? new Date(promotion[field]) : '');
        continue;
      }
      if (field === 'winner_announced_on') {
        setValue(
          field,
          promotion.winner_details?.winner_announced_on
            ? new Date(promotion.winner_details?.winner_announced_on)
            : ''
        );
        continue;
      }
      if (field === 'expiry_date') {
        setValue(
          field,
          promotion[field] ? new Date(promotion[field]) : new Date()
        );
        continue;
      }
      setValue(field, promotion[field] ? promotion[field] : '');
    }
  };
  const isBase64 = str => {
    if (str === '' || str.trim() === '') {
      return false;
    }
    try {
      return btoa(atob(str)) == str;
    } catch (err) {
      return false;
    }
  };
  const setEditorVal = promotion => {
    setDesc(
      isValid(promotion.description)
        ? decode(promotion.description)
        : promotion.description
    );
    setDescAr(
      isValid(promotion.description_ar)
        ? decode(promotion.description_ar)
        : promotion.description_ar
    );
    setDrawDesc(
      isValid(promotion.draw_detail)
        ? decode(promotion.draw_detail)
        : promotion.draw_detail
    );
    setDrawDescAr(
      isValid(promotion.draw_detail_ar)
        ? decode(promotion.draw_detail_ar)
        : promotion.draw_detail_ar
    );
    setWinnerDetail(
      isValid(promotion.winner_details?.winner_detail)
        ? decode(promotion.winner_details?.winner_detail)
        : promotion.winner_details?.winner_detail
    );
    setWinnerDetailAr(
      isValid(promotion.winner_details?.winner_detail_ar)
        ? decode(promotion.winner_details?.winner_detail_ar)
        : promotion.winner_details?.winner_detail_ar
    );
    try {
      setDrawTitle(
        isValid(promotion.draw_title)
          ? decode(promotion.draw_title)
          : decode(promotion.draw_title)
      );
    } catch (error) {
      setDrawTitle(promotion.draw_title);
    }

    try {
      setDrawTitleAr(
        isValid(promotion.draw_title_ar)
          ? decode(promotion.draw_title_ar)
          : decode(promotion.draw_title_ar)
      );
    } catch (error) {
      setDrawTitleAr(promotion.draw_title_ar);
    }

    try {
      setTitle(
        isValid(promotion.title)
          ? decode(promotion.title)
          : decode(promotion.title)
      );
    } catch (error) {
      setTitle(promotion.title);
    }

    try {
      setTitleAr(
        isValid(promotion.title_ar)
          ? decode(promotion.title_ar)
          : decode(promotion.title_ar)
      );
    } catch (error) {
      setTitleAr(promotion.title_ar);
    }
    try {
      setWinnerTitle(
        isValid(promotion.winner_details?.winner_title)
          ? decode(promotion.winner_details?.winner_title)
          : decode(promotion.winner_details?.winner_title)
      );
    } catch (error) {
      setWinnerTitle(promotion.winner_details?.winner_title);
    }
    try {
      setWinnerTitleAr(
        isValid(promotion.winner_details?.winner_title_ar)
          ? decode(promotion.winner_details?.winner_title_ar)
          : decode(promotion.winner_details?.winner_title_ar)
      );
    } catch (error) {
      setWinnerTitleAr(promotion.winner_details?.winner_title_ar);
    }
    try {
      setDrawNote(
        isValid(promotion.draw_note)
          ? decode(promotion.draw_note)
          : decode(promotion.draw_note)
      );
    } catch (error) {
      setDrawNote(promotion.draw_note);
    }
    try {
      setDrawNoteAr(
        isValid(promotion.draw_note_ar)
          ? decode(promotion.draw_note_ar)
          : decode(promotion.draw_note_ar)
      );
    } catch (error) {
      setDrawNoteAr(promotion.draw_note_ar);
    }
  };
  const setPromoType = val => {
    setValue('type_id', val.value);
    setValue('tickets_per_unit', val.tickets_per_unit);
  };
  const setDealType = val => {
    setValue('type', val);
    setDealTypeVal(val.value);
  };
  const PromotionForm = (
    <Box mt={3}>
      <DevTool control={control} /> {/* set up the dev tool */}
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <Card>
          <CardHeader title="Promotion Details" />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={12} xs={12}>
                <h4 style={{ paddingBottom: '14px' }}>Promotion Title</h4>
                <Controller
                  control={control}
                  name="title"
                  render={({ onChange }) => (
                    <SunEditor
                      setContents={title}
                      onChange={data => {
                        var sectionTitle = data.replace(/<[^>]+>/g, '');
                        if (!validConsecutiveChar.test(sectionTitle)) {
                          enqueueSnackbar("Don't Enter Consecutive character", {
                            variant: 'error'
                          });
                        }
                        onChange(encode(data));
                      }}
                      setDefaultStyle="font-family: Almarai; font-size: 14px; color:#F5F8F8"
                      setOptions={{
                        buttonList: ButtonCustomList1,
                        defaultTag: 'div',
                        minHeight: '50px',
                        showPathLabel: false,
                        font: sortedFontOptions
                      }}
                    />
                  )}
                />
                {errors?.title?.message && (
                  <Typography
                    variant="caption"
                    component="p"
                    style={{ color: 'red' }}
                  >
                    {errors?.title?.message}
                  </Typography>
                )}
              </Grid>
              <Grid item md={12} xs={12}>
                <h4 style={{ paddingBottom: '14px' }}>Promotion Title (Ar.)</h4>
                <Controller
                  control={control}
                  name="title_ar"
                  render={({ onChange }) => (
                    <SunEditor
                      setContents={title_ar}
                      onChange={data => {
                        var sectionTitle = data.replace(/<[^>]+>/g, '');
                        if (!validConsecutiveChar.test(sectionTitle)) {
                          enqueueSnackbar("Don't Enter Consecutive character", {
                            variant: 'error'
                          });
                        }
                        onChange(encode(data));
                      }}
                      setDefaultStyle="font-family: Almarai; font-size: 14px; color:#F5F8F8"
                      setOptions={{
                        buttonList: ButtonCustomList1,
                        defaultTag: 'div',
                        minHeight: '50px',
                        showPathLabel: false,
                        font: sortedFontOptions,
                        rtl: true
                      }}
                    />
                  )}
                />
                {errors?.title_ar?.message && (
                  <Typography
                    variant="caption"
                    component="p"
                    style={{ color: 'red' }}
                  >
                    {errors?.title_ar?.message}
                  </Typography>
                )}
              </Grid>

              <Grid item md={4} xs={12}>
                <Typography>Deal Type</Typography>
                <Controller
                  name="type"
                  control={control}
                  render={({ onChange, value, name, ref }) => (
                    <Select
                      styles={classes.selectBox}
                      inputRef={ref}
                      options={type}
                      value={type.find(c => c.value === dealType)}
                      onChange={val => setDealType(val)}
                    />
                  )}
                />
              </Grid>
              {dealType === 'bundle' && (
                <Grid item md={8} xs={12}>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    style={{ marginTop: '26px' }}
                  >
                    <InputLabel ref={InputLabelRef} htmlFor="members">
                      Promotion Suggestion
                    </InputLabel>
                    <MaterialSelect
                      multiple
                      value={promotionSugg}
                      onChange={event => setPromotionSugg(event.target.value)}
                      input={
                        <OutlinedInput
                          labelWidth={160}
                          name="members"
                          id="members"
                        />
                      }
                      renderValue={selected => (
                        <div className={classes.chips}>
                          {selected.map(value => (
                            <Chip
                              key={value}
                              label={
                                isBase64(value.title)
                                  ? ReactHtmlParser(
                                      decode(value.title).replace(
                                        /<[^>]+>/g,
                                        ''
                                      )
                                    )
                                  : value.title
                              }
                              className={classes.chip}
                            />
                          ))}
                        </div>
                      )}
                      MenuProps={MenuProps}
                    >
                      {promotions.map(promotion => (
                        <MenuItem
                          key={promotion.promotion_id}
                          value={promotion}
                        >
                          <Checkbox
                            checked={promotionSugg.indexOf(promotion) > -1}
                          />
                          <ListItemText
                            primary={
                              isBase64(promotion?.title)
                                ? ReactHtmlParser(
                                    decode(promotion?.title).replace(
                                      /<[^>]+>/g,
                                      ''
                                    )
                                  )
                                : promotion?.title
                            }
                          />
                        </MenuItem>
                      ))}
                    </MaterialSelect>
                  </FormControl>
                </Grid>
              )}

              <Grid item md={4} xs={12}>
                <Typography>User level</Typography>
                <Controller
                  name="level"
                  options={level}
                  control={control}
                  as={Select}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <Typography>Promotion Category</Typography>
                <Controller
                  name="category_id"
                  options={categories}
                  control={control}
                  as={Select}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <Typography>Promotion Type</Typography>
                <Controller
                  control={control}
                  name="type_id"
                  render={({ onChange, value, name, ref }) => (
                    <Select
                      styles={classes.selectBox}
                      inputRef={ref}
                      options={types}
                      value={types.find(c => c.value === value)}
                      onChange={val => setPromoType(val)}
                    />
                  )}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <Typography>Select Product</Typography>
                <Controller
                  name="product_id"
                  cacheOptions
                  defaultOptions
                  loadOptions={promiseOptions}
                  control={control}
                  as={<AsyncSelect styles={classes.selectBox} />}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <Typography>Select Sponsor</Typography>
                <Controller
                  name="sponsor_id"
                  cacheOptions
                  defaultOptions
                  isClearable
                  loadOptions={sponsorsOptions}
                  control={control}
                  as={AsyncSelect}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <Typography>Select Currency</Typography>
                <Controller
                  name="currency"
                  cacheOptions
                  defaultOptions
                  isClearable
                  loadOptions={currencyOptions}
                  control={control}
                  as={AsyncSelect}
                />
              </Grid>
              <Grid item xs={12}>
                <h4 style={{ paddingBottom: '14px' }}>Description *</h4>
                <Controller
                  control={control}
                  name="description"
                  render={({ onChange }) => (
                    <SunEditor
                      setContents={desc}
                      onChange={data => {
                        var sectionTitle = data.replace(/<[^>]+>/g, '');
                        if (!validConsecutiveChar.test(sectionTitle)) {
                          enqueueSnackbar("Don't Enter Consecutive character", {
                            variant: 'error'
                          });
                        }

                        onChange(
                          encode(
                            data
                              .replaceAll(
                                '<table>',
                                '<table style="border-spacing: 0px;width: 100%;">'
                              )
                              .replaceAll(
                                '<td>',
                                '<td style="border-bottom: 1px solid #827373;padding: 12px 0px;font-size: 14px;">'
                              )
                          )
                        );
                      }}
                      setDefaultStyle="font-family: Almarai; font-size: 14px; color:#F5F8F8"
                      setOptions={{
                        buttonList: ButtonCustomList,
                        defaultTag: 'div',
                        minHeight: '200px',
                        showPathLabel: false,
                        font: sortedFontOptions
                      }}
                    />
                  )}
                />
                {errors?.description?.message && (
                  <Typography
                    variant="caption"
                    component="p"
                    style={{ color: 'red' }}
                  >
                    {errors?.description?.message}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                <h4 style={{ paddingBottom: '14px' }}>
                  Description (Arabic) (Optional)
                </h4>
                <Controller
                  control={control}
                  name="description_ar"
                  render={({ onChange }) => (
                    <SunEditor
                      setContents={descAr}
                      onChange={data => {
                        var sectionTitle = data.replace(/<[^>]+>/g, '');
                        if (!validConsecutiveChar.test(sectionTitle)) {
                          enqueueSnackbar("Don't Enter Consecutive character", {
                            variant: 'error'
                          });
                        }
                        onChange(
                          encode(
                            data
                              .replaceAll(
                                '<table>',
                                '<table style="border-spacing: 0px;width: 100%;">'
                              )
                              .replaceAll(
                                '<td>',
                                '<td style="border-bottom: 1px solid #827373;padding: 12px 0px;font-size: 14px;">'
                              )
                          )
                        );
                      }}
                      setDefaultStyle="font-family: Almarai; font-size: 14px; color:#F5F8F8"
                      setOptions={{
                        buttonList: ButtonCustomList,
                        defaultTag: 'div',
                        minHeight: '200px',
                        showPathLabel: false,
                        font: sortedFontOptions,
                        rtl: true
                      }}
                    />
                  )}
                />
                {errors?.description_ar?.message && (
                  <Typography
                    variant="caption"
                    component="p"
                    style={{ color: 'red' }}
                  >
                    {errors?.description_ar?.message}
                  </Typography>
                )}
              </Grid>
              <Grid item md={6} xs={12}>
                <Controller
                  name="promotion_charity"
                  control={control}
                  render={({ value }) => (
                    <FormControlLabel
                      name="promotion_charity"
                      onChange={e =>
                        setValue('promotion_charity', e.target.checked)
                      }
                      control={<Checkbox checked={value} />}
                      label="Promotion Charity"
                    />
                  )}
                />
                <Controller
                  name="highlight"
                  control={control}
                  render={({ value }) => (
                    <FormControlLabel
                      name="highlight"
                      onChange={e => setValue('highlight', e.target.checked)}
                      control={<Checkbox checked={value} />}
                      label="Highlight"
                    />
                  )}
                />
                <Controller
                  name="show_timer"
                  control={control}
                  render={({ value }) => (
                    <FormControlLabel
                      name="show_timer"
                      onChange={e => setValue('show_timer', e.target.checked)}
                      control={<Checkbox checked={value} />}
                      label="Show Timer"
                    />
                  )}
                />
                <Controller
                  name="discount"
                  control={control}
                  render={({ value }) => (
                    <FormControlLabel
                      name="discount"
                      onChange={e => setValue('discount', e.target.checked)}
                      control={<Checkbox checked={value} />}
                      label="Discount"
                    />
                  )}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <br></br>
        <Card>
          <CardHeader title="Ticket Details" />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={4} xs={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Controller
                    name="start_date"
                    control={control}
                    render={({ ref, ...rest }) => (
                      <KeyboardDatePicker
                        fullWidth
                        inputVariant="outlined"
                        InputLabelProps={{ shrink: true }}
                        variant="dialog"
                        format="dd/MM/yyyy"
                        label="Launch Date"
                        error={Boolean(errors?.start_date?.message)}
                        helperText={errors.start_date?.message}
                        KeyboardButtonProps={{
                          'aria-label': 'change date'
                        }}
                        {...rest}
                      />
                    )}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item md={4} xs={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Controller
                    name="draw_date"
                    control={control}
                    render={({ ref, ...rest }) => (
                      <KeyboardDatePicker
                        fullWidth
                        inputVariant="outlined"
                        variant="dialog"
                        minDate={new Date()}
                        format="dd/MM/yyyy"
                        label="Maximum Draw Date"
                        error={Boolean(errors?.draw_date?.message)}
                        helperText={errors.draw_date?.message}
                        KeyboardButtonProps={{
                          'aria-label': 'change date'
                        }}
                        {...rest}
                      />
                    )}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item md={4} xs={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Controller
                    name="expiry_date"
                    control={control}
                    render={({ ref, ...rest }) => (
                      <KeyboardDateTimePicker
                        fullWidth
                        ampm={false}
                        inputVariant="outlined"
                        variant="dialog"
                        minDate={new Date()}
                        format="dd/MM/yyyy HH:mm:ss"
                        label="Expiry Date"
                        error={Boolean(errors?.expiry_date?.message)}
                        helperText={errors.expiry_date?.message}
                        KeyboardButtonProps={{
                          'aria-label': 'change date'
                        }}
                        {...rest}
                      />
                    )}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  error={Boolean(errors?.total_tickets_alloted?.message)}
                  helperText={errors.total_tickets_alloted?.message}
                  inputRef={register}
                  label="Total Tickets Alloted"
                  name="total_tickets_alloted"
                  type="number"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  error={Boolean(errors?.tickets_per_unit?.message)}
                  helperText={errors.tickets_per_unit?.message}
                  inputRef={register}
                  label="Tickets per Unit"
                  name="tickets_per_unit"
                  type="number"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  error={Boolean(errors?.extra_tickets?.message)}
                  helperText={errors.extra_tickets?.message}
                  inputRef={register}
                  label="Extra Tickets"
                  name="extra_tickets"
                  type="number"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  error={Boolean(errors?.fresh_catch?.message)}
                  helperText={errors.fresh_catch?.message}
                  inputRef={register}
                  label="Fresh Catch percentage"
                  name="fresh_catch"
                  type="number"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item md={4} xs={6}>
                <TextField
                  error={Boolean(errors?.ticket_price_with_tax?.message)}
                  helperText={errors.ticket_price_with_tax?.message}
                  inputRef={register}
                  label="Selling price(with Tax)"
                  name="ticket_price_with_tax"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {currencylbl}
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid item md={4} xs={6}>
                <TextField
                  error={Boolean(errors?.original_price?.message)}
                  helperText={errors.original_price?.message}
                  inputRef={register}
                  label="Original price (with Tax)"
                  name="original_price"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {currencylbl}
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid item md={4} xs={6}>
                <TextField
                  error={Boolean(errors?.worth_price?.message)}
                  helperText={errors.worth_price?.message}
                  inputRef={register}
                  name="worth_price"
                  variant="outlined"
                  fullWidth
                  label="Worth Price"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {currencylbl}
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid item md={4} xs={6}>
                <TextField
                  error={Boolean(errors?.purchase_points?.message)}
                  helperText={errors.purchase_points?.message}
                  inputRef={register}
                  name="purchase_points"
                  type="number"
                  variant="outlined"
                  fullWidth
                  label="Purchase Points"
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <br></br>
        <Card>
          <CardHeader title="Upload Files" />
          <Divider />
          <CardContent>
            <Controller
              control={control}
              name="files"
              render={({ value, ref }) => (
                <DropZone
                  id="file2"
                  col={1}
                  onFilesChange={filesArray =>
                    setValue('files', filesArray, {
                      shouldValidate: true,
                      shouldDirty: true
                    })
                  }
                  initialFiles={files}
                  label="Upload Images"
                  error={errors?.files?.message}
                  inputRef={ref}
                />
              )}
            />
          </CardContent>
        </Card>
        <br></br>
        <Card>
          <CardHeader title="Draw Details" />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={12} xs={12}>
                <h4 style={{ paddingBottom: '14px' }}>Draw Title</h4>
                <Controller
                  control={control}
                  name="draw_title"
                  render={({ onChange }) => (
                    <SunEditor
                      setContents={draw_title}
                      onChange={data => {
                        var sectionTitle = data.replace(/<[^>]+>/g, '');
                        if (!validConsecutiveChar.test(sectionTitle)) {
                          enqueueSnackbar("Don't Enter Consecutive character", {
                            variant: 'error'
                          });
                        }
                        onChange(
                          encode(
                            data
                              .replaceAll(
                                '<table>',
                                '<table style="border-spacing: 0px;width: 100%;">'
                              )
                              .replaceAll(
                                '<td>',
                                '<td style="border-bottom: 1px solid #827373;padding: 12px 0px;font-size: 14px;">'
                              )
                          )
                        );
                      }}
                      setDefaultStyle="font-family: Almarai; font-size: 18px; color:#24CDE6"
                      setOptions={{
                        buttonList: ButtonCustomList1,
                        defaultTag: 'div',
                        minHeight: '100px',
                        showPathLabel: false,
                        font: sortedFontOptions,
                        maxCharCount: '34'
                      }}
                    />
                  )}
                />
                {errors.draw_title?.message && (
                  <Typography
                    variant="caption"
                    component="p"
                    style={{ color: 'red' }}
                  >
                    {errors.draw_title?.message}
                  </Typography>
                )}
              </Grid>
              <Grid item md={12} xs={12}>
                <h4 style={{ paddingBottom: '14px' }}>Draw Title (Ar.)</h4>
                <Controller
                  control={control}
                  name="draw_title_ar"
                  render={({ onChange }) => (
                    <SunEditor
                      setContents={draw_title_ar}
                      onChange={data => {
                        var sectionTitle = data.replace(/<[^>]+>/g, '');
                        if (!validConsecutiveChar.test(sectionTitle)) {
                          enqueueSnackbar("Don't Enter Consecutive character", {
                            variant: 'error'
                          });
                        }
                        onChange(
                          encode(
                            data
                              .replaceAll(
                                '<table>',
                                '<table style="border-spacing: 0px;width: 100%;">'
                              )
                              .replaceAll(
                                '<td>',
                                '<td style="border-bottom: 1px solid #827373;padding: 12px 0px;font-size: 14px;">'
                              )
                          )
                        );
                      }}
                      setDefaultStyle="font-family: Almarai; font-size: 18px; color:#24CDE6"
                      setOptions={{
                        buttonList: ButtonCustomList1,
                        defaultTag: 'div',
                        minHeight: '100px',
                        showPathLabel: false,
                        font: sortedFontOptions,
                        maxCharCount: '34',
                        rtl: true
                      }}
                    />
                  )}
                />
                {errors?.draw_title_ar?.message && (
                  <Typography
                    variant="caption"
                    component="p"
                    style={{ color: 'red' }}
                  >
                    {errors?.draw_title_ar?.message}
                  </Typography>
                )}
              </Grid>
              <Grid item md={8} xs={12}></Grid>
              <Grid item md={12} xs={12}>
                <h4 style={{ paddingBottom: '14px' }}>Draw Details *</h4>
                <Controller
                  control={control}
                  name="draw_detail"
                  render={({ onChange }) => (
                    <SunEditor
                      setContents={drawDesc}
                      onChange={data => {
                        var sectionTitle = data.replace(/<[^>]+>/g, '');
                        if (!validConsecutiveChar.test(sectionTitle)) {
                          enqueueSnackbar("Don't Enter Consecutive character", {
                            variant: 'error'
                          });
                        }
                        onChange(
                          encode(
                            data
                              .replaceAll(
                                '<table>',
                                '<table style="border-spacing: 0px;width: 100%;">'
                              )
                              .replaceAll(
                                '<td>',
                                '<td style="border-bottom: 1px solid #827373;padding: 12px 0px;font-size: 14px;">'
                              )
                          )
                        );
                      }}
                      setDefaultStyle="font-family: Almarai; font-size: 14px; color:#F5F8F8"
                      setOptions={{
                        buttonList: ButtonCustomList1,
                        defaultTag: 'div',
                        minHeight: '200px',
                        showPathLabel: false,
                        font: sortedFontOptions,
                        maxCharCount: '78'
                      }}
                    />
                  )}
                />
                {errors?.draw_detail?.message && (
                  <Typography
                    variant="caption"
                    component="p"
                    style={{ color: 'red' }}
                  >
                    {errors?.draw_detail?.message}
                  </Typography>
                )}
              </Grid>
              <Grid item md={12} xs={12}>
                <h4 style={{ paddingBottom: '14px' }}>Draw Details (Ar.) *</h4>
                <Controller
                  control={control}
                  name="draw_detail_ar"
                  render={({ onChange }) => (
                    <SunEditor
                      setContents={drawDescAr}
                      onChange={data => {
                        var sectionTitle = data.replace(/<[^>]+>/g, '');
                        if (!validConsecutiveChar.test(sectionTitle)) {
                          enqueueSnackbar("Don't Enter Consecutive character", {
                            variant: 'error'
                          });
                        }
                        onChange(
                          encode(
                            data
                              .replaceAll(
                                '<table>',
                                '<table style="border-spacing: 0px;width: 100%;">'
                              )
                              .replaceAll(
                                '<td>',
                                '<td style="border-bottom: 1px solid #827373;padding: 12px 0px;font-size: 14px;">'
                              )
                          )
                        );
                      }}
                      setDefaultStyle="font-family: Almarai; font-size: 14px; color:#F5F8F8"
                      setOptions={{
                        buttonList: ButtonCustomList1,
                        defaultTag: 'div',
                        minHeight: '200px',
                        showPathLabel: false,
                        font: sortedFontOptions,
                        maxCharCount: '78',
                        rtl: true
                      }}
                    />
                  )}
                />
                {errors?.draw_detail_ar?.message && (
                  <Typography
                    variant="caption"
                    component="p"
                    style={{ color: 'red' }}
                  >
                    {errors?.draw_detail_ar?.message}
                  </Typography>
                )}
              </Grid>
              <Grid item md={6} xs={12}>
                <Controller
                  control={control}
                  name="soldout_image"
                  render={({ value, ref }) => (
                    <DropZone
                      col={1}
                      id="soldout_image"
                      onFilesChange={filesArray =>
                        setValue('soldout_image', filesArray, {
                          shouldValidate: true,
                          shouldDirty: true
                        })
                      }
                      initialFiles={soldoutImage}
                      label="Upload Sold Out Image"
                      error={errors?.soldout_image?.message}
                      inputRef={ref}
                    />
                  )}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Controller
                  control={control}
                  name="draw_image"
                  render={({ value, ref }) => (
                    <DropZone
                      col={1}
                      onFilesChange={filesArray =>
                        setValue('draw_image', filesArray, {
                          shouldValidate: true,
                          shouldDirty: true
                        })
                      }
                      initialFiles={drawImage}
                      label="Upload Draw Image"
                      error={errors?.draw_image?.message}
                      inputRef={ref}
                    />
                  )}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <h4 style={{ paddingBottom: '14px' }}>Draw Note(En)</h4>
                <Controller
                  control={control}
                  name="draw_note"
                  render={({ onChange }) => (
                    <SunEditor
                      setContents={draw_note}
                      onChange={data => {
                        var sectionTitle = data.replace(/<[^>]+>/g, '');
                        if (!validConsecutiveChar.test(sectionTitle)) {
                          enqueueSnackbar("Don't Enter Consecutive character", {
                            variant: 'error'
                          });
                        }
                        onChange(encode(data));
                      }}
                      setDefaultStyle="font-family: Almarai; font-size: 14px; color:#F5F8F8"
                      setOptions={{
                        buttonList: ButtonCustomList1,
                        defaultTag: 'div',
                        minHeight: '50px',
                        showPathLabel: false,
                        font: sortedFontOptions
                      }}
                    />
                  )}
                />
                {errors?.draw_note?.message && (
                  <Typography
                    variant="caption"
                    component="p"
                    style={{ color: 'red' }}
                  >
                    {errors?.draw_note?.message}
                  </Typography>
                )}
              </Grid>
              <Grid item md={12} xs={12}>
                <h4 style={{ paddingBottom: '14px' }}>Draw Note(Ar)</h4>
                <Controller
                  control={control}
                  name="draw_note_ar"
                  render={({ onChange }) => (
                    <SunEditor
                      setContents={draw_note_ar}
                      onChange={data => {
                        var sectionTitle = data.replace(/<[^>]+>/g, '');
                        if (!validConsecutiveChar.test(sectionTitle)) {
                          enqueueSnackbar("Don't Enter Consecutive character", {
                            variant: 'error'
                          });
                        }
                        onChange(encode(data));
                      }}
                      setDefaultStyle="font-family: Almarai; font-size: 14px; color:#F5F8F8"
                      setOptions={{
                        buttonList: ButtonCustomList1,
                        defaultTag: 'div',
                        minHeight: '50px',
                        showPathLabel: false,
                        font: sortedFontOptions,
                        rtl: true
                      }}
                    />
                  )}
                />
                {errors?.title?.message && (
                  <Typography
                    variant="caption"
                    component="p"
                    style={{ color: 'red' }}
                  >
                    {errors?.title?.message}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <br></br>
        <Card>
          <CardHeader title="Winner Details" />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={12} xs={12}>
                <h4 style={{ paddingBottom: '14px' }}>Winner Title</h4>
                <Controller
                  control={control}
                  name="winner_title"
                  render={({ onChange }) => (
                    <SunEditor
                      setContents={winner_title}
                      onChange={data => {
                        var sectionTitle = data.replace(/<[^>]+>/g, '');
                        if (!validConsecutiveChar.test(sectionTitle)) {
                          enqueueSnackbar("Don't Enter Consecutive character", {
                            variant: 'error'
                          });
                        }
                        onChange(encode(data));
                      }}
                      setDefaultStyle="font-family: Almarai; font-size: 14px; color:#F5F8F8"
                      setOptions={{
                        buttonList: ButtonCustomList1,
                        defaultTag: 'div',
                        minHeight: '100px',
                        showPathLabel: false,
                        font: sortedFontOptions
                      }}
                    />
                  )}
                />
                {errors?.winner_title?.message && (
                  <Typography
                    variant="caption"
                    component="p"
                    style={{ color: 'red' }}
                  >
                    {errors?.winner_title?.message}
                  </Typography>
                )}
              </Grid>
              <Grid item md={12} xs={12}>
                <h4 style={{ paddingBottom: '14px' }}>Winner Title (Ar.)</h4>
                <Controller
                  control={control}
                  name="winner_title_ar"
                  render={({ onChange }) => (
                    <SunEditor
                      setContents={winner_title_ar}
                      onChange={data => {
                        var sectionTitle = data.replace(/<[^>]+>/g, '');
                        if (!validConsecutiveChar.test(sectionTitle)) {
                          enqueueSnackbar("Don't Enter Consecutive character", {
                            variant: 'error'
                          });
                        }
                        onChange(encode(data));
                      }}
                      setDefaultStyle="font-family: Almarai; font-size: 14px; color:#F5F8F8"
                      setOptions={{
                        buttonList: ButtonCustomList1,
                        defaultTag: 'div',
                        minHeight: '100px',
                        showPathLabel: false,
                        font: sortedFontOptions,
                        rtl: true
                      }}
                    />
                  )}
                />
                {errors?.winner_title_ar?.message && (
                  <Typography
                    variant="caption"
                    component="p"
                    style={{ color: 'red' }}
                  >
                    {errors?.winner_title_ar?.message}
                  </Typography>
                )}
              </Grid>
              <Grid item md={12} xs={12}>
                <h4 style={{ paddingBottom: '14px' }}>Winner Description</h4>
                <Controller
                  control={control}
                  name="winner_detail"
                  render={({ onChange }) => (
                    <SunEditor
                      setContents={winnerDetail}
                      onChange={data => {
                        var sectionTitle = data.replace(/<[^>]+>/g, '');
                        if (!validConsecutiveChar.test(sectionTitle)) {
                          enqueueSnackbar("Don't Enter Consecutive character", {
                            variant: 'error'
                          });
                        }

                        onChange(
                          encode(
                            data
                              .replaceAll(
                                '<table>',
                                '<table style="border-spacing: 0px;width: 100%;">'
                              )
                              .replaceAll(
                                '<td>',
                                '<td style="border-bottom: 1px solid #827373;padding: 12px 0px;font-size: 14px;">'
                              )
                          )
                        );
                      }}
                      setDefaultStyle="font-family: Almarai; font-size: 14px; color:#F5F8F8"
                      setOptions={{
                        buttonList: ButtonCustomList,
                        defaultTag: 'div',
                        minHeight: '200px',
                        showPathLabel: false,
                        font: sortedFontOptions
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <h4 style={{ paddingBottom: '14px' }}>
                  Winner Description (Arabic)
                </h4>
                <Controller
                  control={control}
                  name="winner_detail_ar"
                  render={({ onChange }) => (
                    <SunEditor
                      setContents={winnerDetailAr}
                      onChange={data => {
                        var sectionTitle = data.replace(/<[^>]+>/g, '');
                        if (!validConsecutiveChar.test(sectionTitle)) {
                          enqueueSnackbar("Don't Enter Consecutive character", {
                            variant: 'error'
                          });
                        }
                        onChange(
                          encode(
                            data
                              .replaceAll(
                                '<table>',
                                '<table style="border-spacing: 0px;width: 100%;">'
                              )
                              .replaceAll(
                                '<td>',
                                '<td style="border-bottom: 1px solid #827373;padding: 12px 0px;font-size: 14px;">'
                              )
                          )
                        );
                      }}
                      setDefaultStyle="font-family: Almarai; font-size: 14px; color:#F5F8F8"
                      setOptions={{
                        buttonList: ButtonCustomList,
                        defaultTag: 'div',
                        minHeight: '200px',
                        showPathLabel: false,
                        font: sortedFontOptions,
                        rtl: true
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Controller
                    name="winner_announced_on"
                    control={control}
                    render={({ ref, ...rest }) => (
                      <KeyboardDatePicker
                        fullWidth
                        inputVariant="outlined"
                        variant="dialog"
                        format="dd/MM/yyyy"
                        label="Winner Announced On"
                        InputLabelProps={{ shrink: true }}
                        error={Boolean(errors?.winner_announced_on?.message)}
                        helperText={errors.winner_announced_on?.message}
                        KeyboardButtonProps={{
                          'aria-label': 'change date'
                        }}
                        {...rest}
                      />
                    )}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item md={8} xs={12}>
                <TextField
                  error={Boolean(errors?.draw_video?.message)}
                  helperText={errors.draw_video?.message}
                  inputRef={register}
                  label="Draw Video(Youtude Code)"
                  name="draw_video"
                  type="text"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <Controller
                  control={control}
                  name="winner_image"
                  render={({ value, ref }) => (
                    <DropZone
                      col={1}
                      id="winner_image"
                      onFilesChange={filesArray =>
                        setValue('winner_image', filesArray, {
                          shouldValidate: true,
                          shouldDirty: true
                        })
                      }
                      initialFiles={winnerImage}
                      label="Upload Winner Image"
                      error={Boolean(errors?.winner_image)}
                      inputRef={ref}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
        </Card>
        <br></br>
        <Card>
          <CardHeader title="Note" />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={12} xs={12}>
                <TextField
                  inputRef={register}
                  label="Note"
                  name="note"
                  type="text"
                  variant="outlined"
                  fullWidth
                  error={Boolean(errors?.note?.message)}
                  helperText={errors.note?.message}
                />
              </Grid>
            </Grid>
          </CardContent>
          {progress || isSubmitting ? (
            <LinearProgress variant="determinate" progress={progress} />
          ) : null}
          <Box display="flex" justifyContent="flex-end" p={2}>
            <Button
              disabled={isSubmitting}
              variant="contained"
              type="submit"
              color="primary"
            >
              {progress
                ? 'Submitting'
                : promotion_id
                ? 'Update Promotion'
                : 'Add Promotion'}
            </Button>
          </Box>
        </Card>
      </form>
    </Box>
  );

  return (
    <Page className={classes.root} title="Settings">
      <Container maxWidth="lg">
        <GoBack onClick={goBack} />
        {/* {promotion_id ? (
          Object.keys(promotionEdit).length > 0 &&
          promotionEdit.constructor === Object ? (
            PromotionForm
          ) : (
            <Loader />
          )
        ) : (
          PromotionForm
        )} */}
        {PromotionForm}
      </Container>
    </Page>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    addPromotion: (
      data,
      drawImage,
      files,
      soldout_image,
      winner_image,
      action,
      promotion_id
    ) =>
      dispatch(
        actionCreators.addPromotion(
          data,
          drawImage,
          files,
          soldout_image,
          winner_image,
          action,
          promotion_id
        )
      ),
    loadProducts: (searchKey, page) =>
      dispatch(actionCreators.loadProducts(searchKey, page)),
    loadSponsors: (searchKey, page) =>
      dispatch(actionCreators.loadSponsors(searchKey, page)),
    loadCountries: () => dispatch(actionCreators.loadCountries())
  };
};

const mapStateToProps = state => {
  console.log(state);
  return {
    progress: state.progress.fileProgress,
    promotions: state.promotion.promotions,
    products: state.products.products.map(el => ({
      value: el.product_id,
      label: el?.title
    })),
    sponsors: state.sponsors.sponsors
      .filter(el => el.status === '1')
      .map(el => ({
        value: el.id,
        label: el.title
      })),
    currency: state.countries.Countries.map(el => ({
      value: el.code,
      label: el.currency
    }))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddPromotion);
