import { Types } from 'src/store/actions/actionTypes';

const initialState = {
  // Categories

  categories: [],
  filteredCategories: [],

  // Types

  filteredTypes: [],
  types: [],

  // COUPONS

  filteredCoupons: [],
  coupons: [],
  // Auctions

  auctions: [],
  filteredAuctions: [],

  //Promotion Ticket
  auctionTickets: [],
  Tickets: [],
  printPromoTickets: []
};

const auction = (state = initialState, action) => {
  const { type, payload } = action;

  switch (
    type // Categories
  ) {
    case Types.SET_AUCTION_CATEGORIES:
      return {
        ...state,
        categories: payload.categories
      };
    case Types.SET_FILTERED_AUCTION_CATEGORIES:
      return {
        ...state,
        filteredCategories: payload.filteredCategories
      };
    case Types.CLEAR_AUCTION_CATEGORIES:
      return {
        ...initialState
      };
    // Manage Auctions

    case Types.SET_AUCTIONS:
      return {
        ...state,
        auctions: state.auctions.concat(payload.auctions),
        filteredAuctions: state.auctions.concat(payload.auctions)
      };
    case Types.SET_FILTERED_AUCTIONS:
      return {
        ...state,
        filteredAuctions: payload.filteredAuctions
      };
    case Types.CLEAR_AUCTIONS:
      return {
        ...state,
        auctions: []
      };

    // Types

    case Types.SET_AUCTION_TYPES:
      return {
        ...state,
        types: payload.auctionTypes
      };
    case Types.SET_FILTERED_AUCTION_TYPES:
      return {
        ...state,
        filteredTypes: payload.filteredPromoTypes
      };
    case Types.CLEAR_AUCTION_TYPES:
      return {
        ...initialState
      };

    // Types

    case Types.SET_AUCTION_COUPONS:
      return {
        ...state,
        coupons: payload.auctionCoupons
      };
    case Types.SET_FILTERED_AUCTION_COUPONS:
      return {
        ...state,
        filteredCoupons: payload.filteredPromoCoupons
      };
    case Types.CLEAR_AUCTION_COUPONS:
      return {
        ...initialState
      };

    //Promotion Ticket
    case Types.SET_AUCTION_TICKETS:
      return {
        ...state,
        auctionTickets: state.auctionTickets.concat(payload.auctionTickets)
      };
    case Types.SET_PRINT_AUCTION_TICKETS:
      return {
        ...state,
        printPromoTickets: payload.printPromoTickets
      };
    case Types.CLEAR_FILTERED_TICKETS:
      return {
        ...state,
        auctionTickets: []
      };
    case Types.SET_TICKETS:
      return {
        ...state,
        Tickets: state.Tickets.concat(payload.Tickets)
      };
    case Types.CLEAR_TICKETS:
      return {
        ...state,
        Tickets: []
      };
    default:
      return state;
  }
};

export default auction;
