import axios from '../../services/axios';
import { Types } from 'src/store/actions/actionTypes';

/**
 * Load Categories action
 */
export const loadPromoTickets = data => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const body = {
        page: data.page,
        promotion_id: data.promotion_id,
        search: data.search
      };
      axios
        .post('get-promotion-tickets', JSON.stringify(body))
        .then(res => {
          console.log(res);
          if (res?.data?.success) {
            dispatch(setPromoTickets(res.data.data));
            dispatch(setTicket(res.data.data));
            resolve(res.data.data);
          } else {
            
            // dispatch(clearFilteredTickets());
            resolve(res.data);
          }
        })
        .catch(error => {
          console.log(error);
          if (error) {
            reject(new Error(error));
          }
        });
    });
  };
};

export const printPromoTickets = (promotionId, customerId = '') => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const body = {
        promotion_id: promotionId,
        customer_id: customerId
      };
      axios
        .post('print-promotion-tickets', JSON.stringify(body))
        .then(res => {
          console.log(res);
          if (res?.data?.success) {
            dispatch(setPrintPromoTickets(res.data.data));
            resolve();
          } else {
            reject();
          }
        })
        .catch(error => {
          console.log(error);
          if (error) {
            reject(new Error(error));
          }
        });
    });
  };
};

export const setPromoTickets = promoTickets => {
  return {
    type: Types.SET_PROMO_TICKETS,
    payload: { promoTickets }
  };
};
export const setTicket = Tickets => {
  return {
    type: Types.SET_TICKETS,
    payload: { Tickets }
  };
};
const setPrintPromoTickets = printPromoTickets => ({
  type: Types.SET_PRINT_PROMO_TICKETS,
  payload: { printPromoTickets }
});

export const clearFilteredTickets = () => ({
  type: Types.CLEAR_FILTERED_TICKETS
});

export const clearTickets = () => ({
  type: Types.CLEAR_TICKETS
});
