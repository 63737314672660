import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import { Chip, Fab, Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
// Carousel
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import {
  KeyboardArrowLeft,
  CreateRounded as EditIcon
} from '@material-ui/icons';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import moment from 'moment';
import { Edit, Gift, Trash2 as Delete } from 'react-feather';
import ConfirmDialog from 'src/components/common/dialog';
import ActiveChip from 'src/components/common/ActiveChip';
import { useNavigate } from 'react-router';
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2
} from 'react-html-parser';
import { encode, decode, isValid } from 'js-base64';

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 400,
    flexGrow: 1
  },
  imgDiv: {
    height: 255,
    width: 255,
    margin: 'auto'
  },
  img: {
    display: 'block',
    maxHeight: '100%',
    maxWidth: '100%',
    margin: 'auto'
  },
  edit: {
    marginRight: 4,
    color: 'white'
  },
  delete: {
    color: 'white',
    backgroundColor: '#f50057',
    '&:hover': {
      backgroundColor: '#ef5350'
    }
  }
}));

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const ProductCard = ({ className, product, onDeleteProduct, ...rest }) => {
  const classes = useStyles();
  const permissions = useSelector(state => state.permissions.permissions);
  const navigate = useNavigate();

  const [activeStep, setActiveStep] = useState(0);
  const [open, setOpen] = useState(false);
  const maxSteps = product.files.length;

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleStepChange = step => {
    setActiveStep(step);
  };

  const goTo = e => {
    e.preventDefault();
    navigate('/products/addProduct/' + `${product.product_id}`);
  };

  const launchDialog = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleComfirm = () => {
    //Emit Event To Parent Component
    onDeleteProduct(product.product_id, '4');
  };
  const onStatusChangeHandler = status => {
    onDeleteProduct(product.product_id, status);
  };

  return (
    <Card className={classes.root}>
      <CardHeader
        title={decode(product.title).replace(/<[^>]+>/g, '')}
        subheader={moment(product.created_at).format('Do MMM, YYYY')}
      />

      <AutoPlaySwipeableViews
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {product.files.map((step, index) => (
          <div key={index} className={classes.imgDiv}>
            {Math.abs(activeStep - index) <= product.files.length &&
            step.type === 'image' ? (
              <img className={classes.img} src={step.file} alt={step.label} />
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <MobileStepper
        steps={maxSteps}
        position="static"
        variant="dots"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            <KeyboardArrowRight />
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            <KeyboardArrowLeft />
            Back
          </Button>
        }
      />
      <CardContent>
        <Typography variant="h3" component="h3">
          AED {product.price}
        </Typography>
        <Chip
          size="small"
          color="default"
          label={product?.category?.category_name_en}
        />
      </CardContent>
      <CardActions disableSpacing>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid>
            <Chip
              color="secondary"
              label={product.max_stock + ' in stock'}
              icon={<Gift size="20" />}
            />
          </Grid>
          <Grid item>
            <ActiveChip
              status={product.status}
              onStatusChange={onStatusChangeHandler}
            />
            {permissions.content_manager == '1' || permissions.admin == '1' ? (
              <Fab
                className={classes.edit}
                size="small"
                aria-label="Edit"
                onClick={goTo}
                color="primary"
              >
                <EditIcon size="20" />
              </Fab>
            ) : (
              ''
            )}
            {permissions.content_manager == '1' || permissions.admin == '1' ? (
              <Fab
                className={classes.delete}
                size="small"
                aria-label="Delete"
                onClick={launchDialog}
              >
                <Delete size="20" />
              </Fab>
            ) : (
              ''
            )}
          </Grid>
        </Grid>
      </CardActions>
      <ConfirmDialog
        open={open}
        message="Are you sure you want to Delete ? This process cannot be undone."
        onConfirmed={handleComfirm}
        handleClose={handleClose}
      />
    </Card>
  );
};

ProductCard.propTypes = {
  className: PropTypes.string,
  product: PropTypes.object.isRequired,
  onDeleteProduct: PropTypes.func.isRequired
};

export default ProductCard;
