import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide
} from '@material-ui/core';
import { Trash2 as Delete, AlertTriangle } from 'react-feather';
import { makeStyles } from '@material-ui/styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.primary
  }
}));

export default function confirmDialog({
  open,
  message,
  confirmButtonName = 'Continue',
  onConfirmed,
  handleClose
}) {
  const classes = useStyles();
  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          <Button>
            {' '}
            <AlertTriangle size="20" className={classes.icon} /> {'Hold On !!!'}
          </Button>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={onConfirmed} color="primary" variant="contained">
            <Delete size="20" color="white" className={classes.icon}></Delete>
            {confirmButtonName}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
