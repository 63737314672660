import React, { useEffect, useState } from 'react';
import {
  Container,
  Paper,
  Card,
  CardContent,
  CardHeader,
  Grid,
  TextField,
  Typography,
  Divider,
  Button,
  CardActions,
  LinearProgress
} from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { useSnackbar } from 'notistack';

import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Select from 'react-select';

import Page from 'src/components/common/Page';
import GoBack from 'src/components/common/goBack';
// import DropZoneBanner from 'src/components/common/dropZoneBanner';
import DropZone from 'src/components/common/dropZone';
import * as actionCreators from 'src/store/actions';
import { connect, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { decode, isValid } from 'js-base64';
import ErrorMessage from 'src/components/common/errorMessage';

const validationSchema = Yup.object().shape({
  first_name: Yup.string()
    .matches(/^(?!(.)\1\1\1\1\1\1\1\1\1\1)/, {
      message: "Don't Enter Consecutive character",
      excludeEmptyString: true
    })
    .required('Sponsor Title is required'),
  first_name_ar: Yup.string().matches(/^(?!(.)\1\1\1\1\1\1\1\1\1\1)/, {
    message: "Don't Enter Consecutive character",
    excludeEmptyString: true
  }),
  description_ar: Yup.string().matches(/^(?!(.)\1\1\1\1\1\1\1\1\1\1)/, {
    message: "Don't Enter Consecutive character",
    excludeEmptyString: true
  }),
  description: Yup.string()
    .matches(/^(?!(.)\1\1\1\1\1\1\1\1\1\1)/, {
      message: "Don't Enter Consecutive character",
      excludeEmptyString: true
    })
    .required('Sponsor Description is required'),

  files: Yup.array()
    .min(1, 'Please Upload Atleast One File')
    .max(1)
    .required('Please Upload Atleast One File')
});

const initialValues = {
  first_name: '',
  first_name_ar: '',
  description: '',
  description_ar: '',
  files: [],
  files_ar: []
};

const count = 0;

const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    zIndex: '4'
  })
};

const addSponsorComponent = ({ addSponsor, progress, sponsors }) => {
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [files, setFiles] = useState([]);
  const [files_ar, setFilesArabic] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  console.log('RENDER :', count + 1);

  const { handleSubmit, register, errors, control, setValue } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'onChange'
  });
  const action = id ? 'edit' : 'add';

  useEffect(() => {
    if (id) {
      prefillForm();
    }
  }, []);

  const onSubmit = (data, e) => {
    setIsSubmitting(true);
    const body = {
      action,
      first_name: data.first_name,
      first_name_ar: data.first_name_ar,
      description: data.description,
      description_ar: data.description_ar,
      id
    };

    console.log('Submitted Form Data', body);
    addSponsor(body, data.files, data.files_ar)
      .then(msg => {
        console.log(msg);
        enqueueSnackbar(msg, {
          variant: 'success'
        });
        navigate(-1);
        setIsSubmitting(false);
        return;
      })
      .catch(err => {
        console.error(err);
        enqueueSnackbar(err, { variant: 'error' });
        setIsSubmitting(false);
      });
  };

  const onError = (errors, e) => {
    console.log(errors, e);
    setIsSubmitting(false);
  };

  const prefillForm = () => {
    if (sponsors.length < 0) {
      enqueueSnackbar('No Sponsor Found. Please come-back again', {
        variant: 'error'
      });
      navigate(-1);
      return;
    }

    const SponsorToBeEditted = sponsors.find(e => e.id == id);
    if (!SponsorToBeEditted) {
      enqueueSnackbar('Error Editting the Sponsor. Please come-back again', {
        variant: 'error'
      });
      navigate(-1);
      return;
    }

    setValue('first_name', SponsorToBeEditted.first_name);
    setValue('first_name_ar', SponsorToBeEditted.first_name_ar);
    setValue('description', SponsorToBeEditted.description);
    setValue('description_ar', SponsorToBeEditted.description_ar);
    setFiles([SponsorToBeEditted.image]);
    setValue('files', [SponsorToBeEditted.image]);
    setFilesArabic([SponsorToBeEditted.image_ar]);
    setValue('files_ar', [SponsorToBeEditted.image_ar]);
  };

  const goBack = () => navigate(-1);
  return (
    <Page title={action == 'add' ? 'Add Sponsor' : 'Edit Sponsor'}>
      <Container>
        <GoBack onClick={goBack} />
        <Card raised>
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <CardHeader
              title={action == 'add' ? 'Add Sponsor' : 'Edit Sponsor'}
            />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    label="Sponsor Title"
                    type="text"
                    name="first_name"
                    variant="outlined"
                    fullWidth
                    error={Boolean(errors?.first_name?.message)}
                    helperText={errors.first_name?.message}
                    inputRef={register}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Sponsor Title (Arabic)"
                    type="text"
                    name="first_name_ar"
                    variant="outlined"
                    fullWidth
                    error={Boolean(errors?.first_name_ar?.message)}
                    helperText={errors.first_name_ar?.message}
                    inputRef={register}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Sponsor Title"
                    type="text"
                    name="title"
                    variant="outlined"
                    fullWidth
                    error={Boolean(errors?.last_name?.message)}
                    helperText={errors.last_name?.message}
                    inputRef={register}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Sponsor Title (Arabic)"
                    type="text"
                    name="last_name_ar"
                    variant="outlined"
                    fullWidth
                    error={Boolean(errors?.last_name_ar?.message)}
                    helperText={errors.last_name_ar?.message}
                    inputRef={register}
                  />
                </Grid>
                o?.
                <Grid item xs>
                  <TextField
                    label="Sponsor Description"
                    type="text"
                    name="description"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={4}
                    error={Boolean(errors?.description?.message)}
                    helperText={errors.description?.message}
                    inputRef={register}
                  />
                </Grid>
                <Grid item xs>
                  <TextField
                    label="Sponsor Description (Arabic) (Optional)"
                    type="text"
                    name="description_ar"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={4}
                    error={Boolean(errors?.description_ar?.message)}
                    helperText={errors.description_ar?.message}
                    inputRef={register}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="files"
                    render={({ value, ref }) => (
                      <DropZone
                        id="files"
                        col={1}
                        onFilesChange={filesArray =>
                          setValue('files', filesArray, {
                            shouldValidate: true,
                            shouldDirty: true
                          })
                        }
                        initialFiles={files}
                        label="Upload English Banner"
                        error={errors?.files?.message}
                        inputRef={ref}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="files_ar"
                    render={({ value, ref }) => (
                      <DropZone
                        id="files_ar"
                        col={1}
                        onFilesChange={filesArray =>
                          setValue('files_ar', filesArray, {
                            shouldValidate: true,
                            shouldDirty: true
                          })
                        }
                        initialFiles={files_ar}
                        label="Upload Arabic Banner"
                        error={Boolean(errors?.files_ar)}
                        inputRef={ref}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}></Grid>
              </Grid>
            </CardContent>
            <Divider />
            {progress > 0 && (
              <LinearProgress variant="determinate" value={progress} />
            )}

            <CardActions>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={Boolean(progress > 0 || isSubmitting)}
              >
                {action === 'add' ? 'Add' : 'Update'} Sponsor
              </Button>
            </CardActions>
          </form>
        </Card>
      </Container>
    </Page>
  );
};

const mapStateToProps = state => {
  return {
    progress: state.progress.fileProgress,
    sponsors: state.sponsors.sponsors
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addSponsor: (body, files, files_ar) =>
      dispatch(actionCreators.addSponsor(body, files, files_ar))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(addSponsorComponent);
