import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Typography
} from '@material-ui/core';
import { useNavigate } from 'react-router';

const Toolbar = () => {
  const permissions = useSelector(state => state.permissions.permissions);
  const navigate = useNavigate();

  const handleSearch = e => {
    const searchKey = e.target.value;
    console.log(e.target.value);
  };

  const goTo = () => navigate('addBanner');

  return (
    <div>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h3">Banners</Typography>
        {permissions.content_manager == "1" || permissions.admin == "1"?<Button color="primary" variant="contained" onClick={goTo}>
          Add Banner
        </Button>:""}
      </Box>
      {/* <Box mt={3}>
        <Card>
          <CardContent>
            <Box maxWidth={500}>
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                placeholder="Search Banner by name"
                variant="outlined"
                onChange={handleSearch}
              />
            </Box>
          </CardContent>
        </Card>
      </Box> */}
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
