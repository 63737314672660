import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,  
  Typography
} from '@material-ui/core';


const Toolbar = () => { 
  return (
    <div>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h3">Statistics</Typography>       
      </Box>    
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
