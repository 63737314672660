import { Types } from 'src/store/actions/actionTypes';

const ishroCurrentUser = JSON.parse(sessionStorage.getItem('ishro_user_data'));
const ishroUserToken = sessionStorage.getItem('ishro_user_token');

const initialState =
  ishroCurrentUser && ishroUserToken
    ? { isLoggedIn: true, ishroCurrentUser, ishroUserToken }
    : {
        isLoggedIn: false,
        ishroCurrentUser: null,
        ishroUserToken: null,
        message: ''
      };

const user = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case Types.SET_USER_DATA:
      return {
        isLoggedIn: true,
        ishroCurrentUser: payload
      };
    case Types.SET_ERROR_MSG:
      return {
        isLoggedIn: false,
        ishroCurrentUser: null,
        ishroUserToken: null,
        message: payload
      };
    default:
      return state;
  }
};

export default user;
