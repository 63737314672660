import React, { useState } from 'react';
import {
  Box,
  Chip,
  TableCell,
  TableRow,
  makeStyles,
  Collapse,
  Typography,
  IconButton,
  Grid,
  Fab,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  LinearProgress
} from '@material-ui/core';
import Image from 'material-ui-image';
import {
  KeyboardArrowUpRounded as Up,
  KeyboardArrowDownRounded as Down
} from '@material-ui/icons';
import Select from 'react-select';
import { useSnackbar } from 'notistack';
import { Send } from 'react-feather';
import TicketCard from './TicketCardMinimal';
import { purple } from '@material-ui/core/colors';
import axios from 'src/services/axios';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import * as actionCreators from '../../../store/actions';
import ErrorMessage from '../../../components/common/errorMessage';
import { decode, isValid } from 'js-base64';
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2
} from 'react-html-parser';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset'
    }
  }
});

let count = 0;

const orderProductStatusOptions = [
  { value: 'Pending', label: 'Pending' },
  { value: 'Received', label: 'Received' },
  { value: 'Delivered', label: 'Delivered' }
];

const ProductCollapse = props => {
  const { product, donate, orderId, showTransferBtn, index } = props;
  const classes = useRowStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [userValue, setUserValue] = React.useState();
  const [userId, setUserId] = React.useState('');
  const [userInputValue, setUserInputValue] = React.useState('');
  const [userOptions, setUserOptions] = React.useState([]);
  const [userError, setUserError] = React.useState(false);
  const [paymentDialog, setPaymentDialog] = useState(false);
  const [status, setStatus] = useState(() =>
    orderProductStatusOptions.find(e => e.value == product.order_product_status)
  );
  const [isLoading, setIsLoading] = useState(false);
  const permissions = useSelector(state => state.permissions.permissions);
  const dispatch = useDispatch();

  const changeProductStatus = async (order_product_id, order_status) => {
    setStatus(order_status);
    setIsLoading(true);
    try {
      // Send Req.
      const resp = await axios.post(
        'change-order-product-status',
        JSON.stringify({
          order_product_id,
          order_product_status: order_status.value
        })
      );
      // Get Data
      const { success, message } = resp.data;

      // Set Loader False
      setIsLoading(false);

      // Process Data - Success
      if (success) {
        enqueueSnackbar(message, { variant: 'success' });
        // refresh('refresh');
        return;
      }

      // Process Data - Failure
      setStatus(
        orderProductStatusOptions.find(
          e => e.value == product.order_product_status
        )
      );
      enqueueSnackbar(message, { variant: 'error' });
      return;
    } catch (error) {
      // Set Loading - False and Set Status back to original
      setIsLoading(false);
      setStatus(
        orderProductStatusOptions.find(
          e => e.value == product.order_product_status
        )
      );
      enqueueSnackbar(error, { variant: 'error' });
      return;
    }
  };

  const openPaymentDialog = () => {
    setPaymentDialog(true);
  };
  const closePaymentDialog = () => {
    setPaymentDialog(false);
  };

  const loadUser = searchKey => {
    setUserOptions([]);
    setUserInputValue(searchKey);
    dispatch(actionCreators.searchCustomers(searchKey))
      .then(res => {
        var useroption = [];
        res.map(o =>
          useroption.push({
            value: o?.user_id,
            title: o?.full_name
          })
        );
        setUserOptions(useroption);
      })
      .catch(err => {
        // setPageLoader(false);
      });
  };

  const onSubmitHandler = async () => {
    try {
      if (userId != '' && userId != undefined) {
        const data = {
          type: 'product',
          order_id: orderId,
          user_id: userId.value,
          promotion_id: product.promotion_id,
          product_id: product.product_id,
          order_product_id: product.order_product_id
        };
        setIsSubmitting(true);
        const resp = await axios.post('transfer-order', {
          ...data
        });
        setIsSubmitting(false);
        if (resp.data.success) {
          enqueueSnackbar(resp.data.message, { variant: 'success' });
          setPaymentDialog(false);
          return;
        }
        enqueueSnackbar(resp.data.message, { variant: 'error' });
        setPaymentDialog(false);
        props.callback(props.index);
        return;
      } else {
        setUserError(true);
      }
    } catch (error) {
      enqueueSnackbar(error, { variant: 'error' });
      return;
    }
  };

  const isBase64 = str => {
    if (str === '' || str.trim() === '') {
      return false;
    }
    try {
      return btoa(atob(str)) == str;
    } catch (err) {
      return false;
    }
  };
  return (
    <>
      <TableRow>
        <TableCell component="th" scope="row">
          <IconButton
            aria-label="expand products"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <Up /> : <Down />}
          </IconButton>
        </TableCell>
        <TableCell align="left">
          {isBase64(product.promotion.title)
            ? ReactHtmlParser(
                decode(product.promotion.title).replace(/<[^>]+>/g, '')
              )
            : product.promotion.title}
          {' / '}
          {isBase64(product.title)
            ? decode(product.title).replace(/<[^>]+>/g, '')
            : product.title}
        </TableCell>
        <TableCell align="left">
          {donate ? (
            <Chip
              size="small"
              style={{ backgroundColor: 'rgb(0, 230, 118)' }}
              label="Donated"
            />
          ) : (
            <Select
              isLoading={isLoading}
              value={status}
              options={orderProductStatusOptions}
              onChange={data => changeProductStatus(product.product_id, data)}
            />
          )}
        </TableCell>
        <TableCell align="left">{product.product_quantity}</TableCell>
        <TableCell align="left">{product.product_total_tickets}</TableCell>
        <TableCell align="left">
          <Image
            src={product.files[0].file}
            imageStyle={{
              width: '100%',
              height: 'auto !important',
              display: 'block'
            }}
          ></Image>
        </TableCell>
        {showTransferBtn && (
          <TableCell style={{ textAlign: 'center' }}>
            <Fab
              color="primary"
              style={{
                margin: '12px 0px',
                color: 'white',
                backgroundColor: purple[300]
              }}
              aria-label=""
              size="small"
              onClick={openPaymentDialog}
            >
              <Send />
            </Fab>
          </TableCell>
        )}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h4" gutterBottom component="div">
                Tickets
              </Typography>
              <Box>
                <Grid container spacing={2}>
                  {product.tickets.map((ticket, index) => (
                    <Grid item key={index}>
                      <TicketCard
                        ticket={ticket}
                        product={product.title}
                        price={product.promotion.title}
                        date={ticket.created_at}
                        image={product.promotion.files[0].file}
                      ></TicketCard>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>

      {/* --------------------Transfer Order Dialog ------------------------------*/}
      <Dialog onClose={closePaymentDialog} open={paymentDialog} maxWidth="md">
        <DialogTitle id="customized-dialog-title">
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h3">Transfer Order</Typography>
          </Box>
        </DialogTitle>
        <DialogContent className={classes.dialog}>
          <Autocomplete
            value={userValue}
            onChange={(event, newValue) => {
              var user = userOptions.find(function(user, index) {
                if (user.title == newValue) return user.value;
              });
              user != '' ? setUserId(user) : setUserId('');
              setUserValue(newValue);
              setUserError(false);
            }}
            inputValue={userInputValue}
            onInputChange={(event, newInputValue) => {
              loadUser(newInputValue);
            }}
            id="controllable-states-demo"
            options={userOptions.map(option => option.title)}
            style={{ width: 300 }}
            renderInput={params => (
              <TextField
                {...params}
                label="User"
                variant="outlined"
                error={userError}
                required={userError}
              />
            )}
          />
          {userError && <ErrorMessage message="User is required" />}
        </DialogContent>
        {isSubmitting && <LinearProgress />}
        <DialogActions>
          <Button
            color="primary"
            disabled={isSubmitting}
            onClick={closePaymentDialog}
          >
            Cancel
          </Button>
          {permissions.admin == '1' ? (
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isSubmitting}
              onClick={onSubmitHandler}
            >
              Transfer
            </Button>
          ) : (
            ''
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ProductCollapse;
