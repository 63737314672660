import React,{useState,useEffect} from 'react';

// Material UI
import {
  Container,
  Box,Typography,Button,
} from '@material-ui/core';
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import PerfectScrollbar from 'react-perfect-scrollbar';
import Page from 'src/components/common/Page';
import {
    LicenseInfo,
    XGrid,
    GridToolbar,
    useGridApiRef
  } from '@material-ui/x-grid';
  import Loader from 'src/components/common/loader';
  import { useSnackbar } from 'notistack';
  import axios from '../../../../src/services/axios';
 import queryString from 'query-string';
 import Dialog from '@material-ui/core/Dialog';
 import DialogActions from '@material-ui/core/DialogActions';
 import DialogContent from '@material-ui/core/DialogContent';
 import DialogTitle from '@material-ui/core/DialogTitle';
 import { readRemoteFile,readString  } from 'react-papaparse';
import { Description } from '@material-ui/icons';
import moment from 'moment';

  LicenseInfo.setLicenseKey(
    '6391385253d2aae4e0d72df787edd9f0T1JERVI6MjYzNzAsRVhQSVJZPTE2NTYwNTc2OTgwMDAsS0VZVkVSU0lPTj0x'
  );
  
  var gridRow=[];
const Permit = () => {
    const apiRef = useGridApiRef();
    const [pageLoader, setPageLoader] = useState(true);
    const { enqueueSnackbar } = useSnackbar();
    const [rows, setRows] = React.useState([]);
    const [descriptionTxt, setDescriptionTxt] = useState('');  
    const [open, setOpen] = React.useState(false);
    const [arabicTxt, setArabicTxt] = React.useState(false);
    useEffect(() => { 
      gridRow=[];     
         getAccessToken();
      }, []);

      const  getAccessToken =async() => {  
         try {
          const request=require('request');
          const csv=require('csvtojson');          
          csv()
          .fromStream(request.get('https://www.dubaipulse.gov.ae/dataset/734ad997-8df2-4db0-b6c5-b8b21d0bf7f8/resource/ddc3009b-c708-4e41-a99f-2347e6dd6dc5/download/permits.csv'))
          .subscribe((json)=>{
              json.id=json.permit_number;
              gridRow.push(json);
             },onError,onComplete);
         
          } catch (error) {
            enqueueSnackbar(error, { variant: 'error' });
            return;
          }
     }
     const  onError =(results) => { console.log('Results:', results); }
     const  onComplete =(results) => { 
       setRows(gridRow); 
       setPageLoader(false);
     }
     const  showDescription =(value,arabicTxt) => { 
      setArabicTxt(arabicTxt);
      setDescriptionTxt(value); 
      setOpen(true);
      }
      const handleClose = () => {
        setOpen(false);
      };
      const columns = [        
        {
          field: 'permit_number',
          headerName: 'Permit Number',
          width: 220
        },  
        {
            field: 'advertisement_company_lice_no',
            headerName: 'Advertisement Company Lice No',
            width: 220
          },
         {
            field: 'description_ar',
            headerName: 'Description(Arabic)',
            width: 300,
            renderCell: params => (
              <p>               
                 <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{ marginRight: 16 }}
                  onClick={() =>showDescription( params.value,true)}
                >
                  Open
                </Button>
              { params.row.description_ar}
              </p>              
            )
          },
          {
            field: 'description_en',
            headerName: 'Description',
            editable: true,
            width: 300,
            renderCell: params => (
              <p>               
                 <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{ marginRight: 16 }}
                  onClick={() =>showDescription( params.value,false)}
                >
                  Open
                </Button>
              { params.value}
              </p>              
            )          
          },
          {
            field: 'license_number',
            headerName: 'License Number',
            width: 220
          },
          {
            field: 'permit_master_type_ar',
            headerName: 'Permit Master Type(Arabic)',
            width: 220
          },
          {
            field: 'permit_master_type_code',
            headerName: 'Permit Master Type Code',
            width: 220
          },
          {
            field: 'permit_master_type_en',
            headerName: 'Permit Master Type',
            width: 220
          },        
          {
            field: 'permit_subtype_code',
            headerName: 'Permit Subtype Code',
            width: 220
          },   
          {
            field: 'permit_subtype_ar',
            headerName: 'Permit Subtype(Arabic)',
            width: 220
          }
          ,     
          {
            field: 'permit_subtype_en',
            headerName: 'Permit Subtype',
            width: 220
          }
          ,
          {
            field: 'cancel_date',
            headerName: 'Cancel date',
            type: 'date',
            width: 220,
            valueGetter: params => {
              if(params.value!=""){
                var dateMomentObject = moment(params.value, "DD/MM/YYYY");
                var dateObject = dateMomentObject.toDate();
                var expDate = moment(dateObject).format('MM/DD/YYYY');
                return expDate ;
              }
              else{
                return "";
              }           
            } 
          },
          {
            field: 'expiry_date',
            headerName: 'Expiry date',
            type: 'date',
            width: 220,
            valueGetter: params => {
              if(params.value!=""){
                var dateMomentObject = moment(params.value, "DD/MM/YYYY");
                var dateObject = dateMomentObject.toDate();
                var expDate = moment(dateObject).format('MM/DD/YYYY');
                return expDate ;
              }
              else{
                return "";
              }           
            }         
          },          
        {
          field: 'issue_date',
          headerName: 'Issue date',
          type: 'date',
          width: 220,
          valueGetter: params => {
            if(params.value!=""){
              var dateMomentObject = moment(params.value, "DD/MM/YYYY");
              var dateObject = dateMomentObject.toDate();
              var expDate = moment(dateObject).format('MM/DD/YYYY');
              return expDate ;
            }
            else{
              return "";
            }           
          }  
        }
      ];

  return (
    <>
    <Box display="flex" m={3} mb={4}>
      <Box flexGrow={1}>
        <Typography variant="h3">Permit</Typography>
      </Box>
    </Box>
    {!pageLoader ? (
      <div
        style={{
          display: 'flex',
          height: '88%',
          boxShadow:
            '0 0 0 1px rgb(63 63 68 / 5%), 0 1px 2px 0 rgb(63 63 68 / 15%)',
          color: '#263238',
          transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          backgroundColor: '#fff',
          borderRadius: '4px',
          margin: '1.5%'
        }}
      >
        <div style={{ flexGrow: 1 }}>
          <XGrid
            rows={rows}
            columns={columns}
            pageSize={100}
            components={{
              Toolbar: GridToolbar
            }}
            rowHeight={83}
            checkboxSelection
            disableSelectionOnClick
            apiRef={apiRef}
            pagination           
            />
         </div>
         <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >    <DialogTitle
            id="alert-dialog-title"
            style={{ padding: '26px 32px 13px 61px' }}
          >
            <Typography color="textPrimary" variant="h3">
              {arabicTxt?'Description(Arabic)':'Description'}
            </Typography>
          </DialogTitle>          
              <DialogContent style={arabicTxt?{textAlign:'right',padding: '20px 62px'}:{textAlign:'left',padding: '20px 62px'}}>
              <Typography color="textPrimary" variant="body">
              
                {descriptionTxt}
                </Typography>
              </DialogContent>
              <DialogActions style={{ paddingBottom: '31px', paddingRight: '31px' }} >               
                <Button
                  onClick={handleClose}
                  variant="contained"
                  style={{
                    backgroundColor: '#e2e2e2',
                    color: 'rgba(0, 0, 0, 0.87)'
                  }}
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
            
      </div>
    ) : (
      <div style={{ margin: '18% 0px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center' }}>
      <div style={{display: 'flex',
      justifyContent: 'center'}}>
        <CountdownCircleTimer
          isPlaying
          duration={10}
          colors={"#24cde6"}
          onComplete={() => [true, 1000]}
        >
          {renderTime}
        </CountdownCircleTimer>
      </div>
        {/* <Loader /> */}
      </div>
    )}
  </>

  );
};


const renderTime = ({ remainingTime }) => { 

  return (
    <div className="timer" style={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'}}>
      <div style={{fontSize:'40px'}}>{remainingTime}</div>
      <div  style={{color: '#aaa'}}>Loading..</div>
    </div>
  );
};

export default Permit;