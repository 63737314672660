import axios from '../../services/axios';
import { Types } from 'src/store/actions/actionTypes';

/**
 * Load customers action
 */
export const loadCustomers = (search = '', page, userType, name = '') => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const body = {
        page: page,
        search: search,
        is_users: userType,
        name: name
        // "temp_mobile":verifyType,
        // "from_date":fromDate,
        // "to_date":toDate
      };
      axios
        .post('get-users', JSON.stringify(body))
        .then(res => {
          if (res.data.success) {
            dispatch(setCustomers(res.data.data));
            dispatch(setTotalCustomers(res.data.total_user_counts));
            resolve(res.data.data);
          } else {
            dispatch(clearCustomers());
            dispatch(clearTotalCustomers());
            resolve(res.data.message);
          }
          resolve(res.data);
        })
        .catch(error => {
          console.log(error);
          if (error) {
            reject(new Error(error));
          }
        });
    });
  };
};

export const searchCustomers = (search = '') => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const body = {
        search: search
      };
      axios
        .post('search-users', JSON.stringify(body))
        .then(res => {
          if (res.data.success) {
            resolve(res.data.data);
          } else {
            resolve(res.data.message);
          }
          resolve(res.data);
        })
        .catch(error => {
          console.log(error);
          if (error) {
            reject(new Error(error));
          }
        });
    });
  };
};
/**
 * Change status action
 */
export const changeCustomerStatus = (customers, index, status, otp) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const body = {
        user_id: customers[index].user_id,
        status: status,
        otp_code: otp
      };
      axios
        .post('user/change-status', JSON.stringify(body))
        .then(res => {
          if (res.data.success) {
            customers[index].status = status;
            dispatch(setCustomers(customers));
            resolve(res.data);
          } else {
            reject(res.data.message);
          }
        })
        .catch(error => {
          console.log(error);
          if (error) {
            reject(new Error(error));
          }
        });
    });
  };
};

/**
 * Change user type action
 */
export const changeCustomerType = (customers, index, userType) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const body = {
        user_id: customers[index].user_id,
        user_type: userType
      };
      axios
        .post('user/change-user-type', JSON.stringify(body))
        .then(res => {
          if (res.data.success) {
            customers[index].user_type = userType;
            dispatch(setCustomers(customers));
            resolve(res.data);
          } else {
            reject(res.data.message);
          }
        })
        .catch(error => {
          console.log(error);
          if (error) {
            reject(new Error(error));
          }
        });
    });
  };
};

export const changeCustomerLevel = (customers, index, userlevel) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const body = {
        user_id: customers[index].user_id,
        user_level: userlevel
      };
      axios
        .post('user/change-user-level', JSON.stringify(body))
        .then(res => {
          if (res.data.success) {
            customers[index].user_level = userlevel;
            dispatch(setCustomers(customers));
            resolve(res.data);
          } else {
            reject(res.data.message);
          }
        })
        .catch(error => {
          console.log(error);
          if (error) {
            reject(new Error(error));
          }
        });
    });
  };
};

export const getUserPermission = user_id => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const body = {
        user_id: user_id
      };
      axios
        .post('user/permissions', JSON.stringify(body))
        .then(res => {
          if (res.data.success) {
            resolve(res.data.data);
          } else {
            reject(res.data.message);
          }
        })
        .catch(error => {
          console.log(error);
          if (error) {
            reject(new Error(error));
          }
        });
    });
  };
};

export const setUserPermission = (user_id, permissions) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const body = {
        user_id: user_id,
        permissions: permissions
      };
      axios
        .post('user/change-permissions', JSON.stringify(body))
        .then(res => {
          if (res.data.success) {
            resolve(res.data.message);
          } else {
            reject(res.data.message);
          }
        })
        .catch(error => {
          console.log(error);
          if (error) {
            reject(new Error(error));
          }
        });
    });
  };
};
const setCustomers = users => ({
  type: Types.SET_CUSTOMERS,
  payload: { users }
});

const clearCustomers = () => ({
  type: Types.SET_CUSTOMERS,
  payload: {}
});

const setTotalCustomers = total => ({
  type: Types.SET_TOTAL_CUSTOMERS,
  payload: { total }
});

const clearTotalCustomers = () => ({
  type: Types.SET_TOTAL_CUSTOMERS,
  payload: {}
});
