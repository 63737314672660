import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CustomerListView from './index';
const NotACustomer = () => {
  return (
    <>
      <CustomerListView userId={'8'} />
    </>
  );
};

NotACustomer.propTypes = {
  className: PropTypes.string
};

export default NotACustomer;
