import axios from '../../services/axios';
import { saveToken, saveUserData, destroy } from 'src/services/authService';
import { Types } from 'src/store/actions/actionTypes';

/**
 * Login user action
 */
export const login = state => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const body = {
        email: state.email,
        password: state.password
      };
      axios
        .post('login', JSON.stringify(body))
        .then(resp => {
          if (resp?.data?.success) {
            // dispatch(setUserData(resp.data.data));
            // saveUserData(resp.data.data);
            // saveToken(resp.data.data.token);
            resolve(resp.data.data);
          } else {
            reject(
              resp?.data?.message ||
                'Error Logging in. Kindly, check your credentials.'
            );
          }
        })
        .catch(error => {
          if (error) {
            reject(new Error(error));
          }
        });
    });
  };
};

/**
 * Prmissions
 */
export const loadPermission = user_id => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      axios
        .post('user/permissions')
        .then(resp => {
          if (resp?.data?.success) {
            dispatch(setUserPermission(resp.data.data));
            resolve(resp.data.data);
          } else {
            reject(resp?.data?.message);
          }
        })
        .catch(error => {
          if (error) {
            reject(new Error(error));
          }
        });
    });
  };
};

/**
 * Logout action
 */
export const logout = () => dispatch => {
  destroy();
  dispatch(clearUserData());
  window.location.reload();
};

export const setProgress = progressEvent => dispatch => {
  const progress = (progressEvent.loaded / progressEvent.total) * 100;
  if (progress == 100) {
    setTimeout(() => dispatch(updateProgress('fileProgress', 0)), 100);
  } else {
    setTimeout(() => dispatch(updateProgress('fileProgress', progress)), 100);
  }
};

const setUserPermission = permissions => ({
  type: Types.SET_USER_PERMISSIONS,
  payload: permissions
});

export const setUserData = user => ({
  type: Types.SET_USER_DATA,
  payload: { user }
});

const clearUserData = () => ({
  type: Types.SET_USER_DATA,
  payload: {}
});

// Progress Bar

const updateProgress = (actionName, progress) => ({
  type: Types.SET_PROGRESS,
  payload: { actionName, progress }
});
