import 'react-perfect-scrollbar/dist/css/styles.css';
import React from 'react';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import { Provider } from 'react-redux';

import GlobalStyles from 'src/assets/styles/GlobalStyles';
import 'src/mixins/chartjs';
import theme from 'src/config/theme';
import Routes from 'src/routes';
import { store } from './store'

const App = () => {
  // const routing = useRoutes(routes);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Provider store={store}>
        <Routes />
      </Provider>
    </ThemeProvider>
  );
};

export default App;
