import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker
} from '@material-ui/pickers';
import { Search as SearchIcon } from 'react-feather';
import * as actionCreators from '../../../../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import Autocomplete from '@material-ui/lab/Autocomplete';
const useStyles = makeStyles(theme => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  }
}));

const validationSchema = Yup.object().shape({
  discount_code: Yup.string()
    .matches(/^(?!(.)\1\1\1\1\1\1\1\1\1\1)/, {
      message: "Don't Enter Consecutive character",
      excludeEmptyString: true
    })
    .max(255)
    .required('Discount Code is required'),
  discount_percent: Yup.number().required('Discount percent is Required')
});

const Toolbar = ({ className, onCatAdded, promotionCoupon, ...rest }) => {
  const dispatch = useDispatch();
  const Coupons = useSelector(state => state.promotion.coupons);
  const permissions = useSelector(state => state.permissions.permissions);
  const classes = useStyles();
  const [addCat, setAddCat] = useState(false);
  const [action, setAction] = useState('add');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [userValue, setUserValue] = React.useState();
  const [userId, setUserId] = React.useState('');
  const [userInputValue, setUserInputValue] = React.useState('');
  const [userOptions, setUserOptions] = React.useState([]);
  const [expiry_date, handleDateChange] = useState(new Date());
  const initialValues = {
    discount_code: '',
    discount_percent: ''
  };

  const { handleSubmit, register, errors, control, setValue } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'onChange'
  });

  useEffect(() => {
    if (promotionCoupon.id != undefined) {
      
      setAction('update');
      setAddCat(true);
      setTimeout(function() {
        setValue('discount_code', promotionCoupon.discount_code);
        setValue('discount_percent', promotionCoupon.discount_percent);
        handleDateChange(promotionCoupon.expiry_date);
      }, 100);
    }
  }, [promotionCoupon]);

  const handleSearch = e => {
    const searchKey = e.target.value;
    console.log(e.target.value);
    dispatch(actionCreators.filterPromoCoupons(searchKey, Coupons));
  };
  const openDialog = () => {
    setAction('add');
    setAddCat(true);
  };
  const handleClose = e => {
    console.log(e.target.value);
    setAddCat(false);
  };
  const onError = (errors, e) => console.log(errors, e);

  const onSubmit = (data, e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const body = { ...data };
    var expiry_date1 = moment(expiry_date).format('YYYY-MM-DD HH:mm:ss');
    body.expiry_date = expiry_date1;
    body.action = action;
    body.user_id = userId.value;
    body.status = '1';
    if (action == 'update') {
      body.id = promotionCoupon.id;
    }
    dispatch(actionCreators.addPromoCoupon(body))
      .then(msg => {
        setAddCat(false);
        setIsSubmitting(false);
        onCatAdded({ msg: msg, Coupon: 'success' });
      })
      .catch(err => {
        console.log(err);
        setAddCat(false);
        setIsSubmitting(false);
        onCatAdded({
          msg: 'Error Adding Promotion Discount. Error: ' + err,
          Coupon: 'error'
        });
      });
  };

  const loadUser = searchKey => {
    setUserOptions([]);
    setUserInputValue(searchKey);
    dispatch(actionCreators.searchCustomers(searchKey))
      .then(res => {
        var useroption = [];
        res.map(o =>
          useroption.push({
            value: o?.user_id,
            title: o?.full_name
          })
        );
        setUserOptions(useroption);
      })
      .catch(err => {
        // setPageLoader(false);
      });
  };
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h3">Promotion Discount Code</Typography>
        {permissions.content_manager == '1' || permissions.admin == '1' ? (
          <Button color="primary" variant="contained" onClick={openDialog}>
            Add Promotion Discount Code
          </Button>
        ) : (
          ''
        )}
      </Box>

      <Box mt={3}>
        <Card>
          <CardContent>
            <Box maxWidth={500}>
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                placeholder="Search Promotion Discount Code"
                variant="outlined"
                onChange={handleSearch}
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
      <Dialog
        open={addCat}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {action == 'add' ? 'Add' : 'Update'} Promotion Discount Code
        </DialogTitle>
        <DialogContent>
          <Autocomplete
            value={userValue}
            onChange={(event, newValue) => {
              var user = userOptions.find(function(user, index) {
                if (user.title == newValue) return user.value;
              });
              user != '' ? setUserId(user) : setUserId('');
              setUserValue(newValue);
            }}
            inputValue={userInputValue}
            onInputChange={(event, newInputValue) => {
              loadUser(newInputValue);
            }}
            id="controllable-states-demo"
            options={userOptions.map(option => option.title)}
            style={{ width: '100%' }}
            renderInput={params => (
              <TextField
                {...params}
                fullWidth
                label="User"
                variant="outlined"
                style={{ paddingBottom: '18px' }}
              />
            )}
          />
          <TextField
            error={Boolean(errors?.discount_code?.message)}
            helperText={errors?.discount_code?.message}
            inputRef={register}
            label="Discount Code"
            name="discount_code"
            type="text"
            variant="outlined"
            fullWidth
            style={{ paddingBottom: '18px' }}
            InputProps={{
              autoComplete: 'off'
            }}
            InputLabelProps={{ shrink: true }}
          />

          <TextField
            error={Boolean(errors?.discount_percent?.message)}
            helperText={errors.discount_percent?.message}
            inputRef={register}
            label="Discount Percent"
            name="discount_percent"
            type="number"
            variant="outlined"
            fullWidth
            style={{ paddingBottom: '18px' }}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>
            }}
          />
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDateTimePicker
              value={expiry_date}
              onChange={handleDateChange}
              label="Expiry Date"
              onError={console.log}
              minDate={new Date()}
              format="MM/dd/yyyy HH:mm:ss"
              name="expiry_date"
              error={Boolean(errors?.expiry_date?.message)}
              helperText={errors.expiry_date?.message}
              fullWidth
              ampm={false}
              inputVariant="outlined"
            />
          </MuiPickersUtilsProvider>
          <DialogActions>
            <Button
              disabled={isSubmitting}
              onClick={handleClose}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              disabled={isSubmitting}
              variant="contained"
              type="submit"
              color="primary"
              onClick={handleSubmit(onSubmit, onError)}
            >
              {action == 'add' ? 'Add' : 'Update'}
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
  onCatAdded: PropTypes.func
};

export default Toolbar;
