import React from 'react';
import { Fab, makeStyles } from '@material-ui/core';
import { CreateRounded } from '@material-ui/icons';
const useStyles = makeStyles(theme => ({
  EditFab: {
    color: 'white'
  }
}));

export default ({ ...props }) => {
  const classes = useStyles();
  return (
    <Fab
      {...props}
      color="primary"
      aria-label="edit"
      className={classes.EditFab}
    >
      <CreateRounded />
    </Fab>
  );
};
