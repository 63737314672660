import { Types } from 'src/store/actions/actionTypes';

const initialState = {
  products: [],
  filteredProducts: []
};

const products = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case Types.SET_PRODUCTS:
      return {
        ...state,
        products: state.products.concat(payload.products),
        filteredProducts: state.products.concat(payload.products)
      };
    case Types.SET_FILTERED_PRODUCTS:
      return {
        ...state,
        filteredProducts: payload.filteredProducts
      };
    case Types.CLEAR_PRODUCTS:
      return {
        ...state,
        products: []
      };
    default:
      return state;
  }
};

export default products;
