import React, { useState, useEffect } from 'react';
import { Container, Grid, makeStyles } from '@material-ui/core';
import Page from 'src/components/common/Page';
import Budget from './Budget';
import LatestOrders from './LatestOrders';
import LatestProducts from './LatestProducts';
import Sales from './Sales';
import TotalCustomers from './TotalCustomers';
import CurrencyFormat from 'react-currency-format';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import InsertChartOutlinedIcon from '@material-ui/icons/InsertChartOutlined';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { connect } from 'react-redux';
import * as actionCreators from '../../../../store/actions';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Dashboard = ({ loadDashboards, clearOrders, dashboard, orders }) => {
  const classes = useStyles();
  const [pageLoader, setPageLoader] = useState(true);
  const [year, setYear] = useState(new Date().getFullYear());
  useEffect(() => {
    loadDashboards(year)
      .then(() => {
        setPageLoader(false);
      })
      .catch(err => {
        setPageLoader(false);
      });
    clearOrders();
  }, [year]);

  return (
    <Page className={classes.root} title="Dashboard">
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <TotalCustomers
              title={'TOTAL USERS'}
              subtitle={'Today Users'}
              value={dashboard.dashboards.total_users}
              subvalue={dashboard.dashboards.today_users}
              icon={<PeopleIcon />}
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <TotalCustomers
              title={'TOTAL SALES'}
              subtitle={'Today Sales'}
              value={
                <CurrencyFormat
                  value={
                    dashboard.dashboards.total_sales
                      ? dashboard.dashboards.total_sales
                      : 0
                  }
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'AED '}
                  renderText={value => value}
                />
              }
              subvalue={
                <CurrencyFormat
                  value={
                    dashboard?.dashboards?.today_sales
                      ? dashboard?.dashboards?.today_sales
                      : 0
                  }
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'AED '}
                  renderText={value => value}
                />
              }
              icon={<InsertChartOutlinedIcon />}
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <TotalCustomers
              title={'TOTAL ORDERS'}
              subtitle={'Today Orders'}
              value={
                dashboard.dashboards.total_orders
                  ? dashboard.dashboards.total_orders
                  : 0
              }
              subvalue={dashboard.dashboards.today_orders}
              icon={<ShoppingCartOutlinedIcon />}
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <TotalCustomers
              title={'LAST MONTH SALES'}
              subtitle={'Last Month Orders'}
              value={
                <CurrencyFormat
                  value={
                    dashboard.dashboards.monthly_sales
                      ? dashboard.dashboards.monthly_sales
                      : 0
                  }
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'AED '}
                  renderText={value => value}
                />
              }
              subvalue={dashboard.dashboards.monthly_orders}
              icon={<DateRangeIcon />}
            />
          </Grid>

          {/* <Grid item lg={4} md={6} xl={3} xs={12}>
            <AppStoreStats
              title={'App Store Stats'} 
            />
          </Grid> */}
          {dashboard?.dashboards?.sales ? (
            <Grid item lg={12} md={12} xl={12} xs={12}>
              <Sales
                value={dashboard.dashboards.sales}
                setYearCallback={setYear}
              />
            </Grid>
          ) : (
            ''
          )}
          {/* <Grid item lg={4} md={6} xl={3} xs={12}>
            <LatestProducts />
          </Grid> */}
          {/* <Grid item lg={12} md={12} xl={12} xs={12}>
            <LatestOrders orders={orders.orders} />
          </Grid> */}
        </Grid>
      </Container>
    </Page>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    clearOrders: () => dispatch(actionCreators.clearOrders()),
    loadDashboards: year => dispatch(actionCreators.loadDashboards(year))
  };
};

const mapStateToProps = state => {
  return {
    dashboard: state.dashboard,
    orders: state.orders
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
