import React, { useEffect, useState } from 'react';
import axios from 'src/services/axios';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Divider,
  Button,
  Checkbox,
  LinearProgress,
  FormControlLabel,
  FormGroup,
  FormControl,
  FormLabel
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import 'emoji-mart/css/emoji-mart.css';
import '../../../../assets/styles/MainStyles.css';
import { CSVLink } from 'react-csv';
import { decode, isValid } from 'js-base64';
const DownloadDialog = ({ open, onClose, promotion_id, promotion_title }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [isBusiness, setBusiness] = useState('');
  const [downloadData, setDownloadData] = useState('');

  const [isUsers, setUsers] = React.useState({
    all: false,
    businessMakers: false,
    promotionBusinessMaker: false,
    test: false,
    cart: false,
    favorite: false
  });

  const {
    all,
    businessMakers,
    promotionBusinessMaker,
    test,
    cart,
    favorite
  } = isUsers;

  const handleChange = event => {
    setUsers({ ...isUsers, [event.target.name]: event.target.checked });
    if (event.target.checked == true) {
      switch (event.target.name) {
        case 'test':
          setBusiness('-1');
          break;
        case 'all':
          setBusiness('0');
          break;
        case 'businessMakers':
          setBusiness('1');
          break;
        case 'promotionBusinessMaker':
          setBusiness('2');
          break;
        case 'cart':
          setBusiness('3');
          break;
        case 'favorite':
          setBusiness('4');
          break;
        default:
          setBusiness('');
      }
    } else {
      setBusiness('');
    }
  };
  const isBase64 = str => {
    if (str === '' || str.trim() === '') {
      return false;
    }
    try {
      return btoa(atob(str)) == str;
    } catch (err) {
      return false;
    }
  };
  const onSubmitHandler = async () => {
    try {
      const data = {
        is_users: isBusiness.toString()
      };
      setIsSubmitting(true);
      const resp = await axios.post('get-users', {
        promotion_id: promotion_id,
        ...data
      });
      setIsSubmitting(false);
      if (resp.data.success) {
        setDownloadData(resp.data.data);
        enqueueSnackbar(resp.data.message, { variant: 'success' });
        // onClose();
        return;
      }
      enqueueSnackbar(resp.data.message, { variant: 'error' });
      return;
    } catch (error) {
      enqueueSnackbar(error, { variant: 'error' });
      return;
    }
  };

  const onClickHandler = () => {
    onClose();
  };

  console.log('downloadData :', downloadData);
  return (
    <Dialog
      open={open}
      onClose={onClickHandler}
      aria-labelledby="form-dialog-title"
      PaperProps={{ style: { overflowY: 'visible', width: '1000px' } }}
    >
      <DialogTitle id="form-dialog-title">Download Users List</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item md={12} xs={12} style={{ padding: '0px 0px 0px 18px' }}>
            <FormControl component="fieldset" style={{ marginTop: '12px' }}>
              <FormLabel component="legend">Users</FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={test}
                      onChange={handleChange}
                      name="test"
                    />
                  }
                  label="Test"
                  disabled={
                    promotionBusinessMaker ||
                    businessMakers ||
                    all ||
                    cart ||
                    favorite
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={all}
                      onChange={handleChange}
                      name="all"
                    />
                  }
                  label="All"
                  disabled={
                    promotionBusinessMaker ||
                    businessMakers ||
                    test ||
                    cart ||
                    favorite
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={businessMakers}
                      onChange={handleChange}
                      name="businessMakers"
                    />
                  }
                  label="Customers"
                  disabled={
                    all || promotionBusinessMaker || test || cart || favorite
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={promotionBusinessMaker}
                      onChange={handleChange}
                      name="promotionBusinessMaker"
                    />
                  }
                  label={
                    (isBase64(promotion_title)
                      ? decode(promotion_title).replace(/<[^>]+>/g, '')
                      : promotion_title) + ' Ticket Holder'
                  }
                  disabled={businessMakers || all || test || cart || favorite}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={cart}
                      onChange={handleChange}
                      name="cart"
                    />
                  }
                  label={
                    (isBase64(promotion_title)
                      ? decode(promotion_title).replace(/<[^>]+>/g, '')
                      : promotion_title) + ' In Cart'
                  }
                  disabled={
                    businessMakers ||
                    all ||
                    test ||
                    promotionBusinessMaker ||
                    favorite
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={favorite}
                      onChange={handleChange}
                      name="favorite"
                    />
                  }
                  label={
                    (isBase64(promotion_title)
                      ? decode(promotion_title).replace(/<[^>]+>/g, '')
                      : promotion_title) + ' In Favorite'
                  }
                  disabled={
                    businessMakers ||
                    all ||
                    test ||
                    promotionBusinessMaker ||
                    cart
                  }
                />
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      {isSubmitting && <LinearProgress />}
      <DialogActions>
        <Button
          disabled={isSubmitting}
          onClick={onClickHandler}
          color="primary"
        >
          {' '}
          Cancel
        </Button>
        {downloadData == '' ? (
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={isSubmitting}
            onClick={onSubmitHandler}
          >
            {' '}
            Process{' '}
          </Button>
        ) : (
          <CSVLink data={downloadData}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={false}
            >
              Download{' '}
            </Button>
          </CSVLink>
        )}
      </DialogActions>
    </Dialog>
  );
};

DownloadDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  promotion_id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired
};

export default DownloadDialog;
