import { Typography } from '@material-ui/core';
import zIndex from '@material-ui/core/styles/zIndex';
import React from 'react';
import Loader from 'react-loader-spinner';

function BubbleLoader(props) {
  const { status } = props;
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        position: 'inherit',
        margin: '20% 0'
      }}
    >
      {status ? (
        <Typography variant="h3" color="textSecondary">
          {status}
        </Typography>
      ) : (
        <Loader
          type="ThreeDots"
          color="#26cde6"
          height={100}
          width={100}
          timeout={200000} //3 secs
        />
      )}
    </div>
  );
}

export default BubbleLoader;
